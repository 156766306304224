import { roundToTwo, log, debug, warn, generateAssetUrl } from "global.js";
import { isDef } from 'validators.mjs'
import { ViewGameboard } from 'UI/ViewGameboard.mjs'
import { CustomElement } from 'UI/CustomElement.mjs'
import { Canvas } from 'UI/Canvas.mjs'

import { Door } from 'Thing/Door.mjs'
import { Item } from 'Thing/Item.mjs'
import { Npc, Monster, Character } from 'Thing/Characters.mjs'

import { Tile } from 'Board/Tile.mjs'
import { Marker, DoorMarker, ItemMarker, CharacterMarker } from 'Board/Markers.mjs'

/**
 * Board controls the in-game board, which is represented by the BoardElement
 *
 * let m = new Board(parentElement) -> use parentElement
 *
 */
export class Board extends CustomElement {

	static templateString;
	static padding = 0; // TODO: fix this - resize doesn't work if over 0

	constructor() {
		super()
	}

	setup(viewWidth, viewHeight, boardWidth, boardHeight, referenceTileWidth, referenceTileHeight, boardContentScale) {
		if ( !boardWidth || !boardHeight ) { throw "Board: Invalid width or height parameter" }

		this.idCounter = 0
		this.tiles = {}
		this.markers = {}
		this.generics = {}
		this.allowInteractions = false

		this.canvasScale = 0 // actual scale value for css style on canvas - see resizeScales()
		this.referenceScale = 0 // view scale that fits a tile nicely - see calculateReferenceScale()
		this.boardScale = 0 // view scale that 'contains' the whole board - see calculateBoardScale()

		this.viewWidth = viewWidth
		this.viewHeight = viewHeight

		this.boardContentScale = boardContentScale

		if ( this.boardContentScale != 1 ) {
			this.boardWidth = Math.round(boardWidth * this.boardContentScale)
			this.boardHeight = Math.round(boardHeight * this.boardContentScale)
			this.referenceTileWidth = Math.round(referenceTileWidth * this.boardContentScale)
			this.referenceTileHeight = Math.round(referenceTileHeight * this.boardContentScale)
		} else {
			this.boardWidth = boardWidth
			this.boardHeight = boardHeight
			this.referenceTileWidth = referenceTileWidth
			this.referenceTileHeight = referenceTileHeight
		}

		this.resizeScales()

		this.canvasScale = this.calculateCanvasScale()

 		this.view = this.getRootNode().host

 		if ( ! (this.view instanceof ViewGameboard) ) {
 			throw `Board.setup(): can't find parent view element. Got: ${ this.view } with tag: ${ this.view.tagName }`
 		}

 		this.canvas = new Canvas(this, this.shadowRoot.querySelector('canvas'))
	}

	resize(viewWidth, viewHeight) {
		this.viewWidth = viewWidth
		this.viewHeight = viewHeight
		this.resizeScales()

		this.canvas.resizeVisibleElement()
	}

	resizeScales() {
		this.referenceScale = this.calculateReferenceScale()
		this.boardScale = this.calculateBoardScale()

		// just in case the board size is smaller than the reference...
		this.canvasScale = this.calculateCanvasScale()

	}

	calculateCanvasScale() {
		// tile is a bit over half the height of the view (assume landscape)
		return Math.max(0.4, roundToTwo(this.viewHeight / 1.75 / this.referenceTileHeight))
	}

	/**
	 * Calculates reference zoom scale multiplier for the canvas,
	 * that will fit the typical tile ("reference tile") into the
	 * view when centered.
	 *
	 * @return {decimal} multiplier rounded to two decimals
	 */
	calculateReferenceScale() {
		// add 40% empty space padding around the tile
		let visibleAreaWidth = this.referenceTileWidth * 1.5
		let visibleAreaHeight = this.referenceTileHeight * 1.75

		let scaleWidth = this.viewWidth / visibleAreaWidth
		let scaleHeight = this.viewHeight / visibleAreaHeight

//		debug("Ref scale: " + scaleWidth + " –or– " + scaleHeight)
		let multiplier = Math.min(scaleWidth, scaleHeight)

		return roundToTwo(multiplier)
	}

	/**
	 * Calculated maximum zoom scale for the canvas, so that
	 * the board will always cover the view.
	 *
	 * @return {decimal} multiplier rounded to two decimals
	 */
	calculateBoardScale() {
		let scaleWidth = this.viewWidth / this.boardWidth
		let scaleHeight = this.viewHeight / this.boardHeight

//		debug("Board scale: " + scaleWidth + " –or– " + scaleHeight)

		let multiplier = Math.min(scaleWidth, scaleHeight)

		return roundToTwo(multiplier)
	}

	scaleToCanvas(x, y) {
		return [ Math.round( x / this.canvasScale ), Math.round( y / this.canvasScale )]
	}

	/**
	 * Allow user to interact with the board:
	 * – scroll board
	 * – click on markers
	 */
	enableInteraction() { this.allowInteractions = true }
	disableInteraction() { this.allowInteractions = false }


	startDrawLoop() {
		this.drawLoopTimestamp = null
		this.canvasModified = true

		let board = this
		this.canvasDrawLoop = window.requestAnimationFrame(function(timestamp) {
			board.drawLoop(timestamp)
		})
	}

	stopDrawLoop() {
		this.drawLoopTimestamp = null
		window.cancelAnimationFrame(this.canvasDrawLoop)
	}


	/**
	 * Callback for startDrawLoop()
	 *
	 * If you want to call this without
	 *
	 * @param {DOMHighResTimeStamp} timestamp
	 */
	async drawLoop(timestamp) {
		if ( this.canvasModified ) {
			// this looks silly but canvasModified is set to true outside of
			// the drawLoop, so it may be 'true' after: await this.draw()
			this.canvasModified = false
			let redraw = await this.draw(timestamp)

			this.canvasModified = redraw || this.canvasModified
			this.drawLoopTimestamp = timestamp
		}

		let board = this
		this.canvasDrawLoop = window.requestAnimationFrame(function(timestamp) {
			board.drawLoop(timestamp)
		})
	}

	/**
	 * Clears entire canvas and draws the Board and markers (expecting a clear canvas!)
	 *
	 *
	 * If the draw() is called from requestAnimationFrame(), pass it's timestamp to make sure
	 * animations happen in sync.
	 *
	 * If the draw() is called somewhere else, give performance.now()
	 *
	 * @param {DOMHighResTimeStamp} timestamp
	 */
	async draw(timestamp) {

		this.clearCanvasModifiedOnly()

		let redraw = 0

		let animated = this.listAnimatedElements()
		if ( animated.length > 0 ) {
			redraw += await this.animate(animated, timestamp)
		}

		let tiles = this.listTiles()
		if ( tiles.length > 0 ) {
			redraw += await this.drawTiles(tiles)
		}

		let markers = this.listMarkers()
		if ( markers.length > 0 ) {
			redraw += await this.drawMarkers(markers)
		}

		return redraw > 0
	}

	clearCanvas() {
		this.canvas.clear()
	}

	// Chrome works fast without this, but Safari is noticably slow
	// on drawing speed with requestAnimationFrame() and full clear()
	// - added Jan 2024
	clearCanvasModifiedOnly() {
		let all = [].concat(this.listAnimatedElements(), this.listTiles(), this.listMarkers())

		let lx, ly, rx, ry;

		for ( let thing of all ) {
			if ( !thing ) { continue }

			let leftX = Math.round(thing.cx - thing.width/2.0)
			let leftY = Math.round(thing.cy - thing.height/2.0)
			let rightX = Math.round(thing.cx + thing.width/2.0)
			let rightY = Math.round(thing.cy + thing.height/2.0)

			if ( lx == undefined || lx > leftX ) { lx = leftX }
			if ( ly == undefined || ly > leftY ) { ly = leftY }
			if ( rx == undefined || rx < leftX ) { rx = leftX }
			if ( ry == undefined || ry < leftY ) { ry = leftY }
		}

		this.canvas.clear(lx, ly, rx, ry)
	}

	/**
	 * Recalculates next animation states before next drawing
	 *
	 * Types: fadeIn, fadeOut, redraw
	 *
	 * This begins animation if element has field animate: { ...} defined.
	 *
	 * After the animation is finished, it will remove the field.
	 *
	 *
	 *
	 * @param {array} list of elements to animate
	 * @param {DOMHighResTimeStamp} timestamp or 0
	 */
	async animate(list, timestamp) {
		let needRedraw = false

		for ( const element of list ) {
			if ( !element.animate ) { continue }

			// start time on first step
			if ( !element.animate.start ) { element.animate.start = timestamp }

			// default animation length is 500ms
			if ( element.animate.length == null ) { element.animate.length = 500 }


			let { type, value, start, length, callback } = element.animate

			if ( !type ) {
				log(`Board.animate: element ${ element.id } animation parameters missing – removing it`)
				delete element.animate
				continue
			}

			if ( type == "fadeIn" ) {
				//(let t = element.animate.value + 0.03 || 0
				let t = parseFloat(Math.min( (timestamp - start)/length, 1.0)).toFixed(2)

				if ( t == element.animate.value ) { continue }

				element.animate.value = t
				element.opacity = t

				if ( t >= 1 ) {
					delete element.opacity
				} else {
					needRedraw = true
					continue
				}
			}

			if ( type == "fadeOut" ) {
				//let t = element.animate.value - 0.03 || 1
				let t = parseFloat(1 - Math.min( (timestamp - start)/length, 1.0)).toFixed(2)

				if ( t == element.animate.value ) { continue }

				element.animate.value = t
				element.opacity = t

				if ( t <= 0 ) {
					delete element.opacity

				} else {
					needRedraw = true
					continue
				}
			}

			if ( type == "redraw" ) {
				if ( element.redraw == 0 ) {
					element.redraw += 1
					needRedraw = true
					continue
				} else {
					delete element.redraw
				}
			}

			if ( callback ) { callback() }

			delete element.animate
		}

		return needRedraw
	}


	listAnimatedElements() {
		let list = []

		for ( const id in this.tiles ) {
			if ( this.tiles[id].animate ) { list.push(this.tiles[id]) }
		}

		for ( const id in this.markers ) {
			if ( this.markers[id].animate ) { list.push(this.markers[id]) }
		}

		for ( const id in this.generics ) {
			if ( this.generics[id].animate ) { list.push(this.generics[id]) }
		}

		return list
	}


	/**
	 * @param {array} tiles to draw
	 */
	async drawTiles(tiles) {
		let needRedraw = 0

		for ( const tile of tiles ) {
			let image = tile.assets.tile.data()
			if ( image instanceof Promise ) { image = await image }

			if ( tile.cx == null || tile.cy == null || tile.width == null || tile.height == null ) {
				throw `Board.drawTiles: tile doesn't have all stuff x,y,w,h: ${tile.cx},${tile.cy},${tile.width},${tile.height}`
			}
			this.canvas.drawImage(image, {
				cx: tile.cx,
				cy: tile.cy,
				width: tile.width,
				height: tile.height,
				opacity: tile.opacity
			})

			if ( tile.animate ) { needRedraw += 1 }
		}

		return needRedraw
	}


	listTiles() {
		let list = []

		for ( const id in this.tiles ) {
			let tile = this.tiles[id]

//			if ( tile.visible ) {
				list.push(tile)
//			}
		}

		return list
	}


	/**
	 * Typical usage: get list of visible tiles after drawTiles()
	 *
	 *
	 * @param {array} markers to draw
	 */
	async drawMarkers(markers) {
		let needRedraw = 0
		for ( const marker of markers ) {

			let image
			let width
			let height

			if ( marker.focus ) {
				image = marker.assets.markerFocus.data()
				if ( image instanceof Promise ) { image = await image }

				width = marker.assets.markerFocus.width
				height = marker.assets.markerFocus.height

			} else {
				image = marker.assets.marker.data()
				if ( image instanceof Promise ) { image = await image }

				width = marker.assets.marker.width
				height = marker.assets.marker.height
			}

			this.canvas.drawImage(image, {
				cx: marker.cx,
				cy: marker.cy,
				width: marker.width,
				height: marker.height,
				opacity: marker.opacity
			})

			if ( marker.animate ) { needRedraw += 1 }
		}

		return needRedraw
	}

	listMarkers() {
		let list = []

		for ( const id in this.markers ) {
			let marker = this.markers[id]

			if ( marker.shouldBeVisible() ) {
				list.push(marker)
			} else {
				//console.log("marker not visible: " + id)
			}
		}

		return list
	}

	/**
	 * @param {Space} [space] Starting position
	 */
	async showBoard(space) {
		this.canvas.setup()

		this.startDrawLoop()

		if ( space ) {
			await this.view.scrollTo(space, false)
		}

		this.canvas.show()
	}

	hideBoard() {
		this.stopDrawLoop()

		this.canvas.clear()
	}

	resetBoard() {
		this.tiles = {}
		this.markers = {}
		this.generics = {}

		this.canvas.clear()
	}


	/**
	 * Note: use thing.getIdForBoard() for this
	 *
	 * @param string boardId from thing.getIfForBoard()
	 */
	hasThing(boardId) {
		return this.markers[boardId] != null
	}

	/**
	 * Note: use thing.getIdForBoard() for this
	 *
	 * @param string boardId from thing.getIfForBoard()
	 */
	hasMarker(boardId) {
		return this.markers[boardId] != null
	}

	/**
	 * Note: use space.getIdForBoard() for this
	 *
	 * @param string boardId from space.getIfForBoard()
	 */
	hasSpace(boardId) {
		return this.tiles[boardId] != null
	}


	/**
	 * Adds the space's tile to board.
	 *
	 * Note: doesn't wait for animation to complete.
	 *
	 * @param {Space} space
	 * @param {boolean} animate
	 */
	async addSpace(space, animate) {

		let boardId = space.getIdForBoard()

		if ( this.tiles[ boardId ] ) { throw `Board.addSpace: tile with id '${ boardId }' already exists.` }

		let asset = space.getTileAsset()
		let tile = new Tile(this, boardId, { tile: asset }, space.cx, space.cy)

		this.tiles[ boardId ] = tile
		this.canvasModified = true

		if ( animate ) {
			await tile.setAnimation("fadeIn")
		}

		return this
	}

	/**
	 * Remove the space's tile from the board.
	 *
	 * Note: doesn't wait for animation to complete.
	 *
	 * @param {Space} space
	 * @param {boolean} animate
	 */
	async removeSpace(space, animate) {

		let boardId = space.getIdForBoard()

		throw `This is propably borken - awaiting animation and delete`

		if ( !this.tiles[ boardId ] ) { throw `Board.removeSpace: tile with id '${boardId}' doesn't exist.` }

		let tile = this.tiles[boardId]
		this.canvasModified = true

		if ( animate ) {
			await tile.setAnimation("fadeOut")
		}

		delete this.tiles[ boardId ]

		return this
	}


	/**
	 * Add thing on board. If it doesn't have a marker yet, it will
	 * be created.
	 *
	 * @param {Thing} thing
	 * @param {boolean} animate
	 */
	async addThing(thing, animate) {

		let boardId = thing.getIdForBoard()

		let marker = this.createOrGetMarkerForThing(thing, boardId)

		this.markers[ boardId ] = marker

		this.canvasModified = true

		if ( marker.shouldBeVisible() && animate ) {
			await marker.setAnimation("fadeIn")
		}

		return this
	}

	createOrGetMarkerForThing(thing, boardId) {

		if ( this.markers[boardId] ) {
			console.warn(`Board.addThing: marker with id '${boardId}' already exists - returning it`)
			return this.markers[boardId]
		}

		if ( !isDef(thing.cx) || !isDef(thing.cy) ) {
			throw `Board.addThing() thing '${thing.id} does not have (x, y) position`
		}

		if ( !isDef(thing.getSpaceName()) ) {
			throw `Board.addThing() thing '${thing.id} does not have 'space'`
		}

		let assets = thing.getMarkerAssets()

		let marker

/*
		switch (thing.constructor.name) {
			case "Door":
				marker = new DoorMarker(this, boardId, assets, thing.cx, thing.cy)
				break
			case "Npc":
				marker = new CharacterMarker(this, boardId, assets, thing.cx, thing.cy)
				break
			case "Monster":
				marker = new CharacterMarker(this, boardId, assets, thing.cx, thing.cy)
				break
			case "Item":
				marker = new ItemMarker(this, boardId, assets, thing.cx, thing.cy)
				break
			default:
				warn(`Board.addThing: fallback to default marker for: ${thing.constructor.name}`)
				marker = new Marker(this, boardId, assets, thing.cx, thing.cy)
		}
*/

		if ( thing instanceof Door ) {
			marker = new DoorMarker(this, boardId, assets, thing.cx, thing.cy)
		}
		else if ( thing instanceof Npc ) {
			marker = new CharacterMarker(this, boardId, assets, thing.cx, thing.cy)
		}
		else if ( thing instanceof Monster ) {
			marker = new CharacterMarker(this, boardId, assets, thing.cx, thing.cy)
		}
		else if ( thing instanceof Item ) {
			marker = new ItemMarker(this, boardId, assets, thing.cx, thing.cy)
		} else {
			warn(`Board.addThing: fallback to default marker for: ${thing.constructor.name}`)
			marker = new Marker(this, boardId, assets, thing.cx, thing.cy)
		}

		marker.linkThing(thing)

		return marker
	}


	/**
	 * Remove the Thing from the board.
	 *
	 * Note: Doesn't wait for the animation to complete.
	 *
	 * @param {Thing} thing
	 * @param {boolean} animate
	 */
	async removeThing(thing, animate) {

		let boardId = thing.getIdForBoard()

		if ( !this.markers[ boardId ] ) {
			warn(`Board.removeThing: marker with id '${boardId}' doesn't exist - not doing anything`)
			return this
		}

		let marker = this.markers[boardId]

		this.canvasModified = true

		if ( animate ) {
			await marker.setAnimation("fadeOut")
		}

		delete this.markers[ boardId ]

		return this
	}

	generateId() {
		return ++this.idCounter
	}

	getCanvas() {
		return this.canvas
	}

	/**
	 *  Returns the element in coordinates (x, y). E.g. for mouse click
	 * @param {integer} x
	 * @param {integer} y
	 * @param {string} type ("interactive"|"all")
	 */
	getElementAt(x, y, type) {
		let elements = []
		if ( type === undefined ) { type = 'all' }

		if ( type == "interactive" || type == "all" ) {
			for ( let m of this.listMarkers() ) {
				if ( m.areYouAt(x, y) ) {
					elements.push(m)
				}
			}
		}

		if ( type == "all" ) {
			for ( let t of this.listTiles() ) {
				if ( t.areYouAt(x, y) ) {
					elements.push(t)
				}
			}
		}

		return elements.length > 0 ? elements[0] : null
	}

	setPointerInteractive() {
		this.canvas.canvasElement.classList.add("pointer")
	}

	setPointerDragging() {
		this.canvas.canvasElement.classList.add("dragging")
	}

	setPointerDefault() {
		this.canvas.canvasElement.classList.remove("pointer")
		this.canvas.canvasElement.classList.remove("dragging")
	}
}

import importMainStyles from "css/import-main.css.mjs";

Board.templateString = `

<style>
	${ importMainStyles }

	:host {
		display: inline-block;
		padding: ${ Board.padding }px;
	}

	:host.cursor-interactive {}

	canvas {
		background: var(--board-background, #000000 url('${generateAssetUrl('media/general/board-grid-tile@1x.png')}') repeat);
		visibility: hidden;
		cursor: grab;
	}

	canvas.pointer {
		cursor: pointer;
	}

	canvas.dragging {
		cursor: grabbing;
	}

	canvas.visible {
		visibility: visible;
	}

</style>
<canvas></canvas>

`;

window.customElements.define('x-board', Board)
