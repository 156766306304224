/**
 * Abstract class for ViewDoor etc.
 *
 */

import { debug, log, warn, error} from 'global.js'
import { isDef } from 'validators.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'

export class ViewAction extends ViewLayer {

	static templateString

	constructor(uiTemplateString) {
		super()

		this.uiTemplateString = uiTemplateString
	}

	setup(uiTemplateString) {

		if ( isDef(uiTemplateString) ) { this.uiTemplateString = uiTemplateString }

		if ( !this.uiTemplateString ) { throw `ViewAction.setup() member 'uiTemplateString' is not defined. `}

		this.backdrop = this.querySelector('#backdrop') || warn("ViewAction can't find #backdrop")
		this.effects = this.querySelector('#effects') || warn("ViewAction can't find #effects")
		this.slides = this.querySelector('#slides') || warn("ViewAction can't find #slides")

		if ( this.uiTemplateString ) {
			this.ui = this.querySelector('#ui') || warn("ViewAction can't find #ui")

			this.ui.setContentFromTemplateString(this.uiTemplateString)

		} else {
			debug("ViewAction created without constructor parameter - this is an abstract class and shouldn't be used without inheritance.")
		}
	}

	async showBackdropLayer(animate, speed) {
		await this.backdrop.show(animate, speed)
	}

	async hideBackdropLayer(animate, speed) {
		await this.backdrop.hide(animate, speed)
	}

	async showSlideshowLayer(animate, speed) {
		await this.slides.show(animate, speed)
	}

	async hideSlideshowLayer(animate, speed) {
		await this.slides.hide(animate, speed)
	}

	async showUiLayer(animate, speed) {
		await this.ui.show(animate, speed)
	}

	async hideUiLayer(animate, speed) {
		await this.ui.hide(animate, speed)
	}

	async showEffectLayer(animate, speed) {
		await this.effect.show(animate, speed)
	}

	async hideEffectLayer(animate, speed) {
		await this.effect.hide(animate, speed)
	}

	updateBackdrop(asset) {
		this.updateBackgroundImageOnElement(this.backdrop, asset)
	}

	updateBackgroundImageOnElement(element, asset) {
		if (asset.type == 'image') {
			element.style.backgroundImage = `url('${asset.url}')`

			if ( asset.style ) {
				for ( let k in asset.style ) {
					element.style[k] = asset.style[k]
				}
			}

		} else {
			throw `ViewAction.updateBackgroundImageOnElement() no asset`
		}
	}

	async playEffect(effectName) {
		log(`TODO: play effect`)
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewAction.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }

	view-content {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		background: none;
	}

	#slides {
		margin: 15vh 15vw;
	}

	#backdrop {
    background: var(--view-default-bg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
	}

	#ui {
		padding: 10vmax;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
</style>
<view-content id="backdrop" style="opacity: 0"></view-content>
<view-content id="effects" style="opacity: 0"></view-content>
<view-slideshow id="slides" style="opacity: 0"></view-slideshow>
<view-content id="ui" style="opacity: 0"></view-content>

`;

window.customElements.define('view-action', ViewAction)
