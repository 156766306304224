import { toNumber, sleep, error, warn, log, debug } from 'global.js'
import { isString } from 'validators.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'

import { Game } from 'Game/Game.mjs'

import { ActionEvent } from 'Command/EventsAction.mjs'

import { ACTION_SEPARATOR } from 'UI/UI.mjs'

export class ViewCustom extends ViewLayer {

    static templateString

    name;
    choices;
    waitForHide;

    constructor() {
        super()
    }

    /**
     * Opens the custom view with content from template 'name'
     *
     * Does not return unless returnAfterShow is true.
     *
     * @param {string} name of the custom content
     * @param {string} [variable] (optional) name of the default variable
     * @param {boolean} [returnAfterShow] (optional) default false
     * @param {boolean} [waitForHideDuringAction] (optional) default false
     * @param {boolean} [animate] (optional)
     * @param {object} [variables] (optional)
     */
    show(name, variable, returnAfterShow, waitForHideDuringAction, animate, variables) {
        if (!isString(name)) { error(`ViewCustom.show() 'name' is not a string. Got: ${name}`)}

        this.name = name
        this.variable = variable
        this.choices = []
        this.returnAfterShow = returnAfterShow
        this.waitForHide = waitForHideDuringAction || false

        this.clearCallbacks()
        this.returnCallback = null

        let selector = `template.custom[name="${name}"][language="${  Game.global.getLanguage() }"]`

        if ( Game.global.getTemplateWithSelector(selector, variables) == null ) {
            throw `ViewCustom.show() can't find template with: ${selector}`
        }

        return new Promise(async (resolve, reject) => {

            const templateElement = this.template()
            this.setContentFromTemplateElement(templateElement, null, variables, false)

            const contentElement = Game.global.getTemplateWithSelector(selector, variables)
            this.setContentFromTemplateElement(contentElement, null, variables, true)

            this.returnCallback = resolve

            if ( contentElement.classList.contains('very-slow') ) {
                this.classList.add("very-slow")
            } else {
                this.classList.remove("very-slow")
            }

            if ( contentElement.classList.contains('slow') ) {
                this.classList.add("slow")
            } else {
                this.classList.remove("slow")
            }

            if ( contentElement.classList.contains('fast') ) {
                this.classList.add("fast")
            } else {
                this.classList.remove("fast")
            }

            await super.show(animate)

            if ( returnAfterShow ) {
                this.returnCallback()
            }
        })
    }

    async hide(animate) {

        await super.hide(animate)

        if ( this.returnCallback ) {
            this.returnCallback()

            delete this.returnCallback
        }

        delete this.name
    }

    async action(listOfActions, element, event) {

        let feedback = false

        let name = element?.getAttribute?.('name')
        let value = element?.getAttribute?.('value')
        let choice = element?.getAttribute?.('choice')
        let variableName = element?.getAttribute?.('variable')

        if ( choice && !this.choices.includes(choice) ) {
            this.choices.push(choice)
            feedback = true
        }

        if ( variableName ) {
            Game.global.setGlobalVariable(variableName, value)
            feedback = true
        }

        if ( listOfActions ) {
            for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {

                if ( action == "hide" ) {
                    if ( this.variable && value ) {
                        Game.global.setGlobalVariable(this.variable, value)
                        feedback = true
                    }

                    if ( this.waitForHide ) {
                        await this.hide(Game.global.getSettingAnimate())
                    } else {
                        this.hide(Game.global.getSettingAnimate())
                    }

                    feedback = true
                }

                if ( this.hasAction(action) ) {
                    this.callAction(action, element, event)
                    feedback = true
                }

                if ( name ) {
                    Game.global.actionEvent(name, value, this.choices)
                } else {
                    Game.global.actionEvent(action, value, this.choices)
                }
            }
        }

        return feedback
    }
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewCustom.templateString = `
<style>
    ${ importMainStyles }
    ${ importViewLayerStyles }
</style>
`;

window.customElements.define('view-custom', ViewCustom)
