import { sleep, log, warn, error, debug } from 'global.js'
import { isObject, isString, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

export const CHARACTERS_ALL_PLAYERS = "all-players"

export class Dialogue {
	constructor(id, specs) {
		if ( !isString(id) ) {
			throw `Dialogue.constructor() argument 'id' is not a string. Got: ${ id }`
		}

		if ( !isObject(specs) ) {
			throw `Dialogue.constructor() argument 'specs' is not an object. Got: ${ specs }`
		}

		if ( !isString(specs.characters) && !isArray(specs.characters) ) {
			throw `Dialogue.constructor() 'characters' in specs is not a String nor Array. Got: ${ specs.characters }`
		}

		if ( !isString(specs.template) ) {
			throw `Dialogue.constructor() 'template' in specs is not a string. Got: ${ specs.template }`
		}

		this.id = id

		if ( isString(specs.characters) ) {

			if ( specs.characters == CHARACTERS_ALL_PLAYERS ) {
				this.characterIds = Game.global.getListOfPlayerIds()
			} else {
				throw `Dialogue.constructor() unknown string for 'characters': ${specs.characters}`
			}

		} else {
			this.characterIds = specs.characters
		}


		this.templateId = specs.template
		this.available = specs.available

		this.choices = []
	}

	enable() {
		this.available = true
	}

	disable() {
		this.available = false
	}

	isAvailable() {
		return this.available
	}

	getCharacters() {
		let list = []

		for ( let charId of this.characterIds ) {
			list.push ( Game.global.getCharacter(charId) )
		}

		return list
	}

	getCharactersIds() {
		return this.characterIds
	}

	getPrimaryCharacter() {
		let charId = this.characterIds[0]

		return Game.global.getCharacter(charId)
	}


	makeChoice(choiceId) {
		this.choices.push(choiceId)
	}

	removeChoice(choiceId) {
		this.choices = this.choices.filter( x => x !== choiceId )
	}

	hasChosen(choiceId) {
		return this.choices.includes(choiceId)
	}

	getChoices() {
		return [...this.choices]
	}

	clearChoices() {
		this.choices = []
	}
}