import { hurl, sleep, error, warn, log, debug, replaceTransVars } from 'global.js'
import { isUndefOrNull, isObject, isDef, isString, isArray } from 'validators.mjs'

import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'
import { Game } from 'Game/Game.mjs'

import { T } from 'Game/Translations.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'


export class ViewMessage extends ViewLayer {
	static templateString

	/**
	 * Opens the popup view using this popup template.
	 *
	 * @param { string } message
	 * @param { object } variables
	 * @param { boolean } [animate]
	 * @param { string } [sfxOverride]
	 * @return nothing
	 */
	async show(message, variables, animate, sfxOverride) {

		this.classList.add('view')
		this.classList.add('fast')

		this.setContentFromTemplateElement(this.template(variables))
		this.insertAdjacentHTML("beforeend", replaceTransVars(message, variables))

		if ( !sfxOverride ) {
			Game.global.playSfx('show-popup-primary')

		} else if ( sfxOverride != "none" ) {
			Game.global.playSfx(sfxOverride)
		
		}

		await super.show(animate)
	}

	async action(listOfActions, element, event) {

		let feedback = false

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				switch(action) {
					case "close":
					case "default":
						await this.hide()
						feedback = true
						break;
				}

				if ( this.actions?.[action] ) {
					await this.hide()

					let cmds = Game.global.createCommandChain( this.actions[action] )
					await Game.global.queueCommandsOnTopAndWait( cmds )

		 			feedback = true
				}
			}
		}
		
		return feedback
	}

	async hide() {
		await super.hide()

		this.remove()
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";
import importPopupStyles from "css/import-popup.css.mjs";

ViewMessage.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }
	${ importPopupStyles }

	h1,h2 {
		text-align: center;
	}

</style>
<div class="popup">
	<div class="texts">
		<slot></slot>
	</div>
	<div class="buttons">
		<text-button action="close">{{~button-ok}}</text-button>
	</div>
</div>

`;

window.customElements.define('view-message', ViewMessage)

