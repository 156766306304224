import { Command, Cmd, CmdShowCustomView, CmdChangeStateNow, CmdChangeStateLater, CmdShowSettings, CmdStopAllMusic, CmdPlayForegroundMusic, CmdStopAllForegroundMusic, CmdPlaySfx, CmdPlayBackgroundMusic, CmdStopAudio, CmdStopAllBackgroundMusic, CmdShowMessage, CmdRestartPlayerRound, CmdShowOverlay, CmdSleep, CmdStop, CmdDeleteGlobalVariable, CmdTakeItemsFromCharacter, CmdExitGame, CmdStartGameRounds, CmdInitBoard, CmdRegisterGlobalVariable, CmdSetGlobalVariable, CmdCreatePlayers, CmdCreateThing, CmdCreateSpace, CmdLog, CmdEndGameWithSlideshow, CmdStartGameLoop, CmdStopGameLoop, CmdAddThing, CmdShowThing, CmdHideThing, CmdRemoveThing, CmdUpdateThing, CmdMissionStart, CmdResetStateAndGlobalVariables, CmdCenterView, CmdShowSlideshow, CmdShowDialogue, CmdShowCustomContentView, CmdShowGameboard, CmdAddSpace, CmdShowSpace, CmdShowSpaceWithContents, CmdHideSpace, CmdRemoveSpace, CmdDelete, CmdShowView, CmdGiveItems, CmdShowPopup, CmdShowItemView, CmdShowAttackView } from 'Command/Command.mjs'

import { If, IfAll, IfAny } from 'Command/If.mjs'
import { Group } from 'Command/Chain.mjs'

import { MissionStartEvent, MissionFailEvent, MissionSuccessEvent, MissionEndEvent, MissionChangeStateEvent } from 'Command/EventsMission.mjs'
import { EndGameEvent, StartGameRoundEvent, EndGameRoundEvent, StartPlayerRoundEvent, EndPlayerRoundEvent, StartWorldRoundEvent, EndWorldRoundEvent } from 'Command/EventsGame.mjs'
import { LookInteractionEvent, UseInteractionEvent } from 'Command/EventsInteraction.mjs'
import { EndDialogueEvent } from 'Command/EventsDialogue.mjs'
import { EndSlideshowEvent } from 'Command/EventsSlideshow.mjs'
import { CharacterDamage } from 'Command/EventsAttack.mjs'
import { ShowViewEvent, HideViewEvent } from 'Command/EventsView.mjs'

export function createEvent(type, specs) {
	switch (type) {
		case "end-game":
			return new EndGameEvent(specs)

		case "end-game-round":
			return new StartGameRoundEvent(specs)

		case "end-player-round":
			return new EndPlayerRoundEvent(specs)

		case "end-world-round":
			return new EndWorldRoundEvent(specs)

		case "character-damage":
			return new CharacterDamage(specs)

		case "dialogue-end":
			return new EndDialogueEvent(specs)

		case "hide-view":
			return new HideViewEvent(specs)

		case "interactive-look":
			return new LookInteractionEvent(specs)

		case "interaction-use":
			return new UseInteractionEvent(specs)

		case "mission-start":
			return new MissionStartEvent(specs)

		case "mission-success":
			return new MissionSuccessEvent(specs)

		case "mission-fail":
			return new MissionFailEvent(specs)

		case "mission-end":
			return new MissionEndEvent(specs)

		case "mission-state-change":
			return new MissionChangeStateEvent(specs)

		case "show-view":
			return new ShowViewEvent(specs)

		case "start-game-round":
			return new StartGameRoundEvent(specs)

		case "start-player-round":
			return new StartPlayerRoundEvent(specs)

		case "start-world-round":
			return new StartWorldRoundEvent(specs)

		case "set-global-variable":
			return new SetGlobalVariableEvent(specs)

		case "slideshow-end":
			return new EndSlideshowEvent(specs)

		default:
			throw `createEvent() unknown event type: '${ type }' with object:\n${ JSON.stringify(specs) }`
	}
}

export function createCommand(specs) {

	if ( "cmd" in specs ) {
		return createCmd(specs.cmd, specs)
	}

	if ( "group" in specs ) {
		return new Group(specs)
	}

	if ( "if" in specs ) {
		return new If(specs)
	}

	if ( "ifAny" in specs ) {
		return new IfAny(specs)
	}

	if ( "ifAll" in specs ) {
		return new IfAll(specs)
	}

	if ( "event" in specs ) {
		return createEvent(specs.event, specs)
	}
}

export function createCmd(type, specs) {
	switch (type) {

		case "addSpace":
			return new CmdAddSpace(specs)

		case "addDoor":
		case "addItem":
		case "addMonster":
		case "addNpc":
		case "addThing":
			return new CmdAddThing(specs)

		case "centerView":
			return new CmdCenterView(specs)

		case "changeStateLater":
			return new CmdChangeStateLater(specs)

		case "changeStateNow":
			return new CmdChangeStateNow(specs)

		case "createPlayer":
		case "createPlayers":
			return new CmdCreatePlayers(specs)

		case "createSpace":
			return new CmdCreateSpace(specs)

		case "createDoor":
		case "createItem":
		case "createMonster":
		case "createNpc":
		case "createThing":
			return new CmdCreateThing(specs)

		case "registerGlobalVariable":
			return new CmdRegisterGlobalVariable(specs)

		case "delete":
			return new CmdDelete(specs)

		case "deleteGlobalVariable":
			return new CmdDeleteGlobalVariable(specs)

		case "endGameWithSlideshow":
			return new CmdEndGameWithSlideshow(specs)

		case "endGameLoop":
			return new CmdEndGameLoop(specs)

		case "endPlayerRound":
			return new CmdEndPlayerRound(specs)

		case "endWorldRound":
			return new CmdEndWorldRound(specs)

		case "exitGame":
			return new CmdExitGame(specs)

		case "giveItems":
			return new CmdGiveItems(specs)

		case "hideSpace":
			return new CmdHideSpace(specs)

		case "hideDoor":
		case "hideItem":
		case "hideMonster":
		case "hideNpc":
		case "hideThing":
			return new CmdHideThing(specs)

		case "initBoard":
			return new CmdInitBoard(specs)

		case "log":
			return new CmdLog(specs)

		case "missionStart":
			return new CmdMissionStart(specs)

		case "playBackgroundMusic":
			return new CmdPlayBackgroundMusic(specs)

		case "playForegroundMusic":
			return new CmdPlayForegroundMusic(specs)

		case "playSfx":
			return new CmdPlaySfx(specs)

		case "removeSpace":
			return new CmdRemoveSpace(specs)

		case "removeDoor":
		case "removeItem":
		case "removeMonster":
		case "removeNpc":
		case "removeThing":
			return new CmdRemoveThing(specs)

		case "restartPlayerRoundIfOnGoing":
			return new CmdRestartPlayerRound(specs)

		case "takeItemsFromCharacter":
			return new CmdTakeItemsFromCharacter(specs)

		case "resetStateAndGlobalVariables":
			return new CmdResetStateAndGlobalVariables(specs)

		case "setGlobalVariable":
			return new CmdSetGlobalVariable(specs)

		case "showCustomView":
			return new CmdShowCustomView(specs)

		case "showCustomContentView":
			return new CmdShowCustomContentView(specs)

		case "showDialogue":
			return new CmdShowDialogue(specs)

		case "showGameboard":
			return new CmdShowGameboard(specs)

		case "showMessage":
			return new CmdShowMessage(specs)

		case "showPopup":
			return new CmdShowPopup(specs)

		case "showSettings":
			return new CmdShowSettings(specs)

		case "showSlideshow":
			return new CmdShowSlideshow(specs)

		case "showSpace":
			return new CmdShowSpace(specs)

		case "showSpaceWithContents":
			return new CmdShowSpaceWithContents(specs)

		case "showNpc":
		case "showItem":
		case "showDoor":
		case "showMonster":
		case "showThing":
			return new CmdShowThing(specs)

		case "showItemView":
			return new CmdShowItemView(specs)

		case "showAttackView":
			return new CmdShowAttackView(specs)

		case "sleep":
			return new CmdSleep(specs)

		case "showView":
			return new CmdShowView(specs)

		case "showOverlay":
			return new CmdShowOverlay(specs)

		case "startGameLoop":
			return new CmdStartGameLoop(specs)

		case "startGameRounds":
			return new CmdStartGameRounds(specs)

		case "startWorldRound":
			return new CmdStartWorldRound(specs)

		case "stop":
			return new CmdStop(specs);

		case "stopAudio":
			return new CmdStopAudio(specs)

		case "stopAllMusic":
			return new CmdStopAllMusic(specs)

		case "stopAllBackgroundMusic":
			return new CmdStopAllBackgroundMusic(specs)

		case "stopAllForegroundMusic":
			return new CmdStopAllForegroundMusic(specs)

		case "updateNpc":
		case "updateItem":
		case "updateDoor":
		case "updateMonster":
		case "updateThing":
		case "updateSpace":
			return new CmdUpdateThing(specs)

		default:
			throw `createCmd() unknown command type: '${ type }'`
	}
}