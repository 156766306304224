import { log, debug, warn } from 'global.js'
import { Drawable } from 'Board/Drawable.mjs'

export class Tile extends Drawable {
	constructor(board, id, assets, x, y) {
		super(board, id, assets, x, y)
	}

/*
	onMouseOver(x, y) {
		debug(`Tile ${this.id} got mousemove at (${x},${y})`)

	}

	onClick(x, y) {
		debug(`Tile ${this.id} got click at (${x},${y})`)

	}
*/
}
