import { sleep, log, warn, error, mixin, hurl } from 'global.js'
import { isDef, isInteger, isString, isObject, isNonEmpty, isDefNotNull, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { UI } from 'UI/UI.mjs'

import { HasAssets } from 'Thing/HasAssets.mjs'
import { HasProps } from 'Thing/HasProps.mjs'

export const THING_PROPS = ["name", "fullname", "type", "pronouns", "the", "asset", "alive", "items", "state", "states", "stats", "description", "spaces", "action", "locked"]

export class Thing extends mixin(HasAssets, HasProps) {
	constructor(id, name, description, assets, spaceOrSpaces, state, states, action, actions, centerX, centerY, visible, markerSpace) {
		super()

		if ( !isString(id) ) { throw `Thing() 'id' not a String. Got: ${ id }`}
		if ( !isString(name) ) { throw `Thing() 'name' not a String. Id '${ id }'. Got: ${ name }` }
		if ( isDefNotNull(assets) && !isObject(assets) ) { throw `Thing() 'assets' not an Object. Id '${ id }'. Got: ${ assets }` }
		if ( isDefNotNull(state) && !isString(state) ) { throw `Thing() 'state' not a String. Id '${ id }'. Got: ${ state }` }
		if ( isDefNotNull(states) && !isObject(states) ) { throw `Thing() 'states' not an Object. Id '${ id }'. Got: ${ states }` }
		if ( isDefNotNull(centerX) && !isInteger(centerX) ) { throw `Thing() centerX not an Integer. Id '${ id }'. Got: ${ centerX }` }
		if ( isDefNotNull(centerY) && !isInteger(centerY) ) { throw `Thing() centerY not an Integer. Id '${ id }'. Got: ${ centerY }` }
 
		this.id = id
		this.name = name
		this.description = description
		this.spaces = {}		
		this.owner = null
		this.marker = undefined // defined by Marker.linkThing()
		this.markerSpace = markerSpace
		this.states = states || undefined
		this.state = state || undefined
		this.action = undefined
		this.actions = actions || undefined
		this.cx = undefined
		this.cy = undefined
		this.action = action
		this.visible = isDef(visible) ? visible : true

		let spaces

		if ( isArray(spaceOrSpaces) ) {
			spaces = spaceOrSpaces
		}
		else if ( isString(spaceOrSpaces) ) {
			spaces = [ spaceOrSpaces ]
		}
		else {
			spaces = []
		}
		
		if ( isDefNotNull(centerX) ) { this.setCenterX(centerX) }
		if ( isDefNotNull(centerY) ) { this.setCenterY(centerY) }

		/* TODO: try to refactor this into HasAssets - doesn't seems to work with mixin() */
		Object.defineProperty(this, 'assets', { value: {}, writable: true })
		
		if ( isDefNotNull(assets) ) { this.addAssets(assets) }
		if ( isDefNotNull(spaces) ) { this.addSpaces(spaces) }
		if ( isDefNotNull(actions) ) { this.addActions(actions) }

		// Maybe good to do this last?
		if ( isString(state) ) { this.changeState(state) }
	}

	getName() {
		return this.name
	}

	getDescription() {
		return this.description
	}

	getCurrentAction(){
		return this.action
	}

	/**
	 * Object of Arrays of Cmd specs
	 * 
	 * actions = {
	 *     "foo": [ { cmd }, { cmd } ],
	 *     "bar": [ { cmd } ]
	 * }
	 * 
	 * @param { object } actions 
	 * @throws InvalidCommand
	 */
	addActions(actions) {
		isObject(actions) || hurl `Thing.addActions() 'actions' is not an Object. Got: ${ actions }`

		for ( let name in actions ) {
			let specs = actions[name]

			this.addActionCmdsFromSpecs(name, specs)
		}
	}

	/**
	 * @param { string } name
	 * @param { array } specs
	 * @throws InvalidCommand
	 */
	addActionCmdsFromSpecs(name, specs) {
		isString(name) || hurl `Thing.addActionCmdsFromSpecs() name not a String. Got: ${ name }`
		isArray(specs) || hurl `Thing.addActionCmdsFromSpecs() specs is not an Array. Got: ${ specs }`

		this.actions[name] = Game.global.createCommandChain(specs)
	}

	/**
	 * @param { string } name
	 * @throws NotFound
	 */
	getActionCmds(name) {
		isString(name) || hurl `Thing.getActionCmds() name not a String. Got: ${ name }`
		isDef(actions[name]) || hurl `Thing.getActionCmds() no actions found for name: ${ name }`

		return this.actions[name]
	}

	getCurrentActionCmds() {
		let action = this.getCurrentAction()

		if ( !action ) {
			return null
		}

		return getActionCmds( action )
	}

	runCurrentActions() {
		if ( this.getCurrentState() ) {
			let commands = this.getActions(this.getCurrentState())

			Game.global.queueCommands(commands)
			
		} else {
			warn(`Thing.runCurrentActions() tried to run actions but no state for thing: ${ this.id }` )
		}
	}

	addSpaces(listOfSpaceIds) {
		for ( let spaceId of listOfSpaceIds ) {
			this.addToSpace(Game.global.getSpace(spaceId))	
		}
	}

	addToSpace(space, stopPropagate) {
		this.spaces[space.id] = space

		if ( !stopPropagate ) {
			space.addThing(this, true)
		}

		return this
	}

	isInSpace() {
		return isNonEmpty(this.spaces)
	}

	/**
	 * Returns name of the space this Thing is located in.
	 * 
	 * Note: For Doors, we return the "first" in the list of spaces.
	 */
	getSpaceName() {
		for ( let spaceName in this.spaces ) {
			return spaceName
		}
	}

	/**
	 * Returns the Space this Thing is located in.
	 * 
	 * Note: For Doors, we return the "first" in the list of spaces.
	 */
	getSpaceReal() {
		for ( let spaceName in this.spaces ) {
			return this.spaces[spaceName]
		}
	}

	async removeFromAllSpaces(stopPropagate) {

		if ( this.isVisible() ) {
			Game.global.hideThing(this.id, false)
		}

		if ( !stopPropagate && isObject(this.spaces) ) {
			for ( let id in this.spaces ) {
				await this.spaces[id].removeThing(this, true)
			}
		}

		this.spaces = {}

		return this
	}

	setCenterX(value) {
		if ( !isInteger(value) || value < 0 ) {
			throw `Thing.setCenterX() value is not a positive integer. Got: ${ value }`
		}

		this.cx = value

		return this
	}

	getCenterX() {
		return this.cx
	}

	setCenterY(value) {
		if ( !isInteger(value) || value < 0 ) {
			throw `Thing.setCenterY() value is not a positive integer. Got: ${ value }`
		}

		this.cy = value

		return this
	}

	getCenterY() {
		return this.cy
	}

	linkWithMission(mission) {
		if ( !missionId ) {
			throw `Thing.linkWithMission() didn't get missionId`
		}

		this.missions[mission.id] = mission

		return this
	}

	unlinkWithMission(mission) {
		if ( !missionId ) {
			throw `Thing.linkWithMission() didn't get missionId`
		}

		this.missions[mission.id] = mission

		return this
	}

	getCurrentState() {
		return this.state
	}

	/**
	 * You propably want to use changeState() so it overrides current properties from the new state
	 * 
	 * @throws UnknownState
	 */
	setState(state) {
		if ( !isObject(this.states[state]) ) { throw `Thing.setState() unknown state given: ${ state }. List of states: ${ Object.keys(this.states) }` }

		this.state = state

		return this
	}

	getState() {
		return this.state
	}

	/**
	 * Calls setState() and then overrides current properties from the state
	 * 
	 * @param [string] newState
	 * @throws UnknownState
	 * @throws InvalidKey
	 */
	changeState(newState) {
		if ( !isString(newState) ) { throw `Thing.setStateVar() invalid 'newState' attribute given. Got: ${ newState }`}

		this.setState(newState)

		for ( let prop in this.states[newState] ) {

			if ( prop === "spaces" ||  prop === "space" ) {
				let space = this.getSpaceReal()

				if ( space ) {
					space.removeThing(this, true)
				}
			}

			this.setProp(prop, this.states[newState][prop])
		}

		return this		
	}

	/**
	 * @param {object} specs
	 */
	update(specs) {
		for ( let keyPath in specs ) {
			let [ key, ...rest ] = keyPath.split(':')

			if ( key == "state" ) {
				this.changeState(specs[key])
			} else {
				this.setProp(keyPath, specs[keyPath])	
			}
		}

		return this
	}

	isVisible() {
		return UI.global.views.gameboard.board.hasThing( this.getIdForBoard() )
	}

	/**
	 * Does this Thing have defined actions? Used by Marker.onClick()
	 * 
	 * @return { boolean }
	 * @throws NotFound
	 */
	hasAction() {
		return this.action ? true : false 
	}

	/**
	 * Run actions for current or other state using Api.addCommands()
	 * 
	 * Returns immediately, does not wait for commands to finish
	 *
	 * @return { boolean } true if action was run, false if not
	 */
	runAction() {
		if ( this.hasAction() ) {
			Game.global.queueCommands(this.actions[ this.action ])
			return true
		}

		return false
	}

	/**
	 * Called by State.deleteThing() before removing it from State
	 */
	delete() {
		warn `Thing.delete() my child classes should override this and do something smart if needed`
	}
}
