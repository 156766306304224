import { sleep, log, error, debug, generateId, replaceVars } from 'global.js'
import { isArray, isObject, isDef, isNumber, isString } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { Command } from 'Command/Command.mjs'
import { Event } from 'Command/Event.mjs'
import { If, IfAll, IfAny } from 'Command/If.mjs'
import { createCommand } from 'Command/create.mjs'

/**
 * CommandChain is a list of Cmd objects and it can execute
 * them in a series.
 *
 * list = [
 *     { "if": [ ... ] },
 *     { "cmd": "resetState", ... },
 *     { "cmd": "showView", ... }
 * ]
 *
 * Becomes:
 *
 * { CommandChain
 *     chain: [
 *         { If },
 *         { CmdResetState },
 *         { CmdShowView }
 *     ]
 * }
 *
 * CommandChain is iterable:
 *
 *     let chain = new CommandChain(...)
 *
 *     for ( let cmd of chain ) {
 *         ...
 *     }
 *
 * Notes:
 * CommandChain pushes all the commands in it to the Processor at once.
 *
 * If an "If" command evalutes to false, rest of the commands
 * in the chain are cancelled.
 *
 * If any succesfull commands within the chain generate more commands
 * before cancellation, they'll be executed normally.
 *
 * This cancellation is done with a special chain identifier,
 * used by Processor.cancelRestOfChain()
 */
export class CommandChain {

	chain;
	queueOnTop = false;
	loop;
	commandCallbacks;
	eventObject;

	/** @type {string} */
	tag;

	/** @type {string} */
	tagParent;


	/**
	 * Create a new CommandChain
	 *
	 * Make a copy from another CommandChain:
	 *
	 *     new CommandChain(otherChain)
	 *
	 * Make a chain out of array of Commands:
	 *
	 * 	   let cmds = [ Cmd, Cmd, ... ]
	 *     new CommandChain( cmds )
	 *
	 * Make a chain out of script objects:
	 *
	 *     let script = [ { "cmd": ... }, { "cmd": ... }, ... ]
	 *     new CommandChain( script )
	 *
	 * @param { array|CommandChain } list (optional)
	 * @param { number|string } [loop=1] (optional) number of loops - may be {{variable}}
	 */
	constructor(list, loop) {
		let tag = `${ isNumber(this.loop) || isString(this.loop) ? "loop" : "chain" }-${generateId(4)}`

		Object.defineProperty(this, 'chain', { value: [], enumerable: false, writable: true })
		Object.defineProperty(this, 'queueOnTop', { value: false, enumerable: false, writable: true })
		Object.defineProperty(this, 'loop', { value: 1, enumerable: true, writable: true })
		Object.defineProperty(this, 'commandCallbacks', { value: [], enumerable: false, writable: false })
		Object.defineProperty(this, 'tag', { value: tag, enumerable: false, writable: false })
		Object.defineProperty(this, 'tagParent', { value: undefined, enumerable: false, writable: true })
		Object.defineProperty(this, 'eventObject', { value: undefined, enumerable: false, writable: true })

		if ( list ) {
			this.addCommands( list )
		}

		if ( isDef(loop) ) {
			this.loop = loop
		}
	}

	/**
	 * Add a command to the end of the queue
	 *
	 * @param { Command | CommandChain | object } command
	 * @param { string } [parentTag]
	 */
	addCommand(command, parentTag) {
		if ( command instanceof Command || command instanceof CommandChain ) {
			this.chain.push(command)
		}
		else if ( isObject(command) ) {
			let cmd = createCommand(command)

			this.chain.push( cmd )
		}
		else {
			throw `CommandChain.addCommand() argument 'command' unknown. Got: ${ command }`
		}
	}

	addCommands(list, parentTag) {
		for ( let command of list ) {
			this.addCommand(command, parentTag)
		}
	}

	/**
	 * Adds Commands from the Chain to Processor and return immediately.
	 *
	 * See Class description for details.
	 * 
	 * If this.loop is defined as N, this will add the list N times.
	 * 
	 * Loop value will be rounded and limited to 1-100 values.
	 * 
	 * TODO: refactor this looping into Processor with queueCommandsOnTop()
	 *
	 * @return true
	 * @throws LoopOutOfBounds
	 */
	async execute() {
		let loop = isNumber(this.loop) ? this.loop : 1

		if ( isString(this.loop) ) {

			let tmp = replaceVars(this.loop, Game.global.getGlobalVariables())

			if ( isNumber(tmp) ) { loop = tmp }
			else if ( tmp instanceof Set ) { loop = Array.from(tmp).length }
			else if ( isArray(tmp) ) { loop = tmp.length }
		}

		loop = Math.floor(loop)

		if ( loop <= 0 || loop > 100 ) {
			throw `Chain.execute() bad loop count: ${ loop } – allowed values: 1-100`
		}

		while ( loop-- > 0 ) {

			// add in reverse order because of "on top"
			for ( let i = this.chain.length -1 ; i >= 0 ; i-- ) {
				let cmd = this.chain[i]

				if ( this.eventObject ) {
					cmd.setEvent(this.eventObject)
				}

				if ( cmd instanceof Group ) {
					cmd.setParentTag(this.getTag())
				} else {
					if ( this instanceof Group && this.hasParentTag() ) {
						cmd.setParentTag(this.getParentTag())
					}
					cmd.setTag(this.getTag())
				}

				await Game.global.queueCommandsOnTop(cmd)	
			}
		}

		return true
	}

	get length() {
		return this.chain.length
	}
	
	validate() {
		if ( !isArray(this.chain) ) {
			throw `CommandChain.validate() property 'chain' is not an array. Get: ${ this.chain }`
		}

		for ( let cmd of this.chain ) {
			if ( !(cmd instanceof Command) && !(cmd instanceof CommandChain) ) {
				if ( cmd.constructor ) {
					throw `CommandChain.validate() cmd '${ cmd.constructor.name }' does not inherit Command or CommandChain class`
				}

				throw `CommandChain.validate() cmd '${ cmd }' is of wrong type: does not inherit Command or CommandChain class`
			}
		}
	}

	hasCallbacks() {
		return isArray(this.commandCallbacks) && this.commandCallbacks.length > 0
	}

	addCallback(callback) {
		this.commandCallbacks.push(callback)
	}

	resolveCallbacks() {
		for ( let callback of this.commandCallbacks ) {
			callback()
		}
	}

	getCallbacks() {
		return this.commandCallbacks
	}

	setTag(tagName) {
		this.tag = tagName
	}

	getTag() {
		return this.tag
	}

	hasTag(tagName) {
		return tagName ? this.tag === tagName : isDef(this.tag)
	}

	setParentTag(tagName) {
		this.tagParent = tagName
	}

	getParentTag() {
		return this.tagParent
	}

	hasParentTag(tagName) {
		return tagName ? this.tagParent === tagName : isDef(this.tagParent)
	}

	setEvent(event) {
		if ( !(event instanceof Event) ) {
			throw `CommandChain.setEvent() argument 'event' is not class Event`
		}

		this.eventObject = event
	}

	getEvent() {
		return this.eventObject
	}

	/**
	 * CommandChain is iterable:
	 *
	 *     let chain = new CommandChain(...)
	 *
	 *     for ( let cmd of chain ) {
	 *         ...
	 *     }
	 *
	 * Based on:
	 * https://stackoverflow.com/a/28741819
	 *
	 */
	[Symbol.iterator]() {
		var index = -1;
		var data  = this.chain;

		return {
			next: () => ({ value: data[++index], done: !(index in data) })
		};
	};
}

/**
 * Group is a special command that extends CommandChain.
 *
 * Without loop:
 * {
 *    group: [
 *        { "if": [ "any-player", "inventory", "includes", "some-document"] },
 *        { "cmd": "showSlideshow", "slideshow": "what-to-do-with-document"}
 *    ]
 * }
 * 
 * With loop:
 * {
 *    loop: 5,
 *    group: [
 *        { "cmd": "setGlobalVariable", "variable": "foobar", add: 1 }
 *    ]
 * }
 * 
 * With loop of array (it only uses the length)
 * 
 * {
 *    loop: "{{SOME_ARRAY}}",
 *    group: [
 *        { "cmd": "setGlobalVariable", "variable": "foobar", add: 1 }
 *    ]
 * }
 **/

export class Group extends CommandChain {

	constructor(specs) {
		if ( !isArray(specs.group) ) { throw `Group() object key 'group' value is not an array. Got: ${ specs.group }` }

		super(specs.group, specs.loop )
	}
}

