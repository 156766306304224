import { generateAssetUrl, error, warn, log, debug } from 'global.js'
import { isString } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { AnimatedElement } from 'UI/CustomElement.mjs'

export class AudioPlayer extends AnimatedElement {

    static templateString
    static playFadeDuration = 1000
    static pauseFadeDuration = 300
    static skipLength = 15

    constructor() {
        super()

        this.playButton = this.querySelector('#play')

        this.assetId = this.getAttribute('play')

        let audioAsset = Game.global.getAsset(this.assetId)
        let promiseAudio = audioAsset.data()

        promiseAudio.then((audio) => {
            audio.on('play', (eventSoundId)=>{
                this.playButton.classList.remove('paused')
                this.playButton.classList.add('playing')
            })
            audio.on('stop', (eventSoundId)=>{
                this.playButton.classList.remove('playing')
                this.playButton.classList.remove('paused')
            })
            audio.on('end', (eventSoundId)=>{
                this.playButton.classList.remove('playing')
                this.playButton.classList.remove('paused')
            })
            audio.on('pause', (eventSoundId)=>{
                this.playButton.classList.remove('playing')
                this.playButton.classList.add('paused')
            })

            this.audio = audio

        })
    }

    connectedCallback() {
        let root = this.getRootNode()
        this.view = root?.host

        this.view?.addCallbackBeforeHide('audioPlayer', ()=>{
            this.stop()
        })

		this.view?.addCallbackAfterShow('audioPlayer', ()=>{
			if ( this.getAttribute('autoStart') == "true" ) {
				console.log("autostart")
				this.play()
			}
		})
    }

    playOrPause() {
        if ( this.playButton.classList.contains('playing') ) {
            this.pause()
        } else {
            this.play()
        }
    }

    play() {
        if ( this.audio && !this.audio.isPlaying() ) {
            this.audio.playWithFade(1, false, AudioPlayer.playFadeDuration, true)
        }
    }

    pause() {
        if ( this.audio ) {
            this.audio.pauseWithFade(AudioPlayer.pauseFadeDuration)
        }
    }

    async stop() {
        if ( this.audio ) {
           await this.audio.stopWithFade(AudioPlayer.pauseFadeDuration)
        }
    }
    rewind() {
        if ( this.audio ) {
            this.audio.rewind(AudioPlayer.skipLength)
        }
    }

    forward() {
        if ( this.audio ) {
            this.audio.forward(AudioPlayer.skipLength)
        }
    }

	/**
	 * Called by Button elements within this component
	 *
	 * @param {string} listOfActions comma separated string
	 * @param {element} element
	 * @param {Event} event
	 */
    async action(listOfActions, element, event) {

        let feedback = false

        if ( listOfActions ) {
            for ( let action of listOfActions.split(',') ) {

                switch(action) {
                    case "rewind":
                        feedback = true;
                        this.rewind()
                        break;

                    case "forward":
                        feedback = true;
                        this.forward()
                        break;

                    case "playOrPause":
                        feedback = true;
                        this.playOrPause()
                        break;
                }

                if ( feedback ) {
                    Game.global.actionEvent(action, null, null)
                }
            }
        }
        
        return feedback
    }
}

import importMainStyles from "css/import-main.css.mjs";

AudioPlayer.templateString = `
<style>
    ${ importMainStyles }

    :host() {
        min-width: 200px !important;
        min-height: 120px !important;
        background: rgba(0,128,0,0.5) !important;
    }

    .controls {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: var(--l-pad);
    }

    image-button.control {
        width: 80px;
        height: 80px;
    }

    #rewind {
        background-image: url('${ generateAssetUrl('/media/general/rewind-default.svg') }');
    }
    #rewind:active, #rewind:hover {
        background-image: url('${ generateAssetUrl('/media/general/rewind-active.svg') }');
    }

    #forward {
        background-image: url('${ generateAssetUrl('/media/general/forward-default.svg') }');
    }
    #forward:active, #forward:hover {
        background-image: url('${ generateAssetUrl('/media/general/forward-active.svg') }');
    }

    #play {
        background-image: url('${ generateAssetUrl('/media/general/play-default.svg') }');
    }
    #play.playing, #play:active, #play:hover {
        background-image: url('${ generateAssetUrl('/media/general/play-active.svg') }');
    }

    #play.paused {
        background-image: url('${ generateAssetUrl('/media/general/play-default.svg') }');
    }
    #play.paused:active, #play.paused:hover {
        background-image: url('${ generateAssetUrl('/media/general/play-active.svg') }');
    }

</style>
<div class="controls">
    <image-button action="rewind" class="control" id="rewind"></image-button>
    <image-button action="playOrPause" class="control" id="play" default="true"></image-button>
    <image-button action="forward" class="control" id="forward"></image-button>
</div>
`;

window.customElements.define('audio-player', AudioPlayer)
