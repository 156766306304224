import { sleep, error, warn, log, debug, generateAssetUrl, replaceTransVars } from "global.js";
import { isDef, isString, isArray } from 'validators.mjs'
import { ViewLayer } from 'UI/ViewLayer.mjs'
import { CustomElement, AnimatedElement } from 'UI/CustomElement.mjs'

import { Game } from 'Game/Game.mjs'
import { Dialogue } from 'Game/Dialogue.mjs'
import { Player, Character} from 'Thing/Characters.mjs'
import { ACTION_SEPARATOR } from 'UI/UI.mjs'


export class ViewDialogue extends ViewLayer {

	static templateString

	language = Game.global.getLanguage();
	animate = Game.global.getSettingAnimate();
	/**
	 * @type DialogueSet
	 */
	currentSet;
	templates = {};

	/**
	 * Initialized by DOM from <view-dialogue>
	 *
	 * Note: currentSet is not replaced, it's contents are replaced
	 * with showDialogue()
	 */
	constructor() {
		super()

		// @ts-ignore : it's a DialogueSet custom element
		this.currentSet = this.querySelector('x-dialogueset')

		this.loadSets()
	}

	loadSets() {
		this.templates = {}

		let allTemplates = Game.global.getAllTemplatesWithSelector(`template.dialogue[language="${this.language}"]`)

		for (let template of allTemplates) {
			this.templates[template.getAttribute('name')] = template
		}
	}


	/**
	 * Opens the dialogue view with this content
	 *
	 * @param { Player } player of the dialogue
	 * @param { Dialogue } dialogue
	 * @param { object } [variables]
	 * @param { boolean } [returnAfterShow]
	 * @param { boolean } [animate]
	 * @return Promise
	 */
	showDialogue(player, dialogue, variables, returnAfterShow, animate) {

		this.name = dialogue.templateId

		if ( this.ended ) { delete this.ended }

		let selector = `template.dialogue[name="${dialogue.templateId}"][language="${this.language}"]`

		if ( Game.global.getAllTemplatesWithSelector(selector) == null ) {
			throw `ViewDialogue.showDialogue: can't find template with: ${selector}`
		}

		return new Promise(async (resolve, reject) => {

			// prepare everything first
			this.currentSet.dialogue = dialogue

			this.currentSet.clearPlayer()

			if ( player ) {
				this.currentSet.setPlayer(player)
			}

			// .. then replace content which will require all above
			this.currentSet.content(selector, variables)

			await this.show(animate)

			this.currentSet.checkTimer()

			this.returnFromSlideshow = resolve

			if ( returnAfterShow ) {
				this.returnFromSlideshow()
			}
		})

	}


	async action(listOfActions, element, event) {
		let choice = element?.getAttribute('choice')
		let enableDialogue = element?.getAttribute('enableDialogue')
		let disableDialogue = element?.getAttribute('disableDialogue')

		let feedback = false

		if ( choice ) {
			debug(`ViewDialogue.action() choice '${choice}' make`)

			this.currentSet.dialogue.makeChoice(choice)
			feedback = true
		}

		if ( enableDialogue ) {
			let other = Game.global.getDialogue(enableDialogue)
			other.enable()
			feedback = true
		}

		if ( disableDialogue ) {
			let other = Game.global.getDialogue(disableDialogue)
			other.disable()
			feedback = true
		}

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				switch(action) {
					case "back":
						await this.currentSet.showPreviousPage()
						feedback = true
						break;

					case "next":
						await this.currentSet.showNextPage()
						feedback = true
						break;

					case "end":
						await this.hide()
						if ( this.returnFromSlideshow ) {
							this.returnFromSlideshow()
							delete this.returnFromSlideshow
						}
						Game.global.endDialogueEvent(this.currentSet.dialogue)
						feedback = true
						break;

					case "jump":
						let to = element.getAttribute('to')
						if ( !to ) { error("ViewDialogue.action() 'jump' no value for attribute 'to'") }
						await this.currentSet.jumpToPage(to)
						feedback = true
						break;
				}
			}
		}

		return feedback
	}

	getDefaultButton() {
		let set = this.currentSet.querySelector('x-dialogue.visible')
		let button = set?.getDefaultButton()

		return button
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewDialogue.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }

	:host() {
		background-color: var(--background);
	}
</style>
<x-dialogueset></x-dialogueset>

`;

window.customElements.define('view-dialogue', ViewDialogue)



/**
 * This is created by DOM as ViewDialogue template is attached.
 *
 * Dialogue contents are replaced by ViewDialogue.showDialogue()
 * using this object's content() from CustomElement.
 */
class DialogueSet extends CustomElement {

	static templateString

	view;
	dialogue;
	player;

	constructor() {
		super()

		this.dialogue
		this.player
	}

	connectedCallback() {
		// @ts-ignore : ShadowRoot has .host
		this.view = this.getRootNode().host

		if ( !(this.view instanceof ViewDialogue) ) {
			this.view = this.view.getRootNode().host
		}

		if ( !(this.view instanceof ViewDialogue) ) {
			throw `DialogueElement.constructor() can't find parent ViewDialogue`
		}
	}

	setPlayer(player) {
		if ( !(player instanceof Player) ) {
			console.warn(`DialogueSet.setPlayer() argument 'player' is not from class Player`)
			return
		}

		this.player = player
	}

	getPlayer() {
		if ( !this.player ) {
			console.warn(`DialogueSet.getPlayer() player is not set`)
		}
		return this.player
	}

	clearPlayer() {
		this.player = undefined
	}

	content(selector, variables) {

		super.setContentFromTemplateSelector(selector, 'slot', null, false)

		if ( this.hasCurrentPage() ) {

			let current = this.getCurrentPage()

			this.updateBackground(current)

			this.updateCharacters()
			this.updateImages()
			this.updateVariables()
		}
	}

	updateCharacters() {
		let element = this.querySelector('.characterimages')

		if ( element ) {

			let htmlString = ""
			for ( let characterId of this.dialogue.characterIds ) {
				//htmlString += `<img name="${characterId}-card-front"></img>`
				//htmlString += `<character-card id="${characterId}" disabled='disabled'></character-card>`
				htmlString += `<span class='card ${characterId}'></span>`
			}

			if ( htmlString !== "" ) {
				element.innerHTML = htmlString
			}

		}
	}

	updateBackground(dialogue) {
		const backgroundColor = dialogue.getAttribute('background')
		const backgroundImageName = dialogue.getAttribute('background-image')

		if ( backgroundColor && backgroundColor != "transparent" ) {
			this.style.backgroundColor = backgroundColor
		} else {
			this.style.backgroundColor = null
		}

		if ( backgroundImageName ) {
			let backgroundAsset = Game.global.getAsset(backgroundImageName)

			this.style.background = null
			this.style.backgroundImage = `url("${backgroundAsset.url}")`;
		} else {
			this.style.backgroundImage = null
		}
	}

	updateImages() {
		let elements = this.querySelectorAll('img[name]')

		for ( let element of elements ) {
			let name = element.getAttribute('name')
			let src = element.getAttribute('src')

			if ( !src && name != null && name != "" ) {
				let asset = Game.global.getAsset(name)
				log(`Setting slide img src to: ${asset.url}`)
				element.setAttribute('src', asset.url)
				//element.setAttribute('src', `media/characters/${name}@${Game.global.settings.quality}x.jpg`)
			}
		}
	}

	/**
	 * Replaces variables with values in the dialogueset.
	 *
	 * Specific variables:
	 * - random-player-name
	 * - current-player-name
	 * - current-player-pronoun-...
	 * - primary-character-name
	 * - primary-character-pronoun-...
	 *
	 * All characters defined in the game:
	 * - <character id>-name
	 * - <character id>-pronoun-...
	 *
	 * ... pronouns are: subject, object, adjective, possessive, reflexive
	 *
	 */
	updateVariables() {
		let randomPlayerNames = this.querySelectorAll('.random-player-name')

		for (let elements of randomPlayerNames) {
			let player = Game.global.getRandomPlayer()

			elements.textContent = player.getName()
		}

		let randomOtherPlayerNames = this.querySelectorAll('.random-other-player-name')

		for (let elements of randomOtherPlayerNames) {
			let player = this.getRandomPlayerNotCurrent()

			elements.textContent = player.getName()
		}


		let playerCount = Game.global.getPlayerCount()
		if ( playerCount > 1 ) {
			this.replaceVariables('.players-plural', 's')
			this.replaceVariables('.players-possessive', `s'`)
			this.replaceVariables('.show-if-single-player', '')
		} else {
			this.replaceVariables('.players-plural', '')
			this.replaceVariables('.players-possessive', `'s`)
			this.replaceVariables('.hide-if-single-player', '')
		}


		if ( playerCount >=2 && playerCount <= 3 ) {
			this.replaceVariables('.hide-if-few-players', '')
		} else {
			this.replaceVariables('.show-if-few-players', '')
		}

		if ( playerCount > 3 ) {
			this.replaceVariables('.hide-if-many-players', '')
		} else {
			this.replaceVariables('.show-if-many-players', '')
		}


		let primaryCharacter = this.dialogue.getPrimaryCharacter()

		this.replaceVariables('.primary-character-name', primaryCharacter.getName())
		this.replaceVariables('.primary-character-pronoun-subject', primaryCharacter.pronouns.subject)
		this.replaceVariables('.primary-character-pronoun-object', primaryCharacter.pronouns.object)
		this.replaceVariables('.primary-character-pronoun-adjective', primaryCharacter.pronouns.adjective)
		this.replaceVariables('.primary-character-pronoun-possessive', primaryCharacter.pronouns.possessive)
		this.replaceVariables('.primary-character-pronoun-reflexive', primaryCharacter.pronouns.reflexive)

		let characters = Game.global.getAllCharacters()

		for ( let character of characters ) {
			let id = character.id
			this.replaceVariables(`${id}-name`, character.getName())
			this.replaceVariables(`${id}-pronoun-subject`, character.pronouns.subject)
			this.replaceVariables(`${id}-pronoun-object`, character.pronouns.object)
			this.replaceVariables(`${id}-pronoun-adjective`, character.pronouns.adjective)
			this.replaceVariables(`${id}-pronoun-possessive`, character.pronouns.possessive)
			this.replaceVariables(`${id}-pronoun-reflexive`, character.pronouns.reflexive)
		}
	}

	getRandomPlayerNotCurrent() {
		let playerCount = Game.global.getPlayerCount()
		let current = Game.global.getCurrentPlayer()

		if ( playerCount == 1 ) {
			return current
		}

		for ( let i = 0 ; i < playerCount ; i++ ) {
			let rando = Game.global.getRandomPlayer()

			if ( rando.id != current?.id ) {
				return rando
			}
		}
	}

	replaceVariables(selector, value) {
		let elements = this.querySelectorAll(selector)

		for ( let element of elements ) {
			element.textContent = value
		}
	}

	async showNextPage() {
		let current = this.getCurrentPage()
		let next = this.getNextPage()

		if ( !current || !next ) {
			error("DialogueSet.showNextPage: can't show next dialogue.")
		}

		this.updateBackground(next)

		await current.hide()
		await next.show()

		this.view.updateFocus()

		this.checkTimer()
	}

	async showPreviousPage() {
		let current = this.getCurrentPage()
		let next = this.getPreviousPage()

		if ( !current || !next ) {
			error("DialogueSet.showNextPage: can't show next dialogue.")
		}

		this.updateBackground(next)

		await current.hide()
		await next.show()

		this.checkTimer()
	}

	async jumpToPage(to) {
		let current = this.getCurrentPage()
		let next = this.getPage(to)

		if ( !current || !next ) {
			error("DialogueSet.jumpToPage: can't show next page.")
		}

		this.updateBackground(next)

		await current.hide()
		await next.show()

		this.checkTimer()
	}

	hasCurrentPage() {
		return this.querySelector('x-dialogue.visible') != null
			|| this.querySelector('x-dialogue') != null
	}

	/**
	 *
	 * @returns {DialogueElement}
	 */
	getCurrentPage() {
		let current = this.querySelector('x-dialogue.visible')

		if ( !current ) {
			// first dialogue
			current = this.querySelector('x-dialogue')

			if ( !current ) {
				debug("DialogueSet.getCurrentPage: didn't find any x-dialogue elements.")
				return null
			}
		}

		return current
	}

	/**
	 *
	 * @returns DialogueElement
	 */
	getNextPage() {
		let current = this.getCurrentPage()
		let next = current.nextSibling

		while ( next != null && next.nodeName != 'X-DIALOGUE' ) {
			next = next.nextSibling
		}

		if ( !next || next.nodeName != 'X-DIALOGUE') {
			error("DialogueSet.getNextPage: didn't find next dialogue.")
			return null
		}

		return next
	}


	/**
	 *
	 * @returns DialogueElement
	 */
	getPreviousPage() {
		let current = this.getCurrentPage()
		let next = current.previousSibling

		while ( next != null && next.nodeName != 'X-DIALOGUE' ) {
			next = next.nextSibling
		}

		if ( !next || next.nodeName != 'X-DIALOGUE') {
			error("DialogueSet.getNextPage: didn't find next dialogue.")
			return null
		}

		return next
	}

	/**
	 *
	 * @returns DialogueElement
	 */
	getPage(id) {
		let get = this.querySelector(`#${id}`)

		if (!get) {
			error(`DialogueSet.getPage: couldn't find page ${id}`)
			return null
		}

		return get
	}

	async checkTimer() {
		let current = this.getCurrentPage()
		let timer = Number(current.getAttribute('timer'))
		let viewWithTimer = this.view.name

		if ( timer > 0 ) {
			await sleep(1000*timer)

			// slideshow skipping might break timer
			if ( this.view.ended || this.view.name != viewWithTimer ) {
				return
			}

			if ( this.view.isVisible() ) {
				await this.showNextPage()
			}
		}
	}

	/**
	 * All handlers are in ViewDialogue
	 */
	async action(action, element, event) {
		await this.view.action(action, element, event)
	}
}

import importDialogueStyles from "css/import-dialogues.css.mjs";

DialogueSet.templateString = `

<style>
	${ importMainStyles }
	${ importDialogueStyles }

	:host {
		background: var(--dialogue-background) !important;
		background-position: center;
		background-size: cover;
		background-repeat: none;
	}

</style>
<slot></slot>

`;
window.customElements.define('x-dialogueset', DialogueSet)


class DialogueElement extends AnimatedElement {

	static templateString

	view;

	/**
	 * This is created by DOM as ViewDialogue.showDialogue() attached
	 * the DialogueSet template.
	 */
	constructor() {
		super()
	}

	connectedCallback() {
		// @ts-ignore : ShadowRoot has .host
		this.view = this.getRootNode().host

		if ( !(this.view instanceof ViewDialogue) ) {
			this.view = this.view.getRootNode().host
		}

		if ( !(this.view instanceof ViewDialogue) ) {
			throw `DialogueElement.constructor() can't find parent ViewDialogue`
		}
	}

	/**
	 * All handlers are in ViewDialogue
	 */
	async action(action, element, event) {
		await this.view.action(action, element, event)
	}

}

import importAnimatedElementStyles from "css/import-animated-element.css.mjs";


DialogueElement.templateString = `

<style>
	${ importMainStyles }
	${ importAnimatedElementStyles }
	${ importDialogueStyles }

	:host {
		position: absolute;
		padding: var(--xl-pad);
		background: transparent;
		width: 100%;

		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}

	:host(.bottom) {
		justify-content: flex-end;
	}

</style>

`;

window.customElements.define('x-dialogue', DialogueElement)
