/**
 * Intercation view shows action dialog for attack
 */

import { debug, log, warn, error, getRandomInt, sleep } from 'global.js'
import { isDef, isObject } from 'validators.mjs'

import { T } from 'Game/Translations.mjs'

import { Game } from 'Game/Game.mjs'
import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'

import { ViewLayer, DEFAULT_BACKGROUND_COLOR } from 'UI/ViewLayer.mjs'
import { Card } from 'UI/Card.mjs'

export class ViewAttack extends ViewLayer {

	static templateString

	/**
	 * Initialized by DOM from <view-dialogue>
	 *
	 * Note: currentSet is not replaced, it's contents are replaced
	 * with showDialogue()
	 */
	constructor() {
		super()

		this.classList.add('view')
		this.style.opacity = 0
	}


	/**
	 * Opens the modal view-attack
	 *
	 * @param { Character } enemy
	 * @param { Asset } [background]
	 * @param { object } [variables]
	 * @param { boolean } [onlyAttack]
	 * @param { boolean } [animate]
	 * @return nothing
	 */
	async show(enemy, background, variables, onlyAttack, animate) {

		this.setAttribute('id', enemy.id)
		this.enemy = enemy

		if ( isDef(enemy.getSpaceName()) ) {
			let asset = enemy.getSpaceReal().getActionAsset()

			if ( !isObject(asset) ) {
				this.updateBackgroundColor(DEFAULT_BACKGROUND_COLOR)
			} else {
				this.updateBackgroundElementWithShade( asset )
			}
		}

		else if ( background ) {
			log(`ViewAttack.show() Enemy doesn't have space, using background parameter`)

			this.updateBackgroundElement( background )
		}

		else {
			log(`ViewAttack.show() Enemy doesn't have space NOR is there background parameter, falling back to default background`)
		}

		if ( onlyAttack ) {
			this.classList.add("only-attack")
		} else {
			this.classList.remove("only-attack")
		}

		await super.show(animate)

		await this.animateContents(enemy)
	}

	async action(listOfActions, element, event) {

		if ( !listOfActions ) { return false }

		let feedback = false

		for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {

			switch (action) {
				case "default":
				case "close":
					await this.hide()
					feedback = true
					break;

				case "hide":
					await this.hide()
					await UI.global.showPopup(`popup-${this.enemy.id}-hide`)
					feedback = true
					break;

				case "sneak":
					await this.hide()
					await UI.global.showPopup(`popup-${this.enemy.id}-sneak`)
					feedback = true
					break

				case "attack":
					await this.hide()
					await UI.global.showPopup(`popup-${this.enemy.id}-attack`)
					feedback = true
					break;
			}
		}


		return feedback
	}


	async hide(animate) {
		await super.hide(animate)

		this.remove()
	}

	async switchTab(to) {
		warn("TODO - fix switchTab")

		// TODO prev find parent .tab of clicked element
		let prev = this.querySelector('.tab.visible')

		let next = this.querySelector(`#${to}.tab`)

		prev.classList.remove('visible')
		next.classList.add('visible')
	}

	attack() {
		return Game.global.currentPlayerAttack(this.enemy)
	}

	evade() {
		return Game.global.currentPlayerEvade(this.enemy)
	}

	async animateContents(enemy) {

		// Purpose of these animations is to show content in several pieces
		// These were disabled on Jan 25th 2024

		// await sleep(1000)

		let card = this.querySelector('#preview-enemy character-card')
		card.updateElementFromThing(enemy)
		card.show()

		this.querySelector('#preview-enemy').classList.add('visible')

		// await sleep(125)

		this.querySelector('#attack-menu').classList.add('visible')

		// await sleep(125)

		this.querySelector('#button-back').classList.add('visible')

		// await sleep(1000)
		// card.classList.toggle('flip')
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";
import importAttackViewStyles from "css/import-attack-view.css.mjs";

ViewAttack.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }
	${ importAttackViewStyles }

	:host(.only-attack) .top text-button[action]:not([action='attack']) {
		display: none;
	}

</style>

<div class="top">
	<div class="left">
		<div id="preview" class="tabset">
			<div id="preview-enemy" class="tab animated">
				<character-card action="flip"></character-card>
			</div>
		</div>
	</div>

	<div class="right">
		<div id="menu" class="tabset">

			<!-- attack -->
			<div id="attack-menu" class="tab">
				<h1>{{~attack-title}}</h1>

				<text-button class="align-left" action="hide">{{~button-hide}} ({{~free-action-tag}})</text-button>
				<text-button class="align-left" action="sneak">{{~button-sneak}}</text-button>
				<text-button class="align-left" action="attack">{{~button-attack}}</text-button>
			</div>

<!-- Example if you return the use of tabs:

			<div id="attack-menu-hide" class="tab">
				<div class="dice-input">
					<image-button class="dice minus" action="minus" for="attack-hide-dice"></image-button>
					<h1 id="attack-hide-dice" class="dice-result">0</h1>
					<image-button class="dice plus" action="plus" for="attack-hide-dice"></image-button>
				</div>
				<div class="buttons">
					<text-button action="attack-hide">Do something</text-button>
				</div>
			</div>
-->

		</div>
	</div>
</div>
<div class="bottom">
	<div id="bottom-buttons">
			<text-button id="button-back" action="close" class="animated">{{~button-cancel}}</text-button>
	</div>
</div>

`;

window.customElements.define('view-attack', ViewAttack)
