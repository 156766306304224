import { setDefaultTranslator, sleep, warn, log, error, debug, replaceTransVars } from 'global.js'
import { isDef, isString, isNumber, isObject } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

/**
 * Example translation specs
 *
 * const GAME_TRANSLATIONS = {
 * 	"version": 30,
 * 	"hello-world": {
 * 		"en": "Hello world!",
 * 		"fi": "Hei maailma!"
 * 	}
 * }
 *
 */

export class TranslationManager {

	static version = 30
	static global

	constructor(specs) {
		if ( !specs ) { throw `TranslationManager.constructor: 'specs' parameter missing`}

		if ( TranslationManager.global ) { throw `TranslationManager.constructor: only one TranslationManager can exist` }

		TranslationManager.global = this

		this.translations = this.parseSpecs(specs)

	}

	parseSpecs(specs) {
		if ( specs.version != TranslationManager.version ) { throw `TranslationManager.parseSpecs(): bad version: ${specs.version} != ${ TranslationManager.version}` }

		if ( Object.keys(specs).length <= 1 ) { warn `TranslationManager.parseSpecs(): no translations defined` }

		let translations = JSON.parse( JSON.stringify(specs) )

		delete translations.version

		return translations
	}

	get(id, lang, variables) {
		if ( !isString(id) && !isNumber(id) ) { throw `Argument 'id' is not a string or number` }
		if ( !isString(lang) ) { throw `Argument 'lang' is not a string` }

		let translationObj = this.translations[id]

		if ( !translationObj ) {
			warn `No translation found for '${id}' - returning original`

			return id
		}

		let translation = translationObj[lang]

		if ( !isDef(translation) ) {
			translation = translationObj[ Game.global.getDefaultLanguage() ]
				|| hurl `No default translation for: ${id}. Default: ${ Game.global.getDefaultLanguage() }`

			warn `No translation found for '${id}' in '${lang}' – returning default`
		}

		if ( variables ) {
			translation = replaceTransVars(translation, variables)
		}

		return translation
	}
}

/**
 * Translate key string.
 *
 * If translation is not found, this will throw an exception.
 *
 * This is not recursive. Use T() instead.
 *
 * @param {string} key
 * @param {object} variables (optional)
 */
export function TT(key, variables) {
	if ( !key ) { return "" }

	return TranslationManager.global.get( key, Game.global.getLanguage(), variables )
}

/**
 * Translate key string recursively, replacing each %var% with another translation.
 *
 * If translation is not found, this will throw an exception.
 *
 * @param {string} str
 * @param {object} variables (optional)
 */

const regexVariable = /%([\-a-z\d]+)%/gmi

export function T(str, variables) {
	if ( !str ) { log `TT() got empty str - returning empty string`; return "" }

	if ( str.match(regexVariable) ) {

		let match

		while ( (match = regexVariable.exec(str)) !== null ) {
			let key = match[1]

			str = str.replace(`%${key}%`, TT(key, variables))
		}

		return str

	} else {
		return TranslationManager.global.get( str, Game.global.getLanguage(), variables )
	}
}

setDefaultTranslator(TT)

