/**
 * Animated Slideset with text, photos and video
 */

import { debug, log, sleep, generateAssetUrl } from "global.js";

import { CustomElement, AnimatedElement } from 'UI/CustomElement.mjs'

import { Game } from 'Game/Game.mjs'

export class SlideSet extends CustomElement {

	static templateString

	/**
	 * This object is created by DOM as the template is attached.
	 *
	 * Slide contents are replaced by ViewSlideshow.showSlideshow()
	 * or similar using the content() method
	 */
	constructor() {
		super()

		this.view = undefined
		this.choices = []
	}

	connectedCallback() {
		this.view = this.getRootNode().host
	}

	async content(selector) {
		super.setContentFromTemplateSelector(selector, 'slot', null, false)

		this.style.backgroundColor = null
		this.style.backgroundImage = null
		this.classList.remove("animate-background")
		if ( this.lastBgAnimate ) {
			this.classList.remove(this.lastBgAnimate)
			delete this.lastBgAnimate
		}

//		this.querySelector('style')?.innerHTML += UI.global.gameStyles

		if ( this.hasCurrentSlide() ) {
			let current = this.getCurrentSlide()

			await this.updateBackground(current)

/*
			let videoEnding = this.updateVideo(current)

			videoEnding.then((goToNextSlide) => {
				if ( goToNextSlide ) {
					if ( this.hasNextSlide() ) {
						this.quickShowNextSlide()
					} else {
						this.view.hide()
					}
				}
			})
*/
		}
	}

	async updateBackground(slide) {
		const backgroundColor = slide.getAttribute('background')
		const backgroundImageName = slide.getAttribute('background-image')
		const backgroundAnimate = slide.getAttribute('animate-background')

		if ( !backgroundColor && !backgroundImageName && !backgroundAnimate) {
			// skip changes and leave background as is
			return
		}

		if ( backgroundColor && backgroundColor != "transparent" ) {
			this.style.backgroundColor = backgroundColor
		} else {
			this.style.backgroundColor = null
		}
		
		if ( backgroundImageName ) {
			let backgroundAsset = Game.global.getAsset(backgroundImageName)

			this.style.background = null
			this.style.backgroundImage = `url("${backgroundAsset.url}")`;
		} else {
			this.style.backgroundImage = null
		}

		if ( backgroundAnimate ) {

			if ( this.lastBgAnimate ) {
				this.classList.remove(this.lastBgAnimate)
				delete this.lastBgAnimate
			}

			this.classList.add("animate-background")
			this.classList.add(backgroundAnimate)
			this.lastBgAnimate = backgroundAnimate
		} else {
			this.classList.remove("animate-background")

			if ( this.lastBgAnimate ) {
				this.classList.remove(this.lastBgAnimate)
				delete this.lastBgAnimate
			}
		}
/*

		if ( slide.nodeName == 'X-VIDEO-SLIDE' ) {
			slide.play()
		}

		let placeholders = slide.querySelectorAll('div[type="video"]')

		if ( placeholders.length > 0 ) {
			for (let element of placeholders) {
				let assetId = element.getAttribute('name')
				let crossfade = element.getAttribute('crossfade')

				let video = await this.view.game.assetManager.get(assetId).data()
				video.setAttribute('crossfade', crossfade)

				element.replaceWith(video)
			}
		}
*/
	}

	async showNextSlide() {
		let current = this.getCurrentSlide()
		let next = this.getNextSlide()

		let currentView = this.view.name

		if ( next ) {
			await this.updateBackground(next)

			await current.hide()

/*
			if ( next.nodeName == 'X-VIDEO-SLIDE' ) {
				current.play()
			}
*/

			let ifElements = next.querySelectorAll('[if-variable],[if-not-variable]')

			for ( let el of ifElements ) {
				if ( el.getAttribute('if-variable') ) {
					let value = Game.global.getGlobalVariable(el.getAttribute('if-variable'))

					!value && el.setAttribute('style', 'display: none;')
				}

				if ( el.getAttribute('if-not-variable') ) {
					let value = Game.global.getGlobalVariable(el.getAttribute('if-not-variable'))

					value && el.setAttribute('style', 'display: none;')
				}
			}

			next.querySelectorAll('.play-pre-show').forEach((e)=>{
				e.classList.remove('play-pre-show')
			});

			// Copy of this is in ViewSlideshow.startSlideshow()

			if ( next.getAttribute('music-pre-show') ) {
				let assetId = next.getAttribute('music-pre-show')

				Game.global.playForegroundMusic(assetId, this.view.name)
			}

			if ( next.getAttribute('voice-pre-show') ) {
				let assetId = next.getAttribute('voice-pre-show')

				Game.global.playVoice(assetId, this.view.name)
			}

			if ( next.getAttribute('sfx-pre-show') ) {
				let assetId = next.getAttribute('sfx-pre-show')

				Game.global.playSfx(assetId, this.view.name)
			}

			await next.show()

			this.view.updateFocus()

			next.querySelectorAll('.play-on-show').forEach((e)=>{
				e.classList.remove('play-on-show')
			});

			if ( next.getAttribute('delay-post-show') ) {
				let time = Math.round(1000 * next.getAttribute('delay-post-show'))
				await sleep( time )
			}

			if ( this.view.ended || this.view.name != currentView ) {
				log("View ended, skipping post activities")
				return
			}

			if ( next.getAttribute('music-post-show') ) {
				let assetId = next.getAttribute('music-post-show')

				Game.global.playForegroundMusic(assetId, this.view.name)
			}

			if ( next.getAttribute('voice-post-show') ) {
				let assetId = next.getAttribute('voice-post-show')

				Game.global.playVoice(assetId, this.view.name)
			}

			if ( next.getAttribute('sfx-post-show') ) {
				let assetId = next.getAttribute('sfx-post-show')

				Game.global.playSfx(assetId, this.view.name)
			}

			this.checkTimer()

		} else {

			this.view.endSlideshow(true)
		}



/*
		let videoEnding = this.updateVideo(next)

		videoEnding.then((goToNextSlide) => {
			if ( goToNextSlide ) {
				if ( this.hasNextSlide() ) {
					this.showNextSlide()
				} else {
					this.view.hide()
				}
			}
		})
*/
	}

	hasCurrentSlide() {
		return this.shadowRoot.querySelector('x-slide.visible,x-video-slide.visible') != null
			|| this.shadowRoot.querySelector('x-slide,x-video-slide') != null
	}

	getCurrentSlide() {
		let current = this.shadowRoot.querySelector('x-slide.visible,x-video-slide.visible')

		if ( !current ) {
			// first slide
			current = this.shadowRoot.querySelector('x-slide,x-video-slide')

			if ( !current ) {
				debug("SlideSet.getCurrentSlide: didn't find any x-slide or x-video-slide elements.")
				return null
			}
		}

		return current
	}

	getNextSlide() {
		let current = this.getCurrentSlide()
		let next = current.nextSibling

		while ( next != null && !(next.nodeName == 'X-SLIDE' || next.nodeName == 'X-VIDEO-SLIDE') ) {
			next = next.nextSibling
		}

		if ( !next || !(next.nodeName == 'X-SLIDE' || next.nodeName == 'X-VIDEO-SLIDE') ) {
			return null
		}

		return next
	}

	hasNextSlide() {
		let next = this.getNextSlide()

		return next ? true : false
	}

	async checkTimer() {
		let current = this.getCurrentSlide()
		let timer = Number(current.getAttribute('timer'))
		let viewWithTimer = this.view.name

		if ( timer > 0 ) {
			await sleep(1000*timer)

			// slideshow skipping might break timer
			if ( this.view.ended || this.view.name != viewWithTimer ) {
				return
			}

			if ( this.view.isVisible() ) {
				await this.showNextSlide()
			}
		}
	}

	/**
	 * All handlers are in ViewSlideshow
	 */
	async action(listOfActions, element, event) {
		await this.view.action(listOfActions, element, event)
	}

	makeChoice(choiceId) {
		this.choices.push(choiceId)
	}

	removeChoice(choiceId) {
		this.choices = this.choices.filter( x => x !== choiceId )
	}

	hasChosen(choiceId) {
		return this.choices.includes(choiceId)
	}

	getChoices() {
		return [...this.choices]
	}

	clearChoices() {
		this.choices = []
	}
}

import importMainStyles from "css/import-main.css.mjs";

SlideSet.templateString = `

<style>
	${ importMainStyles }

	:host {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;

		background: var(--slideset-background);
		background-position: center;
		background-size: cover;
		background-repeat: none;
	}

	:host(.bganimate.zoominout) {
	  animation-name: animateZoomInOut;
	  animation-duration: 60s;
	  animation-timing-function: easeInOut;
	  animation-iteration-count: infinite;
	  animation-direction: alternate;

	  background-size: auto 100%;
	}

	@keyframes animateZoomInOut {
	  0% {
	    background-size: auto 100%;
	  }
	  100% {
	    background-size: auto 160%;
	  }
	}

</style>
<slot></slot>

`;

window.customElements.define('x-slideset', SlideSet)


export class Slide extends AnimatedElement {

	static templateString

	/**
	 * This object is created by DOM as the template is attached.
	 *
	 * If the element is within a slideset, we can link to the parent view.
	 */
	constructor() {
		super()
	}

	connectedCallback() {
		this.slideset = this.getRootNode().host

		if ( !this.slideset ) {
			debug(`Slide.constructor: i don't have a slideset`)
		}
		else if ( !this.slideset.view ) {
			debug(`Slide.constructor: i don't have a view in my slideset`)
		}
		else {
			this.view = this.slideset.view
		}
	}

	/**
	 * All handlers are in ViewSlideshow
	 */
	async action(button, event) {
		await this.view.action(button, event)
	}
}

import importAnimatedElementStyles from "css/import-animated-element.css.mjs";
import importSlideStyles from "css/import-slides.css.mjs";

Slide.templateString = `

<style>
	${ importMainStyles }
	${ importAnimatedElementStyles }
	${ importSlideStyles }
</style>

`;

window.customElements.define('x-slide', Slide)

/*
export class VideoSlide extends Slide {

	// This is created by DOM as ViewSlideshow.showSlideshow() attached
	// the SlideSet template.
	//
	constructor() {
		super()

		this.replaceVideos()
	}

	async replaceVideos() {
		let placeholders = this.shadowRoot.querySelectorAll('video')

		for ( let element of placeholders ) {
			let assetId = element.getAttribute('name')
			let crossfade = element.getAttribute('crossfade')

			let video = this.view.game.assetManager.get(assetId).data()
			if ( video instanceof Promise ) { video = await video }
				
			element.replaceWith(video)
		}
	}

	async play() {
		await this.replaceVideos()

		let videos = this.shadowRoot.querySelectorAll('video')

		if ( !videos || videos.length == 0 ) {
			warn("VideoSlide.play: can't find any videos to play")
			return
		}

		// because last element of DOM is visibly on top, play in reverse order..
		//
		let first = videos[ videos.length - 1 ]

		for ( let i = videos.length - 1 ; i >= 0 ; i-- ) {
			let current = videos[i]
			let next = i - 1 >= 0 ? videos[i-1] : null

			current.classList.add('visible')

			current.addEventListener('ended', async (event) => {
				if ( next ) {
					current.classList.remove('visible')
					next.classList.add('visible')
					next.play()
				} else {
					this.slideset.showNextSlide()
				}
			})
		}

		first.play()
	}
}

window.customElements.define('x-video-slide', VideoSlide)
*/
