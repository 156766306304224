/**
 * Abstract class AssetManager for generic asset management.
 *
 * Use storage class like: AssetsBrowserCache
 *
 */

import { log, debug, error } from 'global.js'

import { isNumber, isDef } from 'validators.mjs'

export class AssetManager {

	static version = 33;
	static minQuality = 1;
	static maxQuality = 3;
	static currentQuality = 2;
	static currentQualityScale = 1;
	static SHARED_ASSETS = {};
	static assetCount = 0;

	/**
	 * Registers all available assets for later use.
     *
	 * @param {GAME_ASSETS} specs for all available assets
	 */
	constructor(specs, quality, qualityScale) {
		if (this.constructor == AssetManager) { throw "AssetManager.constructor() Tried to create object from abstract class AssetManager" }

		if ( specs.version != AssetManager.version ) { throw `AssetManager.constructor() bad asset definition file: version != ${ AssetManager.version }` }

		if ( !isNumber(quality) && (quality < AssetManager.qualityMin || quality > AssetManager.qualityMax) ) { throw `AssetManager: quality unspecified or out of bounds: ${ AssetManager.qualityMin } =< ${ quality } <= ${ AssetManager.qualityMax }`}

		AssetManager.currentQuality = quality

		AssetManager.currentQualityScale = qualityScale

		for ( let id in specs ) {
			if ( id == "version") { continue }

			let { type, url, width, height, style, lang, length, maxQuality, scale, preload } = (typeof specs[id] == "string" ? AssetManager.SHARED_ASSETS[specs[id]] : specs[id])

			this.register(id, type, width, height, url, style, lang, length, maxQuality, scale, preload)
		}
	}

	/**
	 * Register an asset
	 *
	 * @param {string} id
	 * @param {string} type
	 * @param {number} width pixels (image, video)
	 * @param {number} height pixels (image, video)
	 * @param {string} url
	 * @param {string} style E.g. 'music', 'voice', 'sfx'
	 * @param {string} lang NOT USED
	 * @param {number} length seconds NOT USED
	 * @param {boolean} scale Resize width/height using this scale
	 * @param {boolean} preload Should preload or not
	 */
	register(id, type, width, height, url, style, lang, length, maxQuality, scale, preload) {
		if ( AssetManager.SHARED_ASSETS[id] != null ) { throw `AssetManager.register: asset id already in use: ${ id }` }

		if ( maxQuality && AssetManager.currentQuality > 1 ) {
			let useQuality = Math.min(maxQuality, AssetManager.currentQuality)

			url = url.replace('@1x', `@${ useQuality }x`)
		}

		let asset = new Asset(this, id, type, width, height, url, style, lang, length, maxQuality, scale, preload)

		AssetManager.SHARED_ASSETS[id] = asset

		AssetManager.assetCount += 1

		return asset
	}

	/**
	 * List of registered asset ids
	 *
	 * @return [string]
	 */
	listAllIds() {
		let ids = Object.keys(AssetManager.SHARED_ASSETS)

		return ids
	}

	/**
	 * List of registered non-audio asset ids
	 *
	 * @return [string]
	 */
	listNonAudioIds() {
		let ids = []

		for ( let id in AssetManager.SHARED_ASSETS ) {
			if ( AssetManager.SHARED_ASSETS[id].type !== "audio" ) {
				ids.push(id)
			}
		}

		return ids
	}


	/**
	 * List of registered audio asset ids
	 *
	 * @return [string]
	 */
	listAudioIds() {
		let ids = []

		for ( let id in AssetManager.SHARED_ASSETS ) {
			if ( AssetManager.SHARED_ASSETS[id].type === "audio" ) {
				ids.push(id)
			}
		}

		return ids
	}

	/**
	 * Retuns the asset from AssetManager.
	 *
	 * Use asset.data() to get the actual image.
	 *
	 * @param {string} id for the asset
	 * @return {Asset} asset
	 * @throws NotFound
	 */
	get(id) {
		if ( !id ) { throw `AssetManager.get: one of the required parameters is missing: ${arguments}` }

		if ( !AssetManager.SHARED_ASSETS[id] ) { throw "AssetManager.get: no asset registered with id: " + id }

		let asset = AssetManager.SHARED_ASSETS[id]

		return asset
	}

	/**
	 * Return asset data (image, audio).
	 *
	 * Calls .get(id).data()
	 * @param {string} id
	 * @return Image, Audio, ..
	 */
	getData(id) {
		return this.get(id).data()
	}

	/**
	 * Returns the asset from AssetManager, preloaded.
	 *
	 * @param {string} id for the asset
	 * @return {Asset} asset
	 * @throws NotFound
	 */
	async getPreloaded(id) {
		let asset = this.get(id)

		let data = asset.data()
		if ( data instanceof Promise ) { data = await data }

		return asset
	}

	getAssetCount() {
		return AssetManager.assetCount
	}
}

/**
 * Asset wrapper class
 *
 * Get contents with asset.data()
 */
export class Asset {
	constructor(assetManager, id, type, width, height, url, style, lang, length, maxQuality, scale, preload) {
		this.manager = assetManager
		this.id = id
		this.type = type
		this.originalWidth = width
		this.originalHeight = height
		this.width = (scale && width) ? Math.round(width * AssetManager.currentQualityScale) : width
		this.height = (scale && height) ? Math.round(height * AssetManager.currentQualityScale) : height
		this.url = url
		this.style = style
		this.lang = lang
		this.length = length
		this.maxQuality = maxQuality || 1
		this.scale = scale
		this.visible = false
		this.preload = isDef(preload) ? preload : true

		if ( !id ) { throw `Asset.constructor: missing id` }

		if ( !type || !type.match(/^(image|video|css|slideset|audio)$/i) ) {
			throw `Asset: invalid type ${type}. Must be: image|video|css|slideset|audio`
		}

		if ( (type == 'image' || type == 'video' ) && !width ) { throw `Asset.constructor: missing width for image/video: ${ id }` }
		if ( (type == 'image' || type == 'video' ) && !height ) { throw `Asset.constructor: missing height for image/video: ${ id }` }
		if ( (type == 'image' || type == 'video' ) && !url ) { throw `Asset.constructor: missing url for image/video: ${ id }` }
		if ( type == 'css' && !style ) { throw `Asset.constructor: missing style for css: ${ id }` }
		if ( type == 'slideset' && !lang ) { throw `Asset.constructor: missing lang for slideset: ${ id }` }
//		if ( type == 'audio' && length !== 0 && !length ) { throw `Asset.constructor: missing length for audio: ${ id }` }
	}

	/**
	 * Returns the asset data in the format provided by used AssetManager.load()
	 *
	 * If the asset hasn't been preloaded before, this will load it.
	 *
	 * @return {data|Promise} data resolves to asset data depending on AssetManager
	 */
	data() {
		return this.manager.load(this.id)
	}
}

