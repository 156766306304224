import { toNumber, sleep, error, warn, log, debug } from 'global.js'
import { ViewLayer } from 'UI/ViewLayer.mjs'
import { CustomElement, AnimatedElement } from 'UI/CustomElement.mjs'

import { ACTION_SEPARATOR } from 'UI/UI.mjs'

import { Game } from 'Game/Game.mjs'

import { SlideSet, Slide } from 'UI/Slides.mjs'

export class ViewSlideshow extends ViewLayer {

	static templateString

	name;
	language;
	slideset;

	constructor() {
		super()

		this.language = Game.global.settings.language
		this.slideset = this.querySelector('x-slideset')
	}

	/**
	 * Opens the slideshow view with this content.
	 *
	 * Does not return until slideshow is finished, unless returnAfterShow is true.
	 *
	 * @param {string} name of the slideshow
	 * @param {boolean} returnAfterShow (optional)
	 * @param {function} onclose callback (optional)
	 * @param {boolean} animate (optional)
	 * @param {boolean} skippable (optional)
	 */
	startSlideshow(name, returnAfterShow, onclose, animate, skippable) {

		this.name = name

		if ( this.ended ) { delete this.ended }

		let selector = `template.slides[name="${name}"][language="${this.language}"]`

		if ( Game.global.getTemplateWithSelector(selector) == null ) {
			throw `ViewSlideshow.startSlideshow: can't find template with: ${selector}`
		}

		this.onclose = onclose
		this.skippable = skippable
		this.animate = animate

		return new Promise(async (resolve, reject) => {

			let templateElement = Game.global.getTemplateWithSelector(selector)

            if ( templateElement.classList.contains('very-slow') ) {
                this.classList.add("very-slow")
            } else {
                this.classList.remove("very-slow")
            }

            if ( templateElement.classList.contains('slow') ) {
                this.classList.add("slow")
            } else {
                this.classList.remove("slow")
            }

            if ( templateElement.classList.contains('fast') ) {
                this.classList.add("fast")
            } else {
                this.classList.remove("fast")
            }

			await this.slideset.content(selector)

			this.returnFromSlideshow = resolve

			let next = this.slideset.getCurrentSlide()

			// Copy of this is in Slides.nextSlide()

			let ifElements = next.querySelectorAll('[if-variable]')

			for ( let el of ifElements ) {
				let varName = el.getAttribute('if-variable')

				if ( Game.global.getGlobalVariable(varName) ) {
					el.setAttribute('style', 'display: none;')
				}
			}

			next.querySelectorAll('.play-pre-show').forEach((e)=>{
				e.classList.remove('play-pre-show')
			});

			if ( next.getAttribute('music-pre-show') ) {
				let assetId = next.getAttribute('music-pre-show')

				Game.global.playForegroundMusic(assetId, this.name)
			}

			if ( next.getAttribute('voice-pre-show') ) {
				let assetId = next.getAttribute('voice-pre-show')

				await Game.global.playVoice(assetId, this.name)
			}

			if ( next.getAttribute('sfx-pre-show') ) {
				let assetId = next.getAttribute('sfx-pre-show')

				Game.global.playSfx(assetId)
			}

			await this.show(animate)

			next.querySelectorAll('.play-on-show').forEach((e)=>{
				e.classList.remove('play-on-show')
			});

			if ( next.getAttribute('delay-post-show') ) {
				let time = Math.round(1000 * next.getAttribute('delay-post-show'))
				await sleep( time )
			}

			if ( next.getAttribute('music-after-show') ) {
				let assetId = next.getAttribute('music-after-show')

				Game.global.playForegroundMusic(assetId, this.name)
			}

			if ( next.getAttribute('voice-after-show') ) {
				let assetId = next.getAttribute('voice-after-show')

				await Game.global.playVoice(assetId, this.name)
			}

			if ( next.getAttribute('sfx-after-show') ) {
				let assetId = next.getAttribute('sfx-after-show')

				Game.global.playSfx(assetId, this.name)
			}

			this.slideset.checkTimer()

			if ( returnAfterShow ) {
				this.returnFromSlideshow(this.slideset.getChoices())
			}
		})
	}

	listSlides() {
		let selector = `template.slides[language="${this.language}"]`

		let slideElements = Game.global.getAllTemplatesWithSelector(selector)

		let slideNames = []

		for ( let s of slideElements ) {
			slideNames.push(s.getAttribute('name'))
		}

		return slideNames
	}

	async endSlideshow(animate) {

		if ( this.onclose ) {
			try {
				await this.onclose()
			} catch (err) {
				throw `ViewSlideshow.endSlideshow: callback onclose threw an excepton: ${err}`
			}
		}

		Game.global.fadeOutAllAudioWithTag(this.name)

		await this.hide(animate)

		if ( this.returnFromSlideshow ) {
			this.returnFromSlideshow(this.slideset.getChoices())

			delete this.returnFromSlideshow
		}

		Game.global.endSlideshowEvent(this)

		this.slideset.clearChoices()
	}

	/**
	 * @param {string} listOfActions comma separated list of actions
	 * @param {HTMLElement} [element] (optional)
	 * @param {Event} [event] (optional)
	 * @returns true if an action was made
	 */
	async action(listOfActions, element, event) {

		const actionSeparator = ','

		let choice = element?.getAttribute?.('choice')
		let select = element?.getAttribute?.('select')
		let unselect = element?.getAttribute?.('unselect')

		let feedback = false

		if ( choice ) {
			this.slideset.makeChoice(choice)
			feedback = true
		}

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				switch(action) {
					case "next":
						await this.slideset.showNextSlide()
						feedback = true
						break;

					case "default":
					case "end":
						this.ended = true
						this.endSlideshow(this.animate)
						feedback = true
						break;

					case "select":
						if ( select ) { this.slideset.makeChoice(select) }
						else if ( unselect ) { this.slideset.removeChoice(unselect) }
						else { error(`ViewSlideshow.action: 'select' action doesn't have 'select' or 'unselect' attributes`) }
						feedback = true
						break;
				}
			}
		}

		return feedback
	}

	getDefaultButton() {
		let slide = this.slideset.querySelector('x-slide.visible')
		let button = slide?.getDefaultButton()

		return button
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewSlideshow.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }
</style>
<x-slideset></x-slideset>

`;

window.customElements.define('view-slideshow', ViewSlideshow)
