import { sleep, log, warn, error, mixin } from 'global.js'
import { isDefNotNull, isString, isObject } from 'validators.mjs'

import { HasAssets } from 'Thing/HasAssets.mjs'
import { HasProps } from 'Thing/HasProps.mjs'


export class Generic extends mixin(HasAssets, HasProps) {
	constructor(id, {name, type, status, meta, assets}) {
		super()

		if ( !id || !isString(id) ) { throw `Generic.constructor() missing valid id`}
		if ( isDefNotNull(name) && !isString(name) ) { throw `Generic.constructor() missing valid 'name' for id '${id}'. Got: ${name}`}
		if ( isDefNotNull(type) && !isString(type) ) { throw `Generic.constructor() missing valid 'type' for id '${id}'. Got: ${type}`}
		if ( isDefNotNull(status) && !isString(status) ) { throw `Generic.constructor() missing valid 'status' for id '${id}'. Got: ${status}`}
		if ( isDefNotNull(meta) && !isObject(meta) ) { throw `Generic.constructor() 'meta' is not an object for id '${id}'. Got: ${meta}'`}
		if ( isDefNotNull(assets) && !isObject(assets) ) { throw `Generic.constructor() missing valid 'assets' for id '${id}'. Got: ${assets}`}

		this.id = id
		this.name = name
		this.type = type
		this.status = status
		this.meta = meta

		/* TODO: try to refactor this into HasAssets - doesn't seems to work with mixin() */
		Object.defineProperty(this, 'assets', { value: {}, writable: true })

		this.addAssets(assets)
	}

	getMeta(name) {
		return this.meta[name]
	}

	setMeta(name, newValue) {
		this.meta[name] = newValue

		return newValue
	}

	getType() {
		return this.type
	}

	setType(newType) {
		this.type = newType
	}

	getStatus() {
		return this.status
	}

	setStatus(newStatus) {
		this.status = newStatus
	}
}
