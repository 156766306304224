import { generateAssetUrl } from "../global.js";

export default `

/* var.css */

:host {
	--background: #000000;
	--body-color: #cccccc;
	--text-color: #ffffff;
	--title-color: #ffffff;
	--default-font-family: sherif;
  	--title-font-family: sherif;
  	--button-font-family: sherif;

	--animation-length-default: 1s;
	--animation-length-fast: 0.25s;
	--animation-length-slow: 2.5s;
	--animation-length-very-slow: 5s;

	--tutorial-font-family: sans-serif;
	--tutorial-text-color: #ffffff;
	--tutorial-icon-bg: url("${generateAssetUrl('media/general/tutorial@1x.png')}");

	--dialogue-message-text-color: #ffffff;
	--dialogue-message-text-alt-color: #ffffbb;
	--dialogue-narrator-text-color: #ffffbb;
	--dialogue-tutorial-text-color: #ffffff;

	--text-button-color: #ffffff;
	--text-button-hover-color: #ff0000;
	--text-button-focus-color: #ff0000;
	--focus-shadow-color: rgba(255, 0, 0, 0.5);
	--hover-shadow-color: rgba(255, 0, 0, 0.5);

	--box-button-background: #ffffff;
	--box-button-color: #000000;

	--view-default-bg: #000000;

	--slide-text-shadow: 2px 2px #000000;

	--popup-fog-color:  rgba(0,0,0, 0.7);
	--popup-bg-color: #000000;
	--popup-text-color: #ffffff;
	--popup-border-color: #ffffff;
	--popup-border-radius: 3px;

	--actionmenu-text-color: #5a4413;
	--actionmenu-bar-color: #877752;
	--actionmenu-player-button-color: #403a21;
	--actionmenu-bar-bg: url("${generateAssetUrl('media/general/bar@1x.png')}");
	--actionmenu-button-bg: url("${generateAssetUrl('media/general/button-end-player-turn@1x.png')}");
	--actionmenu-text-shadow: 0px -1px #9c8245;

	--dice-width: 48px;
	--dice-height: 48px;
	--image-dice-minus: url("${generateAssetUrl('media/general/dice-arrow-left-default@1x.png')}");
	--image-dice-minus-focus: url("${generateAssetUrl('media/general/dice-arrow-left-focus@1x.png')}");
	--image-dice-plus: url("${generateAssetUrl('media/general/dice-arrow-right-default@1x.png')}");
	--image-dice-plus-focus: url("${generateAssetUrl('media/general/dice-arrow-right-focus@1x.png')}");

	--card-width: 280px;
	--card-height: 420px;
	--card-bg-color: #6a6a6a;
	--card-text-color: #efefef;
	--card-border-front-color: #6a6a6a;
	--card-border-back-color: #820605;
	--card-border-hover-color: #ff0000;
	--card-border-selected-color: #cc0000;
	--card-border-radius: 6px;
	--card-border-width: 3px;
	--card-box-shadow: rgba(0,0,0, 0.5) 5px 5px 10px;
	--card-selected-box-shadow: rgba(200,0,0, 0.5) 5px 5px 10px;

	--view-attack-enemy-card-x: 0deg;
	--view-attack-enemy-card-y: 0deg;
	--view-attack-enemy-card-z: 0deg;

	--card-stats-bg: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
	--card-stats-icon-width: 48px;
	--card-stats-icon-height: 48px;
	--image-card-stats-health: url("${generateAssetUrl('media/general/health-color@1x.png')}");
	--image-card-stats-attack: url("${generateAssetUrl('media/general/attack-color@1x.png')}");

	--player1-text-color: #9747FF;
	--player2-text-color: #4786FF;
	--player3-text-color: #FF9900;
	--player4-text-color: #14D9D9;
	--player5-text-color: #FF8B8B;
	--player6-text-color: #00CD14;
	--player7-text-color: #00CD14;
	--player8-text-color: #00CD14;

	--overlaymenu-border-color: #ffffff;
	--overlaymenu-button-bg-color: #000;
	--overlaymenu-button-text-color: #fff;

	--dice-label-any-bg-color: #fff;
	--dice-label-any-text-color: #000;

	--dice-label-strength-bg-color: #f00;
	--dice-label-strength-text-color: #000;

	--dice-label-agility-bg-color: #ff0;
	--dice-label-agility-text-color: #000;

	--dice-label-intelligence-bg-color: #f0f;
	--dice-label-intelligence-text-color: #000;

	--dice-cost-generic-bg-color: #ccc;
	--dice-cost-generic-text-color: #000;

	--action-token-bg-color: #a0a;
	--action-token-text-color: #B38BE7;

	--text-max-width: 100rem;
}

`;
