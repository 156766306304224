/**
 * Abstract class AssetManager for generic asset management.
 *
 * Use storage class like: AssetsBrowserCache or LocalStorage (not yet implemented)
 *
 * You can freely use multiple storage objects, as each will keep track of assets
 * registeted via that object but efficiently share duplicate assets.
 *
 * let cache1 = new AssetsBrowserCache()
 * cache1.addImage( ... resource 1 ... )
 *
 * let cache2 = new AssetsBrowserCache()
 * cache2.addImage( ... resource 1 ... )
 * cache2.addImage( ... resource 2 ... )
 *
 * Resources 1 and 2 are globally shared between cache1 and cache2, because they
 * are of the same type (AssetsBrowserCache). Both can get() assets registered by each other.
 *
 * cache1.releaseAll() will remove the resource 1, but not 2, because it was
 * registered by cache1.
 *
 * cache2.release() will remove resources 1 and 2 and cache1 will also lose it.
 * Next time cache1.get() is called, it will fetch it again.
 *
 * –––––––––––––
 *
 * Each different type of object will store the asset in their own method, so
 * same asset may be stored in two places at the same time. Avoid this.
 *
 * let cache1 = new AssetsBrowserCache()
 * let cache2 = new LocalStorage()
 *
 * cache1.addImage ( ... resource 1 ... )
 * cache2.get( ... resource 1 ... )
 *
 * This will fetch the resource by cache2 and store it in it's own way.
 *
 * cache1.get( ... resource 1 ... )
 *
 * This will fetch the resource AGAIN by cache1, because it can't use assets
 * managed by cache2.
 *
 * –––––––––––––
 *
 * Calling release() or releaseAll() is not necessary. Deleting the storage object
 * itself will
 *
 */

import { log, debug, error } from 'global.js'

import { Asset, AssetManager } from 'Game/Assets.mjs'

import { Audio } from 'Game/Audio.mjs'


/**
 * Loads images as regular HTML elements and they are thus stored in
 * normal browser page cache.
 *
 * Storage manager provised two methods: load(), release() and releaseAll()
 */
export class AssetsBrowserCache extends AssetManager {
	/**
	 * @param {GAME_ASSETS} specs
	 */
	constructor(specs, quality, qualityScale) {
		super(specs, quality, qualityScale)
		this.releaseList = {}
	}

	/**
	 * Creates an in-memory javascript Image object of the asset and stores it.
	 *
	 * Register the asset id into AssetManager with add() first.
	 *
	 * @param {string} id of the asset
	 * @return {Promise|image}
	 */
	load(id) {

		let { width, height, type, url, style, lang, length, maxQuality, scale} = AssetManager.SHARED_ASSETS[id]

		if ( AssetManager.SHARED_ASSETS[id][ this.constructor.name ] ) {
			// debug(`AssetsBrowserCache: image from cache: ${ url }`)
			return AssetManager.SHARED_ASSETS[id][ this.constructor.name ]
		}

		return new Promise((resolve, reject) => {

			if ( type == "video" ) {

				let video = document.createElement('video')

/*
				video.addEventListener('loadstart', (event) => { debug(`AssetsBrowserCache: video loading: ${url}`) })
				video.addEventListener('playing', (event) => { debug(`AssetsBrowserCache: video playing: ${url}`) })
				video.addEventListener('pause', (event) => { debug(`AssetsBrowserCache: video paused: ${url}`) })
				video.addEventListener('seeked', (event) => { debug(`AssetsBrowserCache: video seeked: ${url}`) })
*/
				video.addEventListener('canplaythrough', (event) => {
					//debug(`AssetsBrowserCache: video ready: ${url}`)

					// we save the data per child object name
					AssetManager.SHARED_ASSETS[id][ this.constructor.name ] = video

					this.releaseList[id] = true

//					video.pause()
//					video.currentTime = 0

					resolve(video)
				})

				video.addEventListener('error', (event) => {
					error(`AssetsBrowserCache.load() video failed: ${url}`)
					error(event)
					reject(null)
				})

		 		//debug(`AssetsBrowserCache: starting video load: ${url}`)

		 		video.width = width
		 		video.height = height
		 		video.muted = false
		 		//video.autoplay = true
		 		video.preload = 'auto'
				video.src = url
			}

			else if ( type == "image" || type == "css" ) {

				let image = new Image(width, height)

				image.onload = () => {
					//debug(`AssetsBrowserCache: image ready: ${url}`)

					// we save the data per child object name
					AssetManager.SHARED_ASSETS[id][ this.constructor.name ] = image

					this.releaseList[id] = true
					resolve(image)
				}

				image.onerror = () => {
					//debug(`AssetsBrowserCache: image failed: ${url}`)

					reject(null)
				}

		 		//debug(`AssetsBrowserCache: starting image load: ${url}`)

				image.src = url
			}

			else if ( type == "audio" ) {
				let audio = new Audio(id, url, style, length)

				audio.preload(resolve, reject)

				// we save the data per child object name
				AssetManager.SHARED_ASSETS[id][ this.constructor.name ] = audio

			}
			else {
				reject(null)
				throw `AssetsBrowserCache.load() unknown asset type: ${type} for ${id}`
			}
 		})
	}

	/**
	 * Removed the asset from AssetManager list, but this doesn't free the memory nor does it clear cache.
	 *
	 * Memory is freed by browser garbage collection once the asset is not referenced anywhere anymore.
	 *
	 * Cache is cleared per browser's internal behaviour.
	 *
	 * @param {string} id of the asset
	 */
	async release(id) {
		if ( !id ) { throw `AssetsBrowserCache.release() one of the required parameters is missing: ${arguments}` }

		delete AssetManager.SHARED_ASSETS[id][ this.constructor.name ]
		delete this.releaseList[id]
	}

	/**
	 * Removes all assets from AssetManager list, registered via this object.
	 *
	 * This doesn't free the memory nor does it remove it from disk.
	 *
	 * See release() method documentation for details.
	 */
	async releaseAll() {
		for ( let id of this.releaseList ) {
			this.release(id)
		}
	}
}
