import { sleep, log, error, warn, debug } from 'global.js'


/**
 * Runnable setInterval() implementation.
 *
 * See: RunnableWorkers.mjs for WebWorker version.
 *
 * This is depricated as of Jan 2024.
 *
 * Runnable is a setInterval based "thread" that any class can extend
 * directly or using mixin() from globals.js:
 *
 *     class Child extends Runnable
 *
 *     class Child extends mixin(Parent, Runnable)
 *
 * Child class has to implement methods:
 *
 *     async executeRunnable()
 *
 * Runnable provides methods:
 *
 *     runnable.startRunnable()
 *     runnable.pauseRunnable()
 *     runnable.stopRunnable()
 *
 */
export class Runnable {

	static intervalDelay = 20; //ms
	static executeCommandsUntilEmptyQueue = true;

	constructor(mixinClass) {
		let ChildClass = mixinClass || this.constructor

		this._runnable = {
			timer: null,
			pause: false,
			executing: false,
			haltOnExecutionFail: true
		}
	}

	startRunnable() {
		throw `Runnable.startRunnable() implement me`;
	}


	stopRunnable() {
		throw `Runnable.stopRunnable() implement me`;

	}

	pauseRunnable() {
		throw `Runnable.pauseRunnable() implement me`;
	}

	async executeRunnable(runnable) {
		try {

			if ( runnable._runnable.pause || runnable._runnable.executing || !runnable._runnable.timer ) {
				return
			}

			runnable._runnable.executing = true

			try {

				let queueLength = 0;

				do {

					queueLength = await runnable.executeCommand()

				} while ( queueLength > 0 && Runnable.executeCommandsUntilEmptyQueue )

			} catch (err) {

				error(`Runnable.executeRunnable() exception: ${ err }`)

				if ( runnable._runnable.haltOnExecutionFail ) {

					error(`Runnable.executeRunnable() stopping Runnable...`)
					runnable.stopRunnable()
				}
			}

			runnable._runnable.executing = false

		} catch (err) {
			error(`Runnable.executeRunnable() exception:`)
			error(err)

			if ( runnable._runnable.haltOnExecutionFail ) {

				error(`Runnable.executeRunnable() stopping Runnable...`)
				runnable.stopRunnable()
			}
		}
	}
}
