export default `

/* Import animated element styles */

:host {
	transition: opacity 0s ease-in !important;
	visibility: hidden;
}

:host(.visible) {
	visibility: visible !important;
}

:host(.opaque) {
	opacity: 1 !important;
}

:host(.animated) {
	transition-duration: var(--animation-length-default) !important;
}

:host(.animated.slow) {
	transition-duration: var(--animation-length-slow) !important;
}

:host(.animated.very-slow) {
	transition-duration: var(--animation-length-very-slow) !important;
}

:host(.animated.fast) {
	transition-duration: var(--animation-length-fast) !important;
}

`;