import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const DIALOGUE_EVENTS = ["dialogue-end"]

EVENT_TYPES.push(...DIALOGUE_EVENTS)

export class EndDialogueEvent extends Event {
	static eventType = "dialogue-end"
	static requiredKeys = ["event", "dialogue", "player", "characters" ]
	static optionalKeys = ["choices"]

	constructor(player, dialogue) {
		super ({
			player: player ? player.id : null,
			dialogue: dialogue.id,
			characters: dialogue.getCharactersIds(),
			choices: dialogue.getChoices(),
		})
	}
}
