import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Mission } from 'Game/Mission.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const MISSION_EVENTS = ["end-game", "mission-start", "mission-success", "mission-fail", "mission-end"]

EVENT_TYPES.push(...MISSION_EVENTS)

export class MissionEvent extends Event {

	constructor(object) {
		super(object)

		if (this.constructor == MissionEvent) {
			throw "MissionEvent.constructor(): tried to create MissionEvent object directly – use child classes"
		}
	}

	getMission() {
		return Game.global.getMission(this.mission)
	}

	validate(variables) {
		if ( !isString( this.mission ) ) {
			throw `MissionEvent.validate() mission is not a string. Got: ${ this.mission }`
		}

		if ( !isDef( this.getMission() ) ) {
			throw `MissionEvent.validate() mission '${ this.mission } is not valid.\n\nAvailable missions: ${ Game.global.getListOfMissions() }`
		}
	}
}

export class MissionStartEvent extends MissionEvent {
	static eventType = "mission-start"
	static requiredKeys = ["event", "mission"]
	static optionalKeys = []

	constructor(missionId) {
		super({ mission: missionId })
	}
}

export class MissionSuccessEvent extends MissionEvent {
	static eventType = "mission-success"
	static requiredKeys = ["event", "mission"]
	static optionalKeys = []

	constructor(missionId) {
		super({ mission: missionId })
	}
}

export class MissionFailEvent extends MissionEvent {
	static eventType = "mission-fail"
	static requiredKeys = ["event", "mission"]
	static optionalKeys = []

	constructor(missionId) {
		super({ mission: missionId })
	}
}

export class MissionEndEvent extends MissionEvent {
	static eventType = "mission-end"
	static requiredKeys = ["event", "mission"]
	static optionalKeys = []

	constructor(missionId) {
		super({ mission: missionId })
	}
}

export class MissionChangeStateEvent extends MissionEvent {
	static eventType = "mission-state-change"
	static requiredKeys = ["event", "mission", "newState", "oldState"]
	static optionalKeys = []

	constructor(missionId, newState, oldState) {
		super({
			mission: missionId,
			newState: newState,
			oldState: oldState
		})

		this.newState = newState
		this.oldState = oldState
	}

	validate(variables) {
		// 'variables' are not used, because events are created in-game,
		// not from a script

		if ( !isString( this.newState ) ) {
			throw `MissionStageChange.validate() newState is not a string. Got: ${ this.newState }`
		}

		if ( !this.getMission().getListOfStates().includes( this.newState ) ) {
			throw `MissionStageChange.validate() newState '${ this.newState }' is not valid.\n\nAvailable states: ${ this.getMission().getListOfStates().join(', ') }`
		}

		if ( this.oldState && !isString( this.oldState ) ) {
			throw `MissionStageChange.validate() oldState is not a string. Got: ${ this.oldState }`
		}

		if ( this.oldState && !this.getMission().getListOfStates().includes( this.oldState ) ) {
			throw `MissionStageChange.validate() oldState '${ this.oldState }' is not valid.\n\nAvailable states: ${ this.getMission().getListOfStates().join(', ') }`
		}

		super.validate(variables)
	}
}

export class EndGameEvent extends Event {
	static eventType = "end-game"
	static requiredKeys = []
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject)
	}
}

