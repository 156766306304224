import { generateAssetUrl } from "../global.js";

export default `
/* Import main styles */

:host {
	font-family: var(--default-font-family);
	font-style: normal;
	font-size: 16px;
	line-height: 18px;

	/* @1x */
	--xxs-pad: 0.25rem;
	--xxs-font-size: 1.0rem;
	--xxs-line-height: 1.25rem;
	--xxs-shadow: 0 0 1rem;

	--xs-pad: 0.25rem;
	--xs-font-size: 1.25rem;
	--xs-line-height: 1.5rem;
	--xs-shadow: 0 0 1rem;

	--s-pad: 0.5rem;
	--s-font-size: 1.5rem;
	--s-line-height: 2rem;
	--s-shadow: 0 0 0.75rem;

	--m-pad: 1rem;
	--m-font-size: 2rem;
	--m-line-height: 2.75rem;
	--m-shadow: 0 0 0.5rem;

	--l-pad: 2rem;
	--l-font-size: 2.5rem;
	--l-line-height: 3.5rem;
	--l-shadow: 0 0 0.4rem;

	--xl-pad: 3rem;
	--xl-font-size: 3rem;
	--xl-line-height: 4.5rem;
	--xl-shadow: 0 0 0.4rem;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	font-family: var(--title-font-family, serif);
	color: var(--title-color);
}

.tutorial {
	font-family: var(--tutorial-font-family, sans-serif);
}

.tutorial p {
	color: var(--tutorial-text-color);
	font-size: var(--xs-font-size);
	line-height: var(--xs-line-height);
}

h1 {
	font-size: var(--xl-font-size);
	line-height: var(--xl-line-height);
}

h2 {
	font-size: var(--l-font-size);
	line-height: var(--l-line-height);
}

h3 {
	font-size: var(--m-font-size);
	line-height: var(--m-line-height);
}

p, li {
	font-size: var(--s-font-size);
	line-height: var(--s-line-height);
	margin-block: 0.5em;
}

p.guide {
	font-style: italic;
	color: var(--tutorial-text-color);
}

p.guide .label {
	font-size: inherit;
	white-space: nowrap;
}

.label {
    background: #fff;
    color: #000;
    padding: 2px 6px;
    border-radius: 9px;
    text-shadow: none;
    margin-left: 2px;
   	margin-right: 2px;
   	font-style: normal;
   	font-size: var(--s-font-size);
   	white-space: nowrap;
}

.dice-strength {
	background-color: var(--dice-label-strength-bg-color);
	color: var(--dice-label-strength-text-color);
}

.dice-agility {
	background-color: var(--dice-label-agility-bg-color);
	color: var(--dice-label-agility-text-color);
}

.dice-intelligence {
	background-color: var(--dice-label-intelligence-bg-color);
	color: var(--dice-label-intelligence-text-color);
}

.dice-any {
	background-color: var(--dice-label-any-bg-color);
	color: var(--dice-label-any-text-color);
}

.cost-generic {
	background-color: var(--dice-cost-generic-bg-color);
	color: var(--dice-cost-generic-text-color);
}

.end-round {
	background-color: #fff;
	color: #000;
}

.image-label {
	position: relative;
	margin-left: 1.7em;
	font-style: normal;
}

.image-label:before {
  content: " ";	
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 1.5em;
	height: 1.5em;
	top: -0.1em;
	left: -1.7em;
}

.image-label.action-token {
	color: var(--action-token-text-color);
	margin-left: 3rem !important;
}

.image-label.action-token:before {
	background-image: url("${generateAssetUrl("media/general/action-token@1x.png")}");
}

span.image {
	display: inline-block;
}

.image.positive-token {
  background-image: url("${generateAssetUrl("media/general/positive-token@1x.png")}");
  background-size: cover;
  width: 3.5rem;
  height: 3.5rem;
}

.image.negative-token {
  background-image: url("${generateAssetUrl("media/general/negative-token@1x.png")}");
  background-size: cover;
  width: 3.5rem;
  height: 3.5rem;
}

.vertical-center {
	vertical-align: middle;
}

text-button:hover {
	font-family: var(--button-font-family);
	cursor: pointer;
}

ul,li {
	width: 100%;
}

`;
