import { sleep, log, warn, error, debug, replaceVars, replaceTrans, replaceTransVars, replaceObjectTransVars} from 'global.js'
import { isDef, isNull, isBoolean, isObject, isString, isVariable, isInteger, isNumber, isArray, isFunction, objectHas, objectHasOnly, arrayHasOnly } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { UI } from 'UI/UI.mjs'

import { Character } from 'Thing/Characters.mjs'
import { THING_PROPS } from 'Thing/Thing.mjs'

const CMD_START = 'command-start'
const CMD_END = 'command-start'
const CMD_SUCCESS = 'command-start'
const CMD_FAIL = 'command-fail'


/**
 * Command class (abstract)
 *
 */


export class Command {

	//  These are defined in child classes

	/** @type {array} */
	static requiredKeys;

	/** @type {array} */
	static optionalKeys;

	/** @type {boolean} */
	static allowAdditionalKeys;

	/** @type {boolean} ??? */
	queueOnTop = false;

	/** @type {array} */
	commandCallbacks = [];

	/** @type {string} */
	tag;

	/** @type {string} */
	tagParent;

	/** @type {object} */
	eventObject;

	constructor(object) {

		if ( this.constructor == Command ) { throw "Command.constructor() tried to create abstract class object – use child classes." }

		if ( isObject(object) ) {

			for ( let key in object ) {
				if ( key === "requiredKeys" ) { throw "Command.constructor() can't use key 'requiredKeys'" }
				if ( key === "optionalKeys" ) { throw "Command.constructor() can't use key 'optionalKeys'" }
				if ( key === "allowAdditionalKeys" ) { throw "Command.constructor() can't use key 'allowAdditionalKeys'" }

				this[key] = object[key]
			}

		}

		Object.defineProperty(this, 'queueOnTop', { value: false, enumerable: false, writable: true })
		Object.defineProperty(this, 'commandCallbacks', { enumerable: false, writable: false })
		Object.defineProperty(this, 'tag', { value: undefined, enumerable: false, writable: true })
		Object.defineProperty(this, 'tagParent', { value: undefined, enumerable: false, writable: true })
		Object.defineProperty(this, 'eventObject', { value: undefined, enumerable: false, writable: true })
	}


	/**
	 * Basic validation of the command with:
	 * - object has only required keys in it
	 * - object may have optional keys in it
	 * - object does not have any other keys besides required + optional
	 * - only one at a time: cmd, group, if, ifAll, ifAny
	 * - no multiple key-values
	 *
	 * You can allow additional unknown keys with child class static
	 * member 'allowAdditionalKeys = true'
	 *
	 * @param {object} [variables]
	 * @throw Error if invalid object
	 */
	validate(variables) {

		let ChildClass = this.constructor

		if ( !ChildClass.requiredKeys ) {
			throw `Command.validate() child class ${ChildClass.name} does not define static 'requiredKeys'. Use empty array if needed.`
		}

		if ( !ChildClass.optionalKeys ) {
			throw `Command.validate() child class ${ChildClass.name} does not define static 'optionalKeys'. Use empty array if needed.`
		}

		if ( ChildClass.allowAdditionalKeys ) {
			objectHas(this, ChildClass.requiredKeys)

		} else {

			if ( !objectHas( this, ChildClass.optionalKeys, ChildClass.requiredKeys) ) {
				throw `Command.validate() this object has unallowed keys.\n\nRequired: ${ ChildClass.requiredKeys.join(', ')}\nOptional: ${ ChildClass.optionalKeys.join(',') }\n\nGot: ${ Object.keys(this).join(', ') }`
			}
		}
	}

	hasCallbacks() {
		return isArray(this.commandCallbacks) && this.commandCallbacks.length > 0
	}

	addCallback(callback) {
		this.commandCallbacks.push(callback)
	}

	resolveCallbacks() {
		for ( let callback of this.commandCallbacks ) {
			callback()
		}
	}

	getCallbacks() {
		return this.commandCallbacks
	}

	setTag(tagName) {
		this.tag = tagName
	}

	getTag() {
		return this.tag
	}

	hasTag(tagName) {
		return tagName ? this.tag === tagName : isDef(this.tag)
	}

	setParentTag(tagName) {
		this.tagParent = tagName
	}

	getParentTag() {
		return this.tagParent
	}

	hasParentTag(tagName) {
		return tagName ? this.tagParent === tagName : isDef(this.tagParent)
	}

	hasEvent() {
		return this.eventObject ? true : false
	}

	setEvent(event) {

// Can't validate like this because Event is child class of this
//
//		if ( !event instanceof Event ) {
//			throw `Command.setEvent() argument 'event' is not class Event`
//		}

		this.eventObject = event
	}

	getEvent() {
		return this.eventObject
	}

	printSpecsWithVariables(object, variables) {
		let specs = {}

		console.log("Print specs and variables:")
		for ( let key in this ) {
			console.log(`${ key } => ${this[key]}: ${ replaceTransVars(this[key]) }`)
		}
	}
}

/**
 * Cmd is an intermediary class for all commands, which provides common helper methods.
 *
 * Class inheritance:
 *     Command > Cmd > CmdCreate
 *
 */
export class Cmd extends Command {
	constructor(object) {
		super(object)

		if ( this.constructor == Cmd ) { throw "Cmd.constructor() tried to create Cmd object – use only the child classes." }
	}
}


/**
 * CmdStop
 *
 * Ends the execution of this chain (and parent, if inside group).
 *
 *
 */
export class CmdStop extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	async execute(variables) {

		return Game.global.breakExecution(this)
	}
}


/**
 * CmdPlayBackgroundMusic
 *
 */
export class CmdPlayBackgroundMusic extends Cmd {
	static requiredKeys = ["cmd", "asset"]
	static optionalKeys = []

	async execute(variables) {

		let assetId = replaceTransVars(this.asset, variables)

		Game.global.playBackgroundMusic(assetId)
	}
}

/**
 * CmdStopAllBackgroundMusic
 *
 */
export class CmdStopAllBackgroundMusic extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	async execute(variables) {

		Game.global.fadeOutAllBackgroundMusic()
	}
}

/**
 * CmdStopAudio
 *
 */
export class CmdStopAudio extends Cmd {
	static requiredKeys = ["cmd", "asset"]
	static optionalKeys = []

	async execute(variables) {

		let assetId = replaceTransVars(this.asset, variables)

		Game.global.stopAudio(assetId)
	}
}

/**
 * CmdPlaySfx
 *
 */
export class CmdPlaySfx extends Cmd {
	static requiredKeys = ["cmd", "asset"]
	static optionalKeys = []

	async execute(variables) {

		let assetId = replaceTransVars(this.asset, variables)

		Game.global.playSfx(assetId)
	}
}

/**
 * CmdPlayForegroundMusic
 *
 */
export class CmdPlayForegroundMusic extends Cmd {
	static requiredKeys = ["cmd", "asset"]
	static optionalKeys = []

	async execute(variables) {

		let assetId = replaceTransVars(this.asset, variables)

		Game.global.playForegroundMusic(assetId)
	}
}

/**
 * CmdStopAllForegroundMusic
 *
 */
export class CmdStopAllForegroundMusic extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	async execute(variables) {

		Game.global.fadeOutAllForegroundMusic()
	}
}

/**
 * CmdStopAllMusic
 *
 */
export class CmdStopAllMusic extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	async execute(variables) {

		Game.global.fadeOutAllMusic()
	}
}

/**
 * CmdShowSettings
 *
 * Show settings for the user
 */
export class CmdShowSettings extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * Return the EndGameEvent
	 *
	 * @return EndGameEvent
	 */
	async execute(variables) {

		await Game.global.showSettings()
	}
}

/**
 * CmdShowCustomView
 *
 * Show custom view with template
 */
export class CmdShowCustomView extends Cmd {
	static requiredKeys = ["cmd", "name"]
	static optionalKeys = ["variable", "returnAfterShow", "waitForHide"]

	/**
	 * Return the EndGameEvent
	 *
	 * @return EndGameEvent
	 */
	async execute(variables) {

		let name = replaceTransVars(this.name, variables)
		let variable = replaceTransVars(this.variable, variables)
		let returnAfterShow = replaceTransVars(this.returnAfterShow, variables)
		let waitForHideDuringAction = replaceTransVars(this.waitForHide, variables)

		try {
			await Game.global.showCustomView(name, variable, returnAfterShow, waitForHideDuringAction)
		} catch (err) {
			error(err)
		}

	}
}

/**
 * CmdShowCustomContentView
 *
 * Show custom view with template
 */
export class CmdShowCustomContentView extends Cmd {
	static requiredKeys = ["cmd", "name"]
	static optionalKeys = ["variable", "content", "returnAfterShow", "waitForHide"]

	/**
	 * Return the EndGameEvent
	 *
	 * @return EndGameEvent
	 */
	async execute(variables) {

		let customTemplateName = replaceTransVars(this.name, variables)
		let contentId = replaceTransVars(this.content, variables)
		let variable = replaceTransVars(this.variable, variables)
		let returnAfterShow = replaceTransVars(this.returnAfterShow, variables)
		let waitForHideDuringAction = replaceTransVars(this.waitForHide, variables)

		try {
			await Game.global.showCustomContentView(customTemplateName, contentId, variable, returnAfterShow, waitForHideDuringAction)

		} catch (err) {
			error(err)
		}

	}
}


/**
 * CmdEndGameWithSlideshow
 *
 * Ends the game by creating an EndGameEvent and after it's done,
 * it calls UI to cleanup and return to main menu.
 *
 *
 */
export class CmdEndGameWithSlideshow extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["slideshow"]

	/**
	 * Return the EndGameEvent
	 *
	 * @return EndGameEvent
	 */
	async execute(variables) {

		let slideshow = replaceTransVars(this.slideshow, variables)

		await Game.global.endGameWithSlideshow(slideshow)
	}
}


/**
 * CmdMission (abstract)
 *
 * An abstract helper class for other mission commands.
 */
export class CmdMission extends Cmd {
	constructor(object) {
		super(object)

		if ( this.constructor == CmdMission ) { throw "CmdMission.constructor() tried to create CmdMission object – use only the child classes." }
	}

	getMission(missionId) {
		return Game.global.getMission(missionId || this.mission)
	}

	/**
	 * 
	 * @param {object} variables
	 * @throws validation error
	 */
	validate(variables) {
		super.validate(variables)

		let missionId = replaceTransVars( this.mission, variables )

		if ( !isString(missionId) ) {
			throw `CmdMission.validate() mission is not a string. Got: ${ missionId }`
		}

		if ( !isDef(this.getMission(missionId)) ) {
			throw `CmdMission.validate() mission '${ missionId }' not found.\n\nAvailable missions: ${ Game.global.getListOfMissions().join(', ') }`
		}
	}
}


/**
 * CmdMissionChangeState
 *
 * { "cmd": "missionChangeState", "mission": "example-mission", "state": "start" }
 *
 * Changes the mission state and thus triggers all the commands
 * within the mission tied to the new state
 *
 */
export class CmdMissionChangeState extends CmdMission {
	static requiredKeys = ["cmd", "mission", "state"]
	static optionalKeys = []

	async execute(variables, queueOnTop) {

		if ( !isObject(variables) ) { throw `CmdMissionChangeState.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let missionId = replaceTransVars( this.mission, variables )
		let newState = replaceTransVars( this.state, variables )

		let mission = this.getMission(missionId)

		let chainAndEvent = mission.changeState(newState)
		
		chainAndEvent.queueOnTop = queueOnTop ? true : false

		return chainAndEvent
	}

	validate(variables) {
		super.validate(variables)

		let missionId = replaceTransVars( this.mission, variables )
		let stateId = replaceTransVars( this.state, variables )

		let mission = this.getMission( missionId )

		if ( !isString(stateId) ) {
			throw `CmdMissionChangeState.validate() state is not a string. Got: ${ stateId }`
		}

		if ( !mission.getListOfStates().includes( stateId ) ) {
			throw `CmdMissionChangeState.validate() mission '${ missionId }' doesn't have state '${ stateId }'.\n\nAvailable states: ${ mission.getListOfStates().join(', ') }`
		}
	}
}

export class CmdChangeStateLater extends CmdMissionChangeState {

	/**
	 * Changes mission state after everything else has executed
	 */
	async execute(variables) {
		return super.execute(variables, false)
	}
}

export class CmdChangeStateNow extends CmdMissionChangeState {

	/**
	 * Changes mission state now before  everything else has executed
	 */
	async execute(variables) {
		return super.execute(variables, true)
	}
}

/**
 * CmdMissionStart
 *
 * { "cmd": "missionStart", "mission": "example-mission" }
 *
 * Changes the mission state to "start" and triggers all the commands
 * within the mission tied to the new state.
 *
 */
export class CmdMissionStart extends CmdMission {
	static requiredKeys = ["cmd", "mission"]
	static optionalKeys = []

	/**
	 *
	 * Changes mission state to start and returns the resulting event
	 *
	 * @param (object) variables as key-value pairs
	 * @return CommandChain
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdMissionStart.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let missionId = replaceTransVars( this.mission, variables )

		Game.global.startMission(missionId)
	}
}

/**
 * CmdLog sends a string message (with variables) to log.
 *
 * { "cmd": "log", "msg": <whatever> }
 */
export class CmdLog extends Cmd {
	static requiredKeys = ["cmd", "msg"]
	static optionalKeys = []

	/**
	 * @param nothing
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdLog.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let msg = replaceTransVars( this.msg, variables )

		log(msg)

		return true
	}
}


/**
 * CmdResetStateAndGlobalVariables calls Game.resetStateAndGlobalVariables()
 *
 * { "cmd": "resetStateAndGlobalVariables" }
 */
export class CmdResetStateAndGlobalVariables extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["load"]

	/**
	 * @param nothing
	 * @return boolean
	 */
	async execute() {
		await Game.global.resetStateAndGlobalVariables()

		return true
	}

	validate(variables) {
	}
}

/**
 *
 *
 * { "cmd": "exitGame" }
 */
export class CmdExitGame extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param nothing
	 * @return boolean
	 */
	async execute() {
		await Game.global.app.exitApp()

		return true
	}

	validate(variables) {
	}
}

/**
 * CmdStartGameLoop starts the main loop of the game.
 * 
 * { "cmd": "startGameLoop" }
 */
export class CmdStartGameLoop extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param nothing
	 * @return boolean
	 */
	async execute() {
		Game.global.startGameLoop()

		return true
	}

	validate(variables) {
	}
}

/**
 * CmdStopGameLoop ends the main loop of the game.
 * 
 * { "cmd": "stopGameLoop" }
 */
export class CmdStopGameLoop extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param nothing
	 * @return boolean
	 */
	async execute() {
		Game.global.stopGameLoop()

		return true
	}

	validate(variables) {
	}
}


/**
 * CmdInitBoard calls Game.global.initBoard()
 *
 * This does not reset State.
 * 
 * { "cmd": "initBoard", "board": "act1-board" }
 *
 */
export class CmdInitBoard extends Cmd {
	static requiredKeys = ["cmd", "board"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdInitBoard.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let boardId = replaceTransVars( this.board, variables )

		await Game.global.initBoard(boardId)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let boardId = replaceTransVars( this.board, variables )

		if ( !Game.global.isBoard(boardId) ) {
			throw `CmdInitBoard.validate() unknown board '${boardId}'`
		}
	}
}

/**
 * CmdShowSlideshow calls Game.global.showSlideshow()
 *
 * { "cmd": "showSlideshow", "slideshow": "example-slides" }
 *
 */
export class CmdShowSlideshow extends Cmd {
	static requiredKeys = ["cmd", "slideshow"]
	static optionalKeys = ["returnAfterShow", "skippable"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowSlideshow.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let name = replaceTransVars( this.slideshow, variables )
		let skippable = replaceTransVars( this.skippable, variables )
		let returnAfterShow = replaceTransVars( this.returnAfterShow, variables )

		await Game.global.showSlideshow( name , returnAfterShow, skippable)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let name = replaceTransVars( this.slideshow, variables )

		if ( !Game.global.getListOfSlideshows().includes(name) ) {
			throw `CmdShowSlideshow.validate() unknown slideshow '${name}'\n\nSlideshows: ${ Game.global.getListOfSlideshows() }`
		}
	}
}

/**
 * CmdShowDialogue calls Game.global.showDialogue()
 *
 * { "cmd": "showDialogue", "dialogue": "example-dialogue" }
 *
 */
export class CmdShowDialogue extends Cmd {
	static requiredKeys = ["cmd", "dialogue"]
	static optionalKeys = ["returnAfterShow", "animate"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowDialogue.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let dialogueName = replaceTransVars( this.dialogue, variables )
		let returnAfterShow = replaceTransVars( this.returnAfterShow, variables )
		let animate = replaceTransVars( this.animate, variables )

		await Game.global.showDialogue( dialogueName, returnAfterShow, animate)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let dialogueName = replaceTransVars( this.dialogue, variables )

		if ( !Game.global.getListOfDialogues().includes( dialogueName ) ) {
			throw `CmdShowDialogue.validate() unknown dialogue '${ dialogueName }'\n\nSlideshows: ${ Game.global.getListOfDialogues() }`
		}
	}
}

/**
 * CmdShowGameboard calls Game.global.showGameboard()
 *
 * { "cmd": "showGameboard", "mode": "interactive" }
 *
 * In 'interactive' mode ActionMenu is visible and player can
 * interact with the board markers.
 *
 * In 'presentation' mode ActionMenu is hidden and player can't
 * interact with the board markers.
 *
 */
export class CmdShowGameboard extends Cmd {
	static requiredKeys = ["cmd", "mode"]
	static optionalKeys = ["space"]
	static modes = ["interactive", "presentation", "half-n-half"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowGameboard.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let mode = replaceTransVars( this.mode, variables )
		let spaceId = replaceTransVars( this.space, variables )

		await Game.global.showGameboard( mode , spaceId )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let mode = replaceTransVars( this.mode, variables )

		if ( !CmdShowGameboard.modes.includes( mode ) ) {
			throw `CmdShowGameboard.validate() unknown mode '${ mode }'\n\nAllowed modes: ${ CmdShowGameboard.modes.join(', ') }`
		}
	}
}



/**
 * CmdShowPopup calls UI.global.showPopup()
 *
 * { "cmd": "showPopup", "content": "example-popup", doNotWaitForClose: true, secondary: true }
 *
 * Shows a popup with content, using current language.
 */
export class CmdShowPopup extends Cmd {
	static requiredKeys = ["cmd", "content"]
	static optionalKeys = ["doNotWaitForClose", "secondary", "sfx"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowPopup.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let contentId = replaceTransVars( this.content, variables )
		let secondary = replaceTransVars( this.secondary, variables )
		let sfxOverride = replaceTransVars( this.sfx, variables )

		if ( secondary ) {
			sfxOverride = "show-popup-secondary"
		}

		await UI.global.showPopup( contentId, variables, (isDef(this.doNotWaitForClose) ? this.doNotWaitForClose : false), "fast", sfxOverride)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let content = replaceTransVars( this.content, variables )

		if ( !isString(content) ) {
			throw `CmdShowPopup.validate() key content has to be a string. Got content=${ content }`
		}
	}
}


/**
 * CmdShowItemView calls UI.global.showItemView()
 *
 * { "cmd": "showPopupItem", "item": "phone-at-entrance" }
 *
 */
export class CmdShowItemView extends Cmd {
	static requiredKeys = ["cmd", "item"]
	static optionalKeys = ["background"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowItemView.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let itemId = replaceTransVars( this.item, variables )
		let messageId = replaceTransVars( this.message, variables )
		let backgroundAssetId = replaceTransVars( this.background, variables )

		await UI.global.showItemView( itemId, messageId, backgroundAssetId )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let itemId = replaceTransVars( this.item, variables )

		if ( !isString(itemId) ) {
			throw `CmdShowItemView.validate() key 'item' has to be a string. Got: '${ itemId }'`
		}

		if ( this.message ) {
			let messageId = replaceTransVars( this.message, variables )

			if ( !isString(messageId) ) {
				throw `CmdShowItemView.validate() key 'message' has to be a string. Got: '${ messageId }'`
			}
		}

		if ( this.background ) {
			let backgroundAssetId = replaceTransVars( this.background, variables )

			if ( !isString(backgroundAssetId) ) {
				throw `CmdShowItemView.validate() key 'background' has to be a string. Got: '${ background }'`
			}
		}
	}
}


/**
 * CmdShowAttackView calls UI.global.showAttackView()
 *
 * { "cmd": "showAttack, "enemy": "cthulhu", ("background": "basement") }
 *
 */
export class CmdShowAttackView extends Cmd {
	static requiredKeys = ["cmd", "enemy"]
	static optionalKeys = ["background", "onlyAttack"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowAttackView.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let enemyId = replaceTransVars( this.enemy, variables )
		let backgroundId = replaceTransVars( this.background, variables )
		let onlyAttack = replaceTransVars( this.onlyAttack, variables )

		await Game.global.showAttackView( enemyId, backgroundId, onlyAttack )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let enemy = replaceTransVars( this.enemy, variables )

		if ( !isString(enemy) ) {
			throw `CmdShowItemView.validate() key 'enemy' has to be a string. Got: '${ enemy }'`
		}

		if ( this.background ) {
			let backgroundAssetId = replaceTransVars( this.background, variables )

			if ( !isString(backgroundAssetId) ) {
				throw `CmdShowItemView.validate() key 'background' has to be a string. Got: '${ background }'`
			}
		}
	}
}

/**
 * CmdAddSpace calls Game.addSpace()
 *
 * { "cmd": "addSpace", "space": "example-space", "cx": 1000, "cy": 1000 }
 * { "cmd": "addSpace", "space": "example-space", centerView": true, "cx": 1000, "cy": 1000  }
 *
 * Adds the space tile on the board and optionally shows it.
 *
 * Use CmdShowSpace and CmdHideSpace to show/hide visibility
 * Use CmdRemoveSpace to remove it.
 *
 */
export class CmdAddSpace extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = [ "cx", "cy", "centerView"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdAddSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceName = replaceTransVars( this.space, variables )
		let cx = replaceTransVars( this.cx, variables )
		let cy = replaceTransVars( this.cy, variables )
		let center = replaceTransVars( this.centerView, variables )

		let specs = {
			cx: cx,
			cy: cy
		}

		await Game.global.addSpace(spaceName, specs, center)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceName = replaceTransVars( this.space, variables )
		let cx = replaceTransVars( this.cx, variables )
		let cy = replaceTransVars( this.cy, variables )
		let center = replaceTransVars( this.centerView, variables )

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdAddSpace.validate() 'centerView' is not a boolean. Got: ${ center }`
		}

		if ( !isInteger(cx) || !isInteger(cy) ) {
			throw `CmdAddSpace.validate() keys cx and cy have to be integers. Got cx=${ cx }, cy=${ cy }`
		}
	}
}

/**
 * CmdShowSpace calls Game.showSpace()
 *
 * { "cmd": "showSpace", "space": "example-space" }
 * { "cmd": "showSpace", "space": "example-space", "centerView": true }
 *
 * Shows the space tile in the board and optionally centers the
 * board view to it
 *
 */
export class CmdShowSpace extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = ["centerView", "skipOverlay"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )
		let skipOverlay = replaceTransVars( this.skipOverlay, variables )


		await Game.global.showSpace( spaceName, skipOverlay, center )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdShowSpace.validate() centerView is not a boolean. Got: ${ center }`
		}

		if ( !Game.global.getListOfSpaces().includes( spaceName ) ) {
			throw `CmdShowSpace.validate() no space found with name '${ spaceName }'`
		}
	}
}

/**
 * CmdShowSpaceWithContents calls Game.showSpaceWithContents()
 *
 * { "cmd": "showSpaceWithContents", "space": "example-space" }
 * { "cmd": "showSpaceWithContents", "space": "example-space", "centerView": true }
 *
 * Shows the space tile in the board and optionally centers the
 * board view to it.
 *
 * Messages is list of messages to show.
 *
 */
export class CmdShowSpaceWithContents extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = ["centerView", "animate", "skipOverlay"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowSpaceWithContents.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )
		let animate = replaceTransVars( this.animate, variables )
		let skipOverlay = replaceTransVars( this.skipOverlay, variables )

		await Game.global.showSpaceWithContents( spaceName, skipOverlay, center, animate)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdShowSpaceWithContents.validate() centerView is not a boolean. Got: ${ center }`
		}

		if ( !Game.global.getListOfSpaces().includes( spaceName ) ) {
			throw `CmdShowSpaceWithContents.validate() no space found with name '${ spaceName }'`
		}
	}
}

/**
 * CmdShowOverlay calls Game.showOverlay()
 *
 * { "cmd": "showOverlay", "message": "{{~foo}}" }
 *
 * { "cmd": "showOverlay", "message": "{{~go-to-a-room}}", "variables": { "room": "{{~livingroom}}" } }
 *
 * { "cmd": "showOverlay", "scrollTo": "livingroom", "message": "{{~foo}}" }
 *
 * { "cmd": "showOverlay", "messages" ["{{~foo}}", "{{~bar}}"] }
 *
 * Shows the space tile in the board and optionally centers the
 * board view to it.
 *
 * Message is a single message. Messages is list of messages to show.
 *
 */
export class CmdShowOverlay extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["scrollTo", "message", "messages", "variables"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(globalVariables) {

		if ( !isObject(globalVariables) ) { throw `CmdShowOverlay.execute() need globalVariables as key-value object.\n\nGot: ${ globalVariables }` }

		let scrollTo = replaceTransVars( this.scrollTo, globalVariables )
		let message = this.message
		let messages = this.messages
		let variables = this.variables || {}

		for ( let key in variables ) {
			variables[key] = replaceTransVars( variables[key], globalVariables )
		}


		if ( scrollTo ) {
			await Game.global.scrollTo( scrollTo )
		}

		let msg = ''

		if ( message ) {
			msg += replaceTransVars( message, Object.assign(variables, globalVariables))
		}

		if ( messages ) {
			for ( let i = 0 ; i < messages.length ; i++ ) {
				msg += replaceTransVars( messages[i], Object.assign(variables, globalVariables) )
			}
		}

		await Game.global.showOverlay(msg, Object.assign(variables, globalVariables))

		return true
	}
}

/**
 * CmdShowMessage calls Game.showOverlay()
 *
 * { "cmd": "showOverlay", "message": "{{~foo}}" }
 *
 * { "cmd": "showOverlay", "message": "{{~go-to-a-room}}", "variables": { "room": "{{~livingroom}}" } }
 *
 * { "cmd": "showOverlay", "scrollTo": "livingroom", "message": "{{~foo}}" }
 *
 * { "cmd": "showOverlay", "messages" ["{{~foo}}", "{{~bar}}"] }
 *
 * Shows the space tile in the board and optionally centers the
 * board view to it.
 *
 * Message is a single message. Messages is list of messages to show.
 *
 */
export class CmdShowMessage extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["scrollTo", "message", "messages", "variables", "secondary", "sfx"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(globalVariables) {

		if ( !isObject(globalVariables) ) { throw `CmdShowMessage.execute() need globalVariables as key-value object.\n\nGot: ${ globalVariables }` }

		let scrollTo = replaceTransVars( this.scrollTo, globalVariables )
		let message = replaceTransVars( this.message, globalVariables )
		let messages = this.messages
		let variables = this.variables || {}
		let secondary = replaceTransVars( this.secondary, variables )
		let sfxOverride = replaceTransVars( this.sfx, variables )

		if ( secondary ) {
			sfxOverride = "show-popup-secondary"
		}

		for ( let key in variables ) {
			variables[key] = replaceTransVars( variables[key], globalVariables )
		}

		if ( scrollTo ) {
			await Game.global.scrollTo( scrollTo )
		}

		let msg = ''

		if ( message ) {
			msg += replaceObjectTransVars( message, Object.assign(variables, globalVariables))
		}

		if ( messages ) {
			for ( let i = 0 ; i < messages.length ; i++ ) {
				msg += replaceObjectTransVars( messages[i], Object.assign(variables, globalVariables) )
			}
		}

		await Game.global.showMessage(msg, Object.assign(variables, globalVariables), sfxOverride)

		return true
	}
}

/**
 * CmdRemoveSpace calls Game.removeSpace()
 *
 * { "cmd": "removeSpace", "space": "example-space" }
 *
 * Removes the space from the board. Use CmdAddSpace to restore it.
 */
export class CmdRemoveSpace extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = ["centerView"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdRemoveSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		await Game.global.removeSpace( spaceName, center )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdRemoveSpace.validate() centerView is not a boolean. Got: ${ center }`
		}

		if ( !Game.global.getListOfSpaces().includes( spaceName ) ) {
			throw `CmdRemoveSpace.validate() no space found with name '${ spaceName }'`
		}
	}
}

/**
 * CmdHideSpace calls Game.hideSpace()
 *
 * { "cmd": "hideSpace", "space": "example-space" }
 *
 * Hides the space from the board. Use CmdShowSpace to restore it.
 */
export class CmdHideSpace extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = ["centerView"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdHideSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		await Game.global.hideSpace( spaceName, center )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceName = replaceTransVars( this.space, variables )
		let center = replaceTransVars( this.centerView, variables )

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdHideSpace.validate() centerView is not a boolean. Got: ${ center }`
		}

		if ( !Game.global.getListOfSpaces().includes( spaceName ) ) {
			throw `CmdHideSpace.validate() no space found with name '${ spaceName }'`
		}
	}
}

/**
 * CmdCenterView calls Game.centerView()
 *
 * { "cmd": "centerView", "space": "example-space" }
 *
 * Scrolls the board to center on the given space.
 */
export class CmdCenterView extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdCenterView.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceId = replaceTransVars( this.space, variables )

		await Game.global.centerView( spaceId )

		return true
	}

	validate(variables) {
		super.validate(variables)

		let spaceId = replaceTransVars( this.space, variables )

		if ( !Game.global.getListOfSpaces().includes( spaceId ) ) {
			throw `CmdCenterView.validate() no space found with name '${ spaceId }'`
		}
	}
}


/**
 * CmdCreateSpace calls Game.createXXXX(),
 *
 * Creates an instance of the Space into the State without
 * putting it into the board.
 *
 */
export class CmdCreateSpace extends Cmd {
	static requiredKeys = ["cmd", "space"]
	static optionalKeys = ["cx", "cy"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdCreateSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let spaceId = replaceTransVars( this.space , variables )
		let cx = replaceTransVars( this.cx, variables )
		let cy = replaceTransVars( this.cy, variables )

		await Game.global.createSpace(spaceId, cx, cy)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )

		if ( (isDef(cx) && !isInteger(cx)) || (isDef(cy) && !isInteger(cy)) ) {
			throw `CmdCreateSpace.validate() keys cx and cy have to be integers. Got cx=${ cx }, cy=${ cy }`
		}
	}
}


/**
 * CmdCreateThing calls Game.createXXXX(),
 *
 * Creates an instance of the Thing into the State without
 * putting it into the board.
 *
 */
export class CmdCreateThing extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["npc", "monster", "item", "door", "space", "cx", "cy"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdCreateThing.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )

		let spaceId = replaceTransVars( this.space , variables )
		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )

		if ( doorId ) {
			await Game.global.createDoor(doorId, spaceId, cx, cy)
		}

		if ( itemId ) {
			await Game.global.createItem(itemId, spaceId, cx, cy)
		}

		if ( monsterId ) {
			await Game.global.createMonster(monsterId, spaceId, cx, cy)
		}

		if ( npcId ) {
			await Game.global.createNpc(npcId, spaceId, cx, cy)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )

		if ( (isDef(cx) && !isInteger(cx)) || (isDef(cy) && !isInteger(cy)) ) {
			throw `CmdCreateThing.validate() keys cx and cy have to be integers. Got cx=${ cx }, cy=${ cy }`
		}
	}
}

/**
 * CmdCreatePlayers calls Game.createXXXX(),
 *
 * Creates an instance of the Player into the State without
 * putting it into the board.
 *
 */
export class CmdCreatePlayers extends Cmd {
	static requiredKeys = ["cmd", "players"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdCreatePlayers.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let playerIds = replaceTransVars( this.players , variables )

		for ( let playerId of playerIds.split(",") ) {
			Game.global.createPlayer(playerId)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let players = replaceTransVars( this.players , variables )

		if ( isArray(this.players) ) {
			throw `CmdCreatePlayers.validate() argument players is not an array. Got: ${ this.players }`
		}
	}
}

/**
 * CmdDelete calls Game.deleteXXXX(),
 *
 * Removes an instance of the Player, Thing or Space from the State.
 *
 * Use hideThing() first to control the removal of spaces and markers
 * from the board.
 *
 * { "cmd": "delete", "players": [ "player1", "player2" ], "items": "all" }
 *
 * Note: Deleting a character with items will NOT delete the items
 *
 */
export class CmdDelete extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["players", "npcs", "monsters", "items", "spaces", "doors"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdDelete.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		// (1) Array variable
		let players = isString(this.players) ? replaceTransVars(this.players, variables) : this.players
		let npcs = isString(this.npcs) ? replaceTransVars(this.npcs, variables) : this.npcs
		let monsters = isString(this.monsters) ? replaceTransVars(this.monsters, variables) : this.monsters
		let items = isString(this.items) ? replaceTransVars(this.items, variables) : this.items
		let spaces = isString(this.spaces) ? replaceTransVars(this.spaces, variables) : this.spaces
		let doors = isString(this.doors) ? replaceTransVars(this.doors, variables) : this.doors

		if ( players == "all" ) {
			players = Game.global.getListOfPlayerIds()
		}
		if ( npcs == "all" ) {
			npcs = Game.global.getListOfNpcs()
		}
		if ( monsters == "all" ) {
			monsters = Game.global.getListOfMonsters()
		}
		if ( items == "all" ) {
			items = Game.global.getListOfItems()
		}
		if ( spaces == "all" ) {
			spaces = Game.global.getListOfSpaces()
		}
		if ( doors == "all" ) {
			doors = Game.global.getListOfDoors()
		}

		for ( let tmp of players || [] ) {
			// (2) Array elements are variables
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deletePlayer(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		for ( let tmp of npcs || [] ) {
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deleteNpc(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		for ( let tmp of monsters || [] ) {
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deleteMonster(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		for ( let tmp of items || [] ) {
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deleteItem(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		for ( let tmp of spaces || [] ) {
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deleteSpace(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		for ( let tmp of doors || [] ) {
			let id = replaceTransVars( tmp , variables )

			try {
				await Game.global.deleteDoor(id)
			} catch(err) {
				log(`CmdDelete.execute() failed to delete id: '${id}' reason: ${err}`)
			}
		}

		return true
	}
}


/**
 * CmdSleep calls await sleep(time)
 *
 */
export class CmdSleep extends Cmd {
	static requiredKeys = ["cmd", "time"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		let time = replaceTransVars( this.time, variables )

		if ( time > 100 ) {
			warn `CmdSleep.execute() warning: sleep 'time' is over 100. Time is in seconds, not milliseconds.`
		}

		await sleep(1000 * time)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let time = replaceTransVars( this.time, variables )

		if ( !isNumber(time) ) {
			throw `CmdSleep.validate() time is not a number (in seconds). Got: ${ time }.`
		}
	}
}


/**
 * CmdShowView calls:
 * – UI.showMainMenu()
 * – UI.showGameLoader()
 *
 * { "cmd": "showView", "view": "mainmenu" }
 * { "cmd": "showView", "view": "gameloader" }
 *
 * Shows the view and put it upront.
 *
 * TODO: test and make this work as needed.
 */
export class CmdShowView extends Cmd {
	static requiredKeys = ["cmd", "view"]
	static optionalKeys = ["name"]
	static views = [ "mainmenu", "gameloader"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowView.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let viewName = replaceTransVars( this.view, variables )
		let customName = replaceTransVars( this.name, variables )

		switch (viewName) {
			case "mainmenu":
				await UI.global.showMainMenu()
				break

			case "gameloader":
				await UI.global.showLoader()
				break

			default:
				throw `CmdShowView.execute() unknown view name '${viewName}'.\n\nAllowed views: ${ CmdShowView.views.join(', ') }`
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let viewName = replaceTransVars( this.view, variables )

		if ( !CmdShowView.views.includes(viewName) ) {
			throw `CmdShowView.validate() unknown view name '${viewName}'.\n\nAllowed views: ${ CmdShowView.views.join(', ') }`
		}
	}
}


/**
 * CmdStartGameRounds calls Game.startPlayerRound()
 *
 * { "cmd": "startGameRounds" }
 *
 * Starts the player turn.
 */
export class CmdStartGameRounds extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["doNotEnableInteractions"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdStartGameRounds.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let doNotEnableInteractions = replaceTransVars( this.doNotEnableInteractions, variables )

		await Game.global.startPlayerRound( doNotEnableInteractions)

		return true
	}
}

/**
 * CmdRestartPlayerRound calls Game.startPlayerRound()
 *
 * { "cmd": "restartPlayerRound", showMessage: true }
 *
 * Starts the player turn.
 */
export class CmdRestartPlayerRound extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["showMessage"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdRestartPlayerRound.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let showMessage = replaceTransVars(this.showMessage, variables)

		await Game.global.restartPlayerRound(isDef(showMessage) ? showMessage : true)

		return true
	}
}


/**
 * CmdStartWorldRound calls Game.startWorldRound()
 *
 * { "cmd": "startWorldRound" }
 *
 * Starts the world turn.
 */
export class CmdStartWorldRound extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdStartWorldRound.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		await Game.global.startWorldRound()

		return true
	}
}

/**
 * CmdEndPlayerRound calls Game.endPlayerRound()
 *
 * { "cmd": "endPlayerRound" }
 *
 * Ends the player round.
 */
export class CmdEndPlayerRound extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdEndPlayerRound.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		await Game.global.endPlayerRound()

		return true
	}
}

/**
 * CmdEndWorldRound calls Game.endWorldRound()
 *
 * { "cmd": "endWorldRound" }
 *
 * Ends the world turn.
 */
export class CmdEndWorldRound extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdEndWorldRound.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		await Game.global.endWorldRound()

		return true
	}
}

/**
 * CmdAddThing
 *
 * This command has multiple aliases just for convenience.
 *
 *     addNpc
 *     addMonster
 *     addItem
 *     addDoor
 *
 * Because they are just aliases, one can use "addDoor" to add an Npc.
 *
 * For example within a Group:
 *
 * { "group": [
 *
 *       { "cmd": "addNpc", "space": "entrance-hall", "npc": "professor", "cx": 2142, "cy": 1892, "centerView": true },
 *       { "cmd": "addDoor", "space": "entrance-hall", "door": "door-to-small-library", "cx": 1642, "cy": 1442 }
 *       { "cmd": "addItem", "space": "entrance-hall", "item": "phone-at-entrance", "cx": 1872, "cy": 1892 }
 *   ]
 * },
 *
 * Adds the Thing into the Space.
 */
export class CmdAddThing extends Cmd {
	static requiredKeys = ["cmd", "space", "cx", "cy"]
	static optionalKeys = ["npc", "item", "monster", "door"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdAddThingToSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )
		let center = replaceTransVars( this.centerView , variables )

		let specs = {
			cx: cx,
			cy: cy,
		}

		if ( doorId ) {
			await Game.global.addDoorWithSpecs(doorId, specs, spaceId, center)
		}

		if ( itemId ) {
			await Game.global.addItemWithSpecs(itemId, specs, spaceId, center)
		}

		if ( monsterId ) {
			await Game.global.addMonsterWithSpecs(monsterId, specs, spaceId, center)
		}

		if ( npcId ) {
			await Game.global.addNpcWithSpecs(npcId, specs, spaceId, center)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )
		let center = replaceTransVars( this.centerView , variables )

		if ( !isInteger(cx) || !isInteger(cy) ) {
			throw `CmdAddThing.validate() keys cx and cy have to be integers. Got cx=${ cx }, cy=${ cy }`
		}

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdAddThing.validate() centerView is not a boolean. Got: ${ center }`
		}
	}
}

/**
 * CmdRemoveThing
 *
 * This command has multiple aliases just for convenience.
 *
 *     removeNpc
 *     removeMonster
 *     removeItem
 *     removeDoor
 *
 * Because they are just aliases, one can use "removeDoor" to remove an Npc.
 *
 * For example within a Group:
 *
 * { "group": [
 *
 *       { "cmd": "removeThing", "npc": "professor", "centerView": true },
 *       { "cmd": "removeThing", "door": "door-to-small-library" }
 *       { "cmd": "removeThing", "item": "phone-at-entrance" }
 *   ]
 * },
 *
 * Adds the Thing into the Space.
 */
export class CmdRemoveThing extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["npc", "item", "monster", "door"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdRemoveThingToSpace.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )
		let center = replaceTransVars( this.centerView , variables )

		if ( doorId ) {
			await Game.global.removeDoor(doorId, center)
		}

		if ( itemId ) {
			await Game.global.removeItem(itemId, center)
		}

		if ( monsterId ) {
			await Game.global.removeMonster(monsterId, center)
		}

		if ( npcId ) {
			await Game.global.removeNpc(npcId, center)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let center = replaceTransVars( this.centerView , variables )

		// TODO maybe instead check against things currently in state ?

		let allowedNpcs = Game.global.getListOfNpcs()
		let allowedMonsters = Game.global.getListOfMonsters()
		let allowedItems = Game.global.getListOfItems()
		let allowedSpaces = Game.global.getListOfSpaces()
		let allowedDoors = Game.global.getListOfDoors()

		if ( npcId && !allowedNpcs.includes(npcId) ) {
			throw `CmdRemoveThing.validate() unknown npc '${npcId}'\n\nAllowed npcs: ${ allowedNpcs.join(', ') }`
		}

		if ( monsterId && !allowedMonsters.includes(monsterId) ) {
			throw `CmdRemoveThing.validate() unknown monster '${monsterId}'\n\nAllowed monsters: ${ allowedMonsters.join(', ') }`
		}

		if ( itemId && !allowedItems.includes(itemId) ) {
			throw `CmdRemoveThing.validate() unknown item '${itemId}'\n\nAllowed items: ${ allowedItems.join(', ') }`
		}

		if ( spaceId && !allowedSpaces.includes(spaceId) ) {
			throw `CmdRemoveThing.validate() unknown space '${spaceId}'\n\nAllowed spaces: ${ allowedSpaces.join(', ') }`
		}

		if ( doorId && !allowedDoors.includes(doorId) ) {
			throw `CmdRemoveThing.validate() unknown door '${doorId}'\n\nAllowed doors: ${ allowedDoors.join(', ') }`
		}

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdRemoveThing.validate() centerView is not a boolean. Got: ${ center }`
		}
	}
}

/**
 * CmdShowThing
 *
 * This command has multiple aliases just for convenience.
 *
 *     showNpc
 *     showMonster
 *     showItem
 *     showDoor
 *
 * Because they are just aliases, one can use "showDoor" to show an Npc.
 *
 * For example within a Group:
 *
 * { "group": [
 *
 *       { "cmd": "showNpc", "npc": "professor", "centerView": true },
 *       { "cmd": "showDoor", "door": "door-to-small-library" }
 *       { "cmd": "showItem", "item": "phone-at-entrance" }
 *   ]
 * },
 *
 * Adds the Thing into the Space.
 */
export class CmdShowThing extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["npc", "item", "monster", "door", "cx", "cy", "centerView"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdShowThing.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )
		let center = replaceTransVars( this.centerView , variables )

		if ( isNull(cx) || isNull(cy) ) {
			warn(`CmdShowThing.execute() not showing - missing CX,CY for: ${ doorId||itemId||monsterId||npcId }`)
			return false
		}

		if ( doorId ) {
			await Game.global.showThing(doorId, spaceId, cx, cy, center)
		}

		if ( itemId ) {
			await Game.global.showThing(itemId, spaceId, cx, cy, center)
		}

		if ( monsterId ) {
			await Game.global.showThing(monsterId, spaceId, cx, cy, center)
		}

		if ( npcId ) {
			await Game.global.showThing(npcId, spaceId, cx, cy, center)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let cx = replaceTransVars( this.cx , variables )
		let cy = replaceTransVars( this.cy , variables )
		let center = replaceTransVars( this.centerView , variables )

		let allowedNpcs = Game.global.getListOfNpcs()
		let allowedMonsters = Game.global.getListOfMonsters()
		let allowedItems = Game.global.getListOfItems()
		let allowedSpaces = Game.global.getListOfSpaces()
		let allowedDoors = Game.global.getListOfDoors()

		if ( npcId && !allowedNpcs.includes(npcId) ) {
			throw `CmdShowThing.validate() unknown npc '${npcId}'\n\nAllowed npcs: ${ allowedNpcs.join(', ') }`
		}

		if ( monsterId && !allowedMonsters.includes(monsterId) ) {
			throw `CmdShowThing.validate() unknown monster '${monsterId}'\n\nAllowed monsters: ${ allowedMonsters.join(', ') }`
		}

		if ( itemId && !allowedItems.includes(itemId) ) {
			throw `CmdShowThing.validate() unknown item '${itemId}'\n\nAllowed items: ${ allowedItems.join(', ') }`
		}

		if ( spaceId && !allowedSpaces.includes(spaceId) ) {
			throw `CmdShowThing.validate() unknown space '${spaceId}'\n\nAllowed spaces: ${ allowedSpaces.join(', ') }`
		}

		if ( doorId && !allowedDoors.includes(doorId) ) {
			throw `CmdShowThing.validate() unknown door '${doorId}'\n\nAllowed doors: ${ allowedDoors.join(', ') }`
		}

		if ( (isDef(cx) && !isInteger(cx)) || (isDef(cy) && !isInteger(cy)) ) {
			throw `CmdShowThing.validate() keys cx and cy have to be integers. Got cx=${ cx }, cy=${ cy }`
		}

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdShowThing.validate() centerView is not a boolean. Got: ${ center }`
		}
	}
}

/**
 * CmdHideThing
 *
 * This command has multiple aliases just for convenience.
 *
 *     hideNpc
 *     hideMonster
 *     hideItem
 *     hideDoor
 *
 * Because they are just aliases, one can use "hideDoor" to hide an Npc.
 *
 * For example within a Group:
 *
 * { "group": [
 *
 *       { "cmd": "hideNpc", "npc": "professor", "centerView": true },
 *       { "cmd": "hideDoor", "door": "door-to-small-library" }
 *       { "cmd": "hideItem", "item": "phone-at-entrance" }
 *   ]
 * },
 *
 * Adds the Thing into the Space.
 */
export class CmdHideThing extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["npc", "item", "monster", "door", "centerView"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdHideThing.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let center = replaceTransVars( this.centerView , variables )

		if ( doorId ) {
			await Game.global.hideThing(doorId, center)
		}

		if ( itemId ) {
			await Game.global.hideThing(itemId, center)
		}

		if ( monsterId ) {
			await Game.global.hideThing(monsterId, center)
		}

		if ( npcId ) {
			await Game.global.hideThing(npcId, center)
		}

		return true
	}

	validate(variables) {
		super.validate(variables)

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )
		let center = replaceTransVars( this.centerView , variables )

		let allowedNpcs = Game.global.getListOfNpcs()
		let allowedMonsters = Game.global.getListOfMonsters()
		let allowedItems = Game.global.getListOfItems()
		let allowedSpaces = Game.global.getListOfSpaces()
		let allowedDoors = Game.global.getListOfDoors()

		if ( npcId && !allowedNpcs.includes(npcId) ) {
			throw `CmdHideThing.validate() unknown npc '${npcId}'\n\nAllowed npcs: ${ allowedNpcs.join(', ') }`
		}

		if ( monsterId && !allowedMonsters.includes(monsterId) ) {
			throw `CmdHideThing.validate() unknown monster '${monsterId}'\n\nAllowed monsters: ${ allowedMonsters.join(', ') }`
		}

		if ( itemId && !allowedItems.includes(itemId) ) {
			throw `CmdHideThing.validate() unknown item '${itemId}'\n\nAllowed items: ${ allowedItems.join(', ') }`
		}

		if ( spaceId && !allowedSpaces.includes(spaceId) ) {
			throw `CmdHideThing.validate() unknown space '${spaceId}'\n\nAllowed spaces: ${ allowedSpaces.join(', ') }`
		}

		if ( doorId && !allowedDoors.includes(doorId) ) {
			throw `CmdHideThing.validate() unknown door '${doorId}'\n\nAllowed doors: ${ allowedDoors.join(', ') }`
		}

		if ( isDef(center) && !isBoolean(center) ) {
			throw `CmdHideThing.validate() centerView is not a boolean. Got: ${ center }`
		}
	}
}

/**
 * CmdUpdateThing
 *
 * This command has multiple aliases just for convenience.
 *
 *     updateNpc
 * 	   updateSpace
 *     updateMonster
 *     updateItem
 *     updateDoor
 *     updateThing
 *
 * Because they are just aliases, one can use "updateDoor" to update an Npc.
 * 
 * This uses setState() to set properties (except 'states' and 'state').
 * 
 * Priority for properties is: 'states', 'state', ... everything else
 * 
 * Example:
 *
 *     { "cmd": "updateDoor", "door":"some-door", state": "locked" }
 *     { "cmd": "updateNpc", "npc":"some-npc", alive": false }
 *     { "cmd": "updateThing", "asset:marker": "foobar" }
 *
 */
export class CmdUpdateThing extends Cmd {
	static requiredKeys = ["cmd"]
	static optionalKeys = ["npc", "item", "monster", "door", "space"].concat(THING_PROPS)

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdUpdateThing.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )

		let id = npcId || spaceId || doorId || monsterId || itemId 

		if ( !isDef(id) ) { throw `CmdUpdateThing.execute() need npc, space, door, monster or item id` }

		let specs = {}

		for ( let key of THING_PROPS ) {
			// important: value may be null – this is the way to set the key to "nothing"
			if ( isDef(this[key]) ) {
				specs[key] = replaceTransVars(this[key], variables)	
			}
		}

		return Game.global.updateThing(id, specs)
	}

	/**
	 * 
	 * @param {object} variables 
	 */
	validate(variables) {
		super.validate(variables)

		let npcId = replaceTransVars( this.npc , variables )
		let spaceId = replaceTransVars( this.space , variables )
		let doorId = replaceTransVars( this.door , variables )
		let monsterId = replaceTransVars( this.monster , variables )
		let itemId = replaceTransVars( this.item , variables )

		let states = this.states
		let state = replaceTransVars( this.state, variables )

		let id = npcId || spaceId || doorId || monsterId || itemId 

		if ( !isString(id) ) {
			warn("Invalid command:")
			warn(this)
			throw `CmdUpdateThing.validate() no id defined or not a valid string. Got: ${ id }`
		}

		if ( isDef(states) && !isObject(states) ) {
			throw `CmdUpdateThing.validate() 'states' is not an Object. Got: ${ states }`
		}

		if ( isDef(state) && !isString(state) ) {
			throw `CmdUpdateThing.validate() 'state' is not a String. Got: ${ state }`
		}

		for ( let key of THING_PROPS ) {
			if ( isDef(this[key]) ) {
				let value = replaceTransVars(this[key], variables)
				if ( !isString(value) && !isBoolean(value) && !isNumber(value) ) {
					throw `CmdUpdateThing.validate() '${key}' value '${value}' is not a string|number|boolean. Original value: ${ this[key] }`
				}
			}
		}
	}
}


/**
 * CmdGiveItems calls Game.giveItemsToCharacter
 *
 * { "cmd": "giveItems", "character": "professor, "items": [ "key-to-the-garden" ] }
 *
 * Creates the item and gives it to a character.
 */
export class CmdGiveItems extends Cmd {
	static requiredKeys = ["cmd", "character", "items"]
	static optionalKeys = []
	static views = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdGiveItems.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let characterId = replaceTransVars( this.character, variables )

		if ( characterId == null ) {
			error("CmdGiveItems.execute() 'character' is null")
		}

		if ( characterId instanceof Character ) {
			debug(`CmdGiveItems.execute() silly thing: sometimes ID, sometimes Object. TODO fix it`)
			characterId = characterId.id
		}

		let itemIds = []

		for ( let tmp of this.items ) {
			let itemId = replaceTransVars( tmp, variables )

			itemIds.push(itemId)
		}

		await Game.global.giveItemsToCharacter(characterId, itemIds)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let characterId = replaceTransVars( this.character, variables )

		if ( characterId instanceof Character ) {
			debug(`CmdGiveItems.validate() silly thing: sometimes ID, sometimes Object. TODO fix it`)
			characterId = characterId.id
		}

		let allowedCharacters = Game.global.getListOfCharacters()

		if ( !allowedCharacters.includes( characterId ) ) {
			throw `CmdGiveItems.validate() unknown character name '${ characterId }'.\n\nAllowed characters: ${ allowedCharacters.join(', ') }`
		}

		let allowedItems = Game.global.getListOfItems()

		for ( let tmp of this.items ) {
			let itemId = replaceTransVars( tmp, variables )

			if ( !allowedItems.includes( itemId ) ) {
				throw `CmdGiveItems.validate() unknown item name '${ itemId }'.\n\nAllowed items: ${ allowedItems.join(', ') }`
			}
		}
	}
}

/**
 * CmdRemoveItems calls Game.takeItemsFromCharacter
 *
 * { "cmd": "takeItemsFromCharacter", "character": "professor, "items": [ "key-to-the-garden" ] }
 *
 * Creates the item and gives it to a character.
 */
export class CmdTakeItemsFromCharacter extends Cmd {
	static requiredKeys = ["cmd", "items"]
	static optionalKeys = ["character"]
	static views = []

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdTakeItemsFromCharacter.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let characterId = replaceTransVars( this.character, variables )

		if ( characterId instanceof Character ) {
			debug(`CmdTakeItemsFromCharacter.execute() silly thing: sometimes ID, sometimes Object. TODO fix it`)
			characterId = characterId.id
		}

		let itemIds = []

		for ( let tmp of this.items ) {
			let itemId = replaceTransVars( tmp, variables )

			itemIds.push(itemId)
		}

		await Game.global.takeItemsFromCharacter(characterId, itemIds)

		return true
	}

	validate(variables) {
		super.validate(variables)

		let characterId = replaceTransVars( this.character, variables )

		if ( characterId instanceof Character ) {
			debug(`CmdTakeItemsFromCharacter.validate() silly thing: sometimes ID, sometimes Object. TODO fix it`)
			characterId = characterId.id
		}

		let allowedCharacters = Game.global.getListOfCharacters()

		if ( !allowedCharacters.includes( characterId ) ) {
			throw `CmdTakeItemsFromCharacter.validate() unknown character name '${ characterId }'.\n\nAllowed characters: ${ allowedCharacters.join(', ') }`
		}

		let allowedItems = Game.global.getListOfItems()

		for ( let tmp of this.items ) {
			let itemId = replaceTransVars( tmp, variables )

			if ( !allowedItems.includes( itemId ) ) {
				throw `CmdTakeItemsFromCharacter.validate() unknown item name '${ itemId }'.\n\nAllowed items: ${ allowedItems.join(', ') }`
			}
		}
	}
}


/**
 * CmdSetGlobalVariable calls Game.setGlobalVariable()
 *
 * { "cmd": "setGlobalVariable", "variable": "some-variable", "value": <anything> }
 * { "cmd": "setGlobalVariable", "variable": "some-variable", "add": 2 }
 * { "cmd": "setGlobalVariable", "variable": "some-variable", "add": 2, "multiply": 3}
 *
 * Set a global variable value, which can then be referenced in If commands
 * for Mission events.
 * 
 * Execution order:
 * - value
 * - value from
 * - value from random
 * - value from length
 * - concat
 * - push
 * - multiply
 * - divide
 * - add
 * - subtract
 * - round
 * - min
 * - max
 * - delete
 * 
 * Use CmdRegisterGlobalVariable to create the variable first.
 */
export class CmdSetGlobalVariable extends Cmd {
	static requiredKeys = ["cmd", "variable"]
	static optionalKeys = ["value", "add", "subtract", "multiply", "divide", "valueFromRandom", "push", "delete", "round", "min", "max", "valueFromLength", "valueFrom", "concat" ]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdSetGlobalVariable.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		// Value functions			
		let name = replaceTransVars( this.variable, variables )
		let value = replaceTransVars( this.value, variables )
		let multiply = replaceTransVars( this.multiply, variables )
		let divide = replaceTransVars( this.divide, variables )
		let add = replaceTransVars( this.add, variables )
		let subtract = replaceTransVars( this.subtract, variables )
		let round = replaceTransVars( this.round, variables )
		let min = replaceTransVars( this.min, variables )
		let max = replaceTransVars( this.max, variables )

		// Set function
		let del = replaceTransVars( this.delete, variables )
		let valueFrom = replaceTransVars(this.valueFrom, variables )
		let valueFromRandom = replaceTransVars(this.valueFromRandom, variables)
		let valueFromLength = replaceTransVars( this.valueFromLength, variables )

		let concat

		if ( isDef(this.concat) ) {
			concat = Array.from(this.concat)

			if ( isDef(concat) && isArray(concat) ) {
				for ( let i = 0 ; i < concat.length ; i++ ) {
					concat[i] = replaceTransVars( concat[i], variables )
				}
			}

		}

		let push
		if ( isDef(this.push) ) {
			push = this.push

			if ( isArray(push) ) {
				for ( let i = 0 ; i < push.length ; i++ ) {
					push[i] = replaceTransVars( push[i], variables )
				}
			} else {
				push = [replaceTransVars( this.push, variables )]
			}
		}

		return Game.global.setGlobalVariable(name, value, add, subtract, multiply, divide, push, del, round, min, max, valueFrom, valueFromLength, valueFromRandom, concat)
	}

	validate(variables) {
		super.validate(variables)

		if ( !isString(this.variable) ) { throw `CmdSetGlobalVariable.validate() name is not a String. Got: ${ this.name }` }
		if ( isDef(this.multiply) && !isNumber(this.multiply) && !isVariable(this.multiply) ) { throw `CmdSetGlobalVariable.validate() validate: multiply is not a number. Got: ${ this.multiply }` }
		if ( isDef(this.divide) && !isNumber(this.divide) && !isVariable(this.divide)) { throw `CmdSetGlobalVariable.validate() divide is not a number. Got: ${ this.divide }` }
		if ( isDef(this.add) && !isNumber(this.add) && !isVariable(this.add) ) { throw `CmdSetGlobalVariable.validate() add is not a number. Got: ${ this.add }` }
		if ( isDef(this.subtract) && !isNumber(this.subtract) && !isVariable(this.subtract) ) { throw `CmdSetGlobalVariable.validate() subtract is not a number. Got: ${ this.subtract }` }
		// TODO validate rest as well
	}
}

/**
 * CmdRegisterGlobalVariable calls Game.registerGlobalVariable()
 *
 * { "cmd": "registerGlobalVariable", "variable": "some-variable", "value": <anything> }
 *
 * Value is optional.
 * 
 * Use CmdSetGlobalVariable to change it.
 */
export class CmdRegisterGlobalVariable extends Cmd {
	static requiredKeys = ["cmd", "variable"]
	static optionalKeys = ["value"]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdRegisterGlobalVariable.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let name = replaceTransVars( this.variable, variables )
		let value = replaceTransVars( this.value, variables )

		return Game.global.registerGlobalVariable(name, value)
	}

	validate(variables) {
		super.validate(variables)

		if ( !isString(this.variable) ) { throw `CmdRegisterGlobalVariable.validate() name is not a String. Got: ${ this.name }` }
	}
}

/**
 * CmdDeleteGlobalVariable calls Game.deleteGlobalVariable()
 *
 * { "cmd": "deleteGlobalVariable", "variable": "some-variable" }
 *
 *
 * Use CmdSetGlobalVariable to change it.
 */
export class CmdDeleteGlobalVariable extends Cmd {
	static requiredKeys = ["cmd", "variable"]
	static optionalKeys = [""]

	/**
	 * @param (object) variables as key-value pairs
	 * @return boolean
	 */
	async execute(variables) {

		if ( !isObject(variables) ) { throw `CmdDeleteGlobalVariable.execute() need variables as key-value object.\n\nGot: ${ variables }` }

		let name = replaceTransVars( this.variable, variables )

		return Game.global.deleteGlobalVariable(name)
	}

	validate(variables) {
		super.validate(variables)

		if ( !isString(this.variable) ) { throw `CmdDeleteGlobalVariable.validate() name is not a String. Got: ${ this.name }` }
	}
}

