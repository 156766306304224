import { sleep, log, warn, error } from 'global.js'
import { isDef, isNumber } from 'validators.mjs'

import { Thing } from 'Thing/Thing.mjs'

import { T } from 'Game/Translations.mjs'

export class Interaction extends Thing {
	constructor(id, specs) {
		super(id, T(specs.name), specs.description ? T(specs.description) : "", specs.assets, specs.spaces || specs.space, specs.state, specs.states, specs.action, specs.actions, specs.cx, specs.cy, specs.visible, specs.markerSpace)

	}

	getMarkerAsset() {
		if ( !this.assets || !this.assets.marker ) {
			throw `Interaction.getMarkerAsset: i don't have a marker asset OR it isn't loaded yet`
		}

		if ( this.assets && typeof this.assets.marker == 'object') {
			return this.assets.marker
		}

		throw `Interaction.getMarkerAsset: my marker asset isn't loaded`
	}

	getMarkerAssets() {
		if ( !this.assets || !this.assets.marker ) {
			throw `Interaction.getMarkerAssets: i don't have a marker asset OR it isn't loaded yet`
		}

		let asses = {
			marker: this.assets.marker
		}

		if ( typeof this.assets.markerFocus == 'object' ) {
			asses.markerFocus = this.assets.markerFocus
		}

		return asses
	}


	getInteractionAsset() {
		if ( !this.assets || !this.assets.interact ) {
			throw `Interaction.getInteractionAsset: i don't have an interaction asset OR it isn't loaded yet`
		}

		if ( typeof this.assets.interact == 'object') {
			return this.assets.interact
		}

		throw `Interaction.getInteractionAsset: my interaction asset isn't loaded`
	}


	getInteractionAssets() {
		if ( !this.assets || !this.assets.interact ) {
			throw `Interaction.getInteractionAssets: i don't have a interact asset OR it isn't loaded yet`
		}

		let asses = {
			interact: this.assets.interact
		}

		if ( typeof this.assets.interactFocus == 'object' ) {
			asses.interactFocus = this.assets.interactFocus
		}

		return asses
	}

	getName() {
		return this.name
	}
}
