/**
 * Game class is the main view controller after the app starts the game.
 *
 * Game.mjson is the specification file for the game.
 *
 * Example usage:
 *
 * let specs = JSON.parse(...)
 * let game = await new Game(specs)
 *
 * game.start(viewElement)
 *
 */

import { sleep, log, warn, error, debug, replaceTrans } from 'global.js'
import { isObject, isString } from 'validators.mjs'

import { GameApi, GLOBAL_CURRENT_PLAYER, GLOBAL_CURRENT_PLAYER_NAME, GLOBAL_PLAYER_LIST, GLOBAL_PLAYER_COUNT, GLOBAL_PLAYER_COUNT_LITERAL, GLOBAL_CURRENT_ROUND_NAME, GLOBAL_PLAYER_ROUND_NAME, GLOBAL_WORLD_ROUND_NAME, GLOBAL_COUNTER_ROUND, GLOBAL_COUNTER_PLAYER_TURN, GLOBAL_COUNTER_PLAYER_ACTIONS, GLOBAL_ACTIONS_PER_PLAYER_TURN, GLOBAL_PLAYER_BASE_COUNT } from 'Game/Api.mjs'
import { UI } from 'UI/UI.mjs'

import { AssetsBrowserCache as AssetManager } from 'Game/AssetsBrowserCache.mjs'
import { TranslationManager } from 'Game/Translations.mjs'
import { ContentManager } from 'Game/Contents.mjs'

import { Script } from 'Game/Script.mjs'

import { Configuration, CONFIG_PLAYER_TURN_SYSTEM, CONFIG_PLAYER_BASE_COUNT } from 'Game/Configuration.mjs'

import { MissionManager } from 'Game/Mission.mjs'
import { ThingManager } from 'Thing/ThingManager.mjs'

import { AudioManager } from 'Game/Audio.mjs'

import { Processor } from 'Command/Processor.mjs'
import { State } from 'Game/State.mjs'

import { Dialogue } from 'Game/Dialogue.mjs'

import { Event } from 'Command/Event.mjs'

import { StartPlayerRoundEvent, StartWorldRoundEvent, EndPlayerRoundEvent, EndWorldRoundEvent } from 'Command/EventsGame.mjs'

const START_SCRIPT = "start"
const DEVELOP_SCRIPT = "develop"
const PRODUCTION = "production"
const DEVELOPMENT = "development"
const SETTINGS_QUALITY = "SETTINGS_QUALITY"
const SETTINGS_ANIMATE = "SETTINGS_ANIMATE"
const SETTINGS_LANGUAGE = "SETTINGS_LANGUAGE"

const BOARD_CONTENT_SCALE = {
	"1": 0.5,
	"2": 1.0,
	"3": 1.0,
}

export class Game extends GameApi {

	static global
	static version = 36
	static environment = "production"

	/**
	 * Creates a new game object with the specs.
	 *
	 *
	 * @param {object} GAME_SPECS from game_specs.js
	 * @param {object} GAME_ASSETS from game_assets.js
	 * @param {object} GAME_SCRIPT from game_script.js
	 * @param {object} GAME_MISSIONS from game_missions.js
	 * @param {object} GAME_CONTENTS from game_contents.js
	 * @param {object} GAME_TRANSLATIONS from game_translations.js
	 * @param {object} GAME_BOARDS from game_boards.js
	 * @param {string} GAME_STYLES from styles.css
	 * @param {integer} quality Use 1, 2 or 3
	 */
	constructor(app, settings, gameSpecs, assetSpecs, scriptSpecs, missionSpecs, contentSpecs, translationSpecs, thingSpecs, boardSpecs, gameStyles) {
		super()

		if ( Game.global ) { throw "Game.constructor() Game.global singleton is already defined – only one Game can exist at a time!" }

		Game.global = this
		this.app = app

		if ( !isObject(gameSpecs) ) { throw "Game.constructor() didn't get Object 'gameSpecs'" }
		if ( !isObject(assetSpecs) ) { throw "Game.constructor() didn't get Object 'assetSpecs'" }
		if ( !isObject(scriptSpecs) ) { throw "Game.constructor() didn't get Object 'scriptSpecs'" }
		if ( !isObject(missionSpecs) ) { throw "Game.constructor() didn't get Object 'missionSpecs'" }
		if ( !isObject(contentSpecs) ) { throw "Game.constructor() didn't get Object 'contentSpecs'" }
		if ( !isObject(translationSpecs) ) { throw "Game.constructor() didn't get Object 'translationSpecs'" }
		if ( !isObject(thingSpecs) ) { throw "Game.constructor() didn't get Object 'thingSpecs'" }
		if ( !isObject(boardSpecs) ) { throw "Game.constructor() didn't get Object 'boardSpecs'" }
		if ( !isString(gameStyles) ) { throw "Game.constructor() didn't get String 'gameStyles'" }

		if ( gameSpecs.version != Game.version ) {
			throw `Game.constructor() game specs version != ${ Game.version }`
		}

		// All good - continue with normal start

		this.name = gameSpecs.name

		document.querySelector('head title').innerHTML = this.name

		this.defaultLanguage = gameSpecs.defaultLanguage
		this.supportedLanguages = gameSpecs.supportedLanguages

		this.gameStyles = gameStyles

		this.configuration = new Configuration(gameSpecs.configure)

		this.variables = {}

		this.gameSpecs = gameSpecs

		this.settings = settings

		// override quality with mobile
		if ( this.settings.quality > 1 && this.settings.device == "mobile" ) {
			console.log("Game.constructor() overriding quality to 1 with mobile")
			this.settings.quality = 1
		}

		this.boardContentScale = BOARD_CONTENT_SCALE[ this.settings.quality ]

		this.resetVariables()

		this.processor = new Processor()

		this.translationManager = new TranslationManager(translationSpecs)

		this.assetManager = new AssetManager(assetSpecs, this.settings.quality, this.boardContentScale)

		this.thingManager = new ThingManager(thingSpecs)

		this.boardSpecs = boardSpecs

		this.dialogues = gameSpecs.dialogues

		this.script = new Script(scriptSpecs)

		this.missionManager = new MissionManager(missionSpecs)

		this.contentManager = new ContentManager(contentSpecs)

		this.state = new State()

		this.currentPlayerIndex = 0

		this.audioManager = new AudioManager({
			musicVolume: settings["music-volume"],
			sfxVolume: settings["sfx-volume"],
			voiceVolume: settings["voice-volume"],
		})

		debug("Settings:")
		debug("-quality: " + settings.quality)
		debug("-animation: " + settings.animate)
		debug("-lang: " + settings.language)
		debug("-music-volume: " + settings["music-volume"])
		debug("-sfx-volume: " + settings["sfx-volume"])
		debug("-voice-volume: " + settings["voice-volume"])

	}

	resetVariables() {
		this.variables = {
			[SETTINGS_QUALITY]: this.settings.quality,
			[SETTINGS_ANIMATE]: this.settings.animate,
			[SETTINGS_LANGUAGE]: this.settings.language,
			[GLOBAL_CURRENT_PLAYER]: null,
			[GLOBAL_CURRENT_PLAYER_NAME]: null,
			[GLOBAL_CURRENT_ROUND_NAME]: null,
			[GLOBAL_PLAYER_LIST]: [],
			[GLOBAL_PLAYER_COUNT]: 0,
			[GLOBAL_PLAYER_COUNT_LITERAL]: replaceTrans(0),
			[GLOBAL_ACTIONS_PER_PLAYER_TURN]: 1,
			[GLOBAL_COUNTER_ROUND]: 0,
			[GLOBAL_COUNTER_PLAYER_TURN]: 0,
			[GLOBAL_COUNTER_PLAYER_ACTIONS]: 0,
			[GLOBAL_PLAYER_BASE_COUNT]: this.getConfig(CONFIG_PLAYER_BASE_COUNT),
		}

		for ( let name in this.gameSpecs.variables || {} ) {
			this.variables[name] = this.gameSpecs.variables[name]
		}		
	}

	/**
	 * Starts running the game.
	 *
	 * Returns a Promise that won't return until game is closed.
	 *
	 * – setup UI, inserting required elements into DOM
	 * – setup all the managers
	 * – reset into empty state
	 *
	 * – show loading screens and fetch all assets
	 *
	 * – TODO show main menu
	 * – TODO show player character menu
	 *
	 * – show game board view and start script
	 *
	 * @return Promise doesn't return until game is closed
	 */
	async startGame() {
		log("Game.startGame() called – starting")

		UI.global.setup(
			this.gameSpecs.preloadViews,
			this.gameSpecs.boardWidth,
			this.gameSpecs.boardHeight,
			this.gameSpecs.referenceTileWidth,
			this.gameSpecs.referenceTileHeight,
			this.getConfig(CONFIG_PLAYER_TURN_SYSTEM),
			this.boardContentScale
		)

		try {
			UI.global.showLoader()

			await this.preloadAllAssetsInParallel()

			this.resetState()
			
			// start the processor
			this.processor.startProcessor()

			await UI.global.hideLoader(true)

			if ( Game.environment == PRODUCTION ) {
				this.script.runProcessor(START_SCRIPT, this.processor, this.variables)
			} else {
				this.script.runProcessor(DEVELOP_SCRIPT, this.processor, this.variables)
			}

		} catch (err) {
			warn("Game error: ")
			warn(err)
		}
	}

	stopGame() {
		log("Game.stopGame() called – exiting")

		this.app.exitApp()
	}

	async preloadAllAssetsInSeries() {

		let loaderView = UI.global.getLoader( this.assetManager.getAssetCount() )

		let loadTime = Date.now()

		let ids = this.assetManager.listAllIds()

		for ( let assetId of ids ) {
			let asset = Game.global.assetManager.get(assetId)

			if ( asset.preload ) {
				await Game.global.assetManager.getPreloaded(assetId)
			}

			loaderView.increment()
		}

		// let the loading screen stay on for a while
		if ( (Date.now() - loadTime) < 500  ) {
			await sleep(500)
		}
	}


	async preloadAllAssetsInParallel() {

		let loaderView = UI.global.getLoader( this.assetManager.getAssetCount() )

		let loadTime = Date.now()

		let ids = this.assetManager.listAllIds()
		let promises = []

		for ( let assetId of ids ) {
			let asset = Game.global.assetManager.get(assetId)

			if ( asset.preload ) {
				let promise = Game.global.assetManager.getPreloaded(assetId)

				promise.finally(() => {
					loaderView.increment()
				})

				promises.push(promise)
			} else {
				loaderView.increment()
			}
		}

		await Promise.all(promises)

		// let the loading screen stay on for a while
		if ( (Date.now() - loadTime) < 1000  ) {
			await sleep(1000)
		}

		// Mobile requires click (within iframe) for Audio
//		if ( this.getSettingDevice() == "mobile" ) {
			await loaderView.waitForClick()
//		}
 	}

	/**
	 * Start preloading assets in the background
	 *
	 */
	async preloadAllAssetsInTheBackground() {

		let loaderView = UI.global.getLoader( )

		let ids = this.assetManager.listAllIds()

		for ( let assetId of ids ) {
			Game.global.assetManager.getPreloaded(assetId)
		}

		await sleep(3000)
	}


	/**
	 * Creates a new Promise and stores it in this.gameLoop
	 * as well as returns it.
	 * 
	 * To end game loop, call:
	 * 
	 * 	this.stopGameLoop()
	 * 
	 * @return { Promise }
	 */
	startGameLoop() {
		throw `Game.startGameLoop() is disabled`


		let game = this

		this.gameLoopPromise = new Promise((resolve, reject) => {

			game.cancelGameLoop = resolve

			while ( game.cancelGameLoop ) {
			
				game.startPlayerRound()

				game.waitForEvent(EndPlayerRoundEvent)

				if ( !game.gameLoop ) { break }

				if ( game.getConfig(CONFIG_WORLD_ROUND_SYSTEM) ) {

					game.startWorldRound()

					game.waitForEvent(EndWorldRoundEvent)
				}
			}
		})

		return true
	}

	stopGameLoop() {
		warn(`Game.stopGameLoop() is disabled`)

		return true // gameloop disabled for now

		if ( !this.gameLoop ) { throw `Game.stopGameLoop() no game loop running` }
		if ( typeof this.cancelGameLoop !== "function" ) { throw `Game.stopGameLoop() no cancel callback for game loop` }	

		delete this.gameLoop

		return this.cancelGameLoop()
	}

	async endGame() {
		log(`– GAME IS ENDING –`)

		try {

			this.stopGameLoop()

			this.processor.stopProcessor()

			this.thingManager.resetRegistry()

			await UI.global.exitUI()

		} catch (err) {
			console.error("GameApi.endGame() exception: " + err)
		}
	}

	/**
	 * Listen for specific event.
	 * 
	 * This can be used to await execution until a certain event happens.
	 * 
	 * Game.waitForEvent("some-event")
	 * Game.waitForEvent(StartGameRoundEvent)
	 * 
	 * @param { string | Class } type
	 * @return { Promise }
	 */
	async waitForEvent(stringOrClass) {
		let type = stringOrClass.eventType || stringOrClass

		await this.processor.waitForEvent(type)
	}
}