import { generateAssetUrl } from "../global.js";

export default `

/* Import card styles */

:host {
	display: inline-block;
	position: relative;

	color: var(--card-text-color);

	width: var(--card-width);
	height: var(--card-height);

	outline-style: solid;
	outline-width: var(--card-border-width);
	outline-color: transparent;
	border-radius: var(--card-border-radius);
}

:host(.small) {
	width: calc(var(--card-width));
	height: calc(var(--card-height)*0.5);
}

:host(.small) .front,
:host(.small) .back {
	background-size: 200%;
}

:host(:not(.disabled):hover) {
	cursor: pointer;
}

:host(:not([id])) {
	display: none;
}

.front, .back {
	position: absolute;
	width: 100%;
	height:  100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	border-style: solid;
	border-width: var(--card-border-width);
	border-radius: var(--card-border-radius);

	box-shadow: var(--card-box-shadow);
	backface-visibility: hidden;

	transition: transform 0.8s;
	transform-style: preserve-3d;
}

.front {
	z-index: 2;
	border-color: var(--card-border-front-color);
	transform: rotateX(var(--view-attack-enemy-card-x)) rotateY(var(--view-attack-enemy-card-y)) rotateZ(var(--view-attack-enemy-card-z));
}

.back {
	z-index: 1;
	border-color: var(--card-border-back-color);
	transform: rotateX(var(--view-attack-enemy-card-x)) rotateY(calc(var(--view-attack-enemy-card-y) + 180deg)) rotateZ(var(--view-attack-enemy-card-z));

	display:  flex;
	flex-direction: column;
	justify-content: flex-start;
}

:host(:not(.disabled):hover) .front {
	border-color: var(--card-border-hover-color);
}

:host(:not(.disabled):hover) .back {
	border-color: var(--card-border-hover-color);
}

:host(.flip) .front {
	transform: rotateY(calc(var(--view-attack-enemy-card-y) + 180deg));
}

:host(.flip) .back {
	transform: rotateY(calc(var(--view-attack-enemy-card-y)));
}

:host(.selected) .front,
:host(.selected) .back {
	border-color: var(--card-border-selected-color);
	box-shadow: var(--card-selected-box-shadow);
}

.back .title {
	font-size: var(--s-font-size);
	text-overflow: ellipsis;
	padding: var(--m-pad) var(--m-pad);
	text-align: center;
}

.back .text {
	flex-grow: 1;
	overflow-y: hidden;
	padding: var(--m-pad) var(--m-pad);
}

.back .text p {
	font-size: var(--xs-font-size);
	line-height: var(--xs-line-height);
}

.stats {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height:  48px;

	background: var(--card-stats-bg);
}

.stats .left, .stats .right {
	display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.stats .value {
	font-size: var(--m-font-size);
	margin: 0;
	padding: 0;
}

.stats .icon {
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;

	width: var(--card-stats-icon-width);
	height: var(--card-stats-icon-height);
}

.icon.health {
	background-image: var(--image-card-stats-health);
}

.icon.attack {
	background-image: var(--image-card-stats-attack);
}


`;
