import { sleep, warn, log, error, debug, replaceObjectTransVars, replaceTransVars, hurl } from 'global.js'
import { isDef, isObject, isString, isBoolean, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { T } from 'Game/Translations.mjs'

/**
 * Example content specs
 *
 * Uses replaceTransVars() to replace contents.
 * 
 * const GAME_CONTENTS = {
 * 	"version": 32,
 * 
 *	"example-popup": {
 *		"type": "popup-ok",
 * 
 *		"content": {
 *			"text": "{{~hello-world}}"
 *		}
 *	},
 * 
 * 	"popup-frontdoor-chain-lock-is-on": {
 *		"type": "popup-actions",
 * 
 *		"contents": {
 *			"text": `{{~popup-frontdoor-chain-lock-on}}`,
 *			"buttons": {
 * 
 *				"template": `<text-button action="{{action}}">{{label}}</text-button>`,
 * 
 *				"content": [
 *					{ "label": "{{~button-unlock-chain}}", "action": "unlock" },
 *					{ "label": "{{~button-let-it-be}}", "action": "close" }
 *				]
 *			}
 *		},
 * 
 *		"actions": {
 *			"unlock": [ { "cmd": "updateDoor", "door": "door-to-frontyard-from-entrancehall", "state": "closed" } ],
 *		}
 *	},
 * }
 *
 */

export class ContentManager {

	static version = 34

	constructor(contentSpecs) {
		if ( !contentSpecs ) throw `ContentManager.constructor: 'contentSpecs' parameter missing`

		this.contents = this.parseSpecs(contentSpecs)
	}

	parseSpecs(contentSpecs) {
		if ( contentSpecs.version != ContentManager.version ) throw `ContentManager.parseSpecs(): bad version: ${contentSpecs.version} != ${ ContentManager.version}`
		if ( Object.keys(contentSpecs).length <= 1 ) warn `ContentManager.parseSpecs(): propably no content`

		let contents = {}

		for ( let key in contentSpecs ) {

			if ( key == "version" ) { continue }

			if ( !contentSpecs[key].type) throw `ContentManager.parseSpecs() missing 'type' key for '${ key }`

			switch (contentSpecs[key].type) {
				case "popup-ok":
					contents[key] = new ContentPopupOk(key, contentSpecs[key])
					break;


				case "popup-actions":
					contents[key] = new ContentPopupChoice(key, contentSpecs[key])
					break;

				case "popup-pick-card":
					contents[key] = new ContentPopupPickCard(key, contentSpecs[key])
					break;

				case "popup-pick-random-injury-card":
					contents[key] = new ContentPopupPickRandomInjuryCard(key, contentSpecs[key])
					break;

				case "custom-content":
					contents[key] = new CustomContent(key, contentSpecs[key])
					break;

				default:
					throw `ContentManager.parseSpecs() unknown content type: ${ contentSpecs[key].type }`
			}
		}

		return contents
	}

	getContent(id) {
		if ( !id ) throw `ContentManager.getContent() missing id parameter.`
		if ( !this.contents[id] ) throw `ContentManager.getContent(): no content found with id '${id}'`

		return this.contents[id]
	}
}


export class Content {
	constructor(id, specs) {
		if ( this.constructor == Content ) throw "Content() tried to create abstract Content class object – use child classes."
		if ( !isString(id) ) throw "Content() no 'id' or 'id' not a valid string"
		if ( !isObject(specs) ) throw `Content() ${id} no 'specs' or 'sepcs' not an object`
		if ( specs.type != this.type ) throw `Content.validate() specs.type doesn't match this.type: ${ specs.type } <=> ${ this.type }`

		this.required.every( x => specs.contents[x] )
			|| hurl `Content() ${ id } missing required fields: ${ this.required }. Got: ${ specs.contents }`

		Object.keys(specs.contents).every( (x) => {

			return this.optional == "any"
				|| this.optional.includes(x)
				|| this.required.includes(x)

		}) || hurl `Content() ${id} has fields not listed as required or optional by constructor. Required: ${ this.required }. Optional: ${ this.optional }. Got: ${ Object.keys(specs.contents) }`

		this.id = id
		this.contents = specs.contents
		this.actions = specs.actions

	}

	content(variables) {
		let copy = JSON.parse(JSON.stringify( { "contents": this.contents, "actions": this.actions } ))

		copy = replaceObjectTransVars(copy, variables || {}, T)

		for ( let act in copy.actions ??{}) {
			copy.actions[act] = Game.global.createCommandChain(copy.actions[act])
				|| hurl `Content() ${ id } No command chain created for action: ${act}`
		}

		return copy
	}

	get type() { return this.constructor.type }

	get required() { return this.constructor.required }

	get optional() { return this.constructor.optional }

	/**
	 * Child classes must defined member:
	 *
	 * static templateString
	 *
	 * @param {object} variables (optional)
	 * @returns Element
	 */
	template(variables) {

		if ( !isDef(this.constructor.templateString) ) {
			throw `Content.template() no 'templateString' defined`
		}

		let template = document.createElement('div')
		template.innerHTML = replaceTransVars(this.constructor.templateString, variables)

		return template
	}
}


export class ContentPopupOk extends Content {
	static type = 'popup-ok'
	static templateString
	static required = ['text']
	static optional = []

}

import importPopupStyles from "css/import-popup.css.mjs";


ContentPopupOk.templateString = `
<style>
	${ importPopupStyles }
</style>
<div class="popup">
	<div id="text" class="texts">

		<!-- filled from game content -->

	</div>
	<div class="buttons">
		<text-button action="close" default="true">OK</text-button>
	</div>
</div>
`;

export class ContentPopupChoice extends Content {
	static type = 'popup-actions'
	static templateString
	static required = ['text', 'buttons']
	static optional = []
}

ContentPopupChoice.templateString = `
<style>
	${ importPopupStyles }
</style>
<div class="popup">
	<div id="text" class="texts"></div>
	<div id="buttons" class="buttons"></div>
</div>
`;


export class ContentPopupPickCard extends Content {
	static type = 'popup-pick-card'
	static templateString
	static required = ['card', 'text']
	static optional = []
}

ContentPopupPickCard.templateString = `
<style>
	${ importPopupStyles }
</style>
<div class="split-box">
	<div id="card" class="top center"></div>
	<div class="bottom">
		<h3 id="title"></h3>
		<div id="text" class="texts center"></div>
		<div class="buttons">
			<text-button action="close" default="true">{{~button-done}}</text-button>
		</div>
	</div>
</div>
`;

export class ContentPopupPickRandomInjuryCard extends Content {
	static type = 'popup-pick-random-injury-card'
	static templateString
	static required = ['text']
	static optional = []
}

ContentPopupPickRandomInjuryCard.templateString = `
<style>
	${ importPopupStyles }

	.cards .left {
		z-index: 1;
		transform: rotate(-5deg);
	}
	.cards .center {
		z-index: 2;
		margin-left: -10%;
		margin-right: -10%;
	}
	.cards .right {
		z-index: 1;
		transform: rotate(5deg);
	}

</style>
<div class="split-box">
	<div class="cards center">
		<effect-card id='effect-arm-injury' class='disabled tilt left'></effect-card>
		<effect-card id='effect-cracked-skull' class='disabled center'></effect-card>
		<effect-card id='effect-leg-injury' class='disabled tilt right'></effect-card>
	</div>
	<div>
		<h3 id="title"></h3>
		<div id="text" class="texts center"></div>
		<div class="buttons">
			<text-button action="close" default="true">{{~button-done}}</text-button>
		</div>
	</div>
</div>
`;


export class CustomContent extends Content {
	static type = 'custom-content'
	static templateString
	static required = ['template']
	static optional = "any"

	template(variables) {

		if ( !this.template ) { error(`CustomContent.template() this.template not defined.`) }

		let selector = `template.custom[name=${ this.template }]`
		let template = Game.global.getTemplateWithSelector(selector)

		return template
	}
}

