import { hurl, sleep, error, warn, log, debug, replaceTransVars } from 'global.js'
import { isUndefOrNull, isObject, isDef, isString, isArray } from 'validators.mjs'

import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'
import { Game } from 'Game/Game.mjs'

import { T } from 'Game/Translations.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'


export class ViewPopup extends ViewLayer {
	static templateString

	/**
	 * Initialized by DOM from <view-popup>
	 *
	 */
	constructor() {
		super()

		this.classList.add('view')
		this.classList.add('fast')

	}

	/**
	 * Opens the popup view using this popup template.
	 *
	 * @param { string } contentId
	 * @param { object } variables
	 * @param { boolean } [animate]
	 * @param { string } [sfxOverride]
	 * @return nothing
	 */
	async show(contentId, variables, animate, sfxOverride) {

		this.setAttribute('id', contentId)
		this.name = contentId

		let content = Game.global.getContent(contentId, variables) || hurl `ViewPopup.show() no content found for '${contentId}'`
		let contentObj = content.content(variables)
		this.contents = contentObj.contents
		this.actions = contentObj.actions
		this.animate = animate

		let template = content.template() || hurl `ViewPopup.show() template not found for '${contentId}'`

		for ( let key in contentObj.contents ) {
			let selector = `#${ key }`

			let slot = template.querySelector(selector) || hurl `ViewPopup.show() slot not found in template with selector '${selector}'.`

			let stringOrObject = contentObj.contents[key]
			let htmlContent

			if ( isString(stringOrObject) ) {
				htmlContent = stringOrObject
			}

			else if ( isObject(stringOrObject) ) {
				let template = stringOrObject.template || hurl `ViewPopup.show() '${key}' doesn't have a template: ${ stringOrObject }`
				let values = stringOrObject.values || hurl `Content '${key}' doesn't have values: ${ stringOrObject }`

				let subcontent = []

				for ( let item of values ) {
					subcontent.push(template.replace(/%%([-a-z0-9\:]+)%%/gi, (matches, capture) => {

						let makeAttribute = false

						if ( capture?.startsWith("attr:") ) {
							makeAttribute = true
							capture = capture.slice(5)
						}

						if ( isDef(item[capture]) ) {

							if ( makeAttribute ) {
								return `${capture}="${ item[capture] }"`
							} else {
								return item[capture]
							}

						} else {
							return ""
						}
					}))
				}

				htmlContent = subcontent.join(" ")
			}

			else {
				throw `Unknown data for '${key}' in content: ${stringOrObject}`
			}

			slot.innerHTML = replaceTransVars(htmlContent, variables)
		}

		let slot = this.querySelector('#content') || hurl `Element #content not found in template.`

		slot.replaceWith(...template.childNodes)

		if ( !sfxOverride ) {
			Game.global.playSfx('show-popup-primary')

		} else if ( sfxOverride != "none" ) {
			Game.global.playSfx(sfxOverride)

		}

		await super.show(animate)
	}

	async action(listOfActions, element, event) {

		let feedback = false

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				switch(action) {
					case "close":
					case "default":
						await this.hide()
						feedback = true
						break;
				}

				if ( this.actions?.[action] ) {
					await this.hide(this.animate)

					let cmds = Game.global.createCommandChain( this.actions[action] )
					await Game.global.queueCommandsOnTopAndWait( cmds )

					feedback = true
				}
			}
		}

		return feedback
	}

	async hide() {
		await super.hide()

		this.remove()
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewPopup.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }

	h1,h2 {
		text-align: center;
	}

</style>
<slot id="content"></slot>

`;

window.customElements.define('view-popup', ViewPopup)

