import { sleep, log, error, warn, debug } from 'global.js'

import { Runnable } from "Command/Runnable.mjs"


/**
 * Runnable "interface"
 *
 * Runnable is a setInterval based "thread" that any class can extend
 * directly or using mixin() from globals.js:
 *
 *     class Child extends Runnable
 *
 *     class Child extends mixin(Parent, Runnable)
 *
 * Child class has to implement methods:
 *
 *     async executeRunnable()
 *
 * Runnable provides methods:
 *
 *     runnable.startRunnable()
 *     runnable.pauseRunnable()
 *     runnable.stopRunnable()
 *
 * Pause is same as stop for RunnableWorker.
 *
 */
export class RunnableWorker extends Runnable {

	static workerObjectUrl = window.URL.createObjectURL(new Blob([`self.addEventListener('message', function(e){
    switch (e.data) {
        case 'start':
            interval = setInterval(function(){
                self.postMessage('run');
            }, ${ Runnable.intervalDelay });

            break;

        case 'stop':
            clearInterval(interval);
            break;
    };

}, false);`], {type:'application/javascript'}))

	constructor(mixinClass) {
		super(mixinClass)
	}


	startRunnable() {
		this._runnable.timer = new Worker(RunnableWorker.workerObjectUrl)

		this._runnable.timer.onmessage = (event) => {
			switch(event.data) {
				case "run":
					super.executeRunnable(this)
					break

				default:
					console.warn(`RunnableWorker.startRunnable() onmessage: unknown message – got: ${event.data}`)
					break
			}
		}

		this._runnable.timer.postMessage("start")
	}

	stopRunnable() {
		this._runnable.timer.postMessage("stop")
	}

	pauseRunnable() {
		this._runnable.timer.postMessage("stop")
	}
}
