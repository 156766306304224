import { log, debug, warn } from 'global.js'
import { isArray } from 'validators.mjs'
import { Board } from 'Board/Board.mjs'

/**
 * @param {id} id of the drawable element
 * @param {{assetId: Asset}} object of the assets { id: Asset }
 * @param {int} x center coordinate on the board
 * @param {int} y center coordinate on the board
 */
export class Drawable {
	constructor(board, id, assets, cx, cy) {
		if ( board.constructor != Board ) { throw "Tried to create Drawable without board" }
		if (this.constructor == Drawable) { throw "Tried to create object from abstract class Drawable" }

		if ( cx == null || cy == null) {
			throw `Drawable '${id} doesn't have x,y: ${cx},${cy}`
		}

		this.id = id
		this.board = board
		this.assets = assets

		this.setSizeFromAssets(assets)

		this.move(cx, cy)
		this.animate = null
	}

	updateAsset(id, asset) {
		this.assets[id] = asset

		this.setSizeFromAssets({ [asset.id]: asset })

		this.needRedraw()
	}

	move(cx, cy) {
		if ( this.board.boardContentScale != 1 ) {

			this.cx = Math.round(cx * this.board.boardContentScale)
			this.cy = Math.round(cy * this.board.boardContentScale)

		} else {
			this.cx = cx
			this.cy = cy
		}
	}

	setSizeFromAssets(assets) {
		let width = 0, height = 0

		// this.board.boardContentScale is not needed here because
		// Asset will resize width/height if needed
		for ( let id in assets ) {
			if ( assets[id].width > width ) {
				width = assets[id].width
			}
			if ( assets[id].height > height ) {
				height = assets[id].height
			}
		}

		this.width = width
		this.height = height
		this.halfWidth = Math.round(width/2)
		this.halfHeight = Math.round(height/2)
	}

	/**
	 * @param {string} type fadeIn|fadeOut|redraw
	 * @param {int} length ms - default 500, but redraw is 0 (optional)
	 * @return {Promise} or null
	 */
	setAnimation(type, length) {

		if ( type == "redraw" && this.animate ) {
			return null
		}

		return new Promise((resolve, reject) => {
			if ( type != "fadeIn" && type != "fadeOut" && type != "redraw" ) {
				throw `Drawable.setAnimation: unknown type: ${type} - need fadeIn|fadeOut|redraw`
			}

			this.animate = {
				type: type,
				length: Number.isNaN(length) ? (type == "redraw" ? 0 : 500) : length,
				callback: () => { resolve(true) }
			}

			if ( type == "fadeIn" ) {
				this.opacity = 0
			}
			else if ( type == "fadeOut" ) {
				if ( this.opacity >= 0 ) {
					// don't reset if already something
				}
				else { this.opacity = 1 }
			}
			else if ( type == "redraw" ) {
				this.redraw = 0
			}
		})
	}

	/**
	 * @return {Promise}
	 */
	needRedraw() {
		this.board.canvasModified = true
		return this.setAnimation("redraw")
	}

	areYouAt(x, y) {
		return this.areYouAtRectangle(x, y)
	}

	areYouAtRectangle(x, y) {
		let dx = Math.abs(x - this.cx)
		let dy = Math.abs(y - this.cy)

		if ( dx <= this.halfWidth && dy <= this.halfHeight ) {
			return true
		} else {
			return false
		}
	}

	areYouAtCircle(x, y) {
		let dx = Math.abs(x - this.cx)
		let dy = Math.abs(y - this.cy)
		let radius = this.halfWidth

		if ( dx > radius || dy > radius ) {
			return false
		}
		else if ( Math.sqrt(dx*dx + dy*dx) < radius ) {

			return true
		}
		else {
			return false
		}
	}
}
