import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const ATTACK_EVENTS = ["character-damage", "character-horror"]

EVENT_TYPES.push(...ATTACK_EVENTS)

export class AttackEvent extends Event {
	constructor(object) {
		if (this.constructor == AttackEvent) {
			throw "AttackEvent.constructor(): tried to create AttackEvent object directly – use child classes"
		}

		super (object)
	}
}

export class CharacterDamage extends Event {
	static eventType = "character-damage"
	static requiredKeys = ["event", "character", "damage", "type"]
	static optionalKeys = ["attacker"]

	constructor(object) {
		super(object)
	}
}

// Alla oleva on esimerkki jos jossain vaiheessa haluaisi niin, että Command objektit pitää sisällään
// objekteja eikä vaan simppeleitä stringejä
//

	/**
	 * Examples:
	 *
	 * { "event": "character-damage", "characters": "all-players", "attacker": "ripper", "fearHorror": 1 }
	 * { "event": "character-damage", "characters": ["student", "professor"], "attacker": "ripper", "physicalDamage": 3 }
	 *
	 * @param { object } event
	 */
	/*
	static create(event) {
		if ( event.event !== CharacterDamage.eventType ) { throw `CharacterDamage.create() wrong event type: ${event.event} != ${ CharacterDamage.eventType}` }

		let characters, damage, attacker

		if ( event.characters === "all-players" ) {
			characters = Game.global.getAllPlayers()
		}

		if ( !isArray(characters) ) { throw `CharacterDamage.create() attribute 'characters' is not an array` }

		characters = characters.map( x => {
			return Game.global.getCharacter(x)
		})

		if ( "physicalDamage" in event ) {
			damage = new PhysicalDamage( event.physicalDamage )
		}

		else if ( "heavyDamage" in event ) {
			damage = new HeavyDamage( event.heavyDamage )
		}
		else if ( "piercingDamage" in event ) {
			damage = new PiercingDamage( event.piercingDamage )
		}
		else if ( "gunDamage" in event ) {
			damage = new GunDamage( event.gunDamage )
		}

		if ( isString(event.attacker) ) {
			attacker = Game.global.getCharacter(event.attacker)
		}

		return new CharacterDamage(characters, damage, attacker)
	}
	*/
