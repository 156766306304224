/**
 * Loader view shows progress bar
 */

import { ViewLayer } from 'UI/ViewLayer.mjs'

export class ViewLoader extends ViewLayer {
	static templateString

	constructor() {
		super()

		this.max = this.shadowRoot.querySelector('#max')
		this.current = this.shadowRoot.querySelector('#current')
		this.percent = this.shadowRoot.querySelector('#percent')
		this.counter = this.shadowRoot.querySelector('#counter')
	}

	/**
	 * @param {integer} max
	 * @param {integer} current (optional)
	 */
	reset(max, current) {
		if ( max ) {
			this.setMax(max)
			this.setCurrent(current)

			if ( this.counter?.style?.display ) {
				this.counter.style.display = "block"
			}
		} else {
			if ( this.counter?.style?.display ) {
				this.counter.style.display = "none"
			}
		}

		return this
	}

	increment() { return this.add(1) }

	waitForClick() {
		return new Promise((resolve, reject) => {

			let buttons = this.querySelector('.buttons')

			if ( buttons ) {
				buttons.style.opacity = 1
			}

			this.startAction = resolve
		})
	}

	action(event) {
		this.startAction()
	}
	
	/**
	 * @param {integer} amount (optional) defaults to 1
	 */
	add(amount) {
		if ( this?.current?.innerHTML ) {
			this.current.innerHTML = parseInt(this.current.innerHTML) + (amount || 1)
			this.updatePercent()
		}

		return this
	}

	getMax() {
		return parseInt(this.max.innerHTML)
	}

	setMax(value) {
		if (this?.max?.innerHTML) {
			this.max.innerHTML = parseInt(value) || 0
		}

		this.updatePercent()
	}

	getCurrent() {
		return parseInt(this.current.innerHTML)
	}

	setCurrent(value) {
		if ( this?.current?.innerHTML ) {
			this.current.innerHTML = parseInt(value) || 0
			this.updatePercent()
		}

		return this
	}

	updatePercent() {
		if ( this?.percent?.innerHTML ) {
			this.percent.innerHTML = this.calculatePercent()
		}

		return this
	}

	calculatePercent() {
		let current = this.getCurrent() || 0
		let max = this.getMax() || 0

		try {
			return Math.trunc((current / max) * 100) || 0
		} catch (err) {
			return ""
		}
	}

	/**
	 * @return {object} multiple max, current, percent
	 */
	getStats() {
		return { max: this.getMax(), current: this.getCurrent(), percent: this.getCurrent() }
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewLoader.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }

	div.loading {
		position: absolute;
		top: 0; left: 0; bottom: 0; right: 0;
		background: var(--background);
	}

	div.loading div {
		text-align: center;
	}

	div.loading p.percents {
		min-width: 200px;
	}

	.center {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}

	.buttons {
		padding-top: var(--m-pad);
		opacity: 0;
		transition: opacity 2s;
	}

	.font-family-1 { font-family: var(--title-font-family); }
	.font-family-2 { font-family: var(--default-font-family); }
	.font-family-3 { font-family: var(--button-font-family); }
	.font-family-4 { font-family: var(--tutorial-font-family); }

	.style-regular {
	    font-style: normal;
	    font-weight: normal;
	}
	.style-bold {
	    font-style: normal;
	    font-weight: bold;
	}
	.style-regular-italic {
	    font-style: italic;
	    font-weight: normal;
	}
	.style-bold-italic {
	    font-style: italic;
	    font-weight: bold;
	}

</style>
<div class="loading center">
	<div>
		<h1>Loading</h1>
		<p id="counter" class="percents">
			<span id="current">0</span> / <span id="max">0</span> (<span id="percent">0</span> %)
		</p>
		<p class="buttons">
			<text-button id="start" default="true">{{~button-start}}</text-button>
		</p>
	</div>
	<div style="position: absolute; width: 0px; height: 0px; visibility: hidden;">
		<p class="font-family-1">
			<span class="style-regular">x</span>
			<span class="style-regular-italic">x</span>
			<span class="style-bold">x</span>
			<span class="style-bold-italic">x</span>
		</p>
		<p class="font-family-2">
			<span class="style-regular">x</span>
			<span class="style-regular-italic">x</span>
			<span class="style-bold">x</span>
			<span class="style-bold-italic">x</span>
		</p>
		<p class="font-family-3">
			<span class="style-regular">x</span>
			<span class="style-regular-italic">x</span>
			<span class="style-bold">x</span>
			<span class="style-bold-italic">x</span>
		</p>
		<p class="font-family-4">
			<span class="style-regular">x</span>
			<span class="style-regular-italic">x</span>
			<span class="style-bold">x</span>
			<span class="style-bold-italic">x</span>
		</p>
	</div>
</div>

`;

window.customElements.define('view-loader', ViewLoader)
