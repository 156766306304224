import { debug, log, warn, error, replaceTransVars } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { T } from 'Game/Translations.mjs'

import { UI } from 'UI/UI.mjs'

const regexTransKey = /^[\-a-z\d]+$/i


export class CustomElement extends HTMLElement {

	constructor() {
		super()

		if (this.constructor == CustomElement) { throw "Tried to create object from abstract class CustomElement" }

		this.attachShadow({mode: 'open'})

		// 1) fill shadowRoot with template if any
		if ( this.constructor.templateString ) {
			this.setContentFromTemplateElement(this.template(), null, null, false)
		}

		// 2) append shadowRoot with light DOM content if any
		if ( this.childNodes.length > 0 ) {
			this.setContentFromTemplateString(this.innerHTML, null, null, true)
			//this.innerHTML = ''
		}
	}


	/**
	 * Replace the shadow contents of this element with either a string of HTML
	 * or <template> element contents
	 *
	 * If 'append' is specified, will not empty innerHTML = '' before
	 *
	 * @param {Element} templateElement
	 * @param {string} [targetSelector] (optional)
	 * @param {object} [variables] (optional) defaults to GameApi.getGlobalVariables()
	 * @param {boolean} [append=false]
	 */
	setContentFromTemplateElement(templateElement, targetSelector, customVariables, append) {
		if ( templateElement === undefined ) { throw `CustomElement.setContentFromTemplateElement() No 'templateElement' attribute.` }
		if ( !(templateElement instanceof Element) ) { throw `CustomElement.setContentFromTemplateElement() attribute 'templateElement' is not an Element. Got: ${ templateElement }.` }
		if ( targetSelector && typeof targetSelector !== "string" ) { throw `CustomElement.setContentFromTemplateElement() attribute 'targetSelector' is not a String. Got: ${ targetSelector }` }

		let target = this.shadowRoot || this
		let variables = customVariables || Game.global.getGlobalVariables()

		if ( targetSelector ) {
			// @ts-ignore : element and shadowRoot both have innerHTML and append() below
			target = this.shadowRoot.querySelector(targetSelector)

			if ( !target ) { throw `CustomElement.setContentFromTemplateElement() target element not found with selector: ${ targetSelector }.` }
		}

		if ( !append ) {
			target.innerHTML = ''
		}

		let templateHtml = ''

		// Handle <template> and other elements differently
		if ( templateElement?.content instanceof DocumentFragment ) {
			let tmp = document.createElement('div')
			tmp.appendChild(templateElement.content.cloneNode(true))

			templateHtml = replaceTransVars(tmp.innerHTML, variables)
		} else {
			templateHtml = replaceTransVars(templateElement.innerHTML, variables)
		}

		target.innerHTML += templateHtml

		if ( templateElement?.classList?.length > 0 ) {
			let classes = new Array(...templateElement.classList)

			if ( target instanceof DocumentFragment ) {
				if ( target?.host?.nodeName?.includes('VIEW-') ) {
					target.host.classList.add(...templateElement.classList)
				} else {
					// can't transfer classes
				}
			}
			else if ( target?.nodeName == 'SLOT' ) {
				// don't tranfer classes
			}
			else if ( target.classList ) {
				target.classList.add(...templateElement.classList)
			}
		}

        if ( target.querySelector('script') ) {

            let oldScripts = target.querySelectorAll('script')

            for ( let oldScript of oldScripts ) {
            	try {
	                let newScript = document.createElement('script')
	                newScript.innerHTML= oldScript.innerHTML

	                window.currentScriptTag = newScript

	                oldScript.replaceWith(newScript)

	            } catch (err) {
	            	warn(`CustomElement.setContentFromTemplateElement() failed: ${err}`)
	            }
            }
        }
	}

	/**
	 * Replace the shadow contents of this element with either a string of HTML
	 * or <template> element contents
	 *
	 * @param {string} templateString
	 * @param {string} [targetSelector] (optional)
	 * @param {object} [variables] (optional) defaults to GameApi.getGlobalVariables()
	 * @param {boolean} [append=false]
	 */
	setContentFromTemplateString(templateString, targetSelector, variables, append) {
		if ( templateString === undefined ) { throw `CustomElement.setContentFromTemplateString() No 'templateString' attribute.` }
		if ( typeof templateString !== "string" ) { throw `CustomElement.setContentFromTemplateString() attribute 'templateString' is not a string. Got: ${ templateString }.`}

		let template = document.createElement('div')
		template.innerHTML = templateString

		this.setContentFromTemplateElement(template, targetSelector, variables, append)
	}


	/**
	 * @param {string} templateString
	 * @param {string} [targetSelector] (optional)
	 * @param {object} [variables] (optional) defaults to GameApi.getGlobalVariables()
	 * @param {boolean} [append=false]
	 */
	setContentFromText(templateString, targetSelector, variables, append) {
		let templateElement = document.createElement('div')
		templateElement.innerText = templateString

		return this.setContentFromTemplateElement(templateElement, targetSelector, variables, append)
	}


	/**
	 * Replace this or target elements contents with the template.
	 *
	 * Optionally replace translations and variables in the content.
	 *
	 * @param {string} templateSelector
	 * @param {string} [targetSelector] (optional)
	 * @param {object} [variables] (optional) defaults to GameApi.getGlobalVariables()
	 * @param {boolean} [append=false]
	 */
	setContentFromTemplateSelector(templateSelector, targetSelector, variables, append) {
		let originalTemplateElement = Game.global.getTemplateWithSelector(templateSelector)

		let templateElement = document.createElement('div')
		templateElement.appendChild(originalTemplateElement.content.cloneNode(true))
		templateElement.classList.add( ...originalTemplateElement.classList )

		return this.setContentFromTemplateElement(templateElement, targetSelector, variables, append)

	}

	/**
	 * Wrapper for real querySelector() for convenience
	 * 
	 * @param {string} selector
	 * @returns {Element|ShadowRoot|CustomElement|DialogueElement}
	 */
	querySelector(selector) {
		if ( this.shadowRoot ) {
			return this.shadowRoot.querySelector(selector)
		} else {
			return super.querySelector(selector)
		}
	}

	/**
	 * Wrapper for real querySelectorAll() for convenience
	 * 
	 * @param {string} selector
	 * @returns {NodeList}
	 */
	querySelectorAll(selector) {
		if ( this.shadowRoot ) {
			return this.shadowRoot.querySelectorAll(selector)
		} else {
			return super.querySelectorAll(selector)
		}
	}

	updateImage(element, asset) {
		if ( !element ) { throw `CustomElement.updateImage() did not get element attribute` }
		if ( !asset ) { throw `CustomElement.updateImage() did not get asset attribute` }

		if (asset.type != 'image') { throw `CustomElement.updateImage: asset type has to be image. Got: ${ asset.type }` }

		element.style.backgroundImage = `url('${asset.url}')`

		if ( asset.style ) {
			for ( let k in asset.style ) {
				element.style[k] = asset.style[k]
			}
		}
	}

	clearContent() {
		(this.shadowRoot || this).innerHTML = ''
	}

	/**
	 * Child classes must defined member:
	 *
	 * static templateString
	 *
	 * @param {object} variables (optional)
	 * @returns {Element}
	 */
	template(variables) {

		if ( this.constructor.templateString === undefined ) {
			throw `CustomElement.template(): no 'templateString' defined`
		}

		let html = replaceTransVars(this.constructor.templateString, variables)

		let template = document.createElement('div')
		template.innerHTML = html

		if ( UI.global.gameStyles ) {

			let existingStyleElement = template.querySelector('style:last-of-type')

			let newStyleElement = document.createElement('style')
			newStyleElement.innerHTML = UI.global.gameStyles

			if ( existingStyleElement ) {
				existingStyleElement.after(newStyleElement)
			} else {
				template.append(newStyleElement)
			}

		}

		return template
	}

	updateFocus() {
		let defaultButton = this.getDefaultButton()

		if ( typeof defaultButton?.focus === 'function' ) {
			defaultButton.focus({preventScroll: true})
		}
	}

	getActiveButton() {
		//if ( UI.global.activeElement instanceof Button ) {
			return UI.global.activeElement
		//}

		return null
	}

	getDefaultButton() {
		let button = this.querySelector('[default=true]')

		return button
	}
}

export class AnimatedElement extends CustomElement {
	constructor() {
		super()

		if ( this.constructor == AnimatedElement ) { throw `AnimatedElement.constructor() tried to create object from this abstract class.`}

		this.addEventListener('transitionend', this.transitionEnd)
		this.addEventListener('transitioncancel', this.transitionEnd)
	}

	// TODO: respect animate parameter - see example from ViewLayer
	show(animate) {
		if ( Number(window.getComputedStyle(this).getPropertyValue('opacity')) > 0 && this.classList.contains('visible') ) {
			console.log("tried to show visible element - ignoring this")
			return null
		}

		if ( animate === undefined ) { animate = Game.global.settings.animate }

		if ( this.transitionShow ) { return null }
		if ( this.transitionHide ) { this.transitionEnd() }

		let promise = new Promise( (resolve, reject) => {

			//resolve() done by transitionEnd()
			this.transitionShow = resolve

			this.classList.add('animated')
			this.classList.add('visible')

			this.forceCssRedraw()

			this.classList.add('opaque')

		}).finally( () => {
			this.classList.remove('animated')
		})

		return promise
	}

	// TODO: respect animate parameter - see example from ViewLayer
	hide(animate) {

		if ( animate === undefined ) { animate = Game.global.settings.animate }

		if ( Number(window.getComputedStyle(this).getPropertyValue('opacity')) == 0 ) {
			console.log("tried to hide hidden element - ignoring this")
			return null
		}

		if ( this.transitionHide ) { return null }
		if ( this.transitionShow ) { this.transitionEnd() }

		let promise = new Promise( (resolve, reject) => {

			this.classList.add('animated')
			this.classList.remove('opaque')

			// resolve() done by transitionEnd()
			this.transitionHide = resolve

			this.forceCssRedraw()
		}).finally( () => {
			this.classList.remove('animated')
			this.classList.remove('visible')
			this.classList.remove('opaque')
		})

		return promise
	}

	transitionEnd(event) {
		if ( this.transitionHide ) {
			this.transitionHide()

			delete this.transitionHide
		}

		if ( this.transitionShow ) {
			this.transitionShow()

			delete this.transitionShow
		}
	}

	forceCssRedraw() {
		// force browser paint - doesn't trigger transition otherwise
		void this.offsetWidth;
	}

	isVisible() {
		return this.classList.contains("visible")
	}
}


/**
 * Abstract Button class for buttons
 *
 * Use child classes like: TextButton, ImageButton
 *
 */
export class Button extends AnimatedElement {

	constructor() {
		super()

		if (this.constructor == Button) { throw "Button.constructor() tried to create object from this abstract class." }

		this.tabIndex = 0 // Make Buttons focusable

		this.addEventListener('click', this.triggerAction)


		this.addEventListener("focus", (event) => {
			return UI.global.activeElement = this
		});

		this.addEventListener("blur", (event) => {
			return UI.global.activeElement = this
		});
	}

	/**
	 * Trigger action defined in parent (or grandparent)
	 *
	 * @param {Event} event
	 */
	triggerAction(event) {

		let listOfActions = this.getAttribute('action')
		let feedback = false

		let root = this.getRoot()

		if ( root?.action ) {
			feedback = root.action(listOfActions, this, event)
		} else {
			warn(`Button.action: can't find root element with action`)
		}

		let sfx = this.getAttribute('sfx') || 'button-click'

		if ( sfx != "none" ) {
			feedback && Game.global.playSfx(sfx)
		}
	}
	getRoot() {
		// @ts-ignore : getRootNode() returns ShadowRoot or Node
		let root = this.getRootNode().host || this.getRootNode()

		for ( let i = 0 ; i < 100 ; i++ ) {
			if ( root == null ) { break }

			if ( root.action ) {
				break
			}

			root = root.getRootNode().host || this.getRootNode()
		}

		return root
	}
}


/**
 * TextButton
 *
 */
export class TextButton extends Button {

	static templateString

	constructor() {
		super()
	}
}

import importMainStyles from "css/import-main.css.mjs"

TextButton.templateString = `

<style>
	${ importMainStyles }

	:host {
		display: inline-block;
		padding: var(--m-pad);
		background: none;
		color: var(--text-button-color);
		font-family: var(--button-font-family);
		font-weight: 800;
		font-size: var(--m-font-size);
		user-select: none;
		-webkit-user-select: none;
		-webkit-tap-highlight-color: rgba(255,0,0,0);
	}

	:host(.small) {
		font-size: var(--s-font-size);
		line-height: var(--s-line-height);
		padding: var(--s-pad);
	}

	:host(.medium) {
		font-size: var(--m-font-size);
		line-height: var(--m-line-height);
	}

	:host(.align-left) {
		padding-left: 0px;
	}
	:host(.align-right) {
		padding-right: 0px;
	}

	:host(.focus),
	:host(:focus) {
		color: var(--text-button-focus-color) !important;
		text-shadow: var(--m-shadow) var(--focus-shadow-color) !important;
		outline: none;
		transition: color 0.5s, text-shadow 0.5s;
	}

	:host(:hover) {
		color: var(--text-button-hover-color) !important;
		text-shadow: var(--m-shadow) var(--hover-shadow-color) !important;
		cursor: pointer;
		transition: color 0.25s, text-shadow 0.25s;
	}

</style>
`;

window.customElements.define('text-button', TextButton)


/**
 * ImageButton
 *
 */
export class ImageButton extends Button {

	static templateString

	constructor() {
		super()

		let width = this.getAttribute('width')
		let height = this.getAttribute('height')

		if ( width ) {
			this.style.width = width
			this.removeAttribute('width')
		}

		if ( height ) {
			this.style.height = height
			this.removeAttribute('height')
		}

		let iconClass = this.getAttribute('icon')
		let iconElement = this.querySelector('.icon')

		if ( iconClass ) {
			iconElement.classList.add(icon)
			this.removeAttribute('icon')
		} else {
			iconElement.classList.add('hidden')
		}

		let text = this.getAttribute('text')

		if ( text ) {
			this.querySelector('.text').innerHTML = text
			this.removeAttribute('text')
		}

	}
}

ImageButton.templateString = `
<style>
	:host {
		position: relative;
		display: flex;
		background: none;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: contain;
		color: var(--text-button-color);
		font-family: var(--button-font-family);
		font-weight: 500;
		font-size: var(--s-font-size);
		user-select: none;
		-webkit-user-select: none;
		-webkit-tap-highlight-color: rgba(255,0,0,0);

		cursor: pointer;
	}

	.content {
		position: absolute;
		left: 12px;
		right: 12px;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;
	}

	.icon {
		width: 30%;
		height: 30%;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon.hidden {
		display: none;
	}

	.text {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		text-align: center;
		text-transform: uppercase;
		line-height: 140%;
	}

</style>
<div class="content">
	<div class="icon"></div>
	<div class="text"></div>
</div>
`;

window.customElements.define('image-button', ImageButton)


/**
 * BoxButton is a button the player can interact with.
 *
 */
export class BoxButton extends Button {

	static templateString

	constructor() {
		super()

		let leftIconClass = this.getAttribute('left-icon')
		let leftIconElement = this.querySelector('.icon.left')

		if ( leftIconClass ) {
			leftIconElement.classList.add(leftIconClass)
			this.removeAttribute('left-icon')
		} else {
			leftIconElement.classList.add('hidden')
		}

		let rightIconClass = this.getAttribute('right-icon')
		let rightIconElement = this.querySelector('.icon.right')

		if ( rightIconClass ) {
			rightIconElement.classList.add(rightIconClass)
			this.removeAttribute('right-icon')
		} else {
			rightIconElement.classList.add('hidden')
		}

		let text = this.getAttribute('text')

		if ( text ) {
			this.querySelector('.text').innerHTML = text
			this.removeAttribute('text')
		}

	}
}

BoxButton.templateString = `
<style>
	:host {
		position: relative;
		background: var(--box-button-background);
		color: var(--box-button-color);

		font-family: var(--button-font-family);
		font-weight: 500;
		font-size: var(--s-font-size);

		user-select: none;
		-webkit-user-select: none;
		-webkit-tap-highlight-color: rgba(255,0,0,0);
		cursor: pointer;

		min-width: 200px;
		width: 100%;
		max-width: 280px;

		height: 80px;
	}

	.icon {
		position: absolute;
		background-repeat: no-repeat;
		background-size: contain;
		width: 28px;
		height: 28px;
	}

	.icon.left {
		top: 16px;
		left: 0px;
	}

	.icon.right {
		top: 16px;
		right: 0px;
	}

	.icon.hidden {
		display: none;
	}

	.text {
		position: absolute;
		top: 12px;
		left: 0px;
		right: 0px;

		display: inline-block;
		text-align: center;
		text-transform: uppercase;
		line-height: 180%;
	}

	.cutout {
		position: absolute;
		top: 0px;
	}

	.cutout.left {
		left: -16px;
	}

	.cutout.right {
		right: -16px;
	}

	.svgbg {
		fill: var(--box-button-background);
	}

	:host(.tab) {
		width: auto;
		min-width: 120px;
		height: 60px;
	}

	:host(.tab) .text {
		opacity: 0.5;
	}

	:host(.tab) .cutout {
		height: 60px;
	}

	:host(.tab.active) .text {
		opacity: 1.0;
	}

	.box {
		height: 100%;
	}

	:host(.long) .cutout.short { display: none; }
	:host(.short) .cutout.long { display: none; }
  	:host :not(.long):nost(.short) .cutout.long { display: none; }

</style>
<div class="box">
	<svg class="cutout left short" width="16" height="80" xmlns="http://www.w3.org/2000/svg">
		<polygon class="svgbg" points="16 0, 16 0, 16 80, 0 80, 0 16" />
	</svg>
	<svg class="cutout right short" width="16" height="80" xmlns="http://www.w3.org/2000/svg">
		<path class="svgbg" d="M0 0H8C12.4183 0 16 3.58172 16 8V80H0V0Z" />
	</svg>
	<svg class="cutout left long" width="16" height="120" xmlns="http://www.w3.org/2000/svg">
		<polygon class="svgbg" points="16 0, 16 0, 16 120, 0 120, 0 16" />
	</svg>
	<svg class="cutout right long" width="16" height="120" xmlns="http://www.w3.org/2000/svg">
		<path class="svgbg" d="M0 0H8C12.4183 0 16 3.58172 16 8V120H0V0Z" />
	</svg>
	<span class="icon left"></span>
	<span class="text"></span>
	<span class="icon right"></span>
</div>
`;

window.customElements.define('box-button', BoxButton)
