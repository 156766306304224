import { warn, log, debug, generateAssetUrl } from "global.js";

import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'
import { Game } from 'Game/Game.mjs'
import { ViewLayer } from 'UI/ViewLayer.mjs'
import { Board } from 'Board/Board.mjs'
import { AnimatedElement, Button } from 'UI/CustomElement.mjs'


export class ViewGameboard extends ViewLayer {

	static templateString;

	constructor() {
		super()
	}

	setup(boardWidth, boardHeight, referenceTileWidth, referenceTileHeight, usePlayerTurnSystem, boardContentScale) {
		this.usePlayerTurnSystem = usePlayerTurnSystem

		this.board = this.shadowRoot.querySelector('x-board')
		this.board.setup(this.offsetWidth, this.offsetHeight, boardWidth, boardHeight, referenceTileWidth, referenceTileHeight, boardContentScale)

		this.board.showBoard()

		this.actions = {}
		this.actionMenuIsCreated = false
		this.actionMenu = this.shadowRoot.querySelector('x-actionmenu')

		this.overlayMenu = this.shadowRoot.querySelector('x-overlaymenu')

		this.lastPositionRealX = 0
		this.lastPositionRealY = 0

		this.scrollDetectDelay = 500 //ms
		this.scrollDetectTimeout = null

		var stupidjs = this
		this.onscroll = (event) => {
			// ignore scroll events while resizing
			if ( UI.global.resizeDetectTimeout ) { return }

			stupidjs.onScrollDetect(event)
		}
	}

	createActionMenu() {
		this.actionMenu.setContentFromTemplateElement(this.actionMenu.template(Game.global.getGlobalVariables()))

		this.actionMenu.updatePlayers(Game.global.getListOfPlayerIds())

		this.actionMenu.querySelector('#endRound').onclick = function(){
			Game.global.nextTurn()
		}
		this.actionMenu.querySelector('#nextRound').onclick = function(){
			Game.global.nextTurn()
		}
		this.actionMenu.querySelector('#menu').onclick = function(){
			Game.global.showInGameMenu()
		}

		this.actionMenuIsCreated = true
	}

	async showActionMenu(animate) {
		if ( !this.actionMenuIsCreated ) {
			this.createActionMenu()
		}

		if ( !this.isActionMenuVisible() ) {

			if ( Game.global.isPlayerRound() ) {
				this.showPlayerMenu()

				if ( this.usePlayerTurnSystem ) {
					this.actionMenu.setCurrentPlayer(Game.global.getCurrentPlayerId())
				}
			} else {
				this.showWorldMenu()
			}

			return this.actionMenu.show(animate)
		}
	}

	async hideActionMenu(animate) {
		if ( this.isActionMenuVisible() ) {
			return this.actionMenu.hide(animate)
		}

	}

	isActionMenuVisible() {
		return this.actionMenu.classList.contains('visible')
	}

	addAction(actionName, commands) {
		if ( this.actions[actionName] ) { throw `ViewGameboard.addAction() action '${actionName}' already exists` }

		this.actions[actionName] = commands
	}

	removeAction(actionName) {
		delete this.actions[actionName]
	}

	/**
	 * @param { string } actionName
	 * @param { string } label
	 * @param { boolean } setDefault
	 * @param { Command | CommandChain } commands (optional)
	 */
	addOverlayButton(actionName, label, setDefault, commands) {
		if ( this.hasOverlayButton(actionName) ) { throw `ViewGameboard.addOverlayButton() tried to add another button with action id: '${actionName}'` }

		let button = new OverlayButton(actionName, label)

		if ( setDefault ) {
			button.setAttribute("default", true)
		}

		if ( commands ) {
			this.addAction(actionName, commands)
		}

		this.overlayMenu.querySelector('#buttons').append(button)
	}


	removeAllOverlayButtons(){
		let buttons = this.overlayMenu.querySelector('#buttons').querySelectorAll('.button')
		for ( let x of buttons ) {

			let actionName = x.getAttribute('action')

			this.removeOverlayButton(actionName)
		}
	}

	removeOverlayButton(actionName) {
		this.removeAction(actionName)

		this.overlayMenu.querySelectorAll(`#${actionName}`)?.forEach(
			(x)=>{x.remove()}
		)
	}

	setOverlayText(text) {
		this.overlayMenu.querySelector('#message').innerHTML = text
	}

	async runAction(actionName) {
		if ( this.actions[actionName] ) {
			let cmds = Game.global.createCommandChain( this.actions[actionName] )

			await Game.global.queueCommandsOnTopAndWait( cmds )

			return true
		}

		return false
	}

	hasOverlayButton(actionName) {
		return this.overlayMenu.querySelector(`#${actionName}`) ? true : false
	}

	async showOverlayMenu(animate) {
		return this.overlayMenu.show(animate)
	}

	showOverlayMenuAndWait(animate) {

		let promise = new Promise((resolve, reject) => {

			this.overlayMenu.callbackOnHide = resolve
			this.overlayMenu.show(animate)
		})

		return promise
	}

	async hideOverlayMenu(animate) {
		return this.overlayMenu.hide(animate)
	}

	isOverlayMenuVisible() {
		return this.overlayMenu.classList.contains('visible')
	}

	setCurrentPlayer(player){
		if ( player ) {
			this.actionMenu.setCurrentPlayer(player.id)
		} else {
			this.clearCurrentPlayer()
		}
	}

	clearCurrentPlayer(){
		this.actionMenu.clearCurrentPlayer()
	}

	enableInteractions() {
		this.classList.remove('disable-interactions')

		this.board.enableInteraction()
	}

	disableInteractions() {
		this.classList.add('disable-interactions')

		this.board.disableInteraction()
	}

	isInteractionEnabled() {
		return !this.classList.contains('disable-interactions')
	}

	/**
	 * @return {Promise}
	 */
	scrollToPosition(realX, realY, animate) {

		// if caller hasn't disabled interactions, we do it
		let enableInteractionsAfterScroll = false

		if ( this.board.allowInteractions ) {
			enableInteractionsAfterScroll = true
			this.board.disableInteraction()
		}

		if ( realX == null || realY == null ) {
			throw `ViewGameboard.scrollToPosition bad x,y: ${realX},${realY}`
		}

		let scaledX = Math.round(realX * this.board.canvasScale)
		let scaledY = Math.round(realY * this.board.canvasScale)

		// note: offsetHeight doesn't include css margins - potential future bug if CSS is changed
		let visibleWidth = this.offsetWidth
		let visibleHeight = this.offsetHeight

		let scrollLeft = Board.padding + Math.round(Math.max(scaledX - (visibleWidth/2), 0))
		let scrollTop = Board.padding + Math.round(Math.max(scaledY - (visibleHeight/2), 0))

		let promise = new Promise((resolve, reject) => {
			this.onScrollResolve = resolve

			// buggy behaviour: atleast on Chrome onscroll event doesn't fire
			// if scrolling doesn't actually take place ... so we have to
			// check if scrolling WILL take place or not

			if ( this.scrollLeft == scrollLeft && this.scrollTop == scrollTop ) {
				this.onScrollResolve()
			} else {
				this.scroll({
					left: scrollLeft,
					top: scrollTop,
					behavior: animate ? 'smooth' : 'instant' // does this work..?
				})
			}
		})

		promise.finally(() => {
			if ( enableInteractionsAfterScroll ) {
				this.board.enableInteraction()
			}
		} )

		return promise
	}

	scrollTo(thing, animate) {
		let menuHeight = this.actionMenu.offsetHeight

		let cx,cy

		if ( this.board.boardContentScale ) {
			cx = Math.round(thing.cx * this.board.boardContentScale)
			cy = Math.round(thing.cy * this.board.boardContentScale)
		} else {
			cx = thing.cx
			cy = thing.cy
		}

		if ( this.isActionMenuVisible ) {
			cy += menuHeight
		}

		return this.scrollToPosition(cx, cy, animate)
	}

	onScrollEnd(){
//		debug(`View gameboard scrolled: (${this.lastPositionRealX} -> ${this.scrollTop}, ${this.lastPositionRealY} -> ${this.scrollLeft})`)

		let { cx, cy } = this.getRealCenterPosition()

		this.lastPositionRealX = cx
		this.lastPositionRealY = cy
		this.scrollDetectTimeout = null

		if ( this.onScrollResolve ) {
			this.onScrollResolve(true)
		}
	}

	getRealCenterPosition() {
		// note: offsetHeight doesn't include css margins - potential future bug is CSS is changed
		let visibleWidth = this.offsetWidth
		let visibleHeight = this.offsetHeight

		let cx = (this.scrollLeft + visibleWidth/2)/this.board.canvasScale
		let cy = (this.scrollTop + visibleHeight/2)/this.board.canvasScale

		return { cx: cx, cy: cy }
	}

	onScrollDetect(event) {
		clearTimeout(this.scrollDetectTimeout)
		this.scrollDetectTimeout = setTimeout(() => { this.onScrollEnd() }, this.scrollDetectDelay)
	}

	/**
	 * @param {Space} [space] Starting position
	 */
	showBoard(space) {
		this.board.showBoard(space)
	}

	async show(space, animate) {

		// TODO: doesn't work. Doesn't become pre-scrolled
		await super.show(false)

		this.querySelector(".button.menu")?.addEventListener("focus", (event) => {
			console.log("button menu focus event")
			UI.global.activeElement = this
		});

		this.querySelector(".button.menu")?.addEventListener("blur", (event) => {
			console.log("button menu blur event")
			UI.global.activeElement = null
		});

		if ( space ) {
			this.classList.add('visible')
			await this.scrollTo(space, false)
		}
	}

	hideBoardAndMenu() {
		this.actionMenu.hide(animate)

		this.hideBoard()
	}

	hideBoard() {
		this.board.hideBoard()
	}

	showPlayerMenu(){
		this.actionMenu.querySelector('.player-buttons').classList.remove('hide')
		this.actionMenu.querySelector('#endRound').classList.remove('hide')
		this.actionMenu.querySelector('#menu').classList.remove('hide')
		this.actionMenu.querySelector('#nextRound').classList.add('hide')
	}

	showWorldMenu(){
		this.actionMenu.querySelector('.player-buttons').classList.add('hide')
		this.actionMenu.querySelector('#endRound').classList.add('hide')
		this.actionMenu.querySelector('#menu').classList.add('hide')
		this.actionMenu.querySelector('#nextRound').classList.remove('hide')
	}


/*
	zoomIn() {
		this.zoomBoard(this.board.zoomsScale + 0.5)
	}

	zoomOut() {
		this.zoomBoard(this.board.zoomScale - 0.5)
	}

	zoomBoard(zoom) {
		this.board.setZoom(zoom)
	}
*/
	onScreenResize(screenWidth, screenHeight) {
		if ( this.board ) {
			this.board.resize(this.offsetWidth, this.offsetHeight)
			this.scrollToPosition(this.lastPositionRealX, this.lastPositionRealY)
		}
	}

	async action(listOfActions, element, event) {

		let feedback = false

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				if ( this.overlayMenu.isVisible() ) {
					switch(action) {
						case "close":
						case "default":
							await this.overlayMenu.hide()
							feedback = true
							break;
					}
				}
			}
		}

		return feedback
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewGameboard.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }

</style>
<x-board></x-board>
<x-actionmenu class="animatedelement fast" style="opacity: 0"></x-actionmenu>
<x-overlaymenu class="animatedelement fast" style="opacity: 0"></x-overlaymenu>

`;

window.customElements.define('view-gameboard', ViewGameboard)

export class OverlayButton extends Button {

	static templateString

	constructor(action, label) {
		super()

		this.setAttribute('id', action)
		this.setAttribute('action', action)
		this.classList.add('button')

		this.setContentFromText("Done")
	}

	async triggerAction(event) {
		let root = this.getRootNode().host

		if ( !root.actions ) {
			root = root.getRootNode().host
		}

		if ( !root.actions ) {
			error(this)
			throw `OverlayButton.action() can't find root element with action: ${this.getAttribute('action')}`
		}

		Game.global.playSfx('button-click')

		await root.runAction(this.getAttribute('action'))

		if ( this.callback ) {
			await this.callback(this.getAttribute('action'))
		}

		await root.querySelector('x-overlaymenu').hide()
	}
}

OverlayButton.templateString = `
<style>
	:host(:focus) {
		color: var(--text-button-focus-color) !important;
		text-shadow: var(--m-shadow) var(--focus-shadow-color) !important;
		outline: none;
	}
</style>
`;

window.customElements.define('x-overlaybutton', OverlayButton )

export class OverlayMenu extends AnimatedElement {

	static templateString

	constructor() {
		super()
	}

	async show(animate) {

		await super.show(animate)

		this.updateFocus()
	}

	async hide(animate){
		await super.hide(animate)

		if ( this.callbackOnHide ) {
			this.callbackOnHide()
		}
	}

	connectedCallback() {
		let root = this.getRootNode().host

		if ( root instanceof ViewGameboard ) {
			this.gameboard = root

		} else {
			warn(`OverlayMenu.connectedCallback(): parent ViewGameboard not found.\n\nGot getRootNode(): ${ root }`)
		}
	}
}

import importAnimatedElementStyles from "css/import-animated-element.css.mjs";

OverlayMenu.templateString = `

<style>
	${ importMainStyles }
	${ importAnimatedElementStyles }

	:host {
		position: fixed;
		width: 100%;
		height: 18rem;
		padding-top: 2rem;
		bottom: 0px;

		background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1) 75%);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

	#message {
		text-align: center;
	}

	#buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: var(--m-pad);
	}

	.button {
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-color: var(--overlaymenu-button-bg-color);
		color: var(--overlaymenu-button-text-color);
		min-width: 14rem;
		cursor: pointer;
		padding: var(--m-pad) var(--m-pad);
		font-size: var(--m-font-size);
		text-align: center;


		border: solid 2px var(--overlaymenu-border-color);
		border-radius: 1.1rem 1.1rem 1.1rem 1.1rem;
		box-shadow: 0rem 0rem 1.5rem rgba(0,0,0,0.5);

		transition: margin 0.5s, border-color 0.1s, box-shadow 1s;
		transition-timing-function: ease-out;

		user-select: none;
		-webkit-user-select: none;
	}

	.button.selected {
		margin-top: -2rem;
		border-color: #ffffbb;
		box-shadow: 0px 0px 2rem rgba(255, 255, 0, 0.75);
	}

	.button:focus, .button:hover {
		border-color: #ff0000;
		box-shadow: 0px 0px 5rem rgba(255, 0, 0, 0.75);
		outline: none;
	}

	p.guide {
		font-size: var(--xs-font-size);
	}

</style>
<div id="message"></div>
<div id="buttons"></div>

`;

window.customElements.define('x-overlaymenu', OverlayMenu )

export class ActionMenu extends AnimatedElement {

	static templateString

	constructor() {
		super()

	}

	updatePlayers(playerIds) {
		let htmlString = ''

		for ( let playerId of playerIds ) {
			htmlString += `<div class="button player ${playerId}" tabIndex="0"></div>`
		}

		if ( htmlString !== "" ) {
			let playerButtons = this.querySelector('.player-buttons')

			playerButtons.innerHTML = htmlString
		}
	}

	clearCurrentPlayer() {
		let selected = this.querySelectorAll(`.button.player.selected`)
		for ( let x of selected ) { x.classList.remove('selected') }
	}

	setCurrentPlayer(playerId) {
		this.clearCurrentPlayer()

		let element = this.querySelector(`.button.${playerId}`)

		if ( !element ) {
			warn(`ActionMenu.setCurrentPlayer(playerId) couldn't find player with playerId: ${ playerId } `)
		} else {
			element.classList.add('selected')
		}
	}

	connectedCallback() {
		let root = this.getRootNode().host

		if ( root instanceof ViewGameboard ) {
			this.gameboard = root
		} else {
			warn(`OverlayMenu.connectedCallback(): parent ViewGameboard not found.\n\nGot getRootNode(): ${ root }`)
		}
	}
}

ActionMenu.templateString = `

<style>
	${ importMainStyles }
	${ importAnimatedElementStyles }

	:host {
		position: fixed;
		width: 100%;
		height: 6rem;
		bottom: 0px;

		background-color: transparent;
		background-image: var(--actionmenu-bar-bg, none);
		background-size: cover;

		display: flex;
		justify-content: center;
	}

	.button {
		display: inline-block;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		background-color: var(--actionmenu-bar-color);
		height: 12rem;
		cursor: pointer;
		margin: 0rem 0.5rem;
		border: solid 2px transparent;
		border-radius: 1.1rem 1.1rem 0rem 0rem;
		transition: margin 0.5s, border-color 0.1s, box-shadow 1s;
		transition-timing-function: ease-out;
		box-shadow: 0rem 0rem 1.5rem rgba(0,0,0,0.5);

		user-select: none;
		-webkit-user-select: none;
	}

	.button.menu {
		border-color: var(--actionmenu-bar-color);

    	text-align: center;
    	padding-top: 1.5em;
    	font-weight: bold;
    	font-size: var(--s-font-size);
    	color: var(--actionmenu-text-color);

		flex-shrink: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		text-shadow: var(--actionmenu-text-shadow);
	}
	.button.menu:hover {
		text-shadow: 0px -1px #9c8245, 0px 2px 3px rgba(255,0,0,0.3)
	}

	.button.player {
		border-color: #191812;
		background-color: var(--actionmenu-player-button-color);
	}

	.button:hover, .button.focus {
		margin-top: -4rem;
	}

	.button.selected {
		margin-top: -2rem;
	}

	.button.hide {
		display: none;
	}

	.player-buttons.hide {
		display: none;
	}

	.button.selected {
		border-color: #ffffbb;
		box-shadow: 0px 0px 2rem rgba(255, 255, 0, 0.75);
	}

	.button:focus,.button:hover {
		border-color: #ff0000;
		box-shadow: 0px 0px 5rem rgba(255, 0, 0, 0.75);
		outline: none;
	}

	.all-buttons {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.player-buttons {
		flex-grow: 1;
		display: grid;
		grid-template-columns: repeat(auto-fit,  minmax(1rem, max-content));
		margin-right: 1rem;
	}
	.player.button {
		width: 12rem;
	}

	.button.player.player1 { background-image: url("${generateAssetUrl('media/characters/player1-menu@1x.webp')}") }

	.button.player.player2 { background-image: url("${generateAssetUrl('media/characters/player2-menu@1x.webp')}") }

	.button.player.player3 { background-image: url("${generateAssetUrl('media/characters/player3-menu@1x.webp')}") }

	.button.player.player4 { background-image: url("${generateAssetUrl('media/characters/player4-menu@1x.webp')}") }

	.button.player.player5 { background-image: url("${generateAssetUrl('media/characters/player5-menu@1x.webp')}") }

	.button.player.player6 { background-image: url("${generateAssetUrl('media/characters/player6-menu@1x.webp')}") }

	.button.player.player7 { background-image: url("${generateAssetUrl('media/characters/player7-menu@1x.webp')}") }

	.button.player.player8 { background-image: url("${generateAssetUrl('media/characters/player8-menu@1x.webp')}") }

</style>
<div class="all-buttons">
	<div class="player-buttons"></div>
	<div id="menu" class="button menu" tabIndex="0">{{~button-menu}}</div>
	<div id="endRound" class="button menu" tabIndex="0">{{~button-end-round}}</div>
	<div id="nextRound" class="button menu" tabIndex="0">{{~button-next-round}}</div>
</div>
`;

window.customElements.define('x-actionmenu', ActionMenu)
