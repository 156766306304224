import { sleep, log, warn, error, debug, generateId, mixin } from 'global.js'
import { isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { Event } from 'Command/Event.mjs'
import { Eventable } from 'Command/Eventable.mjs'
import { RunnableWorker as Runnable } from 'Command/RunnableWorker.mjs'
//import { RunnableInterval as Runnable } from 'Command/RunnableInterval.mjs'
import { Command } from 'Command/Command.mjs'
import { CommandChain } from 'Command/Chain.mjs'
import { If } from 'Command/If.mjs'


/**
 * Starts a setInterval based "thread" that processes the
 * commands (CmdXXXX) in a queue.
 *
 * Control the execution of the queue:
 *
 *     proc.start()  - start or continue
 *     proc.pause()  - wait
 *     proc.stop()   - and clear queue
 *
 * Add commands or clear queue:
 *
 *     proc.push(myCommand)
 *     proc.clearQueue()
 *
 * Add/remove callbacks to events:
 *
 *     proc.addListener("queue-empty", callback)
 *     proc.removeListener("queue-empty", callback)
 *
 *     proc.addListener("character-damage", callback)
 *
 * Types of events from processor:
 *
 *     queue-start			queue starts processing (again)
 *     queue-paused			queue is manually paused
 *     queue-stop			queue is manually stopped
 *     queue-empty			queue stops because it's empty
 *
 *     -> callback( eventName )
 *
 * Types of events from processor:
 *
 *     command-start		command is about to be executed
 *     command-end			command finished, success or fail
 *     command-success		command finished successfully
 *     command-fail         command finished in failure
 *
 *     -> callback( eventName , Command )
 *
 */


export class Processor extends mixin(Eventable, Runnable) {
//	static runLoopDelay = 1

	static PROC_START = "processor-start"
	static PROC_STOP = "processor-stop"
	static PROC_PAUSE = "processor-pause"
	static PROC_EMPTY = "processor-empty"

	static CMD_START = 'command-start'
	static CMD_END = 'command-end'
	static CMD_SUCCESS = 'command-success'
	static CMD_FAIL = 'command-fail'

	static eventTypes = [
		Processor.PROC_START,
		Processor.PROC_STOP,
		Processor.PROC_PAUSE,
		Processor.PROC_EMPTY,
		Processor.CMD_START,
		Processor.CMD_END,
		Processor.CMD_SUCCESS,
		Processor.CMD_FAIL]

	/**
	 * Creates a new Processor.
	 */
	constructor() {
		super()

		this.validateBeforeExecution = true
		this.queue = []
		this.currentCommand = null
	}

    /**
     * Add a Command or CommandChain at the end of the queue.
     *
     * queueCommands() can't fail, but it returns true/false depending
     * on if the processor is currently active or not
     *
     * @param { Command | CommandChain | Array } command(s)
     * @param { object } variables as key-value pairs
     * @return nothing
     */
	queueCommands(cmds, variables) {
		let list = isArray(cmds) ? cmds : [ cmds ]

		for ( let cmd of list ) {

			if ( !(cmd instanceof Command) && !(cmd instanceof CommandChain) ) {
				throw `Processor.queueCommands() argument 'cmd' is not a Command nor CommandChain. Got: ${ cmd }`
			}

			if ( !isObject(variables) ) {
				throw `Processor.queueCommands() argument 'variables' is not an object. Got: ${ variables }`
			}

			this.queue.push( [cmd, variables] )

			if ( this._waitUntilAdd ) {
				this._waitUntilAdd = false
			}
		}
	}

    /**
     * Same as addCommand(), but places the Command or CommandChain at
     * the beginning of the queue, so they will be processed next.
     *
     * This is used by Commands that generate an Event, then add the
     * Event on top of queue to be processed immediately after
     * the command execution ends.
     *
     * @param { Command | CommandChain | Array } cmds
     * @param { object } variables as key-value pairs
     * @return nothing
     */
	queueCommandsOnTop(cmds, variables) {
		let list = isArray(cmds) ? cmds : [ cmds ]

		for ( let cmd of list ) {

			if ( !(cmd instanceof Command) && !(cmd instanceof CommandChain) ) {
				throw `Processor.queueCommandsOnTop() argument 'cmd' is not a Command nor CommandChain. Got: ${ cmd }`
			}

			if ( !isObject(variables) ) {
				throw `Processor.queueCommandsOnTop() argument 'variables' is not an object. Got: ${ variables }`
			}

			this.queue.unshift( [cmd, variables] )

			if ( this._waitUntilAdd ) {
				this._waitUntilAdd = false
			}
		}
	}


	/**
	 * Adds one or more commands to the queue and fulfills Promise
	 * once all of them are done.
	 *
	 * @param { Command | CommandChain | Array } cmds
	 * @param { object } variables as key-value pairs
	 * @return Promise
	 */
	queueCommandsOnTopAndWait(cmds, variables) {

		let cpu = this
		let list = isArray(cmds) ? cmds : [ cmds ]

		let promise = new Promise((resolve, reject) => {

			let lastCmd = list[ list.length - 1 ]
			lastCmd.addCallback(resolve)

			cpu.queueCommandsOnTop(cmds, variables)

		})

		return promise
	}

	/**
	 * Adds one or more commands to the queue and fulfills Promise
	 * once all of them are done.
	 *
	 * @param { Command | CommandChain | Array } cmds
	 * @param { object } variables as key-value pairs
	 * @return Promise
	 */
	queueCommandsAndWait(cmds, variables) {

		let cpu = this
		let list = isArray(cmds) ? cmds : [ cmds ]

		let promise = new Promise((resolve, reject) => {

			let lastCmd = list[ list.length - 1 ]
			lastCmd.addCallback(resolve)

			cpu.queueCommands(cmds, variables)

		})

		return promise
	}	

	_hasCommand() {
		return this.queue.length > 0
	}

	_getCommand() {
		return this.queue.shift()
	}

	/**
	 * Executes Commands and/or CommandChains from the queue:
	 *
	 *     await Command.execute()
	 *     await CommandChain.execute()
	 *
	 * Will await for the Command|CommandChain to finish.
	 *
	 * Because execution may take longer than the intervalDelay,
	 * next _execute() will silently skip until previous execution
	 * has finished.
	 *
	 * This is called internally from Runnable
	 *
	 * Events 'processor-empty' if queue is empty.
	 *
	 * @return {number} queueLength
	 */
	async executeCommand() {
		if ( this._waitUntilAdd ) { return }

		else if ( !this._hasCommand() ) {
			this._waitUntilAdd = true;
			return 0
		}

		let [cmd, variables] = this._getCommand()
		this.currentCommand = cmd
		this.eventCallback(Processor.CMD_START, cmd)

		//debug(this.queueToString(cmd))

		let result

		try {

			if ( this.validateBeforeExecution ) {
				cmd.validate(variables)
			}

			if ( cmd instanceof Event ) {

				result = await cmd.execute(variables)

				if ( result ) {
					this.eventCallback(cmd.getType(), cmd)
				}

			} else if ( cmd instanceof If ) {

				result = await cmd.execute(variables)

				// new behaviour with then & else
				if ( result && cmd.hasThen() ) {
					this.queueCommandsOnTop(cmd.getThen(), variables)
				}

				if ( !result && cmd.hasElse() ) {
					this.queueCommandsOnTop(cmd.getElse(), variables)
				}

				// old behaviour without then & else
				if ( !cmd.hasThen() && !cmd.hasElse() && !result && cmd.hasTag() ) {
					this.cancelCmdsWithTag( cmd.getTag() )
				}

			} else if ( cmd instanceof Command || cmd instanceof CommandChain ) {

				result = await cmd.execute(variables)

				if ( result instanceof Event  || result ? result.queueOnTop : false ) {
					this.queueCommandsOnTop(result, variables)
				}
				else if ( result instanceof Command || result instanceof CommandChain ) {
					this.queueCommands(result, variables)
				}

			} else {
				throw `Processor.executeCommand() unknown command type: ${ cmd }`
			}

		} catch (err) {
			error(`Processor.executeCommand() '${ cmd.constructor.name }' failed: ${err}`)
			result = false

			if ( cmd.hasTag() ) {
				this.cancelCmdsWithTag( cmd.getTag() )
			}
		}

		if ( result ) {
			this.eventCallback(Processor.CMD_SUCCESS, cmd)

		} else {
			this.eventCallback(Processor.CMD_FAIL, cmd)
		}

		this.eventCallback(Processor.CMD_END, cmd)

		if ( cmd.hasCallbacks() ) {
			try {

				await cmd.resolveCallbacks(cmd, variables)

			} catch (err) {
				throw `Processor.executeCommand() command's own callback failed: ${err}`
			}
		}

		if ( this.queue.length == 0 ) {
			this.eventCallback(Processor.PROC_EMPTY)
		}

//		await sleep(Processor.runLoopDelay)

		return this.queue.length
	}

	queueToString(current) {
		let str = `Current process queue:\n\nXX: ${ current.constructor.name } <--- current\n`

		for ( let i = 0 ; i < this.queue.length ; i++ ) {
			let cmd = this.queue[i][0]
			let cmdName = cmd.constructor.name
			let tag = ""

			if ( cmd.hasTag() ) { tag = ` (${cmd.getTag()})`}

			str += `${ String(i).padStart(2, '0') }: ${ cmdName }${ tag }\n`
		}

		return str
	}

	cancelCmdsWithTag(tagName) {
		for ( let i = 0 ; i < this.queue.length ; i++ ) {
			let cmd = this.queue[i][0]

			if ( cmd.hasTag(tagName) || cmd.hasParentTag(tagName) ) {
				this.queue.splice(i, 1)

				i-- // retry this index
			}
		}
	}

	/**
	 * Starts execution.
	 *
	 * Events 'processor-start'
	 */
    startProcessor() {
    	this.startRunnable()

    	this.eventCallback(Processor.PROC_START)
    }

	/**
	 * Stops execution.
	 *
	 * Events 'processor-stop'
	 */
    stopProcessor() {
    	this.stopRunnable()

    	this.clearQueue()

    	this.eventCallback(Processor.PROC_STOP)
    }

	/**
	 * Pauses execution.
	 *
	 * Events 'processor-pause'
	 */
    pauseProcessor() {
    	this.pauseRunnable()

    	this.eventCallback(Processor.PROC_PAUSE)
    }



	/**
	 * Halts execution, but doesn't fail() commands.
	 *
	 * Silently clears the queue and won't execute callbacks.
	 *
	 */
    haltProcessor() {
    	this.stopRunnable()

		this.queue = []
    }

	/**
	 * Does not stop execution, but clears all commands
	 * from the queue one-by-one with cmd.fail()
	 * 
	 * Events are silently passed
	 *
	 * Use halt() if you don't want to call cmd.fail()
	 *
	 * Events 'command-fail'
	 * Events 'processor-empty'
	 *
	 * @return nothing
	 */
	clearQueue() {
		while (this.queue.length > 0 ) {

			let [cmd, vars] = this._getCommand()

			try {

				if ( cmd instanceof Event ) {
					warn(`Processor.clearQueue() cmd is an event - ignoring it: ${ cmd.event }`)
				} else {

					if ( cmd.fail ) {
						cmd.fail()

						this.eventCallback(Processor.CMD_FAIL, cmd)					
					}

				}

			} catch ( err ) {
				warn(`Processor.clearQueue() cmd.fail() error, ignoring it: ${ err }`)
			}
		}

		this.queue = []

		this.eventCallback(Processor.PROC_EMPTY)
	}
}
