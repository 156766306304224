export default `

/* Import slides styles */

:host {
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}

:host(.title) {
	color: var(--title-color);
}

:host(.text) {
	padding: 5vh 10vw;
}

:host(.text) * {
	max-width: var(--text-max-width);
	margin-left: auto;
	margin-right: auto;
}

:host(.center) {
	text-align: center;
}

:host(.bottom) {
	justify-content: flex-end;
}

.center {
	text-align: center;
}

.extra-pad-below { padding-bottom: var(--l-pad); }
.extra-pad-above { padding-top: var(--l-pad); }
.extra-pad-left { padding-left: var(--l-pad); }
.extra-pad-right { padding-right: var(--l-pad); }

.no-pad-below { padding-bottom: 0px; }
.no-pad-above { padding-top: 0px; }

.image.extra-pad-below { margin-bottom: var(--l-pad); }
.image.extra-pad-above { margin-top: var(--l-pad); }
.image.extra-pad-left { margin-left: var(--l-pad); }
.image.extra-pad-right { margin-right: var(--l-pad); }

.image.no-pad-below { margin-bottom: 0px; }
.image.no-pad-above { margin-top: 0px; }


.pad-wide {
	height: var(--xs-pad);
	width: var(--xl-pad);
}

.pad-tall {
	height: var(--xl-pad);
	width: var(--xs-pad);
}

ul.no-pad-below, ol.no-pad-below, p.no-pad-below {
	margin-block-end: 0px;
}

ul.no-pad-above, ol.no-pad-above, p.no-pad-above {
	margin-block-start: 0px;
}

p,li {
	width: 100%;
	text-shadow: var(--slide-text-shadow);
	color: var(--body-color);
}

em {
	color: var(--text-color);
}

ul.no-bullets, ol.no-bullets {
	list-style-type: none;	
}

p.buttons {
	display: flex;
	gap: var(--l-pad);
}

p.buttons.center {
	justify-content: center;
	align-items: center;
}

p.buttons.row {
	flex-direction: row;	
}

p.buttons.column {
	flex-direction: column;
}

p.buttons.left text-button:first-child {
	padding-left: 0px;
}

p.buttons.right text-button:last-child {
	padding-right: 0px;
}

p.buttons .fillspace {
	flex-grow: 1;
}

h1 {
	text-align: center;
	width: 100%;
}

h2 {
	text-align: center;
	width: 100%;
}

video {
	position: absolute;
	display: none;
	width: 100%;
	height: 100%;
}

video.visible {
	display: block;
}

div.image {
  position: relative;
  background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex-grow: 1;
	max-height: 70vh;
	width: 100%;
}

div.alt.image {
  position: absolute;
  opacity: 0;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

div.image.rounded {
	border-radius: 1rem;
}

div.image.kenburns {
	animation-iteration-count: 1;
/*
  animation-timing-function: cubic-bezier(0.7,0.0, 0.5,1.0);
*/
  animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

div.image.play-pre-show {
	animation-play-state: paused;
}

div.image.play-on-show {
	animation-play-state: paused;
}

.page {
  width: 100%;
  height: 100%;
}

.card {
	background-color: #666;
	min-width: 200px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: var(--s-pad);
	flex-grow: 1;
}

.characterimages {
	display: flex;
	flex-direction: row;
	gap: var(--m-pad);
	padding: var(--m-pad);
	justify-content: center;
}

`;