export default `

/* Import view layer styles */

:host(.view) {
	transition: opacity 0s ease-in !important;
}

:host(.visible) {
	visibility: visible !important;
}

:host(.opaque) {
	opacity: 1 !important;
}

:host(.animated) {
	transition-duration: var(--animation-length-default) !important;
}

:host(.animated.slow) {
	transition-duration: var(--animation-length-slow) !important;
}

:host(.animated.very-slow) {
	transition-duration: var(--animation-length-very-slow) !important;
}

:host(.animated.fast) {
	transition-duration: var(--animation-length-fast) !important;
}

:host(.click-through) {
	pointer-events: none;
}

:host(.click-through) * {
	pointer-events: auto;
}

/* Hide scrollbars */
:host {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* IE 10+ */
}

:host(::-webkit-scrollbar) {
    width: 0px;
    height: 0px;
    display: none;
    background: rgba(0,0,0,0); /* Chrome/Safari/Webkit */
}

`;