/**
 * ViewLayer is a block element that fills the viewport
 *
 */

import { log, debug, warn } from 'global.js'
import { isString} from 'validators.mjs'

import { AnimatedElement } from 'UI/CustomElement.mjs'

import { Game } from 'Game/Game.mjs'

export const DEFAULT_BACKGROUND_COLOR = '#000000'

export class ViewLayer extends AnimatedElement {

	static templateString;

	name;
	callbacks = { afterHide: {}, afterShow: {}, beforeHide: {}, beforeShow: {} };
	blockActions = false;
	actions = {};

	constructor() {
		super()

		this.name = this.constructor.name

		// During random testing Chrome seems it called both on classList change
		this.addEventListener('transitionend', this.transitionEnd)
		this.addEventListener('transitioncancel', this.transitionEnd)
	}

	async key(event) {
		debug(`${this.constructor.name}.key() pressed: ${event.key}`)

		if ( this.blockActions ) {
			debug(`ViewLayer.key() ignoring key event due to block actions: '${event.key}'`)
			return
		}

		if ( event.key == " " || event.key == "Enter" ) {
			let activeButton = this.getActiveButton()
			let defaultButton = this.getDefaultButton()

			let button = activeButton || defaultButton

			if ( button ) {
				debug("ViewLayer.key() button found, triggering it's action")

				return button.triggerAction(event)

			} else {
				debug("ViewLayer.key() button not found - sending default action")

				let feedback = await this.action?.("default", null, event)

				if ( feedback ) { Game.global.playSfx('button-click') }
			}
		}
	}

	/**
	 * Moves the layer on top and makes it visible, optionally using
	 * an opacity CSS transition.
	 *
	 * Notice: this relies on element tag style attribute opacity:0 as the default
	 * because parentNode.append() & .prepend() will cause redraw of the
	 * element *without* CSS rules applied when using transitions!
	 *
	 * @param {boolean} animate (optional)
	 * @return Promise
	 */
	show(animate) {
		this.blockActions = false;

		if ( Number(window.getComputedStyle(this).getPropertyValue('opacity')) > 0 && this.classList.contains('visible') ) {
//			warn("ViewLayer.show() tried to show visible element - ignoring this")
			return null
		}

		if ( this.classList.contains('visible') ) {
			if ( this.nextSibling == null ) {
				return null
			}
		}

		if ( animate === undefined ) { animate = Game.global.settings.animate }

		if ( !this.animationDirection ) {
			this.animationDirection = 'show'
		} else {
//			debug(`ViewLayer.show() changing animation direction from '${this.animationDirection}' to 'show'`)
			this.animationDirection = 'show'
		}

		if ( this.transitionShow ) { return null }
		if ( this.transitionHide ) { this.transitionEnd() }

		for ( let name in this?.callbacks?.['beforeShow'] ) {
			let func = this.callbacks['beforeShow'][name]
			try {
				func()
			} catch (err) {
				warn("ViewLayer.show() beforeShow callback threw an error - ignoring it: " + err)
			}
		}

		let promise = new Promise( (resolve, reject) => {
			let parent = this.parentNode
			parent.append(this)

			if ( animate ) {
				//resolve() done by transitionEnd()
				this.transitionShow = resolve

				this.classList.add('animated')
				this.classList.add('visible')

				this.forceCssRedraw()

				this.classList.add('opaque')

				this.updateFocus()

			} else {
				this.classList.add('visible')
				this.classList.add('opaque')

				resolve()
			}
		}).finally( () => {
			this.classList.remove('animated')

			for ( let name in this.callbacks['afterShow'] ) {
				let func = this.callbacks['afterShow'][name]

				try {
					func()
				} catch (err) {
					warn("ViewLayer.show() afterShow callback threw an error - ignoring it: " + err)
				}
			}

			Game.global.showViewEvent(this.constructor.name, this.name)
		})

		return promise
	}

	/**
	 * Moves the layer in the bottom and hides it, optionally using
	 * an opacity CSS transition.
	 *
	 * Notice: this relies on element tag style attribute "opacity:0" as the default
	 * because parentNode.append() & .prepend() will cause redraw of the
	 * element *without* CSS rules applied when using transitions!
	 *
	 * @param {boolean} [animate] (optional)
	 */
	hide(animate) {

		this.blockActions = true

		if ( animate === undefined ) { animate = Game.global.settings.animate }

		if ( Number(window.getComputedStyle(this).getPropertyValue('opacity')) == 0 ) {
//			warn("ViewLayer.hide() tried to hide hidden element - ignoring this")
			return null
		}

		if ( !this.animationDirection ) {
			this.animationDirection = 'hide'
		} else {
//			debug(`ViewLayer.show() changing animation direction from '${this.animationDirection}' to 'hide'`)
			this.animationDirection = 'hide'
		}

		if ( this.transitionHide ) { return null }
		if ( this.transitionShow ) { this.transitionEnd() }

		for ( let name in this.callbacks['beforeHide'] ) {
			let func = this.callbacks['beforeHide'][name]

			try {
				func()
			} catch (err) {
				warn("ViewLayer.hide() beforeHide callback threw an error - ignoring it: " + err)
			}
		}

		let promise = new Promise( (resolve, reject) => {

			if ( animate ) {
				this.classList.add('animated')
				this.classList.remove('opaque')

				// parentNode.prepend() done by transitionEnd()
				// resolve() done by transitionEnd()
				this.transitionHide = resolve

				this.forceCssRedraw()

			} else {
				this.classList.remove('animated')
				this.classList.remove('visible')
				this.classList.remove('opaque')

				if ( this.parentNode.firstElementChild.nodeName == "STYLE" ) {
					this.parentNode.firstElementChild.after(this)
				} else {
					this.parentNode.prepend(this)
				}

				resolve()
			}
		}).finally( () => {
			this.classList.remove('opaque')
			this.classList.remove('visible')
			this.classList.remove('animated')

			for ( let name in this.callbacks['afterHide'] ) {
				let func = this.callbacks['afterHide'][name]

				try {
					func()
				} catch (err) {
					warn("ViewLayer.hide() afterHide callback threw an error - ignoring it: " + err)
				}
			}

			Game.global.hideViewEvent(this.constructor.name, this.name, this.choices)
		})

		return promise
	}

	transitionEnd(event) {
		// This fixes the main menu issue: click "end" while show() animation running
		// BUT surprised if this doesn't cause new problems -2.4.2024
		if ( event?.type == "transitioncancel" ) {
			return
		}

		if ( this.transitionHide ) {
			if ( this.animationDirection !== 'hide' ) {
//				debug(`ViewLayer.transitionEnd() animation direction is '${this.animationDirection}' but have callback: transitionHide()`)
			}
			if ( this.parentNode.firstElementChild.nodeName == "STYLE" ) {
				this.parentNode.firstElementChild.after(this)
			} else {
				this.parentNode.prepend(this)
			}

			this.transitionHide()

			delete this.transitionHide
		}

		if ( this.transitionShow ) {
			if ( this.animationDirection !== 'show' ) {
//				debug(`ViewLayer.transitionEnd() animation direction is '${this.animationDirection}' but have callback: transitionShow()`)
			}
			this.transitionShow()

			delete this.transitionShow
		}

		this.animationDirection = null
	}

	forceCssRedraw() {
		// force browser paint - doesn't trigger transition otherwise
		if ( !this.parentNode ) {
			warn("I don't have a parent anymore: ")
			warn(this)
		} else {
			void this.offsetWidth;
			void this.parentNode.offsetWidth;
		}
	}

	onScreenResize(screenWidth, screenHeight) {

		// override if needed

	}

	updateImageElement(selector, asset) {
		let element = this.querySelector(selector)

		if ( !element ) { throw `ViewLayer.updateImageElement() element '${ selector }' not found.` }

		if (asset.type != 'image') { throw `ViewLayer.updateImageElement: asset type has to be image. Got: ${ asset.type }` }

		element.style.backgroundImage = `url('${asset.url}')`

		if ( asset.style ) {
			for ( let k in asset.style ) {
				element.style[k] = asset.style[k]
			}
		}
	}

	updateBackgroundColor(colorHex) {
		if ( !isString(colorHex) || !/^\#\d+$/.test(colorHex) ) { throw `ViewLayer.updateBackgroundColor() bad hex format: '${ colorHex }' has to be string like: '#001122'` }

		this.style.background = colorHex
	}

	updateDefaultBackgroundColor() {
		this.style.background = DEFAULT_BACKGROUND_COLOR
	}
	
	updateBackgroundElement(asset) {

		if (asset.type != 'image') { throw `ViewLayer.updateBackgroundElement: asset type has to be image. Got: ${ asset.type }` }

		this.style.backgroundImage = `url('${asset.url}')`

		if ( asset.style ) {
			for ( let k in asset.style ) {
				element.style[k] = asset.style[k]
			}
		}
	}

	updateBackgroundElementWithShade(asset) {

		if (asset.type != 'image') { throw `ViewLayer.updateBackgroundElement: asset type has to be image. Got: ${ asset.type }` }

		this.style.background = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${asset.url}')`
		this.style.backgroundPosition = 'center';

		if ( asset.style ) {
			for ( let k in asset.style ) {
				element.style[k] = asset.style[k]
			}
		}
	}

	updateTextElement(selector, text) {
		let element = this.querySelector(selector)

		if ( !element ) { throw `ViewLayer.updateTextElement() element '${ selector }' not found.` }

		element.innerText = text
	}

	addCallbackBeforeHide(name, callback) {
		this.callbacks['beforeHide'][name] = callback
	}

	addCallbackBeforeShow(name, callback) {
		this.callbacks['beforeShow'][name] = callback
	}

	addCallbackAfterHide(name, callback) {
		this.callbacks['afterHide'][name] = callback
	}

	addCallbackAfterShow(name, callback) {
		this.callbacks['afterShow'][name] = callback
	}

	clearCallbacks() {
		this.callbacks = { afterHide: {}, afterShow: {}, beforeHide: {}, beforeShow: {} }
	}

	registerAction(actionName, actionFunction) {
		this.actions[actionName] = actionFunction
	}

	hasAction(actionName) {
		return typeof this.actions[actionName] === 'function'
	}

	callAction(actionName, element, event) {
		return this.actions[actionName]?.(actionName, element, event)
	}
}

import importMainStyles from "css/import-main.css.mjs"
import importViewLayerStyles from "css/import-viewlayer.css.mjs"

ViewLayer.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }
</style>

`;

window.customElements.define('view-layer', ViewLayer)

export class ViewContent extends ViewLayer {

	constructor() {

		super()
	}
}

window.customElements.define('view-content', ViewContent)
