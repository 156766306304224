import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const INTERACTION_EVENTS = ["interaction-look", "interaction-use", "interaction-door"]

EVENT_TYPES.push(...INTERACTION_EVENTS)

export class InteractionEvent extends Event {
	constructor(playerId, itemId, spaceId, characterId) {
		super({
			player: playerId,
			item: itemId,
			space: spaceId,
			character: characterIdId
		})

		if (this.constructor == InteractionEvent) {
			throw "InteractionEvent.constructor(): tried to create InteractionEvent object directly – use child classes"
		}
	}

	getPlayer() {
		return Game.global.getPlayer(this.player)
	}

	getItem() {
		return Game.global.getItem(this.item)
	}

	getSpace() {
		if ( isDef(this.space) ) {
			return Game.global.getSpace(this.space)
		}

		return undefined
	}

	getCharacter() {
		if ( isDef(this.character) ) {
			return Game.global.getCharacter(this.character)
		}
	}

	validate(variables) {

		// 'variables' are not used, because events are created in-game,
		// not from a script

		if ( !isString(this.player) ) {
			throw `InteractionEvent.validate() player is not a string. Got: ${ this.player }`
		}

		if ( !isDef(this.getPlayer()) ) {
			throw `InteractionEvent.validate() player '${ this.player } is not valid.\n\nAvailable players: ${ Game.global.getListOfPlayerIds() }`
		}

		if ( !isString(item) ) {
			throw `InteractionEvent.validate() item is not a string. Got: ${ this.item }`
		}

		if ( !isDef(this.getItem()) ) {
			throw `InteractionEvent.validate() item '${ this.item } is not valid.\n\nAvailable players: ${ Game.global.getListOfItems() }`
		}

		if ( ifDef(this.space) ) {
			if ( !isString(space) ) {
				throw `InteractionEvent.validate() space is not a string. Got: ${ this.space }`
			}

			if ( !isDef(this.getSpaceName()) ) {
				throw `InteractionEvent.validate() space '${ this.space } is not valid.\n\nAvailable spaces: ${ Game.global.getListOfSpaces() }`
			}
		}
	}
}

export class LookInteractionEvent extends InteractionEvent {
	static eventType = "interaction-look"
	static requiredKeys = ["event", "player", "item"]
	static optionalKeys = ["space"]

	constructor(eventObject) {
		super (eventObject)
	}
}

export class UseInteractionEvent extends InteractionEvent {
	static eventType = "interaction-use"
	static requiredKeys = ["event", "player", "item"]
	static optionalKeys = ["space"]

	constructor(eventObject) {
		super (eventObject)
	}
}

export class DoorInteractionEvent extends InteractionEvent {
	static eventType = "interaction-door"
	static requiredKeys = ["event", "player", "door"]
	static optionalKeys = ["space"]

	constructor(eventObject) {
		super (eventObject)
	}
}

