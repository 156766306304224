import { sleep, log, warn, error, debug, replaceTrans, replaceObjectTransVars, replaceTransVars } from 'global.js'
import { copy, isString, isBoolean, isDef, isNull, isDefNotNull, isNumber, isObject, isFunction, isArray, isSet} from 'validators.mjs'
import { UI } from 'UI/UI.mjs'

import { Dialogue } from 'Game/Dialogue.mjs'
import { EndDialogueEvent } from 'Command/EventsDialogue.mjs'
import { EndSlideshowEvent } from 'Command/EventsSlideshow.mjs'
import { ActionEvent } from 'Command/EventsAction.mjs'
import { LookInteractionEvent, UseInteractionEvent } from 'Command/EventsInteraction.mjs'
import { ShowViewEvent, HideViewEvent } from 'Command/EventsView.mjs'
import { StartPlayerTurnEvent, StartPlayerRoundEvent, StartWorldRoundEvent, EndPlayerRoundEvent, EndWorldRoundEvent, EndPlayerTurnEvent, SetVariableEvent} from 'Command/EventsGame.mjs'

import { createCommand } from 'Command/create.mjs'
import { Command } from 'Command/Command.mjs'
import { CommandChain, Group } from 'Command/Chain.mjs'

import { Door } from 'Thing/Door.mjs'

import { TT } from 'Game/Translations.mjs'

import { CONFIG_PLAYER_BASE_COUNT, CONFIG_SHOW_ITEM_PICKUP_GUIDE, CONFIG_SHOW_SPACE_GUIDE, CONFIG_DOOR_AFTER_OPENING, CONFIG_WORLD_ROUND_SYSTEM, CONFIG_PLAYER_TURN_SYSTEM } from 'Game/Configuration.mjs'

export const GLOBAL_CURRENT_PLAYER = "GLOBAL_CURRENT_PLAYER"
export const GLOBAL_CURRENT_PLAYER_NAME = "GLOBAL_CURRENT_PLAYER_NAME"
export const GLOBAL_PLAYER_LIST = "GLOBAL_PLAYER_LIST"
export const GLOBAL_PLAYER_COUNT = "GLOBAL_PLAYER_COUNT"
export const GLOBAL_PLAYER_COUNT_LITERAL = "GLOBAL_PLAYER_COUNT_LITERAL"
export const GLOBAL_CURRENT_ROUND_NAME = "GLOBAL_CURRENT_ROUND_NAME"
export const GLOBAL_PLAYER_ROUND_NAME = "player-round"
export const GLOBAL_WORLD_ROUND_NAME = "world-round"
export const GLOBAL_COUNTER_ROUND = "GLOBAL_COUNTER_ROUND"
export const GLOBAL_COUNTER_PLAYER_TURN = "GLOBAL_COUNTER_PLAYER_TURN"
export const GLOBAL_COUNTER_PLAYER_ACTIONS = "GLOBAL_COUNTER_PLAYER_ACTIONS"
export const GLOBAL_ACTIONS_PER_PLAYER_TURN = "GLOBAL_ACTIONS_PER_PLAYER_TURN"
export const GLOBAL_PLAYER_BASE_COUNT = "GLOBAL_PLAYER_BASE_COUNT"

/**
 * Game API provides access to all the game resources and managers.
 *
 * These methods used to be part of Game itself, but for sake
 * of readability they were separated as a parent class.
 *
 */
 export class GameApi {
	app;
	name;
	configuration;
	variables;
	gameSpecs;
	settings;
	processor;
	translationManager;
	assetManager;
	thingManager;
	boardSpecs;
	dialogues;
	script;
	missionManager;
	contentManager;
	state;


	translate(text) {
		return replaceTrans(text)
	}

	/**
	 * @param {string} assetId
	 * @param {string} [tag] (optional)
	 * @returns Audio
	 */
	async playBackgroundMusic(assetId, tag) {
		return this.playAudio(assetId, 'background-music', tag)
	}

	/**
	 * @param {string} assetId
	 * @param {string} [tag] (optional)
	 * @param {boolean [yieldBackgroundMusic] (optional) lower background music volume while this plays
	 * @returns Audio
	 */
	async playForegroundMusic(assetId, tag, yieldBackgroundMusic) {
		return this.playAudio(assetId, 'foreground-music', tag, yieldBackgroundMusic)
	}

	/**
	 * @param {string} assetId
	 * @param {string} [tag] (optional)
	 * @param {boolean [yieldBackgroundMusic] (optional) lower background music volume while this plays
	 * @returns Audio
	 */
	async playSfx(assetId, tag, yieldBackgroundMusic) {
		return this.playAudio(assetId, 'sfx', tag, yieldBackgroundMusic)
	}


	/**
	 * @param {string} assetId
	 * @param {string} [tag] (optional)
	 * @param {boolean [yieldBackgroundMusic] (optional) lower background music volume while this plays
	 * @returns Audio
	 */
	async playVoice(assetId, tag, yieldBackgroundMusic) {
		return this.playAudio(assetId, 'voice', tag, yieldBackgroundMusic)
	}


	async playAudio(assetId, style, tag, yieldBackgroundMusic) {
		if ( !assetId ) { error(`GameApi.playAudio() no 'assetId' defined`) }

		let asset = await this.assetManager.get(assetId)

		if ( !asset ) { throw `GameApi.playAudio() no asset found for id '${assetId}'` }

		let audio = await asset.data()

		if ( tag ) {
			this.audioManager.tag(audio, tag)
		}

		switch(style) {
			case "background-music":
				await this.audioManager.playBackgroundMusic(audio)
				break;

			case "foreground-music":
				await this.audioManager.playForegroundMusic(audio, null, yieldBackgroundMusic)
				break;

			case "sfx":
				await this.audioManager.playSfx(audio, null, yieldBackgroundMusic)
				break;

			case "voice":
				await this.audioManager.playVoice(audio, null, yieldBackgroundMusic)
				break;

			default:
				error(`GameApi.playAudio() unknown style: '${style}'`)
		}

		return audio
	}

	/**
	 * @param {string} assetId
	 * @returns nothing
	 */
	stopAudio(assetId) {

		return this.audioManager.stop(assetId)
	}

	/**
	 * @returns nothing
	 */
	fadeOutAllBackgroundMusic() {

		return this.audioManager.fadeOutAllBackgroundMusic()
	}

	/**
	 * @returns nothing
	 */
	fadeOutAllForegroundMusic() {

		return this.audioManager.fadeOutAllForegroundMusic()
	}

	/**
	 * @returns nothing
	 */
	fadeOutAllMusic() {

		return this.audioManager.fadeOutAllMusic()
	}

	/**
	 * @param {string} tag
	 */
	fadeOutAllAudioWithTag(tag) {

		return this.audioManager.fadeOutAllAudioWithTag(tag)
	}

	getSettingBackgroundMusicVolume() {
		let volume = this.settings["music-volume"]

		if ( !isNumber(volume) ) { throw `GameApi.getSettingBackgroundMusicVolume() music volume not defined in settings.` }

		volume = volume * 0.75

		return volume
	}

	/**
	 * @returns "computer" | "mobile"
	 */
	getSettingDevice() {
		let device = this.settings["device"]

		if ( !device ) { error(`GameApi.getSettingDevice() no setting for 'device'`) }

		return device
	}

	/**
	 * @returns "true" | "false"
	 */
	getSettingAnimate() {
		let animate = this.settings["animate"]

		if ( !animate ) { error(`GameApi.getSettingAnimate() no setting for 'animate'`) }

		return animate
	}

	unloadAllAudio() {

		this.audioManager.unloadAllAudio()
	}

	async stopAllAudioWithFade() {
		debug(`GameApi.stopAllAudioWithFade`)

		await this.audioManager.fadeOutAllAudio()
	}

	breakExecution(cmd) {

		this.processor.cancelCmdsWithTag(cmd.getTag())

		if ( cmd.hasParentTag() ) {
			this.processor.cancelCmdsWithTag(cmd.getParentTag())
		}

		return true
	}

 	/**
 	 * @param {string} selector 'template[id=XXX]'
 	 * @param {object} [variables]
 	 * @returns {Element}
 	 */
 	getTemplateWithSelector(selector, variables) {
 		let template = this.app.shadowRoot.querySelector(selector)

 		if ( !template ) { error(`Api.getTemplateWithSelector() template not found. Selector: ${selector}`) }

		let copy = template.cloneNode(true)
		copy.innerHTML = replaceTransVars(copy.innerHTML, variables)

		return copy
/*
 		let tmp = document.createElement('div')
		tmp.append(template.content.cloneNode(true))

		let copy = document.createElement('template')
		copy.insertAdjacentHTML('afterbegin', replaceTransVars(tmp.innerHTML, variables))

		copy.classList.add(...template.classList)
		return copy
*/
 	}

 	/**
 	 * @param {string} selector 'template[id=XXX]'
 	 * @param {object} [variables]
 	 * @returns {string}
 	 */
 	getTemplateStringWithSelector(selector, variables) {
 		let template = this.getTemplateWithSelector(selector)

 		return replaceTransVars(template.innerHTML, variables)
 	}

 	/**
 	 * @param {string} selector 'template[id=XXX]'
 	 * @returns {array<Element>}
 	 */
 	getAllTemplatesWithSelector(selector, variables) {
 		let templates = this.app.shadowRoot.querySelectorAll(selector)

		let copies = []

 		for ( let t of templates ) {

			let copy = t.cloneNode(true)
			copy.innerHTML = replaceTransVars(copy.innerHTML, variables)

			copies.push(copy)
		}

		return copies
/*
 		let copies = []
 		for ( let t of templates ) {

	 		let tmp = document.createElement('div')
			tmp.append(t.content.cloneNode(true))

			let copy = document.createElement('template')
			copy.insertAdjacentHTML('afterbegin', replaceTransVars(tmp.innerHTML, variables))
			copy.classList.add(...t.classList)

			copies.push(copy)
 		}
 		return copies
*/
 	}

 	/**
 	 * @param {string} selector 'template[id=XXX]'
 	 * @returns {array<string>}
 	 */
 	getAllTemplateStringsWithSelector(selector, variables) {
 		let templates = this.app.shadowRoot.querySelectorAll(selector)

 		let copies = []
 		for ( let t of templates ) {

			copies.push(replaceObjectTransVars(t, variables))
 		}

 		return copies
 	}

 	/**
 	 * @param {string} id
 	 * @returns templateString or null
 	 */
  	getTemplate(id) {
 		let selector = `template[id="${ id }"]`

 		let element = this.getTemplateWithSelector(selector)

 		return element
 	}

 	/**
 	 * Shows the Thing that was previously added or created
 	 * with add...() create...()
 	 *
 	 * @param { string } thingId
 	 * @param { string } [spaceId] (optional)
 	 * @param { number } [centerX] (optional)
 	 * @param { number } [centerY] (optional)
 	 * @param { boolean } [centerView] (optional)
 	 * @param { animate } [animate] (optional)
 	 */
	async showThing(thingId, spaceId, centerX, centerY, centerView, animate) {
		if ( !isDef(thingId) || !isString(thingId) ) { throw `GameApi.showThing() missing thingId or not a String. Got: ${thingId}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.showThing() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.showThing() centerX not a Number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.showThing() centerY not a Number. Got: ${centerY}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.showThing() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showThing() animate not a Boolean. Got: ${animate}`}

		let thing = this.state.getThing(thingId)

		if ( spaceId ) {
			let space = this.state.getThing(spaceId)
			thing.addToSpace(space)
		}

		if ( centerX ) { thing.setCenterX(centerX) }
		if ( centerY ) { thing.setCenterY(centerY) }

		if ( centerView ) {
			let space = thing.getSpaceReal()

			await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		await UI.global.views.gameboard.board.addThing(thing, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return true
	}

 	/**
 	 * Hides the Thing that was previously added or created
 	 * with addFoobar() createFoobar()
 	 *
 	 * @param { string } thingId
 	 * @param { boolean } centerView (optional)
 	 * @param { boolean } animate (optional)
 	 */
	async hideThing(thingId, centerView, animate) {
		if ( !isDef(thingId) || !isString(thingId) ) { throw `GameApi.hideThing() missing id or not a String. Got: ${thingId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.hideThing() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.hideThing() animate not a Boolean. Got: ${animate}`}

		let thing = this.state.getThing(thingId)

		if ( centerView ) {
			let space = thing.getSpaceReal()

			if ( space ) {
				await UI.global.views.gameboard.scrollTo(space)
			}
		}

		await UI.global.views.gameboard.board.removeThing(thing, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return true
	}

	/**
	 * Adds the space tile on board
	 *
	 * TODO: If there are multiple addSpace calls in the script, what should be done?
	 */
	async addSpace(spaceId, specs, centerView, animate) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.addSpace() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(specs) && !isObject(specs) ) { throw `GameApi.addSpace() specs not an Object. Got: ${specs}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.addSpace() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.addSpace() animate not a Boolean. Got: ${animate}`}

		let space = this.state.createSpace(spaceId, specs)

		if ( centerView ) {
			await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		await UI.global.views.gameboard.board.addSpace(space, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return space
	}

	/**
	 * Removes the space from state (fails if doesn't exist)
	 * removes the space tile from board
	 */
	async removeSpace(spaceId, centerView, animate) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.removeSpace() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.removeSpace() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.removeSpace() animate not a Boolean. Got: ${animate}`}

		let space = this.state.getThing(spaceId)

		if ( centerView ) {
			await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		await UI.global.views.gameboard.board.removeSpace(space, isBoolean(animate) ? animate : this.getSettingAnimate() )

		this.state.removeSpace(spaceId)

		return true
	}

	/**
	 * Show space by scrolling to the position and add it
	 * if it is not on the board yet.
	 *
	 * This will mark doors leading to visible spaces as 'open'
	 *
	 * @param { string } spaceId
	 * @param { boolean } [skipOverlay]
	 * @param { boolean } [centerView]
	 * @param { boolean } [animate]
	 */
	async showSpace(spaceId, skipOverlay, centerView, animate) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.showSpace() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.showSpace() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showSpace() animate not a Boolean. Got: ${animate}`}

		let needDisabling = UI.global.views.gameboard.isInteractionEnabled()

		if ( needDisabling ) {
			UI.global.views.gameboard.disableInteractions()
		}

		let space = this.state.getThing(spaceId)

		let spaceWasAlreadyVisible = space.isVisible()

		let doorWasOpened = false

		if ( centerView ) {
			await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		if ( !space.isVisible() ) {
			await UI.global.views.gameboard.board.addSpace(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		let configValue = this.getConfig( CONFIG_DOOR_AFTER_OPENING )

		for ( let thingId in space.contains ) {
			let thing = this.getThing(thingId)

			if ( thing.isVisible() && thing instanceof Door ) {

				if ( !thing.getHiddenSpace() ) {
					if ( configValue === "open" ) {
						thing.openDoor()
						doorWasOpened = true
					} else {
						await this.hideThing(thing.id, false)
					}
				}
			}
		}


		if ( needDisabling ) {
			UI.global.views.gameboard.enableInteractions()
		}

		if ( !skipOverlay ) {
			if ( doorWasOpened ) {
				await this.showDefaultOverlay(TT('add-space-on-board-and-open-doors'), Object.assign({space: space.name}, this.getGlobalVariables() ))
			} else {
				await this.showDefaultOverlay(TT('add-space-on-board'), Object.assign({space: space.name}, this.getGlobalVariables() ))
			}
		}

		return space
	}


	/**
	 * Show the Space and everything in it using a nice animation.
	 * 
	 * Also shows a default overlay message.
	 *
	 * If space exists, ignore it.
	 * 
	 * @param { string } spaceId
	 * @param { boolean } [skipOverlay]
	 * @param { boolean } [centerView]
	 * @param { boolean } [animate]
	 */
	async showSpaceWithContents(spaceId, skipOverlay, centerView, animate) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.showSpaceWithContents() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.showSpaceWithContents() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showSpaceWithContents() animate not a Boolean. Got: ${animate}`}


		let needDisabling = UI.global.views.gameboard.isInteractionEnabled()

		if ( needDisabling ) {
			UI.global.views.gameboard.disableInteractions()
		}

		let space = this.state.getThing(spaceId)

		if ( !space ) { throw `GameApi.showSpaceWithContents() no space found with id: ${ spaceId }` }

		let spaceWasAlreadyVisible = space.isVisible()

		let doorWasOpened = false

		await this.showSpace(spaceId, true, centerView, animate)

		for ( let thingId in space.contains ) {
			let thing = this.getThing(thingId)

			// things may already be visible
			if ( !thing.isVisible() ) {
				await this.showThing(thing.id, space.id, thing.getCenterX(), thing.getCenterY(), false)

				continue
			}
		}

		if ( needDisabling ) {
			UI.global.views.gameboard.enableInteractions()
		}

		if ( !skipOverlay ) {
			if ( doorWasOpened ) {
				await this.showDefaultOverlay(TT('add-space-and-contents-and-open-doors'), Object.assign({space: space.name}, this.getGlobalVariables() ))
			} else {
				await this.showDefaultOverlay(TT('add-space-and-contents'), Object.assign({space: space.name}, this.getGlobalVariables() ))
			}
		}

		return space
	}

	async showDefaultOverlay(message, variables) {
		if ( this.getConfig(CONFIG_SHOW_SPACE_GUIDE) ) {
			await this.showOverlay(message, variables)
		}
	}

	async showOverlay(message, variables) {

		let needDisabling = UI.global.views.gameboard.isInteractionEnabled()

		if ( needDisabling ) {
			UI.global.views.gameboard.disableInteractions()
		}

		UI.global.views.gameboard.setOverlayText(replaceTransVars(message, variables))
		UI.global.views.gameboard.removeAllOverlayButtons()
		UI.global.views.gameboard.addOverlayButton('done', replaceTransVars('button-done'), true)

		await UI.global.views.gameboard.showOverlayMenuAndWait()

		if ( needDisabling ) {
			UI.global.views.gameboard.enableInteractions()
		}
	}

	/**
	 * @param {string} message
	 * @param {object} [variables]
	 * @param {string} [sfxOverride]
	 */
	async showMessage(message, variables, sfxOverride) {

		let needDisabling = UI.global.views.gameboard.isInteractionEnabled()

		if ( needDisabling ) {
			UI.global.views.gameboard.disableInteractions()
		}

		await UI.global.showMessage(message, variables || this.getGlobalVariables(), this.getSettingAnimate(), sfxOverride)

		if ( needDisabling ) {
			UI.global.views.gameboard.enableInteractions()
		}
	}

	/**
	 * @param {string} message
	 * @param {object} [variables]
	 * @param {string} [sfxOverride]
	 */
	async showPopup(message, variables, sfxOverride) {

		let needDisabling = UI.global.views.gameboard.isInteractionEnabled()

		if ( needDisabling ) {
			UI.global.views.gameboard.disableInteractions()
		}

		await UI.global.showMessage(message, variables || this.getGlobalVariables(), this.getSettingAnimate(), sfxOverride)

		if ( needDisabling ) {
			UI.global.views.gameboard.enableInteractions()
		}
	}

	/**
	 * @param {string} thingId
	 * @param {boolean} animate
	 */
	async scrollToId(thingId, animate) {
		let thing = this.state.getThing(thingId)

		if ( !thing ) { throw `GameApi.scrollTo() can't find thing with id: ${thingId}` }

		await this.scrollTo(thing, animate)
	}

	/**
	 * @param {Thing|Space} thing
	 * @param {boolean} animate
	 */
	async scrollTo(thing, animate) {
		if ( !isDef(thing) ) { throw `GameApi.scrollTo() 'thing' not defined.` }

		await UI.global.views.gameboard.scrollTo(thing, isBoolean(animate) ? animate : this.getSettingAnimate())
	}


	/**
	 * Doesn't remove the space from state (fails if doesn't exist)
	 * Removes the space tile from board
	 */
	async hideSpace(spaceId, centerView, animate) {
		if ( !isString(spaceId) ) { throw `GameApi.hideSpace() spaceId not a String. Got: ${spaceId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.hideSpace() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.hideSpace() animate not a Boolean. Got: ${animate}`}

		let space = this.state.getThing(spaceId)

		if ( centerView ) {
			await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
		}

		await UI.global.views.gameboard.board.removeSpace(space, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return space
	}

	/**
	 * @param { string } thingId
	 * @param { string } spaceId
	 * @throws NotFound
	 */
	addToSpace(thingId, spaceId) {
		if ( !isString(thingId) ) { throw `GameApi.addToSpace() thingId not a String. Got: ${ thingId }` }
		if ( !isString(spaceId) ) { throw `GameApi.addToSpace() spaceId not a String. Got: ${ spaceId }`}

		let space = this.state.getThing(spaceId)
		let thing = this.state.getThing(thingId)

		thing.addToSpace(space)
	}

	/**
	 * @param { string } thingId
	 * @param { string } spaceId
	 * @throws NotFound
	 */
	moveToSpace(thingId, spaceId) {
		if ( !isString(thingId) ) { throw `GameApi.moveToSpace() thingId not a String. Got: ${ thingId }` }
		if ( !isString(spaceId) ) { throw `GameApi.moveToSpace() spaceId not a String. Got: ${ spaceId }`}

		let space = this.state.getThing(spaceId)
		let thing = this.state.getThing(thingId)

		thing.removeFromAllSpaces()
		thing.addToSpace(space)
	}	

	/**
	 * Scrolls the board view to show the space.
	 *
	 */
	async centerView(spaceId) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.centerView() spaceId not a String. Got: ${spaceId}`}

		let space = this.state.getThing(spaceId)

		await UI.global.views.gameboard.scrollTo(space, isBoolean(animate) ? animate : this.getSettingAnimate() )
	}

	async removeMonster(monsterId, centerView, animate) {
		if ( !isDef(monsterId) || !isString(monsterId) ) { throw `GameApi.removeMonster() missing monsterId or not a String. Got: ${monsterId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.removeMonster() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.removeMonster() animate not a Boolean. Got: ${animate}`}

		await this.removeThing(monsterId, centerView, animate)

		return true
	}


	async removeNpc(npcId, centerView, animate) {
		if ( !isDef(npcId) || !isString(npcId) ) { throw `GameApi.removeNpc() missing npcId or not a String. Got: ${npcId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.removeNpc() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.removeNpc() animate not a Boolean. Got: ${animate}`}

		await this.removeThing(npcId, centerView, animate)

		return true
	}

	async removeDoor(doorId, centerView, animate) {
		if ( !isDef(doorId) || !isString(doorId) ) { throw `GameApi.removeDoor() missing doorId or not a String. Got: ${doorId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.removeDoor() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.removeDoor() animate not a Boolean. Got: ${animate}`}

		await this.removeThing(doorId, centerView, animate)

		return true
	}


	async removeItem(itemId, centerView, animate) {
		if ( !isDef(itemId) || !isString(itemId) ) { throw `GameApi.removeItem() missing itemId or not a String. Got: ${itemId}`}
		if ( isDef(centerView) && !isNumber(centerView) ) { throw `GameApi.removeItem() centerView not a Boolean. Got: ${centerView}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.removeItem() animate not a Boolean. Got: ${animate}`}

		let item = this.state.getThing(itemId)

		await item.removeFromCharacter()

		await this.removeThing(itemId, centerView, animate)

		return true
	}

	async removeThing(thingId, centerView, animate) {
		let thing = this.state.getThing(thingId)

		await thing.removeFromAllSpaces()

		this.state.deleteThing(thingId)

		return true
	}

	createPlayer(playerId) {
		if ( !isDef(playerId) ) { throw `GameApi.createPlayer(): missing playerId. Got: ${playerId}`}


		let thing = this.state.createPlayer(playerId, {})

		this.getGlobalVariable(GLOBAL_PLAYER_LIST).push(playerId)
		this.setGlobalVariable(GLOBAL_PLAYER_COUNT, this.getGlobalVariable(GLOBAL_PLAYER_LIST).length)
		this.setGlobalVariable(GLOBAL_PLAYER_COUNT_LITERAL, replaceTrans(this.getGlobalVariable(GLOBAL_PLAYER_COUNT)))

		return thing
	}

	deletePlayer(playerId) {
		if ( !isDef(playerId) ) { throw `GameApi.deletePlayer(): missing playerId. Got: ${playerId}`}

		this.state.deletePlayer(playerId)

		let playerList = this.getGlobalVariable(GLOBAL_PLAYER_LIST)
		let index = playerList.indexOf(playerId)

		if ( isDef(index) ) {
			playerList.splice(index, 1)
			this.setGlobalVariable(GLOBAL_PLAYER_COUNT, this.getGlobalVariable(GLOBAL_PLAYER_LIST).length)
			this.setGlobalVariable(GLOBAL_PLAYER_COUNT_LITERAL, replaceTrans(this.getGlobalVariable(GLOBAL_PLAYER_COUNT)))
		}
	}


	createNpc(npcId, spaceId, centerX, centerY) {
		if ( !isDef(npcId) || !isString(npcId) ) { throw `GameApi.createNpc(): missing npcId or not a string. Got: ${npcId}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.createNpc(): spaceId not a string. Got: ${spaceId}`}
		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.createNpc(): centerX not a number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.createNpc(): centerY not a number. Got: ${centerY}`}

		let override = {}
		if ( isNumber(centerX) ) { override.cx = centerX }
		if ( isNumber(centerY) ) { override.cy = centerY }

		let thing = this.state.createNpc(npcId, override)

		if ( spaceId ) {
			let space = this.state.getThing(spaceId)
			thing.addToSpace(space)
		}

		return thing
	}

	deleteNpc(npcId) {
		if ( !isDef(npcId) ) { throw `GameApi.deleteNpc(): missing npcId. Got: ${npcId}`}

		let thing = this.state.removeNpc(npcId)

		return thing
	}

	createMonster(monsterId, spaceId, centerX, centerY) {
		if ( !isDef(monsterId) || !isString(monsterId) ) { throw `GameApi.createMonster(): missing monsterId or not a string. Got: ${monsterId}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.createMonster(): spaceId not a string. Got: ${spaceId}`}
		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.createMonster(): centerX not a number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.createMonster(): centerY not a number. Got: ${centerY}`}

		let override = {}
		if ( isNumber(centerX) ) { override.cx = centerX }
		if ( isNumber(centerY) ) { override.cy = centerY }

		let thing = this.state.createMonster(monsterId, override)

		if ( spaceId ) {
			let space = this.state.getThing(spaceId)
			thing.addToSpace(space)
		}

		return thing
	}

	deleteMonster(monsterId) {
		if ( !isDef(monsterId) ) { throw `GameApi.deleteMonster(): missing monsterId. Got: ${monsterId}`}

		let thing = this.state.removeMonster(monsterId)

		return thing
	}

	createSpace(spaceId, centerX, centerY) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.createSpace(): missing spaceId. Got: ${spaceId}`}

		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.createSpace(): centerX not a number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.createSpace(): centerY not a number. Got: ${centerY}`}

		let override = {}
		if ( isNumber(centerX) ) { override.cx = centerX }
		if ( isNumber(centerY) ) { override.cy = centerY }

		let space = this.state.createSpace(spaceId, override)

		return space
	}

	deleteSpace(spaceId) {
		if ( !isDef(spaceId) ) { throw `GameApi.deleteSpace(spaceId): missing spaceId. Got: ${spaceId}`}

		let thing = this.state.removeSpace(spaceId)

		return thing
	}

	createDoor(doorId, spaceId, centerX, centerY) {
		if ( !isDef(doorId) || !isString(doorId) ) { throw `GameApi.createDoor() missing doorId or not a string. Got: ${doorId}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.createDoor() spaceId not a string. Got: ${spaceId}`}
		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.createDoor() centerX not a number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.createDoor() centerY not a number. Got: ${centerY}`}

		let specs = {}
		if ( isDef(centerX) ) { specs.cx = centerX }
		if ( isDef(centerY) ) { specs.cy = centerY }

		let thing = this.state.createDoor(doorId, specs)

		if ( spaceId ) {
			let space = this.state.getThing(spaceId)
			thing.addToSpace(space)
		}

		return thing
	}

	deleteDoor(doorId) {
		if ( !isDef(doorId) || !isString(doorId) ) { throw `GameApi.deleteDoor(): missing doorId. Got: ${doorId}`}

		let thing = this.state.removeDoor(doorId)

		return thing
	}

	createItem(itemId, spaceId, centerX, centerY) {
		if ( !isDef(itemId) || !isString(itemId) ) { throw `GameApi.createItem() missing itemId or not a string. Got: ${itemId}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.createItem(): spaceId not a string. Got: ${spaceId}`}
		if ( isDef(centerX) && !isNumber(centerX) ) { throw `GameApi.createItem() centerX not a number. Got: ${centerX}`}
		if ( isDef(centerY) && !isNumber(centerY) ) { throw `GameApi.createItem() centerY not a number. Got: ${centerY}`}

		let specs = {}
		if ( isDef(centerX) ) { specs.cx = centerX }
		if ( isDef(centerY) ) { specs.cy = centerY }

		let thing = this.state.createItem(itemId, specs)

		if ( spaceId ) {
			let space = this.state.getThing(spaceId)
			thing.addToSpace(space)
		}

		return thing
	}

	deleteItem(itemId) {
		if ( !isDef(itemId) ) { throw `GameApi.deleteItem(): missing itemId. Got: ${itemId}`}

		let thing = this.state.removeItem(itemId)

		return thing
	}


	/**
	 * 
	 * 
 	 * @param { string } thingId
 	 * @param { object } specs (key, value)
 	 * @throws NotFound
 	 */
	updateThing(thingId, specs) {
		if ( !isString(thingId) ) { throw `GameApi.updateThing() missing thingId or not a String. Got: ${ thingId }` }
		if ( !isObject(specs) ) { throw `GameApi.updateThing() missing specs or not an Object. Got: ${ specs }` }
		
		let thing = this.getThing( thingId )

		thing.update(specs)

		return true
	}

	/**
	 * @param { string } characterId
	 * @param { array<string> } items
	 * @throws NotFound
	 */
	async giveItemsToCharacter(characterId, items) {
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.giveItemsToCharacter(): missing characterId or not a String. Got: ${characterId}`}
		if ( !isDef(items) || !isArray(items) ) { throw `GameApi.giveItemsToCharacter(): missing items or not an array of items. Got: ${items}`}

		let character = this.state.getCharacter(characterId)

		for ( let itemId of items ) {
			let item = this.state.getThing(itemId)

			await item.moveToCharacter(character)
		}

		return true
	}

	/**
	 * @param { string } [characterId]
	 * @param { array<string> } items
	 * @throws NotFound
	 */
	async takeItemsFromCharacter(characterId, items) {
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.takeItemsFromCharacter(): missing characterId or not a String. Got: ${characterId}`}
		if ( !isDef(items) || !isArray(items) ) { throw `GameApi.takeItemsFromCharacter(): missing items or not an array of items. Got: ${items}`}

		for ( let itemId of items ) {
			let item = this.state.getThing(itemId)

			if ( characterId ) {
				let character = this.state.getCharacter(characterId)

				if ( character.hasItem(item) ) {
					await item.removeFromCharacter()
				}

			} else {
				await item.removeFromCharacter()
			}
		}

		return true
	}


	createGeneric(id, specs) {
		let generic = this.state.createGeneric(id, specs)

		return generic
	}

	getGeneric(id) {
		return this.state.getGeneric(id)
	}

	deleteGeneric(id) {
		return this.state.deleteGeneric(id)
	}

	/**
	 * Show slideshow and return await when slideshow ends
	 * 
	 * @param {string} name of the slideshow
	 * @param {boolean} returnAfterShow return immediately after show animation finishes (optional)
	 * @param {boolean} [skippable] (optional)
	 */
	async showSlideshow(name, returnAfterShow, skippable) {
		if ( !isDef(name) || !isString(name) ) { throw `GameApi.showSlideshow(): missing name or not a String. Got: ${name}`}

		return await UI.global.views.slideshow.startSlideshow(name, returnAfterShow, null, this.getSettingAnimate(), skippable)
	}

	getListOfSlideshows() {
		return UI.global.views.slideshow.listSlides()
	}

	/**
	 * @param {string} mode
	 * @param {string} [spaceId] (optional)
	 * @param {boolean} [animate=false] (optional)
	 */
	async showGameboard(mode, spaceId, animate) {
		if ( !isDef(mode) || !isString(mode) ) { throw `GameApi.showGameboard(): missing 'mode' or not a String. Got: ${mode}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showGameboard() 'animate' not a Boolean. Got: ${animate}`}
		if ( isDef(spaceId) && !isString(spaceId) ) { throw `GameApi.showGameboard() 'spaceId' not a String. Got: ${spaceId}`}

		let space
		if (isDef(spaceId) ) {
			space = this.getSpace(spaceId)
		}

		await UI.global.showGameboard(mode, space, isBoolean(animate) ? animate : false )

		return true
	}

	/**
	 * @param { string|Dialogue } dialogue or dialogueId
	 */
	async showDialogue(dialogueOrId, returnAfterShow, animate) {
		if ( !isDef(dialogueOrId) ) { throw `GameApi.showDialogue(): missing dialogue or id. Got: ${dialogueOrId}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showGameboard() animate not a Boolean. Got: ${animate}`}

		let player = this.getCurrentPlayer()
		let dialogue

		if ( isString(dialogueOrId) ) {
			dialogue = this.getDialogue(dialogueOrId)
		}
		else if ( dialogueOrId instanceof Dialogue ) {
			dialogue = dialogueOrId
		}

		await UI.global.views.dialogue.showDialogue(player, dialogue, this.getGlobalVariables(), returnAfterShow, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return true
	}

	/**
	 * @throws NotFound
	 */
	getDialogue(dialogueId) {
		if ( !isDef(dialogueId) || !isString(dialogueId) ) { throw `GameApi.getDialogue(): missing dialogueId or not a String. Got: ${dialogueId}`}

		return new Dialogue(dialogueId, this.dialogues[dialogueId])
	}

	getListOfDialogues() {
		return Object.keys(this.dialogues)
	}

	enableDialogue(characterId, dialogueId) {
		/* EI TESTATTU - SIIRRETTY MUUALTA TÄNNE */
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.enableDialogue(): missing characterId or not a String. Got: ${characterId}`}
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.enableDialogue(): missing dialogueId or not a String. Got: ${dialogueId}`}

		let character = this.state.getCharacter(characterId)
		character.enableDialogue(dialogueId)

		return this
	}

	disableDialogue(characterId, dialogueId) {
		/* EI TESTATTU - SIIRRETTY MUUALTA TÄNNE */
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.disableDialogue(): missing characterId or not a String. Got: ${characterId}`}
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.disableDialogue(): missing dialogueId or not a String. Got: ${dialogueId}`}

		let character = this.state.getCharacter(characterId)
		character.disableDialogue(dialogueId)

		return this
	}


	/**
	 * @param {string} customTemplateName
	 * @param {string} [resultVariable] (optional)
	 * @param {boolean} [returnAfterShow] (optional)
	 * @param {boolean} [waitForHideDuringAction] (optional)
	 * @param {boolean} [animate] (optional)
	 */
	async showCustomView(customTemplateName, resultVariable, returnAfterShow, waitForHideDuringAction, animate) {
		if ( !isString(customTemplateName) ) { throw `GameApi.showCustomView(): 'customTemplateName' is not a string. Got: ${customTemplateName}`}
		if ( isDef(resultVariable) && !isString(resultVariable) ) { throw `GameApi.showCustomView() 'resultVariable' is not a string. Got: ${resultVariable}`}
		if ( isDef(returnAfterShow) && !isBoolean(returnAfterShow) ) { throw `GameApi.showCustomView() 'returnAfterShow' is not a string. Got: ${returnAfterShow}`}
		if ( isDef(waitForHideDuringAction) && !isBoolean(waitForHideDuringAction) ) { throw `GameApi.showCustomView() 'waitForHideDuringAction' is not a string. Got: ${waitForHideDuringAction}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showCustomView() 'animate' is not a string. Got: ${animate}`}

		await UI.global.showCustomView(customTemplateName, resultVariable, returnAfterShow, waitForHideDuringAction, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return true
	}


	/**
	 * @param {string} customTemplateName
	 * @param {string} contentId
	 * @param {string} [resultVariable] (optional)
	 * @param {boolean} [returnAfterShow] (optional)
	 * @param {boolean} [waitForHideDuringAction] (optional)
	 * @param {boolean} [animate] (optional)
	 */
	async showCustomContentView(customTemplateName, contentId, resultVariable, returnAfterShow, waitForHideDuringAction, animate) {
		if ( !isString(customTemplateName) ) { throw `GameApi.showCustomContentView(): 'customTemplateName' is not a string. Got: ${customTemplateName}`}
		if ( !isDef(contentId) || !isString(contentId) ) { throw `GameApi.showCustomContentView() 'contentId' is not an String. Got: ${contentId}`}
		if ( isDef(resultVariable) && !isString(resultVariable) ) { throw `GameApi.showCustomContentView() 'resultVariable' is not a string. Got: ${resultVariable}`}
		if ( isDef(returnAfterShow) && !isBoolean(returnAfterShow) ) { throw `GameApi.showCustomContentView() 'returnAfterShow' is not a string. Got: ${returnAfterShow}`}
		if ( isDef(waitForHideDuringAction) && !isBoolean(waitForHideDuringAction) ) { throw `GameApi.showCustomContentView() 'waitForHideDuringAction' is not a string. Got: ${waitForHideDuringAction}`}
		if ( isDef(animate) && !isBoolean(animate) ) { throw `GameApi.showCustomContentView() 'animate' is not a string. Got: ${animate}`}

		let content = this.getContent(contentId)

		await UI.global.showCustomContentView(customTemplateName, content, resultVariable, returnAfterShow, waitForHideDuringAction, isBoolean(animate) ? animate : this.getSettingAnimate() )

		return true
	}


	/**
	 * @param {string} thingId
	 * @throws NotFound
	 */
	getThing(thingId) {
		if ( !isDef(thingId) || !isString(thingId) ) { throw `GameApi.getThing(): missing thingId or not a String. Got: ${thingId}`}

		return this.state.getThing(thingId)
	}

	/**
	 * @param {string} characterId
	 * @throws NotFound
	 */
	getCharacter(characterId) {
		if ( !isDef(characterId) || !isString(characterId) ) { throw `GameApi.getCharacter(): missing characterId or not a String. Got: ${characterId}`}

		return this.state.getCharacter(characterId)
	}

	/**
	 * @param {string} missionId
	 */
	startMission(missionId) {
		if ( !isDef(missionId) || !isString(missionId) ) { throw `GameApi.startMission(): missing missionId or not a String. Got: ${missionId}`}

		let mission = this.getMission(missionId)

		let chain = mission.startMission()

		if ( chain ) {
			this.queueCommandsOnTop(chain, this.variables)
		}
	}

	/**
	 * @param {string} missionId
	 * @throws NotFound
	 */
	getMission(missionId) {
		if ( !isDef(missionId) || !isString(missionId) ) { throw `GameApi.getMission(): missing missionId or not a String. Got: ${missionId}`}

		return this.missionManager.getMission(missionId)
	}

	getOngoingMissions() {
		return this.missionManager.getOngoingMissions()
	}

	getListOfMissions() {
		return this.missionManager.getListOfMissions()
	}

	getAllItems() {
		return this.state.getItems()
	}

	/**
	 * @throws NotRegistered
	 */
	getItem(itemId) {
		if ( !isDef(itemId) || !isString(itemId) ) { throw `GameApi.getItem(): missing itemId or not a String. Got: ${itemId}`}

		return this.state.getThing(itemId)
	}

	getListOfItems() {
		return this.state.getListOfItems()
	}

	getAllSpaces() {
		return this.state.getSpaces()
	}

	getListOfSpaces() {
		return this.state.getListOfSpaces()
	}

	/**
	 * @throws NotRegistered
	 */
	getSpace(spaceId) {
		if ( !isDef(spaceId) || !isString(spaceId) ) { throw `GameApi.getSpace(): missing spaceId or not a String. Got: ${spaceId}`}

		return this.state.getThing(spaceId)
	}

	getAllDoors() {
		return this.state.getDoors()
	}

	getListOfDoors() {
		return this.state.getListOfDoors()
	}

	getDoor(doorId) {
		if ( !isDef(doorId) || !isString(doorId) ) { throw `GameApi.getDoor(): missing doorId or not a String. Got: ${doorId}`}

		return this.state.getThing(doorId)
	}

	getAllCharacters() {
		return this.state.getCharacters()
	}

	getListOfCharacters() {
		return this.state.getListOfCharacters()
	}

	getAllNpcs() {
		return this.state.getNpcs()
	}

	getNpc(npcId) {
		if ( !isDef(npcId) || !isString(npcId) ) { throw `GameApi.getNpc(): missing npcId or not a String. Got: ${npcId}`}

		return this.state.getThing(npcId)
	}

	getListOfNpcs() {
		return this.state.getListOfNpcs()
	}

	getAllMonsters() {
		return this.state.getMonsters()
	}

	getMonster(monsterId) {
		if ( !isDef(monsterId) || !isString(monsterId) ) { throw `GameApi.getMonster(): missing monsterId or not a String. Got: ${monsterId}`}

		return this.state.getThing(monsterId)
	}

	getListOfMonsters() {
		return this.state.getListOfMonsters()
	}

	getAllPlayers() {
		return this.state.getPlayers()
	}

	getPlayerCount() {
		return this.state.getPlayerCount()
	}

	getPlayer(playerId) {
		if ( !isDef(playerId) || !isString(playerId) ) { throw `GameApi.getPlayer(): missing playerId or not a String. Got: ${playerId}`}

		return this.state.getThing(playerId)
	}

	getListOfPlayerIds() {
		return this.state.getListOfPlayerIds()
	}

	getCurrentPlayer() {
		if ( !this.getConfig(CONFIG_PLAYER_TURN_SYSTEM) ) {
			return null
		}

		let players = this.getAllPlayers()

		if ( this.currentPlayerIndex >= 0 && this.currentPlayerIndex < players.length ) {
			return players[this.currentPlayerIndex]	
		}
		
		return null
	}

	getCurrentPlayerId() {
		return this.getCurrentPlayer()?.id
	}

	resetCurrentPlayerTurn() {
		this.currentPlayerIndex = 0
		let current = this.getCurrentPlayerId()
		let currentName = this.getCurrentPlayer()?.name
		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER, isDef(current) ? current : null )
		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER_NAME, isDef(currentName) ? currentName : null )

		UI.global.views.gameboard.actionMenu.setCurrentPlayer(this.getCurrentPlayerId())

		return true
	}

	/**
	 * @return { boolean } last turn?
	 */
	setNextPlayerTurn() {
		let players = this.getAllPlayers()

		if ( 1 + this.currentPlayerIndex < players.length ) {
			// not last turn
			this.currentPlayerIndex += 1
			this.setGlobalVariable(GLOBAL_CURRENT_PLAYER, this.getCurrentPlayerId() || null)
			this.setGlobalVariable(GLOBAL_CURRENT_PLAYER_NAME, this.getCurrentPlayer()?.name || null)

			UI.global.views.gameboard.actionMenu.setCurrentPlayer(this.getCurrentPlayerId())

			return false
		}

		// last turn
		this.currentPlayerIndex = -1
		return true
	}

	getRandomPlayer() {
		let players = this.getAllPlayers()

		return players[Math.floor(Math.random() * players.length)];
	}

	/**
	 * Doors may or may not have a special view for opening the door.
	 * 
	 * Doors which are open, do nothing
	 */
 	async showDoorAction(doorId) {
		if ( !isDef(doorId) || !isString(doorId) ) { throw `GameApi.showDoorAction(): missing doorId or not a String. Got: ${doorId}`}


		let door = this.state.getThing(doorId)

		if ( door.isOpen() ) { return false }

		await UI.global.views.door.show(door)

		return true
	}

	/**
	 * @param {string} monsterId
	 * @param {string} [assetId='attack']
	 * @param {boolean} [onlyAttack=false]
	 */
	async showAttackView(monsterId, assetId, onlyAttack) {
		if ( !isDef(monsterId) || !isString(monsterId) ) { throw `GameApi.giveItemsToCharacter(): missing monsterId or not a String. Got: ${monsterId}`}

		let monster = this.state.getThing(monsterId)

		let asset = monster.getAssetOrNull(assetId || 'attack')

		await UI.global.showAttackView(monster, asset, this.variables, isDef(onlyAttack) ? onlyAttack : false )
	}

/*
	async showItemAction(itemId) {
		debug("GameApi.showItemAction called")

		let player = this.getCurrentPlayer()

		let item = this.state.getThing(itemId)

		await UI.global.views.itemAction.show(player, item)
	}
*/

	async currentPlayerPickupItem(itemId) {
		if ( !isDef(itemId) || !isString(itemId) ) { throw `GameApi.currentPlayerPickupItem(): missing itemId or not a String. Got: ${itemId}`}

		return await this.playerPickupItem(this.getCurrentPlayerId(), itemId)
	}

	async playerPickupItem(playerId, itemId) {
		if ( !isString(playerId) ) { throw `GameApi.playerUserItem() attribute 'playerId' not a String` }
		if ( !isString(itemId) ) { throw `GameApi.playerUserItem() attribute 'itemId' not a String` }

		let player = this.getPlayer(playerId)
		let item = this.getThing(itemId)

		await item.removeFromAllSpaces()
		item.removeFromCharacter()

		player.addItem(item)

		return true
	}

	async currentPlayerUseItem(itemId) {
		if ( !isDef(itemId) || !isString(itemId) ) { throw `GameApi.currentPlayerUseItem(): missing itemId or not a String. Got: ${itemId}`}

		return this.playerUseItem(this.getCurrentPlayerId(), itemId)
	}

	async playerUseItem(playerId, itemId) {
		if ( !isString(playerId) ) { throw `GameApi.playerUseItem() attribute 'playerId' not a String` }
		if ( !isString(itemId) ) { throw `GameApi.playerUseItem() attribute 'itemId' not a String` }

		let player = this.getPlayer(playerId)
		let item = this.getThing(itemId)
		let cmds = item.getCurrentActionCmds()

		if ( cmds ) {
			await this.queueCommandsOnTopAndWait(cmds)	
		} else {
			warn `GameApi.playerUseItem() no commands found for item '${itemId}' with: ${item.getCurrentAction()}`
		}
	}

	startPlayerTurn() {
		this.addGlobalVariable(GLOBAL_COUNTER_PLAYER_TURN, 1)

		let currentPlayer = this.getCurrentPlayer()

		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER, isDefNotNull(currentPlayer) ? currentPlayer.id : null )
		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER_NAME, isDefNotNull(currentPlayer) ? currentPlayer.name : null )

		UI.global.views.gameboard.setCurrentPlayer( currentPlayer )

		this.processor.queueCommands(new StartPlayerTurnEvent(), this.variables)
	}

	endPlayerTurn() {

		this.processor.queueCommands(new EndPlayerTurnEvent(), this.variables)

		let last = this.setNextPlayerTurn()

		if ( last ) {
			UI.global.views.gameboard.clearCurrentPlayer()
			return this.endPlayerRound()
		}

		this.startPlayerTurn()
	}

	nextTurn() {
		if ( this.getGlobalVariable(GLOBAL_CURRENT_ROUND_NAME) == GLOBAL_PLAYER_ROUND_NAME ) {
		
			if ( this.getConfig(CONFIG_PLAYER_TURN_SYSTEM) ) {
				this.endPlayerTurn()
			} else {
				this.endPlayerRound()
			}
		}
		else if ( this.getGlobalVariable(GLOBAL_CURRENT_ROUND_NAME) == GLOBAL_WORLD_ROUND_NAME ) {

			this.endWorldRound()

		}
		else {
			throw `GameApi.nextTurn() global variable GLOBAL_CURRENT_ROUND_NAME is not 'player' or 'world' - don't know what to do with value: ${ this.getGlobalVariable(GLOBAL_CURRENT_ROUND_NAME) }`
		}
	}

	/**
	 * @param { boolean } doNotEnableInteractions
	 */
	async startPlayerRound(doNotEnableInteractions) {
		this.addGlobalVariable(GLOBAL_COUNTER_ROUND, 1)

		this.processor.queueCommands(new StartPlayerRoundEvent(), this.variables)

		this.setGlobalVariable(GLOBAL_CURRENT_ROUND_NAME, GLOBAL_PLAYER_ROUND_NAME)

		if ( !doNotEnableInteractions ) {
			UI.global.views.gameboard.enableInteractions()
		}

		if ( this.getConfig(CONFIG_PLAYER_TURN_SYSTEM) ) {
			this.resetCurrentPlayerTurn()

			this.startPlayerTurn()
		}

		if ( !doNotEnableInteractions ) {
			await UI.global.showActionMenu(this.getSettingAnimate())
		}
	}

	/**
	 * Restart player round if it's player round.
	 *
	 * Does not trigger events.
	 *
	 * @param {boolean} [showMessage]
	 * @returns {boolean} if restarted or not
	 */
	async restartPlayerRound(showMessage) {
		if ( this.getGlobalVariable(GLOBAL_CURRENT_ROUND_NAME) != GLOBAL_PLAYER_ROUND_NAME ) {
			return false
		}

		this.addGlobalVariable(GLOBAL_COUNTER_ROUND, 1)

		this.setGlobalVariable(GLOBAL_CURRENT_ROUND_NAME, GLOBAL_PLAYER_ROUND_NAME)

		if ( this.getConfig(CONFIG_PLAYER_TURN_SYSTEM) ) {
			this.resetCurrentPlayerTurn()
		}

		if ( showMessage ) {
			await this.showMessage("{{~popup-restart-player-round}}")
		}

		return true
	}

	endPlayerRound() {

		UI.global.views.gameboard.disableInteractions()

		this.processor.queueCommands(new EndPlayerRoundEvent(), this.variables)

		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER, null)
		this.setGlobalVariable(GLOBAL_CURRENT_PLAYER_NAME, null)

		if ( this.getConfig(CONFIG_WORLD_ROUND_SYSTEM) ) {
			this.startWorldRound()
		}

		if ( !this.getConfig(CONFIG_PLAYER_TURN_SYSTEM) ) {
			this.addGlobalVariable(GLOBAL_COUNTER_PLAYER_TURN, this.getGlobalVariable(GLOBAL_PLAYER_COUNT))
		}
	}

	async startWorldRound() {

		await this.playForegroundMusic("meanwhile", null, true)

		await UI.global.hideActionMenu(this.getSettingAnimate())

		await this.showSlideshow("world-turn")

		UI.global.views.gameboard.disableInteractions()

		this.processor.queueCommands(new StartWorldRoundEvent(), this.variables)

		this.setGlobalVariable(GLOBAL_CURRENT_ROUND_NAME, GLOBAL_WORLD_ROUND_NAME)

		UI.global.showWorldMenu()

		await UI.global.showActionMenu(this.getSettingAnimate())
	}

	async endWorldRound() {

		await this.playForegroundMusic("meanwhile", null, true)

		await UI.global.hideActionMenu(this.getSettingAnimate())

		this.processor.queueCommands(new EndWorldRoundEvent(), this.variables)

		await this.showSlideshow("player-turn")

		UI.global.showPlayerMenu()


		this.startPlayerRound()
	}	

	isPlayerRound() {
		let current = this.getGlobalVariable(GLOBAL_CURRENT_ROUND_NAME)

		return current === GLOBAL_PLAYER_ROUND_NAME
	}

	// rename to 'set'
	importState(newState) {
		if ( !isDef(newState) || !isString(newState) ) { throw `GameApi.importState(): missing 'newState' or not a String. Got: ${newState}`}

		this.state.importState(newState)

		return true
	}

	// rename to 'get'
	exportState() {
		return this.state.exportState()
	}

	resetState() {
		this.state.resetState()

		return true
	}

	resetStateAndGlobalVariables() {

		this.resetState()
		this.resetVariables()

		return true
	}

	/**
	 * Load Spaces and Things into State
	 * 
	 * @param { string } boardId
	 * @throws NotFound
	 */
	initBoard(boardId) {
		let board = this.boardSpecs[boardId]

		if ( !board ) { throw `GameApi.initBoard() board '${boardId}' not found` }

		for ( let type in board ) {
			for ( let thingId in board[type] ) {
				let overrideSpecs = board[type][thingId]

				let thing = this.state.createThing(type, thingId, overrideSpecs)
			}
		}
	}

	/**
	 * Check if board exists
	 * 
	 * @return boolean
	 */
	isBoard(boardId) {
		return this.boardSpecs[boardId] ? true : false
	}

	endDialogueEvent(dialogue) {
		if ( !isDef(dialogue) || !isObject(dialogue) ) { throw `GameApi.endDialogueEvent(): missing 'dialogue' or not an Object. Got: ${dialogue}`}

		let event = new EndDialogueEvent(this.getCurrentPlayer(), dialogue)

		this.processor.queueCommandsOnTop(event, this.variables)

		return true
	}

	endSlideshowEvent(slideshow) {
		if ( !isDef(slideshow) || !isObject(slideshow) ) { throw `GameApi.endSlideshowEvent(): missing 'slideshow' or not an Object. Got: ${slideshow}`}

		let event = new EndSlideshowEvent(this.getCurrentPlayer(), slideshow)

		this.processor.queueCommandsOnTop(event, this.variables)

		return true
	}

	registerEventListener(eventType, callback) {
		if ( !isDef(eventType) || !isString(eventType) ) { throw `GameApi.registerEventListener(): missing 'eventType' or not a String. Got: ${eventType}`}
		if ( isDef(callback) && !isFunction(callback) ) { throw `GameApi.registerEventListener(): callback is not a Function. Got: ${callback}`}

		this.processor.addListener(eventType, callback)

		return true
	}

	deleteEventListener(eventType, callback) {
		if ( !isDef(eventType) || !isString(eventType) ) { throw `GameApi.deleteEventListener(): missing 'eventType' or not a String. Got: ${eventType}`}
		if ( isDef(callback) && !isFunction(callback) ) { throw `GameApi.deleteEventListener(): callback is not a Function. Got: ${callback}`}

		this.processor.removeListener(eventType, callback)

		return true
	}

	actionEvent(name, value, choices) {
		if ( !isString(name) ) { throw `GameApi.actionEvent() 'name' is not a string. Got: ${name}`}

		let event = new ActionEvent(name, value, choices)

		this.processor.queueCommandsOnTop(event, this.variables)

		return true
	}


	showViewEvent(view, name) {
		if ( !isString(name) ) { throw `GameApi.showViewEvent() 'view' is not a string. Got: ${view}`}
		if ( !isString(name) ) { throw `GameApi.showViewEvent() 'name' is not a string. Got: ${name}`}

		let event = new ShowViewEvent(view, name)

		this.processor.queueCommandsOnTop(event, this.variables)

		return true
	}

	hideViewEvent(view, name, choices) {
		if ( !isString(name) ) { throw `GameApi.hideViewEvent() 'view' is not a string. Got: ${view}`}
		if ( !isString(name) ) { throw `GameApi.hideViewEvent() 'name' is not a string. Got: ${name}`}

		let event = new HideViewEvent(view, name, choices)

		this.processor.queueCommandsOnTop(event, this.variables)

		return true
	}
	/**
	 *
	 * Pushes the Command to Processor, attaches the game variables
	 * to it and WAITS until it's finished.
	 *
	 * If the command triggers Events, those won't be waited.
	 *
	 * IF this is called from within an Command execution, it will stall
	 * the Processor because it will never finish.
	 *
	 * This is useful for UI or network triggered actions, where they need to wait
	 * the Command to finish.
	 *
	 * @param { Command | CommandChain | Array } commands
 	 * @return Promise
	 */
	queueCommandsOnTopAndWait(cmds) {

		// this must NOT be used from Commands or Events

		if ( !(cmds instanceof CommandChain) && !(cmds instanceof Command) && !isArray(cmds) ) { throw `GameApi.queueCommandsOnTopAndWait() cmds not a Command|CommandChain|Array. Got: ${ cmds }` }

		return this.processor.queueCommandsOnTopAndWait(cmds, this.variables)
	}


	/**
	 *
	 * Pushes the Command to Processor, attaches the game variables
	 * and returns immediately
	 *
	 *
	 * @param { Command | CommandChain | Array } commands
 	 * @return Promise
	 */
 	queueCommandsOnTop(cmds) {

		if ( !(cmds instanceof CommandChain) && !(cmds instanceof Command) && !isArray(cmds) ) { throw `GameApi.queueCommandsOnTopAndWait() cmds not a Command|CommandChain|Array. Got: ${ cmds }` }

		return this.processor.queueCommandsOnTop(cmds, this.variables)
	}


	/**
	 *
	 * Pushes the Command to Processor, attaches the game variables
	 * to it and WAITS until it's finished.
	 *
	 * If the command triggers Events, those won't be waited.
	 *
	 * IF this is called from within an Command execution, it will stall
	 * the Processor because it will never finish.
	 *
	 * This is useful for UI or network triggered actions, where they need to wait
	 * the Command to finish.
	 *
	 * @param { Command | CommandChain | Array } commands
 	 * @return Promise
	 */
 	queueCommandsAndWait(cmds) {

 		// this must NOT be used from Commands or Events

		if ( !(cmds instanceof CommandChain) && !(cmds instanceof Command) && !isArray(cmds) ) { throw `GameApi.queueCommandsOnTopAndWait() cmds not a Command|CommandChain|Array. Got: ${ cmds }` }

		return this.processor.queueCommandsAndWait(cmds, this.variables)
	}

	/**
	 *
	 * Pushes the Command to Processor, attaches the game variables
	 * and returns immediately
	 *
	 *
	 * @param { Command | CommandChain | Array } commands
 	 * @return Promise
	 */
 	queueCommands(cmds) {

		if ( !(cmds instanceof CommandChain) && !(cmds instanceof Command) && !isArray(cmds) ) { throw `GameApi.queueCommandsOnTopAndWait() cmds not a Command|CommandChain|Array. Got: ${ cmds }` }

		return this.processor.queueCommands(cmds, this.variables)
	}

	/**
	 * @throws UnknownCommand
	 */
	createCommand(specs) {
		return createCommand(specs)
	}

	/**
	 * specsList = [ { cmd }, { cmd }, ...]
	 * 
	 * @param { array } specsList
	 * @return CommandChain
	 * @throws UnknownCommand
	 */
	createCommandChain(specsList) {
		let cmds = new CommandChain()

		for ( let specs of specsList ) {
			cmds.addCommand(specs)
		}

		return cmds
	}

	getLanguage() {
		return this.settings.language
	}

	getDefaultLanguage() {
		return this.defaultLanguage
	}

	getContent(contentId, variables) {
		if ( !isString(contentId) ) { throw `GameApi.getContent(): contentId not a String. Got: ${contentId}`}		
		if ( isDef(variables) && !isObject(variables) ) { throw `GameApi.getContent() variables not a valcontentId Object. Got: ${ cmd }` }


		return this.contentManager.getContent(contentId, variables)
	}

	getContentTemplateId(contentId) {
		if ( !isDef(contentId) || !isString(contentId) ) { throw `GameApi.getContentTemplateId(): missing contentId or not a String. Got: ${contentId}`}		

		return this.contentManager.getContentTemplateId(contentId)
	}

	/**
	 * @param { string } assetId
	 * @return { Asset }
	 * @throws NotFound
	 */
	getAsset(assetId) {
		if ( !isString(assetId) ) { throw `GameApi.getAsset(): assetId not a String. Got: ${assetId}`}		

		return this.assetManager.get(assetId)
	}

	/**
	 * @throws NotFound
	 */
	getConfig(name) {
		return this.configuration.getConfig(name)
	}

	/**
	 * @throws NotFound
	 * @throws InvalidValue
	 */
	setConfig(name, value) {
		return this.configuration.setConfig(name, value)
	}

	
	/**
	 * Register a variable, if it's isn't registered already.
	 * 
	 * Value can be:
	 * - undefined, null
	 * - string, number
	 * - array => set (converted)
	 * 
	 * But not: object
	 * 
	 * 
	 * 
	 * @param {string} name
	 * @param {any} value (optional)
	 * @throws AlreadyExists
	 * @throws ObjectNotSupported
	 */
	registerGlobalVariable(name, value) {
		if ( !isString(name) ) { throw `GameApi.registerGlobalVariable() name is not a String. Got: ${ name }` }
		if ( isDef(this.variables[name]) ) { throw `GameApi.registerGlobalVariable() variable '${name}' already exists.` }
		if ( isObject(value) ) { throw `GameApi.registerGlobalVariable() variable '${name}' value is an object - unspported!` }

		let newValue = isDef(value) ? value : null

		this.variables[name] = copy(newValue)

		return true
	}

	/**
	 * Set or modify variable value.
	 * 
	 * Use undefined as empty value.
	 * 
	 * Execution order:
	 * - value
	 * - value as length of
	 * - random value
	 * - push
	 * - multiply
	 * - divide
	 * - add
	 * - subtract
	 * - round
	 * - min
	 * - max
	 * - delete
	 * 
	 * Push will convert the value into a Set, after which math
	 * operations will not work - they expect value to be a number.
	 * 
	 * Rounding (number)
	 * - "up": always round up to integer
	 * - true: round per standard .5 rule to integer
	 * - false: do not round (default)
	 * - "down": always round down to integer
	 * 
	 * Min / max (number)
	 * - if value is smaller than min, value will be min
	 * - if value is larger than max, value will be max
	 * 
	 * Note: min/max may override round
	 *
	 * Concat will override value, not add to it
	 * 
	 * @param {string} name of the variable
	 * 
	 * For simple values:
	 * @param {any} [value]
	 * @param {number} [add]
	 * @param {number} [subtract]
	 * @param {number} [multiply]
	 * @param {number} [divide]
	 * @param {number} [min]
	 * @param {number} [max]
	 * @param {boolean} [round]
	 * 
	 * For arrays (behaves like a Set)
	 * @param {Array} [push]
	 * @param {Array} [has]
	 * @param {Array} [delete]
	 * @param {Array} [valueFrom]
	 * @param {Array} [valueFromLength]
	 * @param {Array} [valueFromRandom]
	 *
	 * For array:
	 * @param {array} [concat]
	 * @throws NotFound
	 */
	addGlobalVariable(name, add) { return this.setGlobalVariable(name, undefined, add) }
	subtractGlobalVariable(name, subtract) { return this.setGlobalVariable(name, undefined, undefined, add) }
	multiplyGlobalVariable(name, multiply) { return this.setGlobalVariable(name, undefined, undefined, undefined, add) }
	divideGlobalVariable(name, divide) { return this.setGlobalVariable(name, undefined, undefined, undefined, undefined, add) }

	setGlobalVariable(name, newValue, add, subtract, multiply, divide, push, del, round, min, max, valueFrom, valueFromLength, valueFromRandom, concat) {
		if ( !isString(name) ) { throw `GameApi.setGlobalVariable() 'name' is not a String. Got: ${ name }` }
		if ( !isDef(this.variables[name]) ) { throw `GameApi.setGlobalVariable() variable '${name}' doesn't exist - use registerGlobalVariable() first.` }
		if ( isDef(multiply) && !isNumber(multiply) ) { throw `GameApi.setGlobalVariable() 'multiply' is not a number. Got: ${ multiply }` }
		if ( isDef(divide) && !isNumber(divide) ) { throw `GameApi.setGlobalVariable() 'divide' is not a number. Got: ${ divide }` }
		if ( isDef(add) && !isNumber(add) ) { throw `GameApi.setGlobalVariable() 'add' is not a number. Got: ${ add }` }
		if ( isDef(subtract) && !isNumber(subtract) ) { throw `GameApi.setGlobalVariable() 'subtract' is not a number. Got: ${ subtract }` }
		if ( isDef(push) && !isArray(push) ) { throw `GameApi.setGlobalVariable() 'push' is not an array. Got: ${ push }` }

		if ( isDef(round) && !isString(round) && !isBoolean(round) ) { throw `GameApi.setGlobalVariable() 'round' is not a string or boolean. Got: ${ round }` }
		if ( isDef(min) && !isNumber(min) ) { throw `GameApi.setGlobalVariable() 'min' is not a number. Got: ${ min }` }
		if ( isDef(max) && !isNumber(max) ) { throw `GameApi.setGlobalVariable() 'max' is not a number. Got: ${ max }` }

		if ( isDef(valueFrom) && !isArray(valueFrom) ) { throw `GameApi.setGlobalVariable() 'valueFrom' is not an Array. Got: ${ valueFrom }`}
		if ( isDef(valueFromLength) && !isArray(valueFromLength) ) { throw `GameApi.setGlobalVariable() 'valueFromLength' is not an Array. Got: ${ valueFromLength }`}
		if ( isDef(valueFromRandom) && !isArray(valueFromRandom) ) { throw `GameApi.setGlobalVariable() 'valueFromRandom' is not an Array. Got: ${ valueFromRandom }` }

		if ( isDef(concat) && !isArray(concat) ) { throw `GameApi.setGlobalVariable() 'concat' is not an array. Got: ${ concat }` }

		let oldValue = this.variables[name]
		let currentValue = this.variables[name]

		if ( isDef(newValue) ) {
			currentValue = newValue
		}

		if ( isDef(valueFrom) ) {
			currentValue = valueFrom
		}

		if ( isDef(valueFromRandom) ) {
			let randomVariable = isArray(valueFromRandom) ? valueFromRandom : Array.from(valueFromRandom)

			currentValue = randomVariable[Math.floor(Math.random() * randomVariable.length)]
		}

		if ( isDef(valueFromLength) ) {
			currentValue = valueFromLength.size
		}

		if ( isDef(concat) ) {
			currentValue = "".concat(...concat)
		}

		if ( isDef(push) ) {
			if ( !isDef(currentValue) || isNull(currentValue) ) {
				currentValue = []
			}

			if ( !isArray(currentValue) ) {
				warn `GameApi.setGlobalVariable() pushing value to non-array variable '${name}'. Converting it to an array.`
				currentValue = [ currentValue ]
			}

			for ( let value of push ) {
				currentValue.push( value )
			}
		}

		if ( isNumber(currentValue) ) {

			if ( isNumber(multiply) ) {
				currentValue = currentValue * multiply
			}
			if ( isNumber(divide) ) {
				currentValue = currentValue / divide
			}
			if ( isNumber(add) ) {
				currentValue = currentValue + add
			}
			if ( isNumber(subtract) ) {
				currentValue = currentValue - subtract
			}

			if ( isBoolean(round) && round ) {
				currentValue = Math.round(currentValue)
			}
			else if ( isString(round) && round == "up" ) {
				currentValue = Math.ceil(currentValue)
			}
			else if ( isString(round) && round == "down" ) {
				currentValue = Math.floor(currentValue)
			}

			if ( isNumber(min) && currentValue < min ) {
				currentValue = min
			}
			if ( isNumber(max) && currentValue > max ) {
				currentValue = max
			}
		}

		if ( isDef(del) ) {
			if ( isArray(currentValue) ) {

				currentValue = currentValue.filter( x => x !== del )

			} else {
				warn `GameApi.setGlobalVariable() variable '${name}' is not an Array. Tried to delete value: '${del}'`
			}
			
		}

		// clone the value
		let valueCopy = copy(currentValue)

		this.variables[name] = valueCopy

		this.processor.queueCommands(new SetVariableEvent({ variable: name, value: valueCopy, oldValue: oldValue }), this.variables)

		return true
	}

	deleteGlobalVariable(name) {
		if ( !isString(name) ) { error(`GameApi.deleteGlobalVariable() name is not a String. Got: ${ name }`) }
		if ( !isDef(this.variables[name]) ) { error(`GameApi.deleteGlobalVariable() variable '${value}' doesn't exist.`) }

		delete this.variables[name]
	}

	getGlobalVariable(name) {
		if ( !isString(name) ) { error(`GameApi.getGlobalVariable() name is not a String. Got: ${ name }`) }

		return this.variables[name]
	}

	getGlobalVariables() {
		return this.variables
	}

	async endGameWithSlideshow(slideshow) {
		console.log("GameApi.endGameWithSlideshow() called")

		try {
			if ( !isString(slideshow) ) { error(`GameApi.endGameWithSlideshow() no 'slideshow' name defined`) }

			// hide gameboard ui
			await this.showGameboard("presentation")

			await this.showSlideshow(slideshow, false, true)

			// stop and unload all audio
			this.stopAllAudioWithFade()
			this.unloadAllAudio()

			// silently stop running commands
			this.processor.haltProcessor()

			// clear state
			this.state.resetState()

			// clear board
			UI.global.views.gameboard.board.resetBoard()

			// TODO fadeout
			this.app.shadowRoot.innerHTML = ""

			// clear variables
			this.resetVariables()

		} catch (err) {
			warn(err)
		}

		this.stopGame()
	}

	async endGameWithFadeOut() {
		console.log("GameApi.endGameWithFadeOut() called")

		try {
			// hide gameboard ui
			await this.showGameboard("presentation")

			// stop and unload all audio
			this.stopAllAudioWithFade()
			this.unloadAllAudio()

			// silently stop running commands
			this.processor.haltProcessor()

			// clear state
			this.state.resetState()

			// clear board
			UI.global.views.gameboard.board.resetBoard()

			// TODO fadeout
			this.app.shadowRoot.innerHTML = ""

			// clear variables
			this.resetVariables()

		} catch (err) {
			warn(err)
		}

		this.stopGame()
	}

	async showSettings() {
		if ( this.app.settingsCallback ) {
			let news = await this.app.settingsCallback()
			let olds = this.settings

			// quality

			// animate

			// language

			let musicVolume = parseFloat(news["music-volume"]).toFixed(2)
			let sfxVolume = parseFloat(news["sfx-volume"]).toFixed(2)
			let voiceVolume = parseFloat(news["voice-volume"]).toFixed(2)

			debug("Updating audio volumes:")
			debug(`- music: ${ musicVolume }`)
			debug(`- sfx: ${ sfxVolume }`)
			debug(`- voice: ${ voiceVolume }`)

			this.audioManager.setVolumes(musicVolume, sfxVolume, voiceVolume)

		} else {
			alert(`Didn't find settings callback.\n\nHere's current:\n\n${JSON.stringify(this.settings)}`)
		}

		return true
	}

	async showInGameMenu() {
		let choices = await this.showSlideshow("ingame-menu")

		if ( choices ) {

			if ( choices.includes("exit") ) {
				this.endGameWithFadeOut()
			}
			else if ( choices.includes("tutorial") ) {
				this.showSlideshow("tutorial-rules")
			}
			else if ( choices.includes("settings") ) {
				this.showSettings()
			}
		}
	}
}

GAME_API = GameApi
