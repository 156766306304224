import { sleep, log, warn, error } from 'global.js'
import { isString, isObject, isArray } from 'validators.mjs'

import { UI } from 'UI/UI.mjs'
import { Game } from 'Game/Game.mjs'
import { Door } from 'Thing/Door.mjs'
import { Item } from 'Thing/Item.mjs'
import { Space } from 'Thing/Space.mjs'
import { Npc, Player, Monster } from 'Thing/Characters.mjs'

import { Generic }  from 'Thing/Generic.mjs'

export class ThingManager {
	static version = 33
	static TYPE_SPACE = 'space'
	static TYPE_DOOR = 'door'
	static TYPE_ITEM = 'item'
	static TYPE_PLAYER = 'player'
	static TYPE_NPC = 'npc'
	static TYPE_MONSTER = 'monster'
	static TYPE_GENERIC = 'generic'

	constructor(thingSpecs, boardContentScale) {
		if ( thingSpecs.version != ThingManager.version ) {
			throw `ThingManager.constructor() thing specs version != ${ ThingManager.version }`
		}

		this.boardContentScale = boardContentScale
		this.registry = undefined
		this.instances = undefined
		this.resetRegistry()

		for (let type in this.registry ) {

			if ( thingSpecs[type] ) {
				for ( let id in thingSpecs[type] ) {
					let specs = thingSpecs[type][id]

					if ( this.boardContentScale != 1 && specs.cx && specs.cy ) {
						specs.cx = Math.round( specs.cx * this.boardContentScale )
						specs.cy = Math.round( specs.cy * this.boardContentScale )
					}

					this.register(type, id, specs)
				}
			}
		}
	}

	resetRegistry() {
		this.registry = {
			[ThingManager.TYPE_SPACE]: {},
			[ThingManager.TYPE_DOOR]: {},
			[ThingManager.TYPE_ITEM]: {},
			[ThingManager.TYPE_PLAYER]: {},
			[ThingManager.TYPE_NPC]: {},
			[ThingManager.TYPE_MONSTER]: {},
			[ThingManager.TYPE_GENERIC]: {}
		}

		this.instances = {
			[ThingManager.TYPE_SPACE]: {},
			[ThingManager.TYPE_DOOR]: {},
			[ThingManager.TYPE_ITEM]: {},
			[ThingManager.TYPE_PLAYER]: {},
			[ThingManager.TYPE_NPC]: {},
			[ThingManager.TYPE_MONSTER]: {},
			[ThingManager.TYPE_GENERIC]: {}
		}
	}

	register(type, id, specs) {
		if ( !isString(type) ) { throw `ThingManager.register() type is not a String. Got: '${ type }' for id: '${id}'`}
		if ( !isString(id) ) { throw `ThingManager.register() id is not a String. Got: '${ id }' for type: '${type}'`}
		if ( !isObject(specs) ) { throw `ThingManager.register() specs is not an Object. Got: '${ specs }' for id: '${id}, type: ${type}'`}
		if ( !isObject(this.registry[type]) ) { throw `ThingManager.register() type '${type}' not found in registry. Id: '${id}'`}

		if ( !isObject( this.registry[type][id] ) ) {
			this.registry[type][id] = {}
		}

		Object.assign(this.registry[type][id], specs)
	}

/*
	isUnique(type, id) {
		if ( !this.registry[type][id] ) { throw `ThingManager.isUnique: can't find thing in registry (type = ${type}, id = ${id})` }

		return this.register[type][id].unique
	}
*/
	get(type, id, overrideSpecs) {
		if ( !this.registry[type][id] ) { throw `ThingManager.get: can't find thing in registry (type = ${type}, id = ${id})` }

		let copy = Object.assign({}, this.registry[type][id])

		if ( isObject(overrideSpecs) ) {
			Object.assign(copy, overrideSpecs)
		}

		return copy
	}

	registerItem(id, specs) {
		return this.register(ThingManager.TYPE_ITEM, id, specs)
	}

	registerNpc(id, specs) {
		return this.register(ThingManager.TYPE_NPC, id, specs)
	}

	registerMonster(id, specs) {
		return this.register(ThingManager.TYPE_MONSTER, id, specs)
	}

	registerPlayer(id, specs) {
		return this.register(ThingManager.TYPE_PLAYER, id, specs)
	}

	registerSpace(id, specs) {
		return this.register(ThingManager.TYPE_SPACE, id, specs)
	}

	registerDoor(id, specs) {
		return this.register(ThingManager.TYPE_DOOR, id, specs)
	}

	registerGeneric(id, specs) {
		return this.register(ThingManager.TYPE_GENERIC, id, specs)
	}

	createPlayer(id, overrideSpecs, space) {
		let player = new Player(id, this.get(ThingManager.TYPE_PLAYER, id, overrideSpecs || {}))

		if ( space ) { player.addToSpace(space) }

		return player
	}

	createMonster(id, overrideSpecs, space) {
		let monster = new Monster(id, this.get(ThingManager.TYPE_MONSTER, id, overrideSpecs || {}))

		if ( space ) { monster.addToSpace(space) }

		return monster
	}

	createNpc(id, overrideSpecs, space) {
		let npc = new Npc(id, this.get(ThingManager.TYPE_NPC, id, overrideSpecs || {}))

		if ( space ) { npc.addToSpace(space) }

		if ( overrideSpecs.items ) {
			if ( isArray(overrideSpecs.items) ) {
				for ( let itemId of overrideSpecs.items ) {
					let item = Game.global.getItem(itemId)

					if ( !item ) {
						item = Game.global.createItem(itemId)
					}

					npc.addItem(item)
				}
			}
		}

		return npc
	}

	createDoor(id, overrideSpecs, space1, space2) {
		let door = new Door(id, this.get(ThingManager.TYPE_DOOR, id, overrideSpecs || {}))

		if ( space1 ) { door.addToSpace(space1) }

		if ( space2 ) { door.addToSpace(space2 ) }

		return door
	}

	createItem(id, overrideSpecs, space) {
		let item = new Item(id, this.get(ThingManager.TYPE_ITEM, id, overrideSpecs || {}))

		if ( space ) { item.addToSpace(space) }

		return item
	}

	createSpace(id, overrideSpecs) {
		return new Space(id, this.get(ThingManager.TYPE_SPACE, id, overrideSpecs || {}))
	}

	createGeneric(id, overrideSpecs) {
		return new Generic(id, this.get(ThingManager.TYPE_GENERIC, id, overrideSpecs || {}))
	}

	getSpaceIdsLinkedWithDoor(doorId) {
		let doorSpecs = this.get('doors', doorId)

		return [...doorSpecs.spaces]
	}

	getSpaceIdsLinkedWithItem(itemId) {
		let itemSpecs = this.get('items', itemId)

		return [itemSpecs.space]
	}
}
