import { sleep, log, warn, error, debug } from 'global.js'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const TYPES = ["show-view", "hide-view"]

EVENT_TYPES.push(...TYPES)

export class ShowViewEvent extends Event {
	static eventType = "show-view"
	static requiredKeys = ["event", "view", "name"]
	static optionalKeys = []

	constructor(view, name) {
		super ({
			view: view,
			name: name,
		})
	}
}

export class HideViewEvent extends Event {
	static eventType = "hide-view"
	static requiredKeys = ["event", "view", "name"]
	static optionalKeys = ["choices"]

	constructor(view, name, choices) {
		super ({
			view: view,
			name: name,
			choices: choices,
		})
	}
}
