/**
 * The main class
 *
 */

import { log, debug, warn, replaceVars } from 'global.js'
import { Game } from 'Game/Game.mjs'
import { UI } from 'UI/UI.mjs'

export class App extends HTMLElement {

	static templateString

	/**
	 * Main app constructor sets some app defaults and starts the first scene
	 *
	 * @constructor
	 */
	constructor() {
		super()
		this.appName = "Wondrous game"

		this.attachShadow({mode: 'open'})

		console.log("App.constructor() done")
	}

	/**
	 * Start the app by launching the main menu
	 *
	 * @param { string } mode start|load
	 * @param { string } environment production|develop
	 * @param { object } gameBundle object with all the data
	 * @param { settings } settings object
	 * @param { function } loadGameCAllback
	 * @param { function } saveGameCAllback
	 * @param { function } settingsCallback to be called for settings view
 	 * @param { function } exitCallback to be called by App when it exits
	 * @return { Promise<function> } killApplication to be called by Client if it wants to kill the App
	 */
	async startGame(mode, environment, gameBundle, settings, loadGameCallback, saveGameCallback, settingsCallback, exitCallback) {
		if ( !mode || (mode !== "start" && mode !== "load") ) { throw `App.startGame() attribute 'mode' is not 'start' or 'load'`}
		if ( !environment || (environment !== "production" && environment !== "development") ) { throw `App.startGame() attribute 'environment' is not 'production', 'development'`}

		if ( !gameBundle || typeof gameBundle !== "object" ) { throw `App.startGame() attribute 'gameBundle' not an object` }

		if ( !gameBundle.specs ) { throw "Global variable gameBundle.specs isn't defined (usually defined by game_specs.js)" }
		if ( !gameBundle.assets ) { throw "Global variable gameBundle.assets isn't defined (usually defined by game_assets.js)" }
		if ( !gameBundle.script ) { throw "Global variable gameBundle.script isn't defined (usually defined by game_script.js)" }
		if ( !gameBundle.missions ) { throw "Global variable gameBundle.missions isn't defined (usually defined by game_missions.js)" }
		if ( !gameBundle.contents ) { throw "Global variable gameBundle.contents isn't defined (usually defined by game_contents.js)" }
		if ( !gameBundle.translations ) { throw "Global variable gameBundle.translations isn't defined (usually defined by game_translations.js)" }
		if ( !gameBundle.things ) { throw "Global variable gameBundle.things isn't defined (usually defined by game_boards.js)" }
		if ( !gameBundle.boards ) { throw "Global variable gameBundle.boards isn't defined (usually defined by game_boards.js)" }

		log("App launching game ...")

		this.innerHTML = ''
		this.shadowRoot.innerHTML = ''

		const vars = {"SETTINGS_QUALITY": settings.quality }

		if ( App.templateString ) {
			let tmp = document.createElement('div')
			tmp.innerHTML = replaceVars(App.templateString, vars)

			this.shadowRoot.append(...tmp.childNodes)
		}

		if ( gameBundle.javascript ) {
			let tmp = document.createElement('script')
			tmp.innerHTML = gameBundle.javascript

			this.shadowRoot.appendChild(tmp)
		}

		if ( gameBundle.fonts ) {
			let tmp = document.createElement('style')
			tmp.innerHTML = gameBundle.fonts
			tmp.classList.add('game-fonts')

			this.appendChild(tmp)
		}

		if ( gameBundle.styles) {
			let styles = replaceVars(gameBundle.styles, vars)

			let tmp = document.createElement('style')
			tmp.innerHTML = replaceVars(styles, vars)
			tmp.classList.add('game-styles')

			this.shadowRoot.appendChild(tmp)
		}

		if ( gameBundle.templates) {
			let tmp = document.createElement('div')
			tmp.innerHTML = replaceVars(gameBundle.templates, vars)

			this.shadowRoot.append(...tmp.childNodes)
		}

		Game.environment = environment
		this.game = new Game(this, settings, gameBundle.specs, gameBundle.assets, gameBundle.script, gameBundle.missions, gameBundle.contents, gameBundle.translations, gameBundle.things, gameBundle.boards, gameBundle.styles)

		GAME_API = this.game

		this.settingsCallback = settingsCallback
		this.loadGameCallback = loadGameCallback
		this.saveGameCallback = saveGameCallback
		this.exitCallback = exitCallback

		// Game content and UI in the shadow dom - see class UI
		this.ui = new UI(this.shadowRoot, gameBundle.styles)

		if ( mode == "start" ) {
			this.game.startGame()
		}
		else if ( mode == "load" ) {
			let loadData = this.loadGameCallback()

			alert("I don't know how to load games yet")
			throw "I don't know how to load games yet"
		}
		else {
			throw "Unknown game mode"
		}

		return this.exitApp
	}

	exitApp() {
		log("App closing game ...")

		try {
			this.shadowRoot.innerHTML = ''
			this.innerHTML = ''

			delete this.game

			UI.global = undefined
			Game.global = undefined

			log(`App exited OK`)

			if ( this.exitCallback ) {
				this.exitCallback(window.frameElement)
			}
			
		} catch ( err ) {
			throw `App exited with error: ${err}`
		}
	}

	/**
	 * Returns the application name
	 * @return string name of the application
	 */
	get applicationName() {
		return this.appName
	}

	/**
	* Load game from a .mjs file using import()
	*
	* Expect default export to be GAME_BUNDLE
	*
	* Always call with await:
	*
	* @param {string} url to game.mjs
	* @returns GAME_BUNDLE
	*/

	async loadGameWithImport(url) {

		log(`Loading game from url with import(): ${ url }`)

		try {
			// import only allows static strings

			let tmp = await import(url);
			let gameBundle = tmp.default

			if ( !gameBundle ) {
		  		throw `App.loadGameWithImport() import() default export not found with script form url: ${ url }`
			}

			// TODO sanity check for bundle contents (from Game)

			return gameBundle
		} catch (err) {
			throw `App.loadGameWithImport(): ${ err }`
		}
	}

	/**
	* Load game from a .js file using <script src="">
	*
	* Expect script to decalre GAME_BUNDLE variable.
	*
	* Always call with await:
	*
	* let bundle = app.loadGameWithImport(gameUrl)
	* app.startGame(bundle)
	*
	* @param {string} url to game.js
	* @returns Promise GAME_BUNDLE
	*/
	loadGameWithScript(url) {

		log(`Loading game from url with script: ${ url }`)

		return new Promise(async (resolve, reject) => {
			try {
				let scriptElement = document.createElement('script')

				this.shadowRoot.append(scriptElement)

				scriptElement.onload = function() {

					// @ts-ignore : GAME_BUNDLE is declared within the scriptElement
					if ( !GAME_BUNDLE ) {
						throw `App.loadGameWithScript() variable 'GAME_BUNDLE' not found after script loaded from: ${url}`
					}

					// TODO sanity check for bundle contents (from Game)

					// @ts-ignore : GAME_BUNDLE is declared within the scriptElement
					resolve(GAME_BUNDLE);
				}

				scriptElement.setAttribute('src', url)

			} catch (err) {
				throw `App.loadGameWithScript(): ${err}`
			}
		});
	}
}

import importMainStyles from "css/main.css.mjs"
import importStyleVariables from "css/var.css.mjs"

App.templateString = `

<style>
	${ importStyleVariables}
	${ importMainStyles }
</style>

`;

window.customElements.define('wondrous-app', App)
