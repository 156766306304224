import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Command } from 'Command/Command.mjs'

export const EVENT_TYPES = []

export class Event extends Command {
//	From child class
//	static eventType = "mission-state-change"
//	static requiredKeys = ["event", "mission", "newState", "oldState"]
//	static optionalKeys = []

	constructor(object) {
		super(object)

		if (this.constructor == Event) {
			throw "Event.constructor() tried to create Event object directly – use child classes"
		}

		if ( !isObject(object) ) {
			throw "Event.constructor() tried to create Event without object argument"
		}

		// TODO replace this with a reference to object itself + another eventName? Or add this.eventDate
		this.event = this.constructor.eventType
	}

	getType() {
		return this.constructor.eventType
	}

	async execute() {
		return true
	}
}
