import { sleep, warn, log, error, debug } from 'global.js'
import { isDef, isObject, isString, isBoolean, isArray, objectHas, isDefNotNull } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { Command, Cmd  } from 'Command/Command.mjs'
import { CommandChain, Group } from 'Command/Chain.mjs'
import { EVENT_TYPES } from 'Command/Event.mjs'
import { MissionStartEvent, MissionEndEvent, MissionSuccessEvent, MissionFailEvent, MissionChangeStateEvent } from 'Command/EventsMission.mjs'


/**
 * Example missions specs

const GAME_MISSIONS = {
	"version": 30
	,"test-mission": { ... }
	,"another-mission": { ... }
}

*/

export class MissionManager {
	static version = 35

	constructor(missionSpecs) {
		if ( !missionSpecs ) { throw `MissionManager.constructor: 'missionSpecs' parameter missing`}

		this.missions = this.parseMissions(missionSpecs)
	}

	parseMissions(missionSpecs) {
		if ( missionSpecs.version != MissionManager.version ) { throw `MissionManager.parseMissions(): bad version: ${missionSpecs.version} != ${ MissionManager.version}` }

		if ( Object.keys(missionSpecs).length <= 1 ) { warn `MissionManager.parseMissions(): no missions defined` }

		let missions = {}

		for ( let key in missionSpecs ) {
			if ( key == "version" ) { continue }

			missions[key] = new Mission(key, missionSpecs[key])
		}

		return missions
	}

	getMission(id) {
		if ( !this.missions[id] ) {
			throw `MissionManager.getMission(): no mission found with id '${id}'`
		}

		return this.missions[id]
	}

	/**
	 * List of ongoing missions, they may be paused.
	 * 
	 * Use isActive() to look for non-paused missions
	 */
	getOngoingMissions() {
		let list = []

		for ( let missId in this.missions ) {
			let mission = this.missions[missId]

			if ( mission.isOnGoing() ) {
				list.push(mission)
			}
		}

		return list
	}

	getListOfMissions() {
		return Object.keys(this.missions)
	}
}


const MISSION_TYPES = ['primary', 'secondary', 'hidden']

const MISSION_PROGRESS = ['ready-to-start', 'ongoing', 'paused', 'success', 'fail']

const MISSION_REQUIRED_STATES = [
	"start",
//	"success",
//	"fail"
]

export class Mission {
	currentState = null;
	static stateLoad = 'load'					// optional, start here if this is defined
	static statePaused = 'paused'				// only used internally
	static stateStart = 'start'					// required, start here if 'load' doesn't exist
	static stateSuccess = 'success'				// mission is considered finished
	static stateFail = 'fail'					// mission is considered finished

	static progressDefault = 'ready-to-start'
	static progressOnGoing = 'ongoing'
	static progressSuccess = 'success'
	static progressFail = 'fail'


	constructor(id, specs) {
		this.id = id
		this.name = specs.name

		this.progress = specs.progress || Mission.progressDefault
		this.currentState = specs.currentState || null

		this.paused = specs.paused || false
		this.pausedState = new MissionState(Mission.statePaused, this, {})
		Object.defineProperty(this, 'pausedState', { enumerable: false })

		this.variables = specs.variables || {}

		this.states = specs.states

		for ( let stateId in this.states ) {
			let stateSpecs = this.states[stateId]

			this.states[stateId] = new MissionState(stateId, this, stateSpecs)
		}

		this.events = {}

		if ( specs.events ) {
			for ( let eventType in specs.events ) {

				let eventCommands = specs.events[eventType]

				let commands = new CommandChain(eventCommands)

				this.events[eventType] = commands
			}
		}		

		let eventCallback = function(eventType, event) {

			let commands = this.getEventCommands(eventType, event)

			Game.global.queueCommandsOnTop(commands)
		}

		Object.defineProperty(this, 'eventCallback', { writable: false, enumerable: false, value: eventCallback.bind(this) })

		this.validate()
	}

	validate() {
		if ( !isString(this.id) ) { throw `Mission.validate() missing id or not a string. Got: ${ this.id }` }

		if ( !isString(this.name) ) { throw `Mission.validate() missing name or not a string. Got: ${ this.name }` }

		if ( !isString(this.progress) ) { throw `Mission.validate() missing progress or not a string. Got: ${ this.progress }` }

		if ( isDefNotNull(this.currentState) && !isString(this.currentState) ) { throw `Mission.validate() current state exists but not a string. Got: ${ this.currentState }` }

		if ( isDef(this.variables) && !isObject(this.variables) ) { throw `Mission.validate() variables is not an object. Got: ${ this.variables }` }

		let myStates = Object.keys(this.states)

		for ( let state of MISSION_REQUIRED_STATES ) {
			if ( !myStates.includes(state) ) {
				throw `Mission.validate() missing required state: '${state}'\n\nRequired: ${ MISSION_REQUIRED_STATES.join(', ') }\n\nGot: ${ myStates.join(', ') }`
			}
		}
	}


	/**
	 * Start missiong by changing state to "start"
	 *
	 * Returns a CommmandChain, which includes (in order):
	 * – any commands assigned to the new state
	 * – event MissionStartEvent
	 *
	 * Caller is responsible for adding the chain
	 * to top of the Processor
	 *
	 * @return CommandChain
	 */
 	startMission() {
		if ( this.isStarted() ) {
			warn `Mission.startMission() tried to start mission which is alrady running - ignoring this.`
			return null
		}

		this.currentState = this.hasState(Mission.stateLoad) ? Mission.stateLoad : Mission.stateStart

		this.registerListeners()

		let startEvent = new MissionStartEvent(this.id)
		let combinedList = this.getCurrentState().getCommands()

		combinedList.addCommand(startEvent)

		let chain = new CommandChain(combinedList)

		return chain
	}

	endMissionFail() {
		this.currentState = Mission.stateFail

		let failEvent = new MissionFailEvent(this.id)
		let endEvent = this._endMission()

		return new CommandChain( [ failEvent, endEvent ] )
	}

	endMissionSuccess() {
		this.currentState = Mission.stateSuccess

		let successEvent = new MissionSuccessEvent(this.id)
		let endEvent = this._endMission()

		return new CommandChain( [ successEvent, endEvent ] )
	}

	_endMission() {
		if ( this.state != Mission.stateSuccess || this.state != Mission.stateFail ) {
			throw `Mission._endMission() tried to end mission without calling success/fail.`
		}

		let event = new MissionEndEvent(this.id)

		return event
	}


	/**
	 * Change state of mission
	 *
	 * Returns an CommmandChain, which includes (in order):
	 * – any commands assigned to the new state
	 * – event MissionStateChangeEvent
	 *
	 * Caller is responsible for adding the chain
	 * to top of the Processor
	 *
	 * @param { string } state to change to
	 * @return CommandChain
	 */
	changeState(newState) {
		if ( !isDef(this.states[newState]) ) {
			throw `Mission.changeState() unknown state '${ newState }'\n\nAvailable states: ${ this.getListOfStates().join(', ') }`
		}

		let oldState = this.currentState
		this.currentState = newState

		this.updateProgress(this.currentState)

		this.deregisterListeners(oldState)
		this.registerListeners(newState)

		let event = new MissionChangeStateEvent(this.id, newState, oldState)
		let combinedList = new CommandChain(this.getCurrentState().getCommands())

		combinedList.addCommand(event)

		let chain = new CommandChain(combinedList)

		return chain
	}

	getProgress() {
		return this.progress
	}

	updateProgress(newState) {
		switch (newState) {
			case Mission.stateDefault:
				this.progress = newState
				break

			case Mission.stateSuccess:
				this.progress = Mission.progressSuccess
				break

			case Mission.stateFail:
				this.progress = Mission.progressFail
				break

			default:
				this.progress = Mission.progressOnGoing
		}
	}

	isStarted() {
		return this.progress == Mission.progressOnGoing
			|| this.progress == Mission.progressFail
			|| this.progress == Mission.progressSuccess
	}

	isNotStarted() {
		return this.progress == Mission.progressDefault
	}


	isFinished() {
		return this.progress == Mission.progressFail
			|| this.progress == Mission.progressSuccess
	}

	isOnGoing() {
		return this.progress == Mission.progressOnGoing
	}

	isPaused() {
		return this.paused
	}

	pauseMission() {
		this.paused = true
	}

	unpauseMission() {
		this.paused = false
	}

	isActive() {
		return !this.isPaused() && this.isOnGoing()
	}

	/**
	 * Get current MissionState
	 *
	 * Default state is null, if it's not started.
	 *
	 * If Mission is paused, Mission.statePaused is returned.
	 *
	 * During pause mission.currentState points of actual state,
	 * so you should use mission.getCurrentState() to check
	 * for the pause condition.
	 *
	 * @return MissionState
	 */
	getCurrentState() {
		if ( this.isPaused() ) {
			return this.pausedState
		}

		if ( this.currentState ) {
			return this.states[this.currentState]
		}

		return null
	}

	getState(stateName) {
		return this.states[stateName]
	}

	/**
	 * List events from this Mission and current MissionState
	 * 
	 * @param {string} stateName (optional)
	 * @return array of eventTypes
	 */
	getListOfEventTypes(stateName) {
		let state = stateName ? this.getState(stateName) : this.getCurrentState()

		let missionEvents = Object.keys(this.events)
		let stateEvents = Object.keys(state.events)

		return missionEvents.concat( stateEvents )
	}

	/**
	 * List events commands from this Mission and current MissionState
	 *
	 * @param { string } eventType
	 * @param { Event } event object
	 * @return CommandChain
	 */
	getEventCommands(eventType, event) {
		let newChain = new CommandChain()

		if ( this.events[eventType] ) {

			for ( let cmd of this.events[eventType] ) {

				// Might need to make a copy of each Cmd? :-/
				// As this modifies the stored Cmd inside the MissionState.
				// Next time it'll get overriden, but ..
				cmd.setEvent(event)

				newChain.addCommand(cmd)
			}

		}

		if ( this.getCurrentState().events[eventType] ) {

			for ( let cmd of this.getCurrentState().events[eventType] ) {

				// Might need to make a copy of each Cmd? :-/
				// As this modifies the stored Cmd inside the MissionState.
				// Next time it'll get overriden, but ..
				cmd.setEvent(event)

				newChain.addCommand(cmd)
			}

		}

		return newChain
	}


	registerListeners(state) {
		let eventTypes = this.getListOfEventTypes(state)

		for ( let eventType of eventTypes ) {

			Game.global.registerEventListener(eventType, this.eventCallback)
		}
	}

	deregisterListeners(state) {
		let eventTypes = this.getListOfEventTypes(state)

		for ( let eventType of eventTypes ) {

			Game.global.deleteEventListener(eventType, this.eventCallback)
		}
	}

	/**
	 * List of state names defined for this mission.
	 *
	 * @return Array of state names
	 */
 	getListOfStates() {
		return Object.keys(this.states)
	}

	hasState(state) {
		return isDef( this.states[state] )
	}

	/**
	 * Get a variable to value.
	 *
	 * Note: variables have to exist in the origianl Mission definition
	 * before they can se get/set in class.
	 *
	 * @param { string } name of variable
	 * @return value
	 */
	getVariable(name) {
		if ( !isDef(this.variables) ) {
			throw `Mission.getVariable() variable '${ name }' is not defined for this mission.\n\nAvailable variables: (none)`
		}

		if ( !isDef(this.variables[name]) ) {
			throw `Mission.getVariable() variable '${ name }' is not defined for this mission.\n\nAvailable variables: ${ Object.keys( this.variables ).join(', ')}`
		}

		return this.variables[name]
	}


	/**
	 * Set a variable to value.
	 *
	 * Note: variables have to exist in the origianl Mission definition
	 * before they can se get/set in class.
	 *
	 * @param { string } name of variable
	 * @param { string } value of variable
	 * @return value
	 */
	setVariable(name, value) {
		if ( !isDef(this.variables) ) {
			throw `Mission.setVariable() variable '${ name }' is not defined for this mission.\n\nAvailable variables: (none)`
		}

		if ( !isDef(this.variables[name]) ) {
			throw `Mission.setVariable() variable '${ name }' is not defined for this mission.\n\nAvailable variables: ${ Object.keys( this.variables ).join(', ')}`
		}

		this.variables[name] = value

		return value
	}

	/**
	 * @param { Character } character
	 * @return boolean
	 */
	hasAvailableDialogueFor(character) {
		return this.getCurrentState().hasAvailableDialogueFor(character.id)
	}

	/**
	 * @param { Character } character
	 * @return { array } List of Dialogue objects
	 */
	getAvailableDialoguesFor(character) {
		return this.getCurrentState().getAvailableDialoguesFor(character.id)
	}
}



export class MissionState {
	constructor(id, mission, specs) {

		this.id = id
		this.mission = mission
		this.commands = new CommandChain(specs.cmds)
		this.events = {}

		if ( specs.events ) {
			for ( let eventType in specs.events ) {

				let eventCommands = specs.events[eventType]

				let commands = new CommandChain(eventCommands)

				this.events[eventType] = commands
			}
		}

		this.dialogues = {}
		for ( let charId in specs.dialogues ) {
			let charDialogues = []

			for ( let id of specs.dialogues[charId] ) {
				 charDialogues.push( Game.global.getDialogue(id) )
			}

			this.dialogues[charId] = charDialogues
		}

		this.validate()
	}

	getCommands() {
		return this.commands
	}


	getDialogues() {
		let list = []

		for ( let charId in this.dialogues ) {
			let charDialogues = []

			for ( let id of this.dialogues[charId] ) {
				 charDialogues.push( Game.global.getDialogue(id) )
			}

			list.push(...charDialogues)
		}

		return list
	}

	getAllDialoguesFor(charId) {
		if ( !isString(charId) ) {
			throw `MissionState.getAllDialoguesFor() argument 'charId' is not a string`
		}

		if ( !isDef(this.dialogues[charId]) ) {
			return []
		}

		let list = []

		for ( let id of this.dialogues[charId] ) {
			let dialogue = Game.global.getDialogue(id)

			list.push( dialogue )
		}

		return list
	}

	getAvailableDialoguesFor(charId) {

		if ( !isString(charId) ) {
			throw `MissionState.getAvailableDialoguesFor() argument 'charId' is not a string`
		}

		if ( !isDef(this.dialogues[charId]) ) {
			return []
		}

		let list = []

		for ( let dialogue of this.dialogues[charId] ) {
			if ( dialogue.isAvailable() ) {

				list.push( dialogue )
			}
		}


		return list
	}

	hasAvailableDialogueFor(charId) {

		if ( !isString(charId) ) {
			throw `MissionState.hasDialogueFor() argument 'charId' is not a string`
		}

		if ( !isDef(this.dialogues[charId]) ) {
			return false
		}

		for ( let dialogue of this.dialogues[charId] ) {
			if ( dialogue.isAvailable() ) {

				return true
			}
		}

		return false
	}

	validate() {
		if ( !isString(this.id) ) {
			throw `MissionState.validate() missing id or not a string. Got: ${ this.id }`
		}

		for ( let cmd of this.commands ) {
			if ( !(cmd instanceof Command) && !(cmd instanceof CommandChain) ) {
				warn `MissionState.validate(): mission '${ this.id }' has an item in commands that is not from Command or CommandChain class. Got: ${ cmd }`
			}
		}

		for ( let type in this.events ) {
			if ( !EVENT_TYPES.includes(type) ) {
				throw `MissionState.validate(): mission '${ this.id }' has unknown event type: ${ type }\n\nAllowed types:\n${ EVENT_TYPES.join(', ') }`
			}
		}
	}
}
