import { warn } from "global.js";
import { isFunction } from "validators.mjs";

/**
 * Canvas object that manipulates a <canvas> tag on the page
 *
 * Note: Safari doesn't support extending native elements yet, so
 * we can't make this class extend actual canvas element for now.
 * (15.1.2021)
 */
export class Canvas {
	/**
	 * Canvas class wraps the HTML DOM canvas element.
	 *
	 * Use setup() before using drawImage, show()
	 *
	 * @param {Element} canvas canvas dom element to use
	 */
 	constructor(board, canvasElement) {
 		this.board = board
 		this.canvasElement = canvasElement
 		this.canvasElementModified = false
 		this.firedEvents = {
 			mouseover: {},
 			click: {}
 		}
 		this.dragging = {
 			startTop: this.canvasElement.scrollTop,
 			startLeft: this.canvasElement.scrollLeft,
 			startX: null,
 			startY: null,
 			mouseX: null,
 			mouseY: null,
 			ignoreNextClickEvent: false,
 			ignoreCheckDelta: 8,
 		}

		 const scrollMove = (event) => {
 			if ( !this.board.allowInteractions ) { return }

			let {x, y, screenX, screenY} = this.getEventLocation(event)

			let [cx, cy] = this.board.scaleToCanvas(x, y)

			// Drag canvas if in dragging mode
			//
			if ( this.dragging.now ) {
				this.updateDragging(screenX, screenY)

				return;
			}

			// Highlight element if over them
			// no need to check for touch gestures as they don't allow 'hover' (?)
			let drawable = this.board.getElementAt(cx, cy, "all")

			if ( drawable ) {
				if ( this.firedEvents.mouseover.id == drawable.id ) {
					// don't repeat again
					return
				}

				// this is some other element, so we can now remove old one
				if ( this.firedEvents.mouseover.callback ) {
					this.firedEvents.mouseover.callback()

					this.firedEvents.mouseover = {}
				}

				// this is new event on new element
				if ( drawable.onMouseOver ) {
					this.firedEvents.mouseover = {
						id: drawable.id,
						callback: drawable.onMouseOver(x, y)
					}
				}
			} else {
				// no element here, so remove all old ones
				if ( isFunction(this.firedEvents.mouseover.callback) ) {
					this.firedEvents.mouseover.callback()
				}

				this.firedEvents.mouseover = {}
			}
 		};

		const handleStartScroll = (event) => {
			if ( !this.board.allowInteractions ) { return }

			let {x, y, screenX, screenY} = this.getEventLocation(event)

			let [cx, cy] = this.board.scaleToCanvas(x, y)

			// Click on element if here
			let drawable = this.board.getElementAt(cx, cy, "interactive")

			if ( drawable ) {
				// do not start dragging on top of elements

				return;
			}

			this.startDragging(screenX, screenY)
		}

		const handleEndScroll = (event) => {
			if (this.dragging.now) {
				this.stopDragging()
			}
		}

		const handleClick = (event) => {
			if ( !this.board.allowInteractions ) { return }

			let {x, y} = this.getEventLocation(event)

			if ( this.dragging.ignoreNextClickEvent ) {
				console.log("click ignored")
				this.dragging.ignoreNextClickEvent = false
				return;
			}

			let [cx, cy] = this.board.scaleToCanvas(x, y)

			let drawable = this.board.getElementAt(cx, cy, "interactive")

			if ( drawable ) {
				if ( drawable.onClick ) {
					let callback = drawable.onClick(x, y)

					// use callback if needed? for events? (on click, after click ..)
				}

				return;
			}

		};

		this.canvasElement.addEventListener('mousedown',handleStartScroll);
 		this.canvasElement.addEventListener('mouseup',handleEndScroll);
		this.canvasElement.addEventListener('mouseleave',handleEndScroll);
		this.canvasElement.addEventListener('mousemove', scrollMove);
		this.canvasElement.addEventListener('click', handleClick);
	}


	setup(referenceWidth, referenceHeight) {
		if ( !this.board.boardWidth || ! this.board.boardHeight) {
			throw `Canvas.setup: no board width x height set`
		}

		let ctx = this.canvasElement.getContext('2d')

		this.canvasElement.width = this.board.boardWidth
		this.canvasElement.height = this.board.boardHeight

		this.resizeVisibleElement()

		this.ctx = ctx
	}

	getEventLocation(event) {
		const rect = this.canvasElement.getBoundingClientRect()
		 switch(event.type) {
			 case 'mousemove':
			 case 'mousedown':
			 case 'click':
				 return { x: event.clientX - rect.left, y: event.clientY - rect.top, screenX: event.clientX, screenY: event.clientY }
			 default:
				 warn('Unsupported event type', event.type);
		 }
	}

	resizeVisibleElement() {
		let setWidth = `${ Math.round(this.canvasElement.width * this.board.canvasScale) }px`
		let setHeight = `${ Math.round(this.canvasElement.height * this.board.canvasScale) }px`

		this.canvasElement.style.width = setWidth
		this.canvasElement.style.height = setHeight
		this.canvasElementModified = true
	}

	/**
	 * @param {integer} [lx] left top x
	 * @param {integer} [ly] left top y
	 * @param {integer} [rx] right bottom x
	 * @param {integer} [ry] right bottom y
	 */
	clear(lx, ly, rx, ry) {
		if ( this.ctx != null ) {

			if ( lx !== undefined && ly !== undefined && rx !== undefined && ry !== undefined ) {
				this.ctx.clearRect(lx, ly, rx-lx, ry-ly)
			} else {
				this.ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height)
			}
		}

		this.canvasElementModified = true
	}

	needsRedraw() {
		this.canvasElementModified = true
	}

	/**
	 * @param {CanvasImageSource} image
	 * @param {integer} x
	 * @param {integer} y
	 * @param {integer} width (optional)
	 * @param {integer} height (optional)
	 * @param {decimal} opacity (optional)
	 */
	drawImage(image, specs) {
		let { cx, cy, width, height, opacity } = specs

		if ( !image ) { throw `Canvas.drawImage: didn't get image as parameter` }
		if ( cx == null ) { throw `Canvas.drawImage: didn't get x as parameter` }
		if ( cy == null ) { throw `Canvas.drawImage: didn't get y as parameter` }

		if ( opacity != null && opacity >= 0 && opacity <= 1 ) { this.ctx.globalAlpha = opacity }


		let leftX = Math.round(cx - width/2.0)
		let leftY = Math.round(cy - height/2.0)

		//context.drawImage(imageObj, 0, 0, 100, 100 * imageObj.height / imageObj.width)
		if ( width && height ) {
			this.ctx.drawImage(image, leftX, leftY, width, height)
		} else {
			this.ctx.drawImage(image, leftX, leftY)
		}


		if ( opacity != null ) { this.ctx.globalAlpha = 1 }

		this.canvasElementModified = true
	}

	show() {
		this.canvasElement.classList.add('visible')
	}

	hide() {
		this.canvasElement.classList.remove('visible')
	}


	startDragging(mouseX, mouseY) {
		this.dragging.now = true
		this.dragging.startTop = this.board.view.scrollTop
		this.dragging.startLeft = this.board.view.scrollLeft
		this.dragging.startX = mouseX
		this.dragging.startY = mouseY
		this.dragging.mouseX = mouseX
		this.dragging.mouseY = mouseY
		this.board.setPointerDragging()
	}

	updateDragging(mouseX, mouseY) {
		this.dragging.mouseX = mouseX
		this.dragging.mouseY = mouseY

		let dx = this.dragging.startX - this.dragging.mouseX
		let dy = this.dragging.startY - this.dragging.mouseY

		if ( !this.dragging.ignoreNextClickEvent && (Math.abs(dx) > this.dragging.ignoreCheckDelta || Math.abs(dy) > this.dragging.ignoreCheckDelta)  ) {
			this.dragging.ignoreNextClickEvent = true
		}

		this.board.view.scrollTop = this.dragging.startTop + dy
		this.board.view.scrollLeft = this.dragging.startLeft + dx
	}

	stopDragging() {
		this.dragging.now = false
		this.dragging.startTop = null
		this.dragging.startLeft = null
		this.dragging.startX = null
		this.dragging.startY = null
		this.dragging.mouseX = null
		this.dragging.mouseY = null
		this.board.setPointerDefault()
	}
}
