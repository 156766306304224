import { sleep, log, warn, error } from 'global.js'

import { isDef, isArray, isString, objectHas } from 'validators.mjs'

import { createCommand } from 'Command/create.mjs'

import { Interaction } from 'Thing/Interaction.mjs'
import { Stat } from 'Thing/Stat.mjs'

import { T } from 'Game/Translations.mjs'

export class Item extends Interaction {
	constructor(id, specs) {
		super(id, specs)

		this.condition = specs.condition != null ? new Stat(specs.condition) : new Stat(1)
		this.description = specs.description ? T(specs.description) : ""
	}

	isBroken() {
		return this.condition != null ? this.condition.get() < 1 : false
	}

	getDescription() {
		return this.getProp('description')
	}

	addToCharacter(character, stopPropagate) {
		this.owner = character

		if ( !stopPropagate ) {
			character.addItem(this, true)
		}

		return this
	}

	removeFromCharacter(stopPropagate) {
		if ( !stopPropagate && this.owner ) {
			this.owner.removeItem(this, true)
		}

		this.owner = null

		return this
	}

	async moveToCharacter(character, stopPropagate) {
		if ( stopPropagate ) { return this }

		if ( this.owner ) {
			this.removeFromCharacter(this.owner)
		}

		if ( this.isInSpace() ) {
			await this.removeFromAllSpaces()
		}

		this.addToCharacter(character)

		return this
	}	
}
