import { log, debug, warn, error } from 'global.js'
import { isDef, isArray } from 'validators.mjs'


import { Game } from 'Game/Game.mjs'
import { Space } from 'Thing/Space.mjs'
import { Door } from 'Thing/Door.mjs'
import { Item } from 'Thing/Item.mjs'
import { Character, Npc, Monster, Player } from 'Thing/Characters.mjs'
import { ThingManager } from 'Thing/ThingManager.mjs'

export class State {
	/**
	 * State can be saved with exportState() method and loaded
	 * with constructor or importState()
	 *
	 * @param {ThingManager} thingManager
	 * @param {object} state (optional) initialize the state with this
	 */
	constructor(state) {
		this.thingManager = Game.global.thingManager

		if ( state ) {
			this.importState(state)
		} else {
			this.resetState()
		}
	}

	resetState() {
		this.state = {
			things: {},
			generics: {},
		}

		this.duplicateCounter = {}

		this.notify = {
			blocking: {},
			nonblocking: {}
		}

		this.listeners = 0
	}

	exportState() {
		warn("Exporting state ... THIS HAS NOT BEEN TESTED")

		return JSON.stringify(this.state)
	}

	importState(newStateJson) {
		warn("Importing state ... THIS HAS NOT BEEN TESTED")

		// this won't work because all objects in State are class instances
		// so we should rebuild the state with them ..

		// option 1: rewrite classes so instead of "this.myMember" they use
		//           this.state.myMember, where 'state' references their own
		//           object within State.state. This would make classes
		//           stateless within their own code
		this.state = JSON.parse(newState)
	}

	async listenStateChange(key, name, blocking, callback) {
		if ( !key || !name || !blocking || !callback ) {
			throw `State.listenStateChange: method called without all arguments: ${key} – ${name} – ${blocking} – ${callback}`
		}

		if ( blocking ) {
			this.notify.blocking[key][name] = callback
		} else {
			this.notify.nonblocking[key][name] = callback
		}

		this.listeners++
	}

	async unlistenStateChange(key, name) {
		if ( !key || !name ) {
			throw `State.unlistenStateChange: method called without all arguments: ${key} – ${name}`
		}

		if ( this.notify.blocking[key] && this.notify.blocking[key][name] ) {
			delete this.notify.blocking[key][name]
			this.listeners--
		}

		if ( this.notify.nonblocking[key] && this.notify.nonblocking[key][name] ) {
			delete this.notify.nonblocking[key][name]
			this.listeners--
		}
	}

	async notifyStateChange(key, update) {
		if ( !this.listeners ) { return }

		debug(`State.notifyStateChange: state updated, notifying listeners (${this.listeners})`)

		for ( let key of this.notify.blocking ) {
			for ( let name of this.notify.blocking[key] ) {
				try {
					this.notify.blocking[key][name](update)

				} catch ( err ) {
					error(`State.notifyStateChange: failed for callback: ${key} – ${name}`)
					error(err)
				}
			}
		}

		for ( let key of this.notify.nonblocking ) {
			for ( let name of this.notify.nonblocking[key] ) {
				try {
					await this.notify.nonblocking[key][name](update)

				} catch ( err ) {
					error(`State.notifyStateChange: failed for callback: ${key} - ${name}`)
					error(err)
				}
			}
		}
	}

	createSpace(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_SPACE, id, overrideSpecs)
	}

	createCharacter(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_CHARACTER, id, overrideSpecs)
	}

	createNpc(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_CHARACTER, id, overrideSpecs)
	}

	createMonster(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_CHARACTER, id, overrideSpecs)
	}

	createDoor(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_DOOR, id, overrideSpecs)
	}

	createGeneric(id, specs) {
		let generic = new Generic(id, specs)

		if ( this.state.generics[id] ) {
			error(`State.createGeneric() generic with id '${id}' exists`)
		}

		this.state.generics[id] = generic

		return generic
	}

	getGeneric(id) {
		return this.state.generics[id]
	}

	deleteGeneric(id) {
		if ( this.state.generics[id] ) {
			delete this.state.generics[id]
			return true
		} else {
			return false
		}
	}

	/**
	 * Create Thing and add to state
	 * 
	 * Type: TYPE_SPACE, TYPE_DOOR, ...
	 * 
	 * @param {string} type
	 * @param {string} id
	 * @param {object} specs additional specs to add/override
	 */
	createThing(type, id, specs) {

 		let thing, generic
 		let tempId = id
 		let tempSpecs = specs

 		if ( this.state.things[id] ) {
 			warn `State.createThing() thing with id '${id} exists in state - creating duplicate (this is untested)`

/*
			if ( this.thingManager.isUnique(id) ) {
				throw `State.createThing() can't duplicate unique character`
			}
*/
			tempId = this.generateDuplicateId(id)
			tempSpecs = Object.assign({ "duplicateOf": id }, specs)
		}

 		switch (type) {
 			case ThingManager.TYPE_SPACE:
 				thing = this.thingManager.createSpace(tempId, tempSpecs)
 				break;

 			case ThingManager.TYPE_DOOR:
 				thing = this.thingManager.createDoor(tempId, tempSpecs)
 				break;
 				
 			case ThingManager.TYPE_ITEM:
 				thing = this.thingManager.createItem(tempId, tempSpecs)
 				break;
 				
 			case ThingManager.TYPE_PLAYER:
 				thing = this.thingManager.createPlayer(tempId, tempSpecs)
 				break;
 				
 			case ThingManager.TYPE_NPC:
 				thing = this.thingManager.createNpc(tempId, tempSpecs)
 				break;

 			case ThingManager.TYPE_MONSTER:
 				thing = this.thingManager.createMonster(tempId, tempSpecs)
 				break;

 			case ThingManager.TYPE_GENERIC:
 				generic = this.thingManager.createGeneric(tempId, tempSpecs)
 				break;

 			default:
 				throw `State.createThing() unknown type: '${type}' for id '${id}'`
 		} 

 		if ( thing ) {
			this.state.things[tempId] = thing
		} else if ( generic ) {
			this.state.generics[tempId] = generic
		}

		return thing
	}

	/**
	 * Get Thing from State
	 * 
	 * @throws NotFound
	 */
	getThing(id) {
		if ( !this.state.things[id] ) {
			throw `State.getThing() thing '${id}' not found.`
		}

		return this.state.things[id] 
	}

	/**
	 * Delete Thing from State
	 * 
	 * @throws NotFound
	 */
	deleteThing(id) {
		let thing = this.getThing(id)

		delete this.state.things[id]

		for ( let type in this.state ) {
			if ( this.state[type][id] ) {
				delete this.state[type][id]
			}
		}
	}


	/**
	 * Get all Things
	 *
	 * @return {[Space]} list of spaces
	 */
	getThings() {
		return Object.values(this.state.things)
	}

	getListOfThings() {
		return Object.keys(this.state.things)
	}

	getThingCount() {
		return this.getThings().length
	}

	/**
	 * Get all Spaces
	 *
	 * @return {[Space]} list of spaces
	 */
	getSpaces() {
		return Object.values(this.state.things).filter( x => x instanceof Space )
	}

	/**
	 * Get list of all Space ids
	 *
	 * @return [ string ] list of space names
	 */
	getListOfSpaces() {
		return this.getSpaces().map( x => x.id )
	}

	getSpaceCount() {
		return this.getSpaces().length
	}

	/**
	 * Get all Doors
	 *
	 * @return {[Door]} list of doors
	 */
	getDoors() {
		return Object.values(this.state.things).filter(x => x instanceof Door )
	}

	/**
	 *  Get all Door ids
	 *
	 * @return { [string] } list of door names
	 */
	getListOfDoors() {
		return this.getDoors().map( x => x.id )
	}

	getDoorCount() {
		return this.getDoors().length
	}

	/**
	 * Get all characters, incl. Players, Npcs and Monsters
	 *
	 * @return {Array[Character]} list of all characters
	 */
	getCharacters() {
		return Object.values(this.state.things).filter(x => x instanceof Character )
	}

	getListOfCharacters() {
		return this.getCharacters().map( x => x.id )
	}

	getCharacterCount() {
		return this.getCharacters().length
	}

	getCharacter(charId) {
		let characters = Object.values(this.state.things).filter(x => x instanceof Character && x.id === charId )

		if ( isArray(characters) && characters.length > 0 ) {
			return characters[0]
		}

		return null
	}


	createPlayer(id, overrideSpecs) {
		return this.createThing(ThingManager.TYPE_PLAYER, id, overrideSpecs)
	}

	/**
	 * Get player as Player objects
	 *
	 * @param {string} playerId
	 * @return {Player}
	 */
	getPlayer(playerId) {
		let player = this.getThing(playerId)

		return player
	}

	/**
	 * Delete Player from State
	 *
	 * @throws NotFound
	 */
	deletePlayer(playerId) {
		return this.deleteThing(playerId)
	}

	/**
	 * Get all players as Player objects
	 *
	 * @return {Array[Player]} list of player characters
	 */
	getPlayers() {
		return Object.values(this.state.things).filter(x => x instanceof Player )
	}

	/**
	 * Get list of player ids
	 * 
	 * @return {array[string]} list of player ids
	 */
	getListOfPlayerIds() {
		return this.getPlayers().map( x => x.id )
	}

	/**
	 * Get number of players in State
	 * 
	 * @return {number}
	 */
	getPlayerCount() {
		return this.getPlayers().length
	}

	/**
	 * Get all monsters.
	 *
	 * @return {[Monster]} list of monster characters
	 */
	getMonsters() {
		return Object.values(this.state.things).filter(x => x instanceof Monster )
	}

	/**
	 * Get list of all Monster ids.
	 *
	 * @return { [ string ] } list of Monster character ids.
	 */
	getListOfMonsters() {
		return this.getMonsters().map( x => x.id )
	}

	getMonsterCount() {
		return this.getMonsters().length
	}

	/**
	 * Get all npcs.
	 *
	 * @return {[Npc]} list of Npc characters
	 */
	getNpcs() {
		return Object.values(this.state.things).filter(x => x instanceof Npc )
	}

	/**
	 * Get list of all npc ids.
	 *
	 * @return { [ string ] } list of Npc character ids.
	 */
	getListOfNpcs() {
		return this.getNpcs().map( x => x.id )
	}

	getNpcCount() {
		return this.getNpcs().length
	}

	/**
	 * Get all items
	 *
	 * @return {[Item]} list of items
	 */
	getItems() {
		return Object.values(this.state.things).filter(x => x instanceof Item )
	}

	/**
	 * Get list of all item names
	 *
	 * @return {[string]} list of item names
	 */
	getListOfItems() {
		return this.getItems().map( x => x.id )
	}

	getItemCount() {
		return this.getItems().length
	}

	/**
	 * Moves the thing (Item, Character, ...) into the space.
	 *
	 * If the item was previously on a Character or another Space,
	 * it will be removed from there.
	 *
	 *
	 * @param {Thing} thing
	 * @param {Space} space
	 * @return {this}
	 */
	moveThingToSpace(thing, space) {

		thing.removeFromAllSpaces()
		thing.removeFromCharacter()

		space.addThing(thing)

		return this
	}

	/**
	 * Generate id string based on existing id with a running number.
	 *
	 * E.g. "student" -> "student-2"
	 *
	 * Will check generated id is not used by any current objects, BUT
	 * as the game spec itself can have manually configured id like
	 * "student-3", it may clash later on.
	 *
	 *
	 * @param {string} characterId as base string
	 * @return {string} new id string
	 */

	generateDuplicateId(id) {
		if ( !this.duplicateCounter[id] ) {
			this.duplicateCounter[id] = 1
		}

		return `${id}-${++this.duplicateCounter[id]}`
	}
}
