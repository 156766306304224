/**
 * Intercation view shows action dialog for door
 */

import { debug, log, error, warn } from 'global.js'
import { isObject } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'
import { ViewContent } from 'UI/ViewLayer.mjs'
import { ViewAction } from 'UI/ViewAction.mjs'

import { Door } from 'Thing/Door.mjs'

import { TT } from 'Game/Translations.mjs'

import { CONFIG_DOOR_AFTER_OPENING } from 'Game/Configuration.mjs'

export class ViewDoor extends ViewAction {

	static templateString
	static uiTemplateString

	constructor() {
		super()
	}

	/**
	 * @param {Door} door
	 */
	async show(door) {
		this.door = door
		this.nextSpace = this.door.getHiddenSpace()

		let backgroundAsset = this.nextSpace?.getActionAsset()

		if ( isObject(backgroundAsset) ) {
			this.updateBackdrop(backgroundAsset)
		} else {
			this.updateDefaultBackgroundColor()
		}

		if ( this.door.interactionTemplate ) {

			// Show interaction slides, user may choose to show space (see action() below)

			await super.show(true)

			await this.showSlideshowLayer(false)

			if ( this.door.introTemplate ) {
				await this.slides.startSlideshow( this.door.introTemplate )
			}

			await this.showBackdropLayer(true, 'slow')

			await this.showInteractionLayer(this.door.interactionTemplate, true)

		} else {

			// Show door intro slides in (this) view

			if ( this.door.introTemplate ) {
				await super.show(true)
				await this.slides.startSlideshow( this.door.introTemplate )
				await this.hide()
			}

			// Do not show slides, only reveal space

			await this.openDoorAndShowSpace()
		}
	}

	async showInteractionLayer(uiTemplateId, animate) {
		let content = UI.global.getTranslatedTemplate(uiTemplateId)

		this.ui.querySelector('#description').replaceChildren(content)

		await super.showUiLayer(animate)
	}

	async action(listOfActions, element, event) {

		let feedback = false

		if ( listOfActions ) {
			for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
				switch(action) {
					case "enter-space":
						await this.hide()
						await this.openDoorAndShowSpace()
						feedback = true
						break;
					case "stay-back":
						this.hide()
						feedback = true
						break;
				}
			}
		}

		return feedback
	}

	async openDoorAndShowSpace() {
		this.door.openDoor()

		let space = await Game.global.showSpaceWithContents(this.door.getHiddenSpace().id, true, true)

		let overlayMessage = TT('add-space-and-contents-and-open-doors')

		await Game.global.showDefaultOverlay(overlayMessage, Object.assign({ space: space.name }, Game.global.getGlobalVariables()))

		let configValue = Game.global.getConfig( CONFIG_DOOR_AFTER_OPENING )

		switch( configValue ) {
			case "open":
				this.door.openDoor()
				break;					

			default:
				warn(`ViewDoor.openDoorAndShowSpace() unknown door configuration. Falling back to hiding!`)

			case "hide":
				await Game.global.hideThing(this.door.id, false)
				break;
		}
	}
}

import importAnimatedElementStyles from "css/import-animated-element.css.mjs";
import importSlideStyles from "css/import-slides.css.mjs";

ViewDoor.templateString = ViewAction.templateString

ViewDoor.uiTemplateString = `

<style>
	${ importAnimatedElementStyles }
	${ importSlideStyles }

	p.buttons {
		display: flex;
		flex-direction: column;
		margin: 0;
		background-color: rgba(0,0,0,0.5);
		border-radius: 0px 0px 10px 10px;
		padding: 20px;
		max-width: 760px;
	}

	#description {
		background-color: rgba(0,0,0,0.5);
		border-radius: 10px 10px 0px 0px;
		padding: 20px;
		max-width: 760px;
	}

</style>
<div id="description"></div>
<p class="buttons">
	<text-button action="enter-space">Enter the room</text-button>
	<text-button action="stay-back">Stay back</text-button>
</p>

`;

window.customElements.define('view-door', ViewDoor)
