/**
 * Configuration rules for the Game engine behaviour.
 * 
 * Configuration is defined by the game Developer.
 * 
 * Settings are defined by Player.
 *
 */

import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, isNull, isDefNotNull, isArray, isString, isObject, isNumber, isBoolean } from 'validators.mjs'

import { GameApi } from 'Game/Api.mjs'

const VALID_TYPES = {"string": isString, "boolean": isBoolean, "number": isNumber}
const VALID_VALUES = []

export const CONFIG_DOOR_AFTER_OPENING = "behaviour-door-after-opening"
export const CONFIG_WORLD_ROUND_SYSTEM = "enable-world-round-system"
export const CONFIG_PLAYER_TURN_SYSTEM = "enable-player-turn-system"
export const CONFIG_SHOW_SPACE_GUIDE = "enable-show-space-guide"
export const CONFIG_SHOW_ITEM_PICKUP_GUIDE = "enable-show-item-pickup-guide"
export const CONFIG_STARTING_MISSION = "starting-mission"
export const CONFIG_PLAYER_BASE_COUNT = "player-base-count"

// Note: ES6 only notation for keys below
const DEFAULT_CONFIGS = {
	[CONFIG_DOOR_AFTER_OPENING]: {
		"name": "Behaviour: Door open",
		"description": "What to do after opening a space, leave door marker on the board as 'open' or hide it?",
		"default": "hide",
		"nullOrUndefinedOK": false,
		"validType": "string",
		"validValues": ["open", "hide"]
	},
	[CONFIG_WORLD_ROUND_SYSTEM]: {
		"name": "System: World rounds",
		"description": "Enable player vs. world round system.",
		"default": false,
		"nullOrUndefinedOK": true,
		"validType": "boolean",
		"validValues": undefined
	},
	[CONFIG_PLAYER_TURN_SYSTEM]: {
		"name": "System: Player individual turns",
		"description": "Enable players to take individual turns during player round.",
		"default": false,
		"nullOrUndefinedOK": true,
		"validType": "boolean",
		"validValues": undefined
	},
	[CONFIG_SHOW_SPACE_GUIDE]: {
		"name": "System: Show player instructions when showing new space",
		"description": "Show game engine's built-in instructions after showing a new space.",
		"default": false,
		"nullOrUndefinedOK": true,
		"validType": "boolean",
		"validValues": undefined
	},	
	[CONFIG_SHOW_ITEM_PICKUP_GUIDE]: {
		"name": "System: Show player instructions after picking up an item",
		"description": "Show game engine's built-in instructions after player picks up an item from the board.",
		"default": false,
		"nullOrUndefinedOK": true,
		"validType": "boolean",
		"validValues": undefined
	},		
	[CONFIG_STARTING_MISSION]: {
		"name": "Starting mission",
		"description": "Start game by running this mission's init state.",
		"default": "start",
		"nullOrUndefinedOK": false,
		"validType": "string",
		"validValues": undefined
	},
	[CONFIG_PLAYER_BASE_COUNT]: {
		"name": "Base count for players",
		"description": "Compare player count to this to calculate balance factors. E.g. current players = 2, base count = 4 => reduce content by 2/4 = 50% or add content by 4/2 = 200%.",
		"default": 4,
		"nullOrUndefinedOK": false,
		"validType": "number",
		"validValues": undefined
	}
}

export class Configuration {
	/**
	 * Load default configuration and set values fron configSpecs
	 * 
	 * Example:
	 * new Configuration({ "game-start-scene": "my-start-scene" })
	 * 
	 * @param {object} configSpecs
	 * @throws NotFound if configSpecs has a key with bad name
	 * @throws InvalidValue if defaultConfigs or configSpecs has an invalid value
	 */
	constructor(configSpecs) {
		this.configuration = {}

		for ( let id in DEFAULT_CONFIGS ) {
			let def = DEFAULT_CONFIGS[id]

			this.setConfigObject(id, new Config(
				id,
				def.name,
				def.description,
				def.default,
				def.nullOrUndefinedOK,
				def.validType,
				def.validValues
			))

			if ( isDef(configSpecs[id]) ) {
				this.setConfig(id, configSpecs[id])
			}
		}
	}

	/**
	 * @throws InvalidValue
	 */
	setConfigObject(name, object) {
		if ( !isString(name) ) { throw `Configuration.setConfigObject() 'name' is not a String. Got: ${ name }` }
		if ( ! (object instanceof Config) ) { throw `Configuration.setConfigObject() 'object' not a Config. Got: ${ object }` }

		this.configuration[name] = object
	}

	/**
	 * @throws NotFound
	 */
	getConfigObject(name) {
		if (!isDef(this.configuration[name])) { throw `Configuration.getConfigObject() configuration '${name}' not found.` }

		return this.configuration[name]
	}

	/**
	 * @throws NotFound
	 */
	getConfig(name) {
		return this.getConfigObject(name).getValue()
	}

	/**
	 * @throws NotFound
	 * @throws InvalidValue
	 */
	setConfig(name, value) {
		return this.getConfigObject(name).setValue(value)
	}
}

class Config {
	/**
	 * Create the Config with default information.
	 * 
	 * Call setValue() to define the actual value.
	 */
	constructor(id, name, description, defaultValue, nullOrUndefinedOK, validType, validValues) {
		if (!isString(id)) { throw `Config() 'id' is not a String. Got: ${ id }` }
		if (!isString(name)) { throw `Config() 'name' is not a String. Got: ${ name }. Id: ${ id }` }
		if (!isString(description)) { throw `Config() '${id}' attribute 'description' is not a String. Got: ${ description }. Id: ${ id }` }
		if (!isDef(defaultValue)) { throw `Config() '${id}' attribute 'defaultValue' not defined. Got: ${ defaultValue}. Id: ${ id }` }
		if (!isBoolean(nullOrUndefinedOK)) { throw `Config() ${id} attrivbute 'nullOrUndefinedOK' is not a Boolean. Got: ${ nullOrUndefinedOK}. Id: ${ id }` }
		if (!isDefNotNull(validType)) { warn(`Config() '${id}' attribute 'validType' is undefined or null. Got: ${ validType }. Id: ${ id }`) }
		if (isDef(validType) && !isString(validType)) { throw `Config() '${id}' attribute 'validType' is not a String. Got: ${ validType }. Id: ${ id }` }
		if (isDef(validValues) && !isArray(validValues)) { throw `Config() '${id}' attribute 'validValues' is not an Array. Got: ${ validValues }. Id: ${ id }` }

		this.id = id
		this.name = name
		this.description = description
		this.defaultValue = defaultValue
		this.value = undefined
		this.nullOrUndefinedOK = nullOrUndefinedOK
		this.validType = validType
		this.validValues = validValues

		try {
			this.validate()
		} catch (err) {
			throw `Config() '${id}' validation failed: ${ err }.\nValid type: ${ validType }. Valid values: ${ validValues }`
		}
	}


	/**
	 * Validates current or default value
	 * 
	 * @throws InvalidValue
	 */
	validate() {
		this.validateValue(this.getValue())
	}

	/**
	 * Validate if the testValue would be ok
	 * 
	 * @throws InvalidValue
	 */
	validateValue(testValue) {
		if ( !this.nullOrUndefinedOK && (!isDef(testValue) || isNull(testValue)) ) { throw `Invalid value: null or undefined` }

		if ( isDef(this.validType) ) {
			let validator = VALID_TYPES[this.validType]

			let isValid = validator(testValue)

			if (!isValid) { throw `Invalid type: not of valid type` }
		}

		if ( isDef(this.validValues) ) {
			let isValid = this.validValues.includes(testValue)

			if (!isValid) { throw `Invalid value: not in list of valid values` }
		}

		return true
	}

	/**
	 * Set the current value.
	 * 
	 * Value has to be any valid value or null.
	 * 
	 * To set it undefined, use unsetValue().
	 * 
	 * @throws InvalidValue
	 */
	setValue(newValue) {
		try {
			this.validateValue(newValue)
		} catch (err) {
			throw `Config.setValue() for '${this.id}' failed: ${ err }.\nValid type: ${ this.validType }. Valid values: ${ this.validValues }`
		}

		this.value = newValue
	}

	/**
	 * Set current value to undefined.
	 * 
	 * Next getValue() will return the default value.
	 */
	unsetValue() {
		this.value = undefined
	}

	/**
	 * Return current value or default if not defined.
	 * 
	 * If current value is ...
	 *     undefined      -> default value
	 *     null           -> current value
	 *     anything else  -> current value
	 */
	getValue() {
		return isDef(this.value) ? this.value : this.defaultValue
	}

	/**
	 * Return default value of the config, regardless if it is defined.
	 */
	getDefaultValue() {
		return this.defaultValue
	}

	/**
	 * Is a value set for the config?
	 * 
	 * This is true if value is set, even if it's default
	 * 
	 * @return boolean
	 */
	isSet() {
		return isDef(this.value)
	}

	/**
	 * Is a non-default value set for the config?
	 * 
	 * This is true if value is set and it's not the default
	 * 
	 * @return boolean
	 */
	isSetNotDefault() {
		return isDef(this.value) && this.value !== this.defaultValue
	}
}

