import { sleep, log, warn, error } from 'global.js'
import { isDef, isObject, isString } from 'validators.mjs'

import { UI } from 'UI/UI.mjs'
import { Game } from 'Game/Game.mjs'
import { Asset } from 'Game/Assets.mjs'

export class HasAssets {
	constructor() {

	}

	/**
	 * Links listed assets from AssetManager to this
	 * 
	 * Example:
	 *     addAssets( { <type> : <assetId> )
	 *     addAssets( { "marker": "door-marker", "markerFocus": "door-marker-focus" } )
	 * 
	 * @param {Object} addAssets
	 * @return this
	 */
	addAssets(assetList) {
		if ( !isDef(assetList) || !isObject(assetList) ) { throw `HasAssets.addAssets() assetList missing or not an Object. Get: ${ assetList }` }

		for ( let type in assetList ) {
			let assetId = assetList[type]

			if ( assetId ) {
				this.setAsset(type, assetId)
			}
		}

		return this
	}

	/**
	 * @throws NotFound
	 */
	setAsset(type, assetId) {
		if ( !isString(type) ) { throw `HasAssets.setAsset() 'type' is not a String. Got: ${ type }` }
		if ( !isString(assetId) ) { throw `HasAssets.setAsset() 'assetId' is not a String. Got: ${ assetId}` }

		this.assets[type] = Game.global.getAsset(assetId)

		if ( this.marker ) {
			this.marker.updateAsset(type, this.assets[type])
		}
	}

	/**
	 * This will give Spaces and Thing's id for Board, so there are no
	 * name clases between Npc, Item, etc.
	 *
	 * This is internally used by Board to have unique ids.
	 *
	 * Example:
	 *
	 *     let npc = new Npc("simon", ..)
	 *     npc.getIfForBoard() -> "npc:simon"
	 * 
	 * TODO actually use different id
	 *
	 * @return unique id from State
	 */
	getIdForBoard() {
		return this.id
	}

	async preload() {
		for ( let type in this.assets ) {
			let asset = this.assetets[type]
			let data = asset.data()

			// wait to load
			if ( data instanceof Promise ) { data = await data }
		}

		return this
	}

	getAsset(assetType) {
		if ( !this.assets[assetType] ) { throw `Thing.getAsset: this doesn't have asset of assetType: ${ assetType }`}

		return this.assets[assetType]
	}

	getAssetOrNull(assetType) {
		return this.assets[assetType]
	}	
}
