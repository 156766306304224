import { sleep, log, warn, error, getRandomInt } from 'global.js'
import { isInteger, isObject, isDef } from 'validators.mjs'

import { Thing } from 'Thing/Thing.mjs'
import { Item } from 'Thing/Item.mjs'

import { Stat } from 'Thing/Stat.mjs'

import { T } from 'Game/Translations.mjs'

var charIdCounter = 0

export class Character extends Thing {
	constructor(id, specs) {
		super(id, T(specs.name), specs.description ? T(specs.description) : "", specs.assets, specs.spaces, specs.state, specs.states, specs.action, specs.actions, specs.cx, specs.cy)

		this.ref = `${id}-${++charIdCounter}`

		this.fullName = T(specs.fullName)

		let [ subject, object, adjective, possessive, reflexive ] = T(specs.pronouns).split("/")
		this.pronouns = {
			subject: subject, 			// he 		she
			object: object,				// him 		her
			adjective: adjective,		// his		her
			possessive: possessive,		// his 		hers
			reflexive: reflexive,		// himself	herself
		}

		this.thes = specs.thes ? T(specs.thes).split("/") : ["person"]

		this.stats = {}
		this.items = []

		if ( isObject(specs.stats) ) {

			const ss = specs.stats // easier to read below

			let health = isInteger(ss.health) ? ss.health : ss.maxHealth
			let maxHealth = isInteger(ss.maxHealth) ? ss.maxHealth : health
			if ( isInteger(health) ) { this.stats.health = new Stat(health, maxHealth) }

			let attack = isInteger(ss.attack) ? ss.attack : ss.maxAttack
			let maxAttack = isInteger(ss.maxAttack) ? ss.maxAttack : attack
			if ( isInteger(attack) ) { this.stats.attack = new Stat(attack, maxAttack) }

			let strength = isInteger(ss.strength) ? ss.strength : ss.maxStrength
			let maxStrength = isInteger(ss.maxStrength) ? ss.maxStrength : strength
			if ( isInteger(strength) ) { this.stats.strength = new Stat(strength, maxStrength) }

			let agility = isInteger(ss.agility) ? ss.agility : ss.maxAgility
			let maxAgility = isInteger(ss.maxAgility) ? ss.maxAgility : agility
			if ( isInteger(agility) ) { this.stats.agility = new Stat(agility, maxAgility) }

			let intelligence = isInteger(ss.intelligence) ? ss.intelligence : ss.maxIntelligence
			let maxIntelligence = isInteger(ss.maxIntelligence) ? ss.maxIntelligence : intelligence
			if ( isInteger(intelligence) ) { this.stats.intelligence = new Stat(intelligence, maxIntelligence) }
		}
	}

	getFullName() {
		return this.fullName || this.getName()
	}

	getPronounSubject() {
		return this.pronouns.subject
	}

	getPronounObject() {
		return this.pronouns.object
	}

	getPronounAdjective() {
		return this.pronouns.adjective
	}

	getPronounPossessive() {
		return this.pronouns.possessive
	}

	getPronounReflexive() {
		return this.pronouns.reflexive
	}

	getThe() {
		return this.thes[0]
	}

	getRandomThe() {
		return this.thes[ getRandomInt(0, this.thes.length )]
	}

	getMarkerAsset() {
		if ( !this.assets.marker ) {
			throw `Character.getMarkerAsset() i don't have a marker asset OR it isn't loaded yet`
		}

		if ( typeof this.assets.marker == 'object') {
			return this.assets.marker
		}

		throw `Character.getMarkerAsset() my marker asset isn't loaded`
	}

	getMarkerAssets() {

		if ( !this.assets || !this.assets.marker ) {
			throw `Character.getMarkerAssets() i don't have a marker asset OR it isn't loaded yet`
		}

		let asses = {
			marker: this.assets.marker
		}

		if ( typeof this.assets.markerFocus == 'object' ) {
			asses.markerFocus = this.assets.markerFocus
		}

		return asses
	}

	addItem(item, stopPropagate) {
		this.items[item.id] = item

		if ( !stopPropagate ) {
			item.addToCharacter(this, true)
		}

	}

	removeItem(item, stopPropagate) {
		if ( !stopPropagate ) {
			item.removeFromCharacter(this, true)
		}

		delete this.items[item.id]
	}

	hasItem(item) {
		return isDef(this.items[item instanceof Item ? item.id : item ])
	}

	isPlayer() {
		return this instanceof Player
	}

	isNeutral() {
		// TODO rename all npc stuff to "Neutral" and make 'npc' a type of character: non-playable
		return this instanceof Npc
	}

	isNpc() {
		return this instanceof Npc
	}

	isMonster() {
		return this instanceof Monster
	}


	isAlive() {
		return this.stats.health.get() > 0
	}

	getHealth() { return this.stats.health }
	getAttack() { return this.stats.attack ? this.stats.attack : this.stats.strength }
	getStrength() { return this.stats.strength }
	getAgility() { return this.stats.agility }
	getIntelligence() { return this.stats.intelligence }

	bindHealth(element) {
		Game.global.bindElementStat(element, this.stats.health)
	}

	bindAttack(element) {
		Game.global.bindElementStat(element, this.stats.attack)
	}
}


export class Player extends Character {
	constructor(id, specs) {
		super(id, specs)

		this.type = "player"
		this.npc = false
	}
}

export class Monster extends Character {
	constructor(id, specs) {
		super(id, specs)

		this.type = "monster"
		this.npc = true
	}
}

export class Npc extends Character {
	constructor(id, specs) {
		super(id, specs)

		this.type = "npc"
		this.npc = true
	}
}

