/**
 * Intercation view shows action dialog for item
 *
 */

import { debug, log, error, sleep } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { UI, ACTION_SEPARATOR } from 'UI/UI.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'


export class ViewItem extends ViewLayer {

	static templateString

	/**
	 * Initialized by DOM from <view-dialogue>
	 *
	 * Note: currentSet is not replaced, it's contents are replaced
	 * with showDialogue()
	 */
	constructor() {
		super()

		this.classList.add('view')
		this.style.opacity = 0
	}


	/**
	 * Opens the modal view-item
	 *
	 * @param { Item } item
	 * @param { string } message (optional)
	 * @param { asset } background (optional)
	 * @param { boolean } animate (optional)
	 * @return nothing
	 */
	async show(item, message, background, animate) {

		this.setAttribute('id', item.id)
		this.item = item

		this.updateItemElement( item )

		if ( message ) {
			this.updateTextElement( message )
		}

		if ( background ) {
			this.updateBackgroundElement( background )
		}

		await super.show(animate)
	}

	async action(listOfActions, element, event) {

		let feedback = false

		for ( let action of listOfActions.split(ACTION_SEPARATOR) ) {
			switch (action) {
				case "default":
				case "close":
					debug("ViewItem.action() 'close' pressed")
					await this.hide(true)
					feedback = true
					break

				case "pickup":
					debug("ViewItem.action() 'pickup' pressed")
					await this.hide(false)
					await sleep(1000)
					await Game.global.currentPlayerPickupItem(this.item.id)
					feedback = true
					break

				case "use":
					debug("ViewItem.action() 'use' pressed")
					await this.hide(false)
					await Game.global.currentPlayerUseItem(this.item.id)
					feedback = true
					break
			}
		}
		
		return feedback
	}

	async hide(animate) {
		await super.hide(animate)

		this.remove()
	}


	updateItemElement(item) {
		super.updateImageElement('#item-image', item.getAsset('interaction') )

/*		super.updateTextElement('#item-name', item.getName() ) */

		super.updateTextElement('#text', item.getDescription() )
	}

	updateTextElement(text) {

		super.updateTextElement('#text', text)
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";
import importItemStyles from "css/import-item-view.css.mjs";

ViewItem.templateString = `

<style>
	${ importMainStyles }
	${ importViewLayerStyles }
	${ importItemStyles }
</style>
<div id="dialog">
	<div class="left">
		<div id="item-image"></div>
	</div>
	<div class="right">
		<p id="text"></p>
		<p id="buttons">
			<text-button class="align-left small" action="pickup">Pick up</text-button>
			<text-button class="align-left small" action="use">Use</text-button>
			<text-button class="align-left small" action="close">Close</text-button>
		</p>
</div>

`;

window.customElements.define('view-item', ViewItem)
