import { generateAssetUrl } from "../global.js";

export default `

/* Import dialogues styles */

:host {
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: row;
}

.extra-pad-below {
	padding-bottom: var(--l-pad);
}

.extra-pad-above {
	padding-top: var(--l-pad);
}

.pad-wide {
	height: var(--xs-pad);
	width: var(--xl-pad);
}

.pad-tall {
	height: var(--xl-pad);
	width: var(--xs-pad);	
}

.no-pad-below {
	padding-bottom: 0px;
}

.no-pad-above {
	padding-top: 0px;
}

ul.no-pad-below, ol.no-pad-below, p.no-pad-below {
	margin-block-end: 0px;
}

ul.no-pad-above, ol.no-pad-above, p.no-pad-above {
	margin-block-start: 0px;
}

p {
	width: 100%;
	text-shadow: var(--slide-text-shadow);
}

p.guide {
	font-style: italic;
	color: var(--tutorial-text-color);
}

p.guide .label {
	font-size: inherit;
	white-space: nowrap;
}

ul.no-bullets, ol.no-bullets {
	list-style-type: none;	
}

section.buttons {
	display: flex;
	gap: var(--xs-pad);
	border-top: solid 1px #fff;
	margin-top: 3em;
}

section.buttons p {
	margin-block: 0px;
}

section.buttons text-button {
	font-size: var(--s-font-size);
	padding: var(--s-pad) 0px;
}

section.buttons.center {
	justify-content: center;
	align-items: center;
}

section.buttons.row {
	flex-direction: row;	
}

section.buttons.column {
	flex-direction: column;
}

section.buttons.left text-button:first-child {
	padding-left: 0px;
}

section.buttons.right text-button:last-child {
	padding-right: 0px;
}

section.buttons .fillspace {
	flex-grow: 1;
}

.background {
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--background);
}

.characterimages {
	position: absolute;
	width: 100%;
	height: 70%;
	top: 10%;
	display: flex;
	flex-direction: row;
	gap: var(--m-pad);
	padding: var(--l-pad) var(--l-pad);
	justify-content: center;
}

.characterimages .card {
	display: inline-block;
	width: var(--card-width);
	height: var(--card-height);
	border-radius: var(--m-pad);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}

.card.player1 {
	background-image: url(${generateAssetUrl('media/characters/player1-card-front@1x.webp')});
}
.card.player2 {
	background-image: url(${generateAssetUrl('media/characters/player2-card-front@1x.webp')});
}
.card.player3 {
	background-image: url(${generateAssetUrl('media/characters/player3-card-front@1x.webp')});
}
.card.player4 {
	background-image: url(${generateAssetUrl('media/characters/player4-card-front@1x.webp')});
}
.card.player5 {
	background-image: url(${generateAssetUrl('media/characters/player5-card-front@1x.webp')});
}
.card.player6 {
	background-image: url(${generateAssetUrl('media/characters/player6-card-front@1x.webp')});
}
.card.player7 {
	background-image: url(${generateAssetUrl('media/characters/player7-card-front@1x.webp')});
}
.card.player8 {
	background-image: url(${generateAssetUrl('media/characters/player8-card-front@1x.webp')});
}

.background .gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

div.images {
	height: 100%;
	width: 40%;
	padding: 10vmax;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: var(--l-pad);
}

div.dialogue {
	position: relative;
	height: 100%;
	width: 70%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	background: transparent;
	padding-bottom: var(--l-pad);
}

section {
	text-align: left;
}

section.message p:not([class=name]) {
	opacity: 0.8;
}

section.message p {
	color: var(--dialogue-message-text-color);
}
section.message:nth-child(even) p {
	color: var(--dialogue-message-text-alt-color);
}

section.message p.name:after {
	content: ":";
}

section.message p.name {
	font-weight: 900;
	margin-block-end: 0.1rem;
}

section.message p.name + p {
	margin-block-start: 0rem;
}


section.narrator p {
	color: var(--dialogue-narrator-text-color);
}

section.buttons {
	text-align: left;
	display: flex;
	flex-direction: column;
}

section.tutorial p {
	color: var(--tutorial-text-color) !important;
	line-height: 2rem;
}

section.tutorial :first-child:before {
    content: "";
    display: block;
    background-size: contain;
    background-image: var(--tutorial-icon-bg);
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    float: left;
    margin: 0 1rem 0 0;
}

`;
