import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const ACTION_EVENTS = ["action"]

EVENT_TYPES.push(...ACTION_EVENTS)

export class ActionEvent extends Event {
	static eventType = "action"
	static requiredKeys = ["event", "name"]
	static optionalKeys = ["value", "choices"]

	constructor(name, value, choices) {
		super ({
			name: name,
			value: value,
			choices: choices,
		})
	}
}
