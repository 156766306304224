import { log, debug, warn } from 'global.js'
import { isDefNotNull } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { UI } from 'UI/UI.mjs'

import { Drawable } from 'Board/Drawable.mjs'
import { Thing } from 'Thing/Thing.mjs'
import { Character, Monster, Npc } from 'Thing/Characters.mjs'
import { Door } from 'Thing/Door.mjs'


export class Marker extends Drawable {
	constructor(board, id, assets, x, y) {
		super(board, id, assets, x, y)

		this.thing = null
	}

	areYouAt(x, y) {
		return this.areYouAtCircle(x, y)
	}

	onMouseOver(x, y) {
		this.focus = true
		this.board.setPointerInteractive()
		this.needRedraw()

		return () => {
			this.board.setPointerDefault()
			this.focus = false
			this.needRedraw()
		}
	}

	onClick(x, y) {
		this.focus = false
		this.needRedraw()

		if ( this.thing.hasAction()) {

			this.thing.runAction()

			return true
		}
	}

	/**
	 * Link the Thing with it's Marker.
	 *
	 * This is required for onClick() to work.
	 *
	 * @param { Thing } thing
	 */
	linkThing(thing) {
		this.thing = thing
		thing.marker = this
	}

	shouldBeVisible() {
		if ( this.thing ) {
			if ( this.thing.markerSpace ) {
				let space = this.thing.spaces[this.thing.markerSpace]

				return space.isVisible()
			}

			return this.thing.visible
		}

		return true
	}

	/**
	 * @return Thing
	 */
	getThing() {
		return this.thing
	}

	hasThing() {
		return this.thing instanceof Thing
	}
}

export class DoorMarker extends Marker {
	constructor(board, id, assets, x, y) {
		super(board, id, assets, x, y)
	}

	onClick(x, y) {
		let stop = super.onClick(x, y)

		if ( stop ) { return stop }

		Game.global.playSfx('button-click')


		if ( this.thing.locked ) {
			if ( !isDefNotNull(this.thing.key) ) {
				warn(`DoorMarker.onClick() locked but no key specified for door: ${ this.id }`)

				return true
			}

			let key = this.thing.key

			Game.global.showMessage("{{~locked-need-key}}", { "key": key.getName() })

			return true

		} else {
			Game.global.showDoorAction(this.thing.id)

			return true
		}
	}
}

export class ItemMarker extends Marker {
	constructor(board, id, assets, x, y) {
		super(board, id, assets, x, y)
	}

	onClick(x, y) {
		let stop = super.onClick(x, y)

		if ( stop ) { return stop }

		//Game.global.playSfx('button-click')

		if ( this.thing.locked ) {
			if ( !isDefNotNull(this.thing.key) ) {
				warn(`ItemMarker.onClick() locked but no key specified for item: ${ this.id }`)
				return true
			}

			let key = this.thing.key

			UI.global.showPopup("locked-need-key", { "key": key.getName() })
			return true
		}

		let itemId = this.thing.id
		let spaceId = this.thing.getSpaceName() // FIXME use getSpace to get space without global
		let actionAsset = Game.global.getSpace(spaceId).getActionAsset()
		let backgroundAssetId = actionAsset ? actionAsset.id : null

		UI.global.showItemView( itemId, null, backgroundAssetId )
		return true
	}
}

export class CharacterMarker extends Marker {
	constructor(board, id, assets, x, y) {
		return super(board, id, assets, x, y)
	}

	onClick(x, y) {
		let stop = super.onClick(x, y)

		if ( stop ) { return stop }

		Game.global.playSfx('button-click')

		let missions = Game.global.getOngoingMissions()

		if ( this.thing instanceof Monster ) {
			debug(`CharacterMarker.onClick() this is a monster - not doing anything`)

			Game.global.showAttackView(this.thing.id)

			return true
		}
		else if ( this.thing instanceof Character ) {
			let possibleDialogues = []

			for ( let miss of missions ) {
				if ( miss.hasAvailableDialogueFor(this.thing) ) {
					let list = miss.getAvailableDialoguesFor(this.thing)

					possibleDialogues.push( ...list )
				}
			}

			if ( possibleDialogues.length > 0 ) {
				warn(`Marker.onClick() only using first available dialogue - TODO improve this`)

				Game.global.showDialogue(possibleDialogues[0])
			}

			return true
		}

		return false
	}
}
