import { sleep, log, warn, error } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { Interaction } from 'Thing/Interaction.mjs'
import { Stat } from 'Thing/Stat.mjs'

const STATE_CLOSED = "closed"
const STATE_LOCKED = "locked"
const STATE_OPEN = "open"

export class Door extends Interaction {
	constructor(id, specs) {
		super(id, specs)

		this.locked = specs.locked
		this.introTemplate = specs.introTemplate
		this.interactionTemplate = specs.interactionTemplate

		if ( specs.key ) {
			this.key = Game.global.getItem(specs.key)
		}
	}

	getSpace() {
		return this.getVisibleSpace()
	}

	getVisibleSpace() {
		for ( let otherSpaceId in this.spaces ) {
			if ( this.spaces[otherSpaceId].isVisible() ) {
				return this.spaces[otherSpaceId]
			}
		}

		throw `Door.getVisibleSpace() no visible space for door ${ this.id }`
	}

	getHiddenSpace() {
		for ( let otherSpaceId in this.spaces ) {
			if ( !this.spaces[otherSpaceId].isVisible() ) {
				return this.spaces[otherSpaceId]
			}
		}

		return null
	}

	isOpen() {
		return this.getCurrentState() === STATE_OPEN
	}

	isClosed() {
		return this.getCurrentState() === STATE_CLOSED
	}

	isLocked() {
		return this.getCurrentState() === STATE_LOCKED
	}

	lockDoor() {
		this.changeState(STATE_LOCKED)
	}

	openDoor() {
		this.changeState(STATE_OPEN)
	}

	closeDoor() {
		this.changeState(STATE_CLOSED)
	}
}
