export default `

/* Import attack view styles */

:host {
	display: flex !important;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;

	background: var(--popup-fog-color);
}

div.top {
	width: 100%;
	height: 70%;
	display: flex;
	flex-direction: row;
}

div.bottom {
	width: 100%;
	heigth: 30%;
}

div.left {
	width:  50%;
    height: 100%;
}

div.right {
	width: 50%;
	height: 100%;

	padding: var(--xl-pad);
}

#preview {
	position: relative;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
    justify-content: end;
    padding-right: var(--xl-pad);
}

#preview > .tab {
	position: absolute;
	justify-content: center;
	height: 100%;
}

#menu {
	position: relative;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	text-align: left;
}

#menu > .tab {
	position: absolute;
	justify-content: center;
	height: 100%;
}

#bottom-buttons {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	margin-bottom: var(--xl-pad);

	text-align: center;
}

#bottom-buttons text-button:not(.visible) {
	display: none;
}

.tabset > .tab {
	display: flex;
	flex-direction: column;
	visibility: hidden;
}

.tabset > .tab.visible {
	visibility: visible;
}


`;
