import { sleep, log, warn, error, debug } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const GAME_EVENTS = ["set-variable", "end-game", "start-game-round", "end-game-round", "start-player-round", "end-player-round", "start-world-round", "end-world-round", "start-player-turn", "end-player-turn" ]

EVENT_TYPES.push(...GAME_EVENTS)

export class EndGameEvent extends Event {
	static eventType = "end-game"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class StartGameRoundEvent extends Event {
	static eventType = "start-game-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class EndGameRoundEvent extends Event {
	static eventType = "end-game-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class StartPlayerRoundEvent extends Event {
	static eventType = "start-player-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class EndPlayerRoundEvent extends Event {
	static eventType = "end-player-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class StartPlayerTurnEvent extends Event {
	static eventType = "start-player-turn"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class EndPlayerTurnEvent extends Event {
	static eventType = "end-player-turn"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class StartWorldRoundEvent extends Event {
	static eventType = "start-world-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class EndWorldRoundEvent extends Event {
	static eventType = "end-world-round"
	static requiredKeys = ["event"]
	static optionalKeys = []

	constructor(eventObject) {
		super (eventObject || {})
	}
}

export class SetVariableEvent extends Event {
	static eventType = "set-variable"
	static requiredKeys = ["event", "variable"]
	static optionalKeys = ["value", "oldValue"]

	constructor(eventObject) {
		super (eventObject || {})
	}
}