import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, isArray, isObject, isInteger, isString, isFunction } from 'validators.mjs'
import { EVENT_TYPES } from 'Command/Event.mjs'

/**
 * Eventable "interface"
 *
 * Eventable is a callback system that any class can extend
 * directly or using mixin() from globals.js:
 *
 *     class Child extends Eventable
 *
 *     class Child extends mixin(Parent, Eventable)
 *
 * Child class has to provide list of events
 *
 *     class Example extend Eventable {
 *         static eventTypes = ['event-foo', 'event-bar']*
 *     }
 *
 * ... And then manually event the callbacks:
 *
 *     this.eventCallback("command-success")
 *
 * Other classes can listen to these events:
 *
 *     example.addListener("event-foo", callback)
 *
 *     example.addListenerOnce("event-bar", callback)
 *
 * ... Or stop listening:
 *
 *     cmd.removeListener("event-foo", callback)
 *
 * The listener is matched with event name and callback function
 * reference.
 *
 */
export class Eventable {

//  Child class has to provide:
//
//	static eventTypes

	constructor(mixinClass) {
		let ChildClass = mixinClass || this.constructor

		this._events = {}
		this._eventsOnce = {}

		ChildClass.eventTypes.map( x => {
			this._events[x] = []
			this._eventsOnce[x] = []
		})

		EVENT_TYPES.map( x => {
			this._events[x] = []
			this._eventsOnce[x] = []
		})
	}

/*
	constructor(mixinClass) {
		let ChildClass = mixinClass || this.constructor

		// We hide our properties from stringification

		Object.defineProperty(this, '_events', { value: {}, writable: false })

		EVENT_TYPES.map( x => { this._events[x] = [] } )
	}
*/

	/**
	 * Add a callback for specific event:
	 *
	 *     let token = cmd.addEvent("command-success", callback)
	 *
	 * You can call addEvent() multiple times and it will only
	 * store it once.
	 *
	 * Clashes, duplicates, repeated calls are silently ignored.
	 *
	 * Callbacks receive object and name of event:
	 *
	 *     callback( <Object>, "eventName" )
	 *
	 * Object is for example a Command or CommandProcessor object.
	 *
	 * @param { string } eventName
	 * @param { function } callback
	 */
	addListener(eventName, callback) {
		if ( !isString(eventName) ) { throw `Eventable.addListener() eventName not a String. Got: ${ eventName }` }
		if ( !isFunction(callback) ) { throw `Eventable.addListener() callback not a function. Got: ${ callback }` }

		const ChildClass = this.constructor

		if ( !isString(eventName) ) {
			throw `Eventable.addListener() argument 'eventName' is not a string. Got: ${ eventName }`
		}

		if ( !isFunction(callback) ) {
			throw `Eventable.addListener() argument 'callback' is not a function. Got: ${ callback }`
		}

		if ( !isDef(this._events[eventName]) ) {
			throw `Eventable.addListener() unknown event name '${ eventName }'\n\nAllowed names: ${ Object.keys(this._events).join(', ') }`
		}

		if ( this._events[eventName].indexOf(callback) < 0 ) {
			this._events[eventName].push(callback)
		}
	}

	addListenerOnce(eventName, callback) {
		if ( !isString(eventName) ) { throw `Eventable.addListenerOnce() eventName not a String. Got: ${ eventName }` }
		if ( !isFunction(callback) ) { throw `Eventable.addListenerOnce() callback not a function. Got: ${ callback }` }

		this.addListener(eventName, callback)

		this._eventsOnce[eventName].push(callback)
	}

	/**
	 * Stop listening to command execution events:
	 *
	 *     cmd.removeEvent("command-success", callback)
	 *
	 * Matching is made with name and function reference of callback.
	 *
	 * If you've added same callback function several times, it's only
	 * stored once and you only have to call remove once.
	 *
	 * Calling remove repeatedly is silently ignored.
	 *
	 *
	 * @param { string } eventName
	 * @param { function } callback
	 * @return nothing
	 */
 	removeListener(eventName, callback) {
		if ( !isString(eventName) ) { throw `Eventable.removeListener() eventName not a String. Got: ${ eventName }` }
		if ( !isFunction(callback) ) { throw `Eventable.removeListener() callback not a function. Got: ${ callback }` }

		const ChildClass = this.constructor

		if ( !isString(eventName) ) {
			throw `Eventable.removeListener() argument 'eventName' is not a string. Got: ${ eventName }`
		}

		if ( !isFunction(callback) ) {
			throw `Eventable.removeListener() argument 'callback' is not a function. Got: ${ callback }`
		}

		if ( !isDef(this._events[eventName]) ) {
			throw `Eventable.removeListener() unknown event name '${ eventName }'\n\nAllowed names: ${ Object.keys(this._events).join(', ') }`
		}

		if ( this._events[eventName].indexOf(callback) > -1 ) {
			let i = this._events[eventName].indexOf(callback)
			this._events[eventName].splice(i, 1)

			let j = this._eventsOnce[eventName].indexOf(callback)
			if ( j ) {
				this._eventsOnce[eventName].splice(i, 1)
			}
		}
	}


	/**
	 * Trigger the callbacks for the event.
	 *
	 * Parameters for the callback are:
	 *
	 *     <Object>, <event name>
	 *
	 * For example:
	 *
	 *     CmdShowView, "command-successful"
	 *
	 * @param { string } eventName
	 * @param { ... } attr some event dependent value
	 * @return nothing
	 */
	eventCallback( eventName, attr ) {
		if ( !isString(eventName) ) { throw `Eventable.eventCallback() eventName not a String. Got: ${ eventName }` }

		if ( !isArray(this._events[eventName]) ) {
			return
		}

		for ( let callback of this._events[ eventName ] ) {
			try {

				callback(eventName, attr)

				if ( this._eventsOnce[eventName].indexOf(callback) >= 0 ) {
					this.removeListener(eventName, callback)
				}

			} catch (err) {
				error(`Eventable.eventCallback() for '${ eventName }' failed: ${ err }`)
			}
		}
	}


	/**
	 * Listen for specific event.
	 * 
	 * This can be used to await execution until a
	 * certain event happens.
	 * 
	 * @param { string } eventName
	 * @return { Promise }
	 */
	waitForEvent(eventName) {
		if ( !isString(eventName) ) { throw `Eventable.waitForEvent() eventName not a String. Got: ${ eventName }` }

		let cpu = this
		
		return new Promise((resolve, reject) => {
			cpu.addListener(eventName, resolve)
		})
	}
}
