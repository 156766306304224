import { toNumber, sleep, error, warn, log, debug, replaceObjectTransVars} from 'global.js'
import { isString } from 'validators.mjs'

import { ViewCustom } from 'UI/ViewCustom.mjs'

import { Game } from 'Game/Game.mjs'

import { ActionEvent } from 'Command/EventsAction.mjs'

export class ViewCustomContent extends ViewCustom {

    static templateString

    name;
    choices;
    waitForHide;

    constructor() {
        super()
    }

    /**
     * Opens the custom view with content from template 'name'
     *
     * Does not return unless returnAfterShow is true.
     *
     * @param {string} templateName
     * @param {object} content
     * @param {string} [variable] (optional) name of the default variable
     * @param {boolean} [returnAfterShow] (optional) default false
     * @param {boolean} [waitForHideDuringAction] (optional) default false
     * @param {boolean} [animate] (optional)
     */
    show(templateName, content, variable, returnAfterShow, waitForHideDuringAction, animate) {
        if (!isString(templateName)) { error(`ViewCustomContent.show() 'templateName' is not a string. Got: ${templateName}`)}

        let { template: _, ...customVariables } = content.contents

        customVariables = replaceObjectTransVars(customVariables, Game.global.getGlobalVariables())

        let allVariables = Object.assign(customVariables, Game.global.getGlobalVariables())

        return super.show(templateName, variable, returnAfterShow, waitForHideDuringAction, animate, allVariables)
    }
}

import importMainStyles from "css/import-main.css.mjs";
import importViewLayerStyles from "css/import-viewlayer.css.mjs";

ViewCustomContent.templateString = `
<style>
    ${ importMainStyles }
    ${ importViewLayerStyles }
</style>
`;

window.customElements.define('view-custom-content', ViewCustomContent)
