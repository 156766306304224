import { sleep, log, warn, error } from 'global.js'
import { isDef, isNull, isNonEmpty, isArray, isObject, isString } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

export class HasProps {
	constructor() {

	}

	/**
	 * Set property
	 * 
	 * The keyPath is a string with ':' separator.
	 * 
	 * Example:
	 *     setProp("locked", true)
	 *     setProp("asset:marker", "some-asset")
	 * 
	 * @param { string } keyPath 
	 * @param { any } value
	 * @throws InvalidKey
	 */
	setProp(keyPath, value) {

		if ( !isString(keyPath) ) { throw `Thing.setProp() attribute 'keyPath' is not a string. Got: ${ keyPath }`}
		if ( !isDef(value) ) { warn(`Thing.setProp() possibly bad value for '${ keyPath }'. Got: ${ value }' (state: ${ this.state })`) }

		let keys = keyPath.split(':')
		let firstKey = keys[0]

		if ( firstKey === "asset" ) {
			if ( !isString(keys[1]) ) { throw `Thing.setProp() asset type is not a String (ex. 'assets:foobar' -> 'foobar'). Got: ${ keys[1] }.` }
			if ( !isString(value) ) { throw `Thing.setProp() value is not a String. Got: ${ value }` }

			let assetType = keys[1]
			let assetId = value

			this.setAsset(assetType, assetId)
			return this
		}

		else if ( firstKey === "states" ) {
			let specs = value

			this.setState(specs)
			return this
		}

		else if ( firstKey === "spaces" || firstKey === "space" ) {

			let spaceIds = isArray(value) ? value : [ value ]

			if ( this.isInSpace() ) {
				//this.removeFromAllSpaces()	// Maybe always spaces = {} ?
				this.spaces = []
			}

			for ( let id of spaceIds ) {
				if ( isNull(id) ) { continue } // trying to unset space

				let space = Game.global.getSpace(id)

				this.addToSpace(space)
			}

			return this
		}

		// ELSE : Traverse to last key in keyPath

		let currentKey
		let objectRef = this

		for ( let i = 0 ; i < keys.length ; i++ ) {

			currentKey = keys[i]

			if ( i + 1 >= keys.length ) {
				break;
			}

		    if ( !objectRef[currentKey] ) {
		      throw `HasProps.setProp() tried to add prop with keypath that goes deeper than current object contains. Keypath: '${keyPath}' to object '${this.id}'`
		    }

		    if ( isObject(objectRef[currentKey]) || isArray(objectRef) ) {
		    	objectRef = objectRef[currentKey]
		    }

		    if ( (i + 1) < keys.length ) {
		    	throw `HasProps.setProp() tried to add prop with keypath that expects key '${currentKey}' to be object/array and it's not. Keypath: '${keyPath}' to object '${this.id}'`
		    }
		}

		if ( isObject(objectRef[currentKey]) || isArray(objectRef[currentKey]) ) {
			throw `HasProps.setProp() key '${currentKey}' is an object/array – not a value. Keypath: '${ keyPath }' with value ${ value }.`
		}

		objectRef[currentKey] = value

		return this
	}

	/**
	 * Get property
	 * 
	 * Example:
	 *     getProp("some-key") -> "value"
	 *     getProp("some-empty-value") -> 0 or ""
	 *     getProp("some-null-key") -> null
	 *     getProp("some-missing-key") -> undefined
	 *     getProp("assets:marker") -> "some-marker"
	 * 
	 * @param key
	 * @return anything
	 * @throws InvalidKey
	 */
	getProp(key) {
		console.log("get prop")
		if ( !isString(key) ) { throw `Thing.getProp() attribute 'key' is not a string. Got: ${ key }`}

		let keys = keyPath.split(':')
		let firstKey = keys[0]

		if ( firstKey === "asset" ) {
			let assetType = keys[1]

			return this.getAsset(assetType)
		}

		else if ( firstKey === "spaces" || firstKey === "space" ) {

			return this.spaces.map( x => x.id)
		}

		// ELSE : Traverse to last key in keyPath

		let currentKey
		let objectRef = this

		for ( let i = 0 ; i < keys.length ; i++ ) {

			currentKey = keys[i]

			if ( i + 1 >= keys.length ) {
				return objectRef[currentKey]
			}

		    if ( !isObject(objectRef[currentKey]) && !isArray(objectRef) ) {
		    	
		    	throw `HasProps.getProp() key '${currentKey}' is a value and not object/array - can't traverse deeper. Keypath: ${ keyPath }.`
		    }

		    objectRef = objectRef[currentKey]
		   	continue
		}

		return objectRef[currentKey] = value
	}

	/**
	 * Get property or die
	 * 
	 * Example:
	 *     getPropOrDie("some-key") -> "value"
	 *     getPropOrDie("some-empty-value") -> 0 or ""
	 *     getPropOrDie("some-null-key") -> exception: null value
	 *     getPropOrDie("some-missing-key") -> exception: missing key
	 * 
	 * @param key 
	 * @return object, array or value like string, number – never null or undefined
	 * @throws NotFound
	 * @throws NullValue
	 */
	getPropOrDie(key) {
		if ( !isDef(this[key]) ) { throw `Thing.getPropOrDie() missing property: ${key}` }
		if ( !isNull(this[key]) ) { throw `Thing.getPropOrDie() null value for key: ${key}` }

		return this[key]
	}


	/**
	 * Get property with value or die
	 * 
	 * Example:
	 *     getPropValueOrDie("some-key") -> "value"
	 *     getPropValueOrDie("some-empty-value") -> exception: empty value
	 *     getPropValueOrDie("some-null-key") -> exception: null value
	 *     getPropValueOrDie("some-missing-key") -> exception: missing key
	 * 
	 * May return object, array or value like string, number – never null or undefined
	 * 
	 * @param key
	 * @return string or number – never null, undefined, array or object
	 * @throws NotFound
	 * @throws NullValue
	 * @throws InvalidValue
	 * @throws EmptyValue
	 */
	getPropValueOrDie(key) {
		if ( !isDef(this[key]) ) { throw `Thing.getPropValueOrDie() missing property: ${key}` }
		if ( !isNull(this[key]) ) { throw `Thing.getPropValueOrDie() null value for key: ${key}` }
		if ( !isArray(this[key]) ) { throw `Thing.getPropValueOrDie() array value for key: ${key}` }
		if ( !isObject(this[key]) ) { throw `Thing.getPropValueOrDie() object value for key: ${key}` }
		if ( !isNonEmpty(this[key]) ) { throw `Thing.getPropValueOrDie() empty value for key: ${key} value: ${ this[key] }` }

		return this[key]
	}
}
