export default `

/* Import popup styles */

:host {
	display: flex !important;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;

	background: var(--popup-fog-color) !important;
}

.popup {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;

	background: var(--popup-bg-color);
	color: var(--popup-text-color);
	padding: var(--m-pad);

	border: solid 1px var(--popup-border-color);
	border-radius: var(--popup-border-radius);

	min-width: 30%;
	max-width: 60%;

	min-height: 30%
}

.texts p {
	margin-top: var(--m-font-size);
}

.buttons {
	text-align: center;
	padding-top: var(--l-pad);
}
.center {
	text-align: center;
}

p.guide,
::slotted(p.guide) {
	font-family: var(--tutorial-font-family);
	font-style: italic;
	color: var(--tutorial-text-color);
}

p.guide .label,
::slotted(p.guide .label) {
	font-size: inherit;
	white-space: nowrap;
}

::slotted(.center) {
	text-align: center;
}

::slotted(p.extra-pad-below) {
	padding-bottom: var(--m-pad);
}

::slotted(p.extra-pad-above) {
	padding-top: var(--m-pad);
}
`;