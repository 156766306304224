const ERROR_MESSAGE_INCLUDE_CODE = true;

var GLOBAL_TRANSLATOR = null;

// these must be defined in validators.mjs as well
const REGEX_VARIABLE = /\{\{(\$?[_\-a-z0-9]+)\}\}/i;
const REGEX_VARIABLE_ALL = /\{\{(\$?[_\-a-z0-9]+)\}\}/gi;
const REGEX_VARIABLE_ONLY = /^\{\{(\$?[_\-a-z0-9]+)\}\}$/i;

const REGEX_VARIABLE_OR_TRANS = /\{\{(\$?~?[_\-a-z0-9]+)\}\}/i;
const REGEX_VARIABLE_OR_TRANS_ALL = /\{\{(\$?~?[_\-a-z0-9]+)\}\}/gi;
const REGEX_VARIABLE_OR_TRANS_ONLY = /^\{\{(\$?~?[_\-a-z0-9]+)\}\}$/i;

const REGEX_SPLIT_KEY = /^([_\-a-z0-9]+):([_\-a-z0-9]+)$/i;

function niceError() {
    if ( typeof arguments[0] == "string" ) {
        let ymd = currentTimestamp()

        arguments[0] = `[ ${ ymd } ] ${arguments[0]}`
    }

    //console.error.apply(console, arguments)
    console.error(...arguments)
}

export const error = console.error.bind(niceError)

/**
 * Throw with additional information.
 *
 * Example with backticks / template literal:
 *
 *     a == b || hurl `a is not b`
 *
 * Example without string:
 *
 *     a == b || hurl("a is not b")
 *
 * The first syntax "abuses" the template literal tag function
 * mechanism to mimic how throw looks like.
 *
 * @param msg
 * @throws msg
 */
export function hurl(msg) {

    let message = msg

    if ( msg instanceof Array ) {
        // rebuild msg from backticks
        message = ""
        for ( let i = 0 ; i < msg.length ; i++ ) {
            message += msg[i] + (arguments[i+1] ? arguments[i+1] : '')
        }
    }

    throw message

    // ----
    // forget below - this function should be deleted
    /*
    let message = msg

    let caller = arguments.callee.caller

    let [functionName, functionParameters] = getFunctionParameters(caller)

    let args = []

    for ( let i = 0 ; i < functionParameters.length ; i++ ) {
        let param = functionParameters[i]
        let argument = caller.arguments[i]

        args.push( `    ${param}: ${argument}` )
    }

    let printout = [
        `Error: ${ message }\n`,
        `Function ${ functionName } (${functionParameters.join(", ")}):\n${ args.join("\n") }`
    ]

    if ( ERROR_MESSAGE_INCLUDE_CODE ) {
        printout.push(`\nCaller:\n${caller}`)
    }

    throw printout.join("\n")
    */
}

/**
 * Get function name (as string) and parameters (as array of strings)
 *
 * Source: https://davidwalsh.name/javascript-arguments
 *
 * @param { function } func
 * @return [ string, array ]
 */
function getFunctionParameters(func) {
  // First match everything inside the function argument parens.
  var [all, name, args] = func.toString().match(/function\s(.*?)\s?\(([^)]*)\)/);

  // Split the arguments string into an array comma delimited.
  let result = args.split(',').map(function(arg) {
    // Ensure no inline comments are parsed and trim the whitespace.
    return arg.replace(/\/\*.*\*\//, '').trim();
  }).filter(function(arg) {
    // Ensure no undefined values are added.
    return arg;
  });

  return [name, result]
}

export function warn(msg) {
    let message = msg

    if ( msg instanceof Array ) {
        message = ""
        for ( let i = 0 ; i < msg.length ; i++ ) {
            message += msg[i] + (arguments[i+1] ? arguments[i+1] : '')
        }
    }

    let time = currentTime()

    //console.warn.apply(console, arguments)
    console.warn(`[${time}] ${message}`)
}

function niceLog() {
    if ( typeof arguments[0] == "string" ) {
        let time = currentTime()

        arguments[0] = `[ ${ time } ] ${arguments[0]}`
    }

    //console.log.apply(console, arguments)
    console.log(...arguments)
}

export const log = console.log.bind(niceLog)

function niceDebug() {
    if ( typeof arguments[0] == "string" ) {
        let time = currentTime()

        arguments[0] = `[ ${ time } ] ${arguments[0]}`
    }

    console.debug.apply(console, arguments)
}

export const debug = console.debug.bind(niceDebug)

export function currentTimestamp() {
    let d = new Date()
    return `${ d.getFullYear() }-${ ('0' + (d.getMonth()+1)).slice(-2) }–${ ('0' + d.getDate()).slice(-2) } ${ ('0' + d.getHours()).slice(-2) }:${ ('0' + d.getMinutes()).slice(-2) }:${ ('0' + d.getSeconds()).slice(-2) }`
}

export function currentTime() {
    let d = new Date()
    return `${ ('0' + d.getHours()).slice(-2) }:${ ('0' + d.getMinutes()).slice(-2) }:${ ('0' + d.getSeconds()).slice(-2) }`
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms || 1))
}

export function toNumber(value) {
    if ( value === null || value === undefined || value === "" ) {
        return null
    }

    let number = Number(value)

    if ( Number.isNaN(number) ) {
        warn(`toNumber: can't convert value ${value} to number – returning original`)

        return value
    } else {
        return number
    }

}

export function toBoolean (value) {
    return (value === true || value === "true") ? true : false
}

export function roundToOne(number) {
    return Math.round((number + Number.EPSILON) * 10) / 10
}

export function roundToTwo(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export function generateId(length) {

    if ( !length || isNaN(length) || length < 0 || length > 20 ) {
        length = 16
    }

    // this is the "random" part
    let notVeryRandom = Date.now() * Math.floor(Math.random() * 1000)
    let almostRandom = `${notVeryRandom}`.padEnd(16, Math.floor(Math.random() * 10))

    // this solves repeated calls problem, so during runtime
    // atleast it's quaranteed unique (until length runs out)
    let uniqueAlmostRandom = almostRandom + `${ ++generateId.prototype.duplcateIdCounter }`

    let id = `${ uniqueAlmostRandom }`.slice(-length)

    return id
}

generateId.prototype.duplcateIdCounter = 0


/**
 * Usage:
 * let nestedObject = { ... }
 * mapNestedObject(nestedObject, (k, v) => {
 *     if ( k = "foobar" ) { return "new modified value" }
 *     // else return undefined => original value stays intact
 * });
 *
 * @param {object} object
 * @param {function} callback (key, value) – return new value
 */
export function mapNestedObject(object, callback) {
    for(var i in object) {
        if(object.hasOwnProperty(i)){
            mapNestedObject(object[i], undefined);
        } else {
            let ret = callback(i, object[i])

            if ( ret !== undefined ) {
                object[i] = ret
            }
        }
    }
    return null;
}

/**
 * Replace {{variable}} in a string. If it's not a string,
 * the original value is returned unchanged.
 *
 * Variable type will be preserved, if possible:
 *
 *   "I am {{age}}", { age: 18 } => "I am 18"
 *
 *   "{{age}}", { age: 18 }, => 18
 *   "{{age}}", { age: "18" }, => "18"
 *
 *   "{{tutorial}}", { tutorial: true }, => true
 *   "{{tutorial}}", { tutorial: "true" }, => "true"
 *
 * @param {string} text
 * @param {object} variables key-value pairs
 * @return value as string, boolean or number
 */
export function replaceVars(text, variables) {
    if ( typeof text !== "string" ) { return text }
    if ( !variables ) { return text }

    if ( REGEX_VARIABLE_ONLY.test(text) ) {
        let varName = REGEX_VARIABLE_ONLY.exec(text)[1]

        if ( typeof variables[varName] !== "undefined" ) {
            return variables[varName]
        }

        return match
    }
    else {
        let result = text.replaceAll(REGEX_VARIABLE_ALL, (match, name) => {
            if ( typeof variables[name] !== "undefined" ) {
                return variables[name]
            }

            return match
        })

        return result
    }

    throw `replaceVars() failed somehow`
}

export function setDefaultTranslator(translator) {
    GLOBAL_TRANSLATOR = translator
}

export function replaceTrans(text, translator) {
    if ( text == null ) { return text }
    if ( typeof translator !== "function" ) { translator = GLOBAL_TRANSLATOR }

    let result = translator(text)

    return result
}

/**
 * Translate a complicated object (or atleast try to)
 *
 * Variable placeholders, which do not have a replacement, are left in.
 *
 * –
 *
 * You can call this function multiple times on same string, using different
 * variable sets or translators.
 *
 * @param {any} Object, Array or HTMLTemplateElement
 * @param {object} variables as key-value pairs
 * @param {function} [translator]
 * @return string if something was a simple value
 */
export function replaceObjectTransVars(something, variables, translator) {

    if ( something instanceof HTMLTemplateElement ) {
        const tmp = something.ownerDocument.createElement('div')
        tmp.append(something.content.cloneNode(true))

        let oldHtmlString = tmp.innerHTML

        let newHtmlString = replaceTransVars(htmlString, variables, translator)

        const newTemplate = something.ownerDocument.createElement('div')
        newTemplate.innerHTML = newHtmlString

        return newTemplate

    }
    else if ( Array.isArray(something) ) {
        const tmp = new Array(something.length)

        for ( let i = 0 ; i < something.length ; i++ ) {
            tmp[i] = replaceObjectTransVars(something[i], variables, translator)
        }

        return tmp

    }
    else if ( typeof something === "object" ) {
        const tmp = {}

        for ( let x in something ) {
            tmp[x] = replaceObjectTransVars(something[x], variables, translator)
        }

        return tmp

    }
    else if ( typeof something !== "string" ) {
        return something

    }
    else {

        return replaceTransVars(...arguments)
    }
}

/**
 * Replace {{variable}} and {{~translation}} in a string.
 *
 * Variable placeholders, which do not have a replacement, are left in.
 *
 * Translation placeholders are always replaced with return value of translator.
 *
 * –
 *
 * You can call this function multiple times on same string, using different
 * variable sets or translators.
 *
 * @param {any} text
 * @param {object} [variables] (optional)
 * @param {function} [translator] (optional)
 * @return original value or new string with replaced variables/translations
 */
export function replaceTransVars(text, variables, translator) {
    let trans = translator ? translator : GLOBAL_TRANSLATOR

    let result = text

    // hits limit if: there are no variables to exchange -> forever
    // hits limit if: number of variables is > maxLoops
    // TODO: move this into the replaceAllTransVars
    // TODO: check for special cases:
    // 1) no variables
    // 2) looping two variables (a -> b -> a -> b ...)
    // 3) looping N variables ( a-b-c-d-a-b-c-d ...)
    let maxLoops = 100
    for ( let i = 0 ; i < maxLoops ; i++ ) {
        if ( typeof text !== "string" || text === "" ) {

            // just return text

        } else if ( REGEX_VARIABLE_OR_TRANS_ONLY.test(result) ) {
            result = _replaceOneTransVars(result, variables, trans, true)

        }
        else if ( REGEX_VARIABLE_OR_TRANS.test(result) ) {
            result = _replaceAllTransVars(result, variables, trans, true)
        }

        return result
    }

    console.warn("Warning: replaceTransVars() hit maxLoops for: " + String(text).slice(0, 50) )
    return result
}

/**
 * Replace {{variable}} and {{~translation}} in a string.
 *
 * Variable placeholders, which do not have a replacement are removed.
 *
 * Translation placeholders are always replaced with return value of translator.
 *
 * –
 *
 * You can call this function only once for same string, as it removes all
 * placeholders even if they match or not.
 *
 * @param {any} text
 * @param {object} [variables] (optional)
 * @param {function} [translator] (optional)
 * @return original value or new string with replaced variables/translations
 */
export function replaceTransVarsAndRemove(text, variables, translator) {
    let trans = translator ? translator : GLOBAL_TRANSLATOR

    if ( REGEX_VARIABLE_OR_TRANS_ONLY.test(text) ) {

        return _replaceOneTransVars(text, variables, trans, false)

    } else {

        return _replaceAllTransVars(text, variables, trans, false)
    }
}

/**
 * Replace trans/vars from a string that only contains 1 variable.
 *
 * Prefix $ will stringify the variable.
 * Prefix ~ will first look for a translation, which may contain variable name.
 *
 *   "{{age}}", { age: 18 }, => 18
 *   "{{$age}}", { age: 18 }, => "18"
 *
 *   "{{tutorial}}", { tutorial: true }, => true
 *   "{{$tutorial}}", { tutorial: true }, => "true"
 *
 *   "{{~trans-foobar}}", translations: { "trans-foobar": "something" }, => "something"
 *
 *   "{{list}}", { list: [1, 2, 3] } => [ 1, 2, 3]
 *   "{{$list}}", { list: [1, 2, 3] } => "1, 2 and 3"
 *
 *   "{{$~foobar}}", translations: { "foobar" => 15 }, => "15"
 *
 * List stringify depends on translation "variable-list-and".
 * If it isn't defined, falls back to & symbol.
 *
 * @param {string} text
 * @param {object} [variables]
 * @param {function} [translator=undefined]
 * @param {boolean} [keepPlaceholders=false]
 * @returns {string}
 */
function _replaceOneTransVars(variableText, variables, translator, keepPlaceholders) {
    if ( !REGEX_VARIABLE_OR_TRANS_ONLY.test(variableText) ) {
        if ( keepPlaceholders ) {
            return variableText
        } else {
            return ""
        }
    }

    let matches = REGEX_VARIABLE_OR_TRANS_ONLY.exec(variableText);
    let stringify = false
    let variableName = matches[1]
    let result = variableName;

    if ( variableName.startsWith("$") ) {
        variableName = variableName.slice(1)

        stringify = true
    }

    if ( variableName.startsWith("~") ) {
        variableName = variableName.slice(1)

        result = translator(variableName)
    }
    else {
        if ( variables && variables[variableName] !== undefined ) {
            result = variables[variableName]
        } else {
            if ( keepPlaceholders ) {
                result = matches[0]
            } else {
                result = variableName
            }
        }
    }

    if ( stringify ) {
        if ( result === undefined || result === null ) {
            result = ""
        }
        else if ( result instanceof Set ) {
            result = arrayToString(Array.from(result), translator("variable-list-and"))
        }
        else if ( Array.isArray(result) ) {
            result = arrayToString(result, translator("variable-list-and"))
        }
        else if ( typeof result === "object" ) {
            result = JSON.stringify(result)
        } else {
            result = String(result)
        }
    }

    return result
}


/**
 * Replace all trans/vars in a string.
 *
 * Arrays, objects etc. will always be stringified.
 *
 * Prefix $ is useless, as this will always stringify.
 * Prefix ~ will first look for a translation.
 *
 *   "I am {{age}}", { age: 18 }, => "I am 18"
 *
 *   "Tutorial: {{tutorial}}", { tutorial: true }, => "Tutorial: true"
 *
 *   "Button: {{~button-hide}}" => "Button: Hide"
 *
 *   "{{list}}", { list: [1, 2, 3] } => "1, 2 and 3"
 *
 * List stringify depends on translation "variable-list-and".
 * If it isn't defined, falls back to & symbol.
 *
 * @param {string} text
 * @param {object} [variables]
 * @param {function} [translator=undefined]
 * @param {boolean} [keepPlaceholders=false]
 * @returns {string}
 */
function _replaceAllTransVars(text, variables, translator, keepPlaceholders) {
    if ( !REGEX_VARIABLE_OR_TRANS_ALL.test(text) ) {
        return text
    }

    let match;
    let finalText = "";
    let newText = ""
    let prevIndex = 0

    REGEX_VARIABLE_OR_TRANS_ALL.lastIndex = 0

    while ((match = REGEX_VARIABLE_OR_TRANS_ALL.exec(text)) !== null) {
        let full = match[0] // "{{$foo}}"
        if ( full == `{{BRAWL_NPC_NAMES}}` ) {
            console.log("pause here")
        }

        let result = _replaceOneTransVars(full, variables, translator, keepPlaceholders)

        let lastIndex = REGEX_VARIABLE_OR_TRANS_ALL.lastIndex
        let before = text.slice(prevIndex, match.index)
        let after = text.slice(lastIndex, text.length)
        let middle;

        if ( result === null || result === undefined ) {
            middle = ""
        }
        else if ( result instanceof Set ) {
            middle = arrayToString(Array.from(result), translator("variable-list-and"))
        }
        else if ( Array.isArray(result) ) {
            middle = arrayToString(result, translator("variable-list-and"))
        }
        else if ( typeof result === "object" ) {
            middle = JSON.stringify(result)
        } else {
            middle = String(result)
        }

        prevIndex = lastIndex
        newText += before + middle
        finalText = after
    }

    newText += finalText
    return newText
}

/**
 * @param {array} array
 * @param {string} [lastSeparator="&"] (optional)
 */
function arrayToString(array, lastSeparator) {
    if ( array && !Array.isArray(array) ) { throw `arrayToString() attribute 'array' is not an Array. Got: ${ array }`}
    if ( !array ) { return "" } // don't mind "empty" or null values

    let value = ''

    for ( let i = 0 ; i < array.length ; i++ ) {
        if ( i == 0 ) {
            value = array[i]
            continue
        }
        else if ( i == (array.length - 1) ) {
            // last item
            value += ` ${lastSeparator || "&"} ${array[i]}`
        }
        else {
            value += ", " + array[i]
        }
    }

    return value
}

/**
 * Usage:
 *
 * let value = getRandomInt(max)
 * let value = getRandomInt(min, max)
 *
 * Min is inclusive, max is inclusive. Max must be larger or equal than min.
 *
 * getRandomInt(0) -> always 0
 * getRandomInt(1) -> 0 or 1
 * getrandomInt(2) -> 0, 1 or 2
 *
 * getRandomInt(0,0) -> always 0
 * getRandomInt(0,1) -> 0 or 1
 * getRandomInt(0,2) -> 0, 1 or 2
 *
 * @param {number} attr1 min or max (0-max)
 * @param {number} [attr2] max (optional)
 */
export function getRandomInt(attr1, attr2) {
    if ( typeof attr1 !== "number" ) { throw `getRandomInt() 1st attribute not a number. Got: ${ attr1 }` }
    if ( typeof attr2 !== "undefined" && typeof attr2 !== "number" ) { throw `getRandomInt() 2nd attribute not a number. Got: ${ attr2 }` }

    if ( typeof attr1 === 'number' && typeof attr2 === 'number' ) {
        if ( attr2 < attr1 ) {
            throw `getRandomInt() error: max < min. Got: ${ attr2 } < ${ attr1 }`
        }
    }

    let min = typeof attr2 === "number" ? attr1 : undefined
    let max = typeof attr2 === "number" ? attr2 : attr1

    max += 1

    if ( typeof min !== "undefined" ) {
        return Math.floor(Math.random() * (max - min) + min)
    }

    return Math.floor(Math.random() * max);
}


/**
 * Mixin for extending multiple classes:
 *
 *     class NewFoo extends mixin(Foo, Bar) { ... }
 *
 * NewFoo will be 'instaceof' NewFoo, not Foo and Bar.
 *
 * Based on:
 * https://stackoverflow.com/a/61860802
 *
 * @param { ... } arguments classes to mix-in
 */
export function mixin( ) {

    let baseClasses = [...arguments]

    class Mixin {
        constructor() {
            baseClasses.forEach(base => Object.assign(this, new base(this.constructor)));
        }
    }

    baseClasses.forEach(base => {
        Object.getOwnPropertyNames(base.prototype)
        .filter(prop => prop != 'constructor')
        .forEach(prop => Mixin.prototype[prop] = base.prototype[prop])

        // TODO make this smarter...
        if ( Object._triggered ) {
            Mixin.prototype._triggered = base.prototype._triggered
        }

        if ( Object._runnable ) {
            Mixin.prototype._runnable = base.prototype._runnable
        }

    })

    return Mixin;
}

export const generateAssetUrl = (assetPath) => { return '/'+assetPath }
