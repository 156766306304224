import { sleep, log, warn, error } from 'global.js'

import { isInteger } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

const MAX = 999
const MIN = 0

export class Stat {
	constructor(value, max, min) {
		if ( !isInteger(value) ) { throw `Stat.constructor() attribute 'value' not defined or not a number` }

		this.current = value
		this.max = isInteger(max) ? max : MAX
		this.min = isInteger(min) ? min : MIN
	}

	toString() { return `${this.current}` }

	valueOf() { return this.current }

	reset(value, max, min) {
		if ( !isInteger(value) ) { throw `Stat.set() attribute 'value' not defined or not a number` }

		this.current = currentValue
		this.max = isInteger(max) ? max : this.max
		this.min = isInteger(min) ? min : this.min

		return this
	}

	setMax(max) { this.max = max; return this }
	setMin(min) { this.min = min; return this }

	set(value) {
		if ( !isInteger(value) ) { throw `Stat.set() attribute 'value' not defined or not a number` }

		if ( value < this.min ) {
			log(`Stat.set() 'value' is smaller than minimum (${this.min}) - setting stat to min`)
		}

		if ( value > this.max ) {
			log(`Stat.set() 'value' is larger than maximum (${this.max}) - setting stat to max`)
		}

		this.current = Math.max(this.min, Math.max(this.min, value));

		return this.current
	}

	get() { return this.current }

	minus(stat) {
		let currentValue = this.get()
		let otherValue = stat.get()

		this.set( currentValue - otherValue )

		return this.get()
	}

	plus(stat) {
		let currentValue = this.get()
		let otherValue = stat.get()

		this.set( currentValue + otherValue )

		return this.get()
	}
}

export class Damage extends Stat {
	constructor(value) {
		if (this.constructor == Damage) {
			throw "Damage.constructor(): tried to create Damage object directly – use child classes"
		}

		super(value, MAX, MIN)
	}
}

export class PhysicalDamage extends Damage {
	constructor(value) { super(value) }
}

export class HeavyDamage extends PhysicalDamage {
	constructor(value) { super(value) }
}
