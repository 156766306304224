export default `

/* Import item view styles */

:host {
	display: flex !important;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;

	background: var(--popup-fog-color) !important;
}

#dialog {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;

	background: var(--popup-bg-color);
	color: var(--popup-text-color);
	padding: var(--xl-pad);

	border: solid 1px var(--popup-border-color);
	border-radius: var(--popup-border-radius);

	min-width: 40%;
	max-width: 80%;

	min-height: 30%
	max-height: 80%;
}


div.left {
	width:  40%;
    height: 100%;
}

div.right {
	width: 60%;
	height: 100%;

	padding: var(--m-font-size);
}

#item-image {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: contain;
    background-position: center;
}

#buttons {
	text-align: left;
	padding-top: var(--l-pad);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

`;