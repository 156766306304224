import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, objectHasOnly, isString, isBoolean, isInteger, isObject, isArray } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'

import { Event, EVENT_TYPES } from 'Command/Event.mjs'

const SLIDESHOW_EVENTS = ["slideshow-end"]

EVENT_TYPES.push(...SLIDESHOW_EVENTS)

export class EndSlideshowEvent extends Event {
	static eventType = "slideshow-end"
	static requiredKeys = ["event", "player", "slideshow"]
	static optionalKeys = ["choices"]

	constructor(player, slideshow) {
		super ({
			player: player ? player.id : null,
			slideshow: slideshow.name,
			choices: slideshow.slideset.getChoices(),
		})
	}
}
