import { sleep, error, warn, log, debug } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { AnimatedElement } from 'UI/CustomElement.mjs'

import { Thing } from 'Thing/Thing.mjs'
import { Character } from 'Thing/Characters.mjs'

import { T } from 'Game/Translations.mjs'

const AUTOFLIP_TIMEOUT = 2000

export class Card extends AnimatedElement {

	static templateString

	constructor() {
		super()
		this.onclick = this.onClick2

		this.addEventListener('click', this.action)

		if ( this.classList.contains('autoflip') ) {
			setTimeout(()=>{
				this.classList.add('flip')
			}, AUTOFLIP_TIMEOUT)
		}
	}

	/**
	 * Trigger action defined in parent (or grandparent)
	 *
	 * @param {Event} event
	 */
	action(event) {

		let action = this.getAttribute?.('action')

		let feedback = false

		switch(action) {
			case "flip":
				this.classList.toggle('flip')
				feedback = true
				break;

			case "select":
				if ( this.classList.toggle('selected') ) {
					this.setAttribute('select', this.id)
					this.removeAttribute('unselect')
				} else {
					this.setAttribute('unselect', this.id)
					this.removeAttribute('select')
				}
				feedback = true
				break;
		}

		// @ts-ignore : getRootNode() returns ShadowRoot or Node
		let root = this.getRootNode().host || this.getRootNode()

		if ( !root.action ) {
			root = root.getRootNode().host || this.getRootNode()
		}

		if ( !root.action ) {
			error(this)
			throw `Card.action() can't find root element with action`
		}

		feedback = root.action(action, this, event) || feedback

		feedback && Game.global.playSfx('button-click')
	}

	updateElement(name, description, frontAsset, backAsset) {

		this.querySelector('.back .title').innerText = name
		this.querySelector('.back .text').innerHTML = description

		this.updateImage(this.querySelector('.front'), frontAsset)
		this.updateImage(this.querySelector('.back'), backAsset)

		return this
	}

	/**
	 *
	 * @param { boolean } animate
	 * @return nothing
	 */
	async show(animate) {

		await super.show(animate)
	}
}

import importMainStyles from "css/import-main.css.mjs";
import importCardStyles from "css/import-card.css.mjs";

Card.templateString = `

<style>
	${ importMainStyles }
	${ importCardStyles }
</style>
<div class="front">

<!-- Stats disabled for now. - Jan 25th 2024
	<div class="stats">

		<div class="top">
			<div class="icon health"></div>
			<div class="value health"></div>
		</div>
		<div class="bottom">
			<div class="value attack"></div>
			<div class="icon attack"></div>
		</div>

	</div>
-->

</div>
<div class="back">
	<div class="title"></div>
	<div class="text"></div>
</div>

`;

export class CharacterCard extends Card {
	static templateString = Card.templateString

	constructor() {
		super()

		if ( this.getAttribute('id') ) {
			try {
				let thing = Game.global.getThing( this.getAttribute('id') )

				this.updateElementFromThing(thing)

			} catch (err) {
				// This is normal
			}
		}
	}

	updateElementFromThing(thing) {
		this.id = thing.id
		
		return this.updateElement(
			thing.name,
			thing.description,
			thing.getAsset('cardFront'),
			thing.getAsset('cardBack')
		)

/* Stats disabled for now. - Jan 25th 2024
		this.statHealthElement = this.querySelector('.front .health.value')
		this.statHealthElement.innerText = this.character.getHealth()

		this.statAttackElement = this.querySelector('.front .attack.value')
		this.statAttackElement.innerText = this.character.getAttack()
*/
	}
}

window.customElements.define('character-card', CharacterCard)

export class EffectCard extends Card {
	static templateString = Card.templateString

	constructor(){
		super()

		if ( this.getAttribute('id') ) {
			let id = this.getAttribute('id')

			let front = Game.global.getAsset(`${id}-front`)
			let back = Game.global.getAsset(`${id}-back`)

			// disabled because image itself currently has details
			let name = '' // T(`${id}-name`)
			let description = '' // T(`${id}-description`)

			this.updateElement(name, description, front, back)
		}
	}
}

window.customElements.define('effect-card', EffectCard)
