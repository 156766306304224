import { log, debug, warn, error } from 'global.js'
import { isDef, isNull, isNumber, isBoolean } from 'validators.mjs'

import 'External/howler-patched.core.js'

// prevent default touchend hack
Howler.autoUnlock = false;
Howler.html5PoolSize = 10;

export class AudioManager {
    static stopAllAudio = Howler.stop
    static unloadAllAudio = Howler.unload

    static BACKGROUND_VOLUME_FACTOR = 0.75

    static playingBackgroundMusic = {}
    static backgroundMusicVolume = 0.75

    static playingForegroundMusic = {}
    static foregroundMusicVolume = 1.0

    static playingSfx = {}
    static sfxVolume = 0.5

    static playingVoice = {}
    static voiceVolume = 1.0

    static allPlaying = {}

    static taggedPlaying = {}

    constructor({
        musicVolume = AudioManager.musicVolume,
        sfxVolume = AudioManager.sfxVolume,
        voiceVolume = AudioManager.voiceVolume
    }={}) {

        this.setVolumes(musicVolume, sfxVolume, voiceVolume)
    }

    setVolumes(musicVolume, sfxVolume, voiceVolume) {
        AudioManager.backgroundMusicVolume = parseFloat(musicVolume * AudioManager.BACKGROUND_VOLUME_FACTOR).toFixed(2)
        AudioManager.foregroundMusicVolume = parseFloat(musicVolume).toFixed(2)
        AudioManager.sfxVolume = parseFloat(sfxVolume).toFixed(2)
        AudioManager.voiceVolume = parseFloat(voiceVolume).toFixed(2)

        this.updatePlayingVolumes()
    }

    updatePlayingVolumes() {
        for ( let audioId in AudioManager.playingBackgroundMusic ) {
            let audio = AudioManager.playingBackgroundMusic[audioId]

            if ( audio.isPlaying() ) {
                audio.volume( AudioManager.backgroundMusicVolume )
            }
        }

        for ( let audioId in AudioManager.playingForegroundMusic ) {
            let audio = AudioManager.playingForegroundMusic[audioId]

            if ( audio.isPlaying() ) {
                audio.volume( AudioManager.foregroundMusicVolume )
            }
        }

        for ( let audioId in AudioManager.playingSfx ) {
            let audio = AudioManager.playingSfx[audioId]

            if ( audio.isPlaying() ) {
                audio.volume( AudioManager.sfxVolume )
            }
        }

        for ( let audioId in AudioManager.playingVoice ) {
            let audio = AudioManager.playingVoice[audioId]

            if ( audio.isPlaying() ) {
                audio.volume( AudioManager.voiceVolume )
            }
        }
    }

    /**
     * @param {Audio} audio
     * @param {string} tag
     */
    tag(audio, tag) {
        if ( !audio ) { error(`AudioManager.tag() undefined audio: '${audio}'`) }
        if ( !tag ) { error(`AudioManager.tag() undefined tag: '${tag}'`) }

        if ( !AudioManager.taggedPlaying[tag] ) {
            AudioManager.taggedPlaying[tag] = {}
        }

        if ( !AudioManager.taggedPlaying[tag][audio.id] ) {
            AudioManager.taggedPlaying[tag][audio.id] = audio
        } else {
            debug(`AudioManager.tag() audio ('${audio.id}')already has tag '${audio.tag}'`)
        }
    }

    /**
     * @param {Audio} audio
     * @param {number} [fadeDuration] (optional) fade in duration in milliseconds
     */
    async playBackgroundMusic(audio, fadeDuration) {
        return await this.#play(audio, true, false, AudioManager.backgroundMusicVolume, AudioManager.playingBackgroundMusic, fadeDuration)
    }

    /**
     * @param {Audio} audio
     * @param {number} [fadeDuration] (optional) fade in duration in milliseconds
     * @param {boolean} yieldBackgroundMusic (optional) lower background music volume while this plays
     */
    async playForegroundMusic(audio, fadeDuration, yieldBackgroundMusic) {

        if ( yieldBackgroundMusic ) {
            this.yieldBackgroundMusic(audio)
        }

        return await this.#play(audio, false, false, AudioManager.foregroundMusicVolume, AudioManager.playingForegroundMusic, fadeDuration)
    }

    /**
     * @param {Audio} audio
     * @param {number} [fadeDuration] (optional) fade in duration in milliseconds
     * @param {boolean} yieldBackgroundMusic (optional) lower background music volume while this plays
     */
    async playSfx(audio, fadeDuration, yieldBackgroundMusic) {

        if ( yieldBackgroundMusic ) {
            this.yieldBackgroundMusic(audio)
        }

        return await this.#play(audio, false, true, AudioManager.sfxVolume, AudioManager.playingSfx, fadeDuration)
    }

    /**
     * @param {Audio} audio
     * @param {number} [fadeDuration] (optional) fade in duration in milliseconds
     * @param {boolean} yieldBackgroundMusic (optional) lower background music volume while this plays
     */
    async playVoice(audio, fadeDuration, yieldBackgroundMusic) {

        if ( yieldBackgroundMusic ) {
            this.yieldBackgroundMusic(audio)
        }

        return await this.#play(audio, false, false, AudioManager.voiceVolume, AudioManager.playingVoice, fadeDuration)
    }


    /**
     * @param {Audio} audio
     * @param {boolean} loop
     * @param {boolean} duplicateIfNeeded
     * @param {number} volume
     * @param {Object} playingList (internal)
     * @param {number} [fadeDuration] (optional) fade in duration in milliseconds
     */
    async #play(audio, loop, duplicateIfNeeded, volume, playingList, fadeDuration) {
        if ( playingList[audio.id] && !duplicateIfNeeded ) {
            warn(`AudioManager.play() audio '${audio.id}' already playing and duplicate disabled - ignoring this`)
            return null
        }

        if ( !playingList[audio.id] ) {
            playingList[audio.id] = audio
        }

        if ( AudioManager.allPlaying[audio.id] ) {
            AudioManager.allPlaying[audio.id] = audio
        }

        audio.once("stop", (audioId)=>{
//            debug(`Audio.play() STOP event called for: ${audio.id} (${audioId})`)

            // if there's duplicates, ignore this
            if ( duplicateIfNeeded && audio.isPlaying() ) {
                return
            }

            delete playingList[audio.id]
            delete AudioManager.allPlaying[audio.id]
        })

        audio.once("end", (audioId)=>{
//            debug(`Audio.play() END event called for: ${audio.id} (${audioId}) - loop is set to: ${loop}`)

            // 'end' is triggered for loops - ignore them
            // if there's duplicates, ignore this
            if ( loop || (duplicateIfNeeded && audio.isPlaying()) ) {
                return
            }

            delete playingList[audio.id]
            delete AudioManager.allPlaying[audio.id]
        })

        if ( fadeDuration ) {
            audio.playWithFade(volume, loop, fadeDuration)
        } else {
            audio.play(volume, loop)
        }
    }

    stop(audioId) {
        if ( AudioManager.allPlaying[audioId] ) {
            AudioManager.allPlaying[audioId].stop()

        } else {
            warn(`AudioManager.stop() audio '${audioId}' not found in playing list - ignoring this`)
        }
    }

    stopAllBackgroundMusicWithFade() {
        if ( AudioManager.allPlaying[audioId] ) {
            AudioManager.allPlaying[audioId].stop()

        } else {
            warn(`AudioManager.stop() audio '${audioId}' not found in playing list - ignoring this`)
        }
    }

    yieldBackgroundMusic(audio) {

        let fadeInTime = 1000
        let fadeOutTime = 3000
        let yieldVolume = 0.2

        let bgAudios = Object.keys(AudioManager.playingBackgroundMusic || {})

        if ( bgAudios.length == 0 ) { return }

        let bgVolumes = []

        audio.once('play', ()=>{
            for ( let audioId of bgAudios ) {
                let bgAudio = AudioManager.playingBackgroundMusic[audioId]

                if ( bgAudio ) {
                    let oldVolume = bgAudio.volume() || bgAudio.originalVolume

                    bgAudio.fade( oldVolume, yieldVolume, fadeInTime )

                }
            }
        })

        audio.once('stop', ()=>{
            for ( let audioId of bgAudios ) {
                let bgAudio = AudioManager.playingBackgroundMusic[audioId]

                if ( bgAudio ) {
                    let newVolume = bgAudio.originalVolume

                    bgAudio.fade( yieldVolume, newVolume, fadeOutTime)
                }
            }
        })

        audio.once('end', ()=>{
            for ( let audioId of bgAudios ) {
                let bgAudio = AudioManager.playingBackgroundMusic[audioId]

                if ( bgAudio ) {
                    let newVolume = bgVolumes.originalVolume

                    bgAudio.fade( yieldVolume, newVolume, fadeOutTime)
                }
            }
        })

        return
    }

    /**
     * @returns Promise
     */
    fadeOutAllBackgroundMusic() {
        return this.fadeOutAllAudioFromList(AudioManager.playingBackgroundMusic)
    }

    /**
     * @returns Promise
     */
    fadeOutAllForegroundMusic() {
        return this.fadeOutAllAudioFromList(AudioManager.playingForegroundMusic)
    }

    /**
     * @returns Promise
     */
    fadeOutAllMusic() {
        let combined = {
            ...AudioManager.playingForegroundMusic,
            ...AudioManager.playingBackgroundMusic,
        }

        return this.fadeOutAllAudioFromList(combined)
    }

    /**
     * @param {string} tag
     * @returns Promise
     */
    fadeOutAllAudioWithTag(tag) {
        return this.fadeOutAllAudioFromList(AudioManager.taggedPlaying[tag])
    }

    /**
     * @returns Promise
     */
    fadeOutAllAudio() {
        return this.fadeOutAllAudioFromList(AudioManager.allPlaying)

    }

    /**
     * @returns Promise
     */
    fadeOutAllAudioFromList(playingList) {
        return new Promise((resolve, reject) => {

            const fadeDuration = 1000
            let counter = 0;

            let counterFallback = setTimeout(()=>{
                resolve()
            }, fadeDuration + 250)

            for ( let id in playingList ) {
                try {
                    let audio = playingList[id]
                    counter++

                    audio.once('fade', ()=>{
                        audio.stop()
                        counter--

                        if ( counter <= 0 ) {
                            clearTimeout(counterFallback)
                            resolve()
                        }
                    })

                    audio.fade(audio.volume(), 0, fadeDuration)

                } catch (err) {
                    error(`AudioManager.fadeOutAllAudio() error: ${err}`)
                }
            }
        })
    }

    unloadAllAudio() {
        AudioManager.stop()
        AudioManager.unloadAllAudio()
    }
}

export class Audio {

    constructor(id, url, style, length) {

        if ( !id ) { throw `Audio.constructor() didn't get id parameter: ${id} ` }
        if ( !url ) { throw `Audio.constructor() didn't get url parameter: ${url} ` }
        if ( style != "music" && style != "sfx" && style != "voice" ) { throw `Audio.constructor() style parameter not music|sfx: ${style}` }
//        if ( length !== 0 && !length ) { throw `Audio.constructor() didn't get length parameter: ${length} ` }

        this.id = id
        this.url = url
        this.style = style
        this.length = length

    }

    /**
     * Called by AssetsBrowserCache
     *
     * @return nothing
     */
    preload(resolve, reject) {
        try {
            this.howl = new Howl({
                src: [this.url],
                preload: true,
                html5: this.useHtmlForThisAudio(),
                onload: ()=>{
                    if ( resolve ) { resolve(this) }
                },
                onloaderror: (id,err)=>{
                    error(`Audio.preload() failed to load '${this.id}' - errocode (check howler doc): ${err}\n${this.url}`)
                    if ( reject ) { reject() }
                },
            })

        } catch (err) {
            warn(`Audio.preload() error: ${err}`)
        }
    }

    on() {
        return this.howl.on.apply(this.howl, arguments)
    }

    once() {
        return this.howl.once.apply(this.howl, arguments)
    }

    fade() {
        return this.howl.fade.apply(this.howl, arguments)
    }

    volume() {
        return this.howl.volume.apply(this.howl, arguments)
    }

    rewind(seconds) {
        let currentPosition = this.howl.seek()
        this.howl.seek( Math.max(0, currentPosition - seconds) )
        //this.howl.play()
    }

    forward(seconds) {
        let currentPosition = this.howl.seek()
        let length = this.howl.duration()

        this.howl.seek( Math.min(length, currentPosition + seconds) )
        //this.howl.play()
    }

    useHtmlForThisAudio() {
        if ( this.style === "music" ) {
            return true
        }

        return false
    }

    play(volume, loop) {
        if ( !this.howl ) { this.preload() }

        if ( isDef(volume) && !isNumber(volume) ) { throw `Audio.play() 'volume' not a number. Got: ${ volume }` }
        if ( isDef(loop) && !isBoolean(loop) ) { throw `Audio.play() 'loop' not a boolean. Got: ${ loop }` }

        if ( loop ) {
            this.howl.loop(true)
        }

        if ( volume ) {
            this.howl.volume(volume)
        }

        this.originalVolume = isDef(volume) ? volume : 1

        return this.howl.play()
    }

    playWithFade(volume, loop, fadeDuration, yieldBackgroundMusic) {
        if ( !this.howl ) { this.preload() }

        if ( isDef(volume) && !isNumber(volume) ) { throw `Audio.playMusicWithFade() 'volume' not a number. Got: ${ volume }` }
        if ( isDef(fadeDuration) && !isNumber(fadeDuration) ) { throw `Audio.playMusicWithFade() 'fadeDuration' not a number. Got: ${ fadeDuration }` }
        if ( isDef(loop) && !isBoolean(loop) ) { throw `Audio.playMusicWithFade() 'loop' not a boolean. Got: ${ loop }` }


        if ( loop ) {
            this.howl.loop(true)
        }

        if  (!isDef(volume) ) {
            volume = this.howl.volume()
        }

        let startVolume = 0
        let endVolume = isDef(volume) ? volume : 1

        this.originalVolume = endVolume

        let fadeInDelay = isNumber(fadeDuration) ? fadeDuration : 1000

        this.howl.volume(startVolume)

        this.howl.once('play', (eventSoundId)=>{
            this.howl.fade(startVolume, endVolume, fadeInDelay, eventSoundId)
        })

        if ( yieldBackgroundMusic ) {
            GAME_API.audioManager.yieldBackgroundMusic(this)
        }

        return this.howl.play()
    }

    stop() {
        if ( !this.howl ) { this.preload() }

        return this.howl.stop()
    }

    stopWithFade(duration) {
        if ( !this.howl ) { this.preload() }

        if ( !this.howl.playing() ) {
            return
        }

        let startVolume = this.howl.volume() || 1
        let endVolume = 0
        let dur = isNumber(duration) ? duration : 1000

        this.howl.once('fade', (eventSoundId) => {
            this.howl.stop(eventSoundId)
        })

        this.howl.fade(startVolume, endVolume, dur)
    }

    pause() {
        if ( !this.howl ) { this.preload() }

        return this.howl.pause()
    }

    pauseWithFade(duration) {
        if ( !this.howl ) { this.preload() }

        if ( !this.howl.playing() ) {
            return
        }

        let startVolume = this.howl.volume() || 1
        let endVolume = 0
        let dur = isNumber(duration) ? duration : 1000

        this.howl.once('fade', (eventSoundId) => {
            this.howl.pause(eventSoundId)
        })

        return this.howl.fade(startVolume, endVolume, dur)
    }

    isPlaying() {
        return this.howl.playing()
    }

    getPosition() {
        return this.howl.seek()
    }
}

function logAudioStatus() {
    debug("Audio library loaded:")
    debug(`- usingWebAudio: ${Howler.usingWebAudio}`)
    debug(`- noAudio: ${Howler.noAudio}`)
    debug(`- autoUnlock: ${Howler.autoUnlock}`)
    debug(`- html5PoolSize: ${Howler.html5PoolSize}`)
    debug(`- autoSuspend: ${Howler.autoSuspend}`)
    debug(`- ctx: ${Howler.ctx}`)
    debug(`- masterGain: ${Howler.masterGain}`)
    debug(`- autoSuspend: ${Howler.autoSuspend}`)
}

logAudioStatus()
