// these must be defined in global.mjs as well
const REGEX_VARIABLE = /\{\{(\$?[_\-a-z0-9]+)\}\}/i;
const REGEX_VARIABLE_ALL = /\{\{(\$?[_\-a-z0-9]+)\}\}/gi;
const REGEX_VARIABLE_ONLY = /^\{\{(\$?[_\-a-z0-9]+)\}\}$/i;

const REGEX_VARIABLE_OR_TRANS = /\{\{(\$?~?[_\-a-z0-9]+)\}\}/i;
const REGEX_VARIABLE_OR_TRANS_ALL = /\{\{(\$?~?[_\-a-z0-9]+)\}\}/gi;
const REGEX_VARIABLE_OR_TRANS_ONLY = /^\{\{(\$?~?[_\-a-z0-9]+)\}\}$/i;

const REGEX_SPLIT_KEY = /^([_\-a-z0-9]+):([_\-a-z0-9]+)$/i

export function isBoolean(x) {
	return typeof x === "boolean"
}

export function isString(x) {
	return isDefNotNull(x) && (typeof x === 'string' || x instanceof String)
}

export function isInteger(x) {
	return isDefNotNull(x) && Number.isInteger(x)
}

export function isNumber(x) {
	return isDefNotNull(x) && x !== "" && !isNaN(x)
}

export function isObject(x) {
	return typeof x === 'object' && !Array.isArray(x) && x !== null
}

export function isArray(x) {
	return typeof x === 'object' && Array.isArray(x) && x !== null
}

export function isSet(x) {
	return x instanceof Set
}

export function isDef(x) {
	return typeof x !== "undefined"
}

export function isNull(x) {
	return x === null
}

export function isDefNotNull(x) {
	return isDef(x) && !isNull(x)
}

export function isUndefOrNull(x) {
	return typeof x === "undefined" || x === null
}

export function isValue(x) {
	return isDef(x) && !isNull(x) && (isString(x) || isNumber(x))
}

export function isNonEmpty(x) {
	return isDef(x) && !isNull(x) && ((isString(x) && x !== "") || (isNumber(x) && x !== 0) || (isArray(x) && x.length > 0) || (isObject(x) && Object.keys(x).length > 0))
}

export function isEmpty(x) {
	return !isNonEmpty(x)
}

export function isFunction(x) {
	return typeof x === "function"
}

export function isVar(x) {
	return isString(x) && REGEX_VARIABLE_ONLY.test(x)
}

export function isStringOrVar(x) {
	return isString(x) || isVar(x)
}

export function isBooleanOrVar(x) {
	return isBoolean(x) || isVar(x)
}
export function isIntegerOrVar(x) {
	return isInteger(x) || isVar(x)
}

export function isObjectOrVar(x) {
	return isObject(x) || isVar(x)
}

export function isArrayOrVar(x) {
	return isArray(x) || isVar(x)
}


export function isVariable(x) {
	return /^\{\{[\_\-\:0-9a-z]+\}\}$/i.test(x)
}

export function objectHasOnly(obj, required) {
	return objectHas(obj, [], required)
}

export function objectHas(obj, allowed, required) {
	if ( !isArray(allowed) || !isObject(obj) ) {
		throw `objectHas invalid arguments.\n\nNeeded: object, array, (array)\n\nAllowed keys:\n${ allowed }\n\nObject has:\n${ Object.keys(obj) }`
	}

	let keys = Object.keys(obj)
	let all = allowed

	// converts keypaths to keys, so "assets:marker" is just 'assets' for the validation
	for ( let i = 0 ; i < keys.length ; i++ ) {

		if ( keys[i].includes(":") ) {
			let match = REGEX_SPLIT_KEY.exec(keys[i])
			let keyPart = match[1]

			keys[i] = keyPart
		}
	}

	if ( typeof required !== "undefined" && isArray(required) ) {
		all = all.concat(required)
	}

	all.every( x =>  { if ( typeof x === "undefined" || x === null ) { return false } } )

	// check that each key is in allowed or required
	let ret = keys.every( x => all.includes(x) )

	// check each required is in keys
	if ( ret && typeof required !== "undefined" ) {

		if ( !isArray(required) ) {
			throw `objectHas() invalid arugments.\n\nNeeded: object, array, (array)\n\nAllowed keys:\n${ all }\n\nRequired keys:\n${ required }\n\nObject has:\n${ keys }`
		}

		ret = required.every( x => x in obj )
	}

	return ret
}

export function arrayHasOnly(keys, required) {
	return arrayHas(keys, [], required)
}

export function arrayHas(keys, allowed, required) {
	if ( !isArray(required) || !isArray(keys) ) { throw `arrayHas() invalid arguments. Needed: array, array, (array)`}

	let all = allowed

	if ( typeof required !== "undefined" && isArray(required) ) {
		all = all.concat(required)
	}

	all.every( x =>  { if ( typeof x === "undefined" || x === null ) { return false } } )

	// check that each key is in allowed or required
	let ret = keys.every( x => all.includes(x) )

	// check each required is in keys
	if ( ret && typeof required !== "undefined" ) {

		if ( !isArray(required) ) {
			throw `arrayHas() invalid arugments.\n\nNeeded: array, array, (array)\n\nAllowed keys:\n${ all }\n\nRequired keys:\n${ required }\n\nArray has:\n${ keys }`
		}

		ret = required.every( x => keys.includes(x) )
	}

	return ret
}

// This is too simple function for copying the value
export function copy(value) {
    let copy;

    if ( isSet(value) ) { copy = new Set(value) }
    else if ( isArray(value) ) { copy = [...value] }
    else if ( isObject(value) ) { copy = JSON.parse(JSON.stringify(value)) }
    else { copy = value }

    return copy
}
