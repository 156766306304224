import { sleep, log, warn, error, debug } from 'global.js'
import { isDef, isObject, isArray, isInteger } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { UI } from 'UI/UI.mjs'
import { Processor } from 'Command/Processor.mjs'
import { createCommand, createEvent } from 'Command/create.mjs'

import { EndGameEvent } from 'Command/EventsMission.mjs'

export class Script {

	static version = 33
	static runLoopDelay = 100

	constructor(scriptSpecs) {
		if ( !isDef(scriptSpecs) ) {
			throw `Script.constructor() missing argument 'scriptSpecs'`
		}

		if ( !isObject(scriptSpecs) ) {
			throw `Script.constructor() argument 'scriptSpecs' is not an object. Got: ${ scriptSpecs }`
		}

		if ( scriptSpecs.version != Script.version ) {
			throw `Script.constructor() bad version: ${scriptSpecs.version} != ${ Script.version}`
		}

		for ( let key in scriptSpecs ) {

			this[key] = scriptSpecs[key]

		}

		Object.defineProperty(this, 'position', { value: { scene: null, index: 0 }, writable: false })
		Object.defineProperty(this, 'processor', { value: null, writable: true })
	}

	/**
	 * Start running the game script itself.
	 *
	 * Script entries are divided into scenes and look like this:
	 *
	 *     { "goto": "scene-2 "}
	 *     { "cmd": "doSomething", ... }
	 *
	 * Depending on entry type, we handle it ours selves or send it
	 * to Processor.
	 *
	 * All entries from current scene at processed at once, but
	 * we wait until processor is "empty" if we encounter a "goto".
	 *
	 * Note this returns a Promise, that will "never" resolve.
	 *
	 * The Promise is only resolved when game is closed and player
	 * should be returned to main menu.
	 *
	 * @param { string } sceneName of the scene in GAME_SCRIPT
	 * @param { Processor } processor
	 * @param { object } variables as key-value pairs
	 * @return { Promise } Resolves only when game is finished
	 */
	async runProcessor(sceneName, processor, variables) {
		if ( !isArray(this[sceneName]) ) {
			throw `Script.runProcessor() unknown scene: '${sceneName}'\n\nList of scenes:\n${ Object.keys(this) }`
		}

		this.processor = processor
		this.processor.addListenerOnce(EndGameEvent.eventType, this.endGameCallback)
		this._endGame = false
		this._setPositionToSceneStart(sceneName)

		while ( true ) {

			await this.process(variables)

			if ( this._closeGame ) {
				debug(this.constructor.name + `.runProcessor() got closeGame - stopping loop`)

				await this.processor.stop()
				break
			}

			await sleep(Script.runLoopDelay)
		}

		return
	}

	async endGameCallback() {
		this._endGame = true
		this.processor.stop()
		this._resetPositionToNothing()

		await Game.global.ui.closeInGameViewsAndReturnToMainmenu()
	}

	/**
	 * Process next script entry, based on current position.
	 *
	 * @param { object } variables as key-value pairs
	 */
	async process(variables) {
		let entry = this._getCurrentEntry()

		//debug(`Script: ${ JSON.stringify(entry) }`)

		if ( entry?.wait ) {
			await this.wait( entry.wait )

		}
		else if ( entry?.goto ) {
			await this.goto( entry.goto )

		}
		else if ( entry?.cmd ) {
			this.command(entry, variables)

		}
		else if ( entry?.event ) {
			this.event(entry, variables)

		}		
		else if ( entry?.closeGame ) {
			this._closeGame = true
			return
		}
		else if ( entry === null ) {
			return
		}

		this._increatePositionIndexByOne()	
	}

	/**
	 * Run Command from script entry by sending it to Processor
	 *
	 * Called by process()
	 *
	 * @param { object } entry from script
	 * @param { object } variables as key-value pairs
	 * @return nothing
	 */
 	command(entry, variables) {
		let cmd = createCommand(entry)

		this.processor.queueCommands(cmd, variables)
	}

	/**
	 * Create Event from script entry by sending it to Processor
	 *
	 * Called by process()
	 *
	 * @param { object } entry from script
	 * @return nothing
	 */
 	event(entry) {
 		console.log("This isn't tested yet.")
 		
		let event = createEvent(entry)

		this.processor.queueCommands(event)
	}

	/**
	 * Wait until some event happens
	 *
	 * Called by process()
	 *
	 * @param { string } sceneName
	 */
 	wait(eventName) {
		return new Promise((resolve, reject) => {
			this.processor.addListenerOnce(eventName, resolve)
		})
	}

	/**
	 * Jump to scene and wait until processor is finished
	 * with all previous work.
	 *
	 * Called by process()
	 *
	 * Continues after receiving gotoCallback().
	 *
	 * @param { string } sceneName
	 */
	goto(sceneName) {
		log (`Script.goto() scene '${sceneName}' – waiting for processor.`)
		this._setPositionToSceneStart(sceneName)

		return new Promise((resolve, reject) => {
			this.processor.addListenerOnce(Processor.PROC_EMPTY, resolve)

		}).finally(() =>{
			log (`Script.goto() Processor callback received – continuing script.`)
		})
	}

	/**
	 * Internal
	 *
	 * Returns the script entry from current position at (scene, index)
	 * or null if nothing available anymore
	 *
	 * @return { object } entry from script or null if last
	 */
	_getCurrentEntry() {
		if ( this.position.index >= this[this.position.scene].length ) {
			return null
		}

		return this[this.position.scene][this.position.index]
	}

	/**
	 * Internal
	 *
	 * Update current position to next index if possble
	 *
	 * @return nothing
	 */
	_increatePositionIndexByOne() {
		if ( this.position.scene == null ) {
			warn(`Script._increatePositionIndexByOne() position has been reset to null - ignoring this`)
		}

		let current = this[this.position.scene]

		this.position.index++

		// this will finally overrun the length of the script
		// -> execution should stop
	}

	/**
	 * Internal
	 *
	 * Reset current position to (null, 0)
	 *
	 * @return nothing
	 */
	_resetPositionToNothing() {
		this.position.scene = null
		this.position.index = 0
	}

	/**
	 * Internal
	 *
	 * Reset current position index to 0 using _setPosition()
	 *
	 * @return nothing
	 */
	_setPositionIndex() {
		this.position.index = 0
	}

	/**
	 * Internal
	 *
	 * Sets the current position to beginning of scene using
	 * _setPosition()
	 *
	 * @param { string } sceneName
	 * @return nothing
	 */
	_setPositionToSceneStart(sceneName) {
		this._setPosition(sceneName, 0)
	}

	/**
	 * Internal
	 *
	 * Sets current position to specific scene and index
	 *
	 * Used by other _setPosition..() methods
	 *
	 * @param { string } sceneName
	 * @param { integer } index
	 * @return nothing
	 */
	_setPosition(sceneName, index) {
		if ( !isDef(this[sceneName]) || sceneName == "version" ) {
			let allowedScenes = Object.keys(this).join(', ').filter( x => x !== "version" )

			throw `Script._setPosition() unknown argument scene: '${ sceneName }.\n\nAllowed scenes: ${ allowedScenes }'`
		}

		if ( !isInteger(index) ) {
			throw `Script._setPosition() argument index is not an integer. Got: ${ index }`
		}

		if ( index < 0 || index >= this[sceneName].length ) {
			throw `Script._setPosition() argument index out of bounds: ${ index } < 0 or larger than scene length.`
		}

		this.position.scene = sceneName
		this.position.index = index
	}
}
