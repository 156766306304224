/**
 * UI classes
 */
import { sleep, log, debug, error, generateId } from 'global.js'

import { Game } from 'Game/Game.mjs'

import { T } from 'Game/Translations.mjs'

import { CustomElement } from 'UI/CustomElement.mjs'

import { ViewLayer } from 'UI/ViewLayer.mjs'
import { ViewLoader } from 'UI/ViewLoader.mjs'
import { ViewGameboard } from 'UI/ViewGameboard.mjs'
import { ViewDialogue } from 'UI/ViewDialogue.mjs'
import { ViewSlideshow } from 'UI/ViewSlideshow.mjs'

import { ViewAttack } from 'UI/ViewAttack.mjs'

import { ViewItem } from 'UI/ViewItem.mjs'
import { ViewDoor } from 'UI/ViewDoor.mjs'
import { ViewPopup } from 'UI/ViewPopup.mjs'
import { ViewMessage } from 'UI/ViewMessage.mjs'

import { ViewCustom } from 'UI/ViewCustom.mjs'
import { ViewCustomContent } from 'UI/ViewCustomContent.mjs'

import { AudioPlayer } from 'UI/AudioPlayer.mjs'


export const ACTION_SEPARATOR = ','

/**
 * UI class allows buttons and other elements to manipulate the Game state
 * and trigger actions
 *
 * @param {element} App element
 */
export class UI {
	static global

	activeElement;
	views = {};

	/**
	 * @param { shadowRoot } rootElement
	 */
	constructor(appRoot, gameStyles) {
		this.appRoot = appRoot
		this.appElement = appRoot.host
		this.gameStyles = gameStyles

		if ( UI.global ) { throw "UI.constructor(): UI.global singleton is already defined – only one UI can exist at a time!" }

		UI.global = this

		// Chrome has a feature where pressing space scrolls the view
		document.addEventListener("keypress", (event)=>{
			if (event.keyCode == 32 ) {
				event.preventDefault()
			}
		})

		// Send all keypresses to current view
		document.addEventListener("keyup", (event)=>{
			let currentView = this.getCurrentView()

			currentView?.key?.(event)
		})
	}

	/**
	 * @returns ViewLayer or null
	 */
	getCurrentView() {
		let views = this.appRoot.querySelectorAll('.view.visible')

		if ( views.length > 0 ) {
			return views.item(views.length-1)
		}

		return null
	}

	/**
	 * Setups the UI elements within the parent appRoot, replacing
	 * all child nodes under appRoot
	 *
	 * Note:
	 * This can only be done AFTER Game.constructor() is called
	 * because attaching these View.. elements to DOM will trigger
	 * their constructor() calls that use Game.global
	 */
	async setup(preloadViews, boardWidth, boardHeight, referenceTileWidth, referenceTileHeight, usePlayerTurnSystem, boardContentScale) {

		for ( let id of preloadViews ) {
			this.createView(id)
		}

		if ( boardWidth && boardHeight && referenceTileWidth && referenceTileHeight ) {
			this.views.gameboard.setup(boardWidth, boardHeight, referenceTileWidth, referenceTileHeight, usePlayerTurnSystem, boardContentScale)
		} else {
			log(`UI.setup() skipping board setup`)
		}


		this.resizeDetectTime = 500 //ms
		this.resizeDetectTimeout = null

		var stupidjs = this
		window.onresize = (event) => { stupidjs.onResizeDetect(event) }

	}

	createView(id) {
		if ( this.views[id] ) {
			throw `UI.createView() view already exists with id: ${id}`
		}

		let view

		switch(id) {
			case "loader":
				view = new ViewLoader()
				break;
			case "slideshow":
				view = new ViewSlideshow()
				break;
			case "gameboard":
				view = new ViewGameboard()
				break;
			case "dialogue":
				view = new ViewDialogue()
				break;
			case "door":
				view = new ViewDoor()
				break;

			default:
				throw `UI.createView() unknown view id: ${id}`
		}

		view.classList.add('view')
		view.style.opacity = 0
		view.id = id

		this.appRoot.append(view)
		this.views[id] = view

		return view
	}

	createCustomView(id) {
		if ( this.views[`custom-${id}`] ) {
			throw `UI.createView() view already exists with id: ${id}`
		}

		let view = new ViewCustom()
		view.classList.add('view')
		view.style.opacity = 0
		view.id = id

		this.appRoot.append(view)
		this.views[`custom-${id}`] = view

		return view
	}

	createCustomContentView(id) {
		if ( this.views[`custom-content-${id}`] ) {
			throw `UI.createView() view already exists with id: ${id}`
		}

		let view = new ViewCustomContent()
		view.classList.add('view')
		view.style.opacity = 0
		view.id = id

		this.appRoot.append(view)
		this.views[`custom-content-${id}`] = view

		return view
	}


	getCustomView(id) {
		return this.views[`custom-${id}`]
	}

	getCustomContentView(id) {
		return this.views[`custom-content-${id}`]
	}

	deleteCustomView(id) {
		let viewName = `custom-${id}`
		const view = this.views[viewName]

		if ( view ) {
			this.appRoot.remove(view)

			delete this.views[viewName]
		}
	}

	async closeInGameViewsAndReturnToMainmenu(animate) {

		let promiseDialogue = this.hideDialogue(animate)
		let promiseAction = this.hideAction(animate)
		let promiseSlideshow = this.hideSlideshow(animate)

		await promiseDialogue
		await promiseAction
		await promiseSlideshow

		let promiseBoard = this.hideGameboard(animate)
		let promiseActionMenu = this.hideActionMenu(animate)

		await promiseBoard
		await promiseActionMenu

		this.showMainMenu(animate)
	}

	/**
	 * Fade to black and remove all contents of appRoot
	 * 
	 * @return { Promise }
	 */
	exitUI() {
		return new Promise( (resolve, reject) => {

			this.appElement.style.transition = "opacity 1s linear !important"
			this.appElement.style.opacity = 0

			setTimeout((x) => {
				this.appRoot.innerHTML = '';
				this.appElement.innerHTML = '';
				resolve()
			}, 1100)
		})
	}

	onResizeEnd(){
		for (let view in this.views) {
			this.views[view]?.onScreenResize?.(this.getScreenWidth(), this.getScreenHeight())
		}

		this.resizeDetectTimeout = null
	}

	onResizeDetect(event) {
		clearTimeout(this.resizeDetectTimeout)
		this.resizeDetectTimeout = setTimeout(() => { this.onResizeEnd() }, this.resizeDetectTime)
	}

	getAppElement() {
		return this.appRoot
	}

	getScreenWidth() {
		let viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

		return viewWidth
	}

	getScreenHeight() {
		let viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

		return viewHeight
	}

	/**
	 * @param {integer} max
	 * @param {integer} current (optional)
	 */
	getLoader(max, current) {
		return this.views.loader.reset(max, current)
	}

	async showLoader(animate) {
		if ( !this.views.loader ) {

		}
		await this.views.loader.show(animate)
	}

	async hideLoader(animate) {
		await this.views.loader.hide(animate)
	}

	async showMainMenu(animate) {
		await this.views.mainmenu.show(animate)
	}

	async hideMainMenu(animate) {
		await this.views.mainmenu.hide(animate)
	}

	/**
	 * @param {string} name of the slide to show
	 * @param {boolean} animate transition to the view
	 */
	async showSlideshow(name, animate) {
		await this.views.slideshow.showSlides(name, {}, Game.global.language)
	}

	async hideSlideshow(animate) {
		await this.views.slideshow.hide(animate)
	}

	async showDialogue(animate) {
		await this.views.dialogue.show(animate)
	}

	async hideDialogue(animate) {
		await this.views.dialogue.hide(animate)
	}


	/**
	 * Available modes:
	 * – interactive: show action menu, enable board interaction
	 * - half-n-half: show action menu, disable board interaction
	 * – presentation: hide action menu, disable board interaction
	 *
	 * Remember to re-enable board after doing a presentation on it.
	 *
	 * @param {string} mode one of: interactive || presentation
	 * @param {Space} [space]
	 * @param {boolean} [animate] transition to the view
	 */
	async showGameboard(mode, space, animate) {

		await this.changeBoardMode(mode, animate)

		await this.views.gameboard.show(space, animate)
	}


	/**
	 * @param {string} name template
	 * @param {string} [variable]
	 * @param {boolean} [returnAfterShow] return immediately after show
	 * @param {boolean} [waitForHideDuringAction] wait for hide during action
	 * @param {boolean} [animate] transition to the view
	 */
	async showCustomView(name, variable, returnAfterShow, waitForHideDuringAction, animate) {

		let view = this.getCustomView(name)

		if ( !view ) {
			view = this.createCustomView(name)
		}

		await view.show(name, variable, returnAfterShow, waitForHideDuringAction, animate)
	}

	/**
	 * @param {string} name template
	 * @param {object} content
	 * @param {string} [variable]
	 * @param {boolean} [returnAfterShow] return immediately after show
	 * @param {boolean} [waitForHideDuringAction] wait for hide during action
	 * @param {boolean} [animate] transition to the view
	 */
	async showCustomContentView(name, content, variable, returnAfterShow, waitForHideDuringAction, animate) {

		let view = this.getCustomContentView(name)

		if ( !view ) {
			view = this.createCustomContentView(name)
		}

		await view.show(name, content, variable, returnAfterShow, waitForHideDuringAction, animate)
	}



	async changeBoardMode(mode, animate) {
		if ( mode == "interactive" ) {
			this.views.gameboard.enableInteractions()
			await this.showActionMenu(true, animate)
		}

		else if ( mode == "half-n-half") {
			this.views.gameboard.disableInteractions()
			await this.showActionMenu(true, animate)
		}

		else if ( mode == "presentation" ) {
			this.views.gameboard.disableInteractions()
			await this.hideActionMenu(false, animate)
		}
	}

	async hideGameboard(animate) {
		await this.views.gameboard.hide(animate)
	}

	async showActionMenu(animate) {
		await this.views.gameboard.showActionMenu(animate)
	}

	async hideActionMenu(animate) {
		await this.views.gameboard.hideActionMenu(animate)
	}

	async showOverlayMenu(animate) {
		await this.views.gameboard.showOverlayMenu(animate)
	}

	async hideOverlayMenu(animate) {
		await this.views.gameboard.hideOverlayMenu(animate)
	}


	showPlayerMenu() {
		this.views.gameboard.showPlayerMenu()
	}

	showWorldMenu() {
		this.views.gameboard.showWorldMenu()
	}

	showPopup(contentId, variables, doNotWaitForClose, animate, sfxOverride) {
		let popup = new ViewPopup()
		popup.setAttribute('id', contentId)
		popup.style.opacity = 0

		this.appRoot.append(popup)

		if ( !doNotWaitForClose ) {
			let promise = new Promise((resolve, reject) => {

				popup.addCallbackAfterHide('showPopup', resolve)
				popup.show(contentId, variables || Game.global.variables, animate, sfxOverride)
			})

			return promise
		} else {
			return popup.show(contentId, variables || Game.global.variables, animate, sfxOverride)
		}
	}

	async hidePopup(id) {
		let popup = this.appRoot.querySelector(`view-popup[id="${ id }"]`)

		await popup.hide()
	}

	showMessage(message, variables, animate, sfxOverride) {
		let popup = new ViewMessage()
		popup.setAttribute('id', `message-${generateId(4)}`)
		popup.style.opacity = 0

		this.appRoot.append(popup)

		let promise = new Promise((resolve, reject) => {

			popup.addCallbackAfterHide('showMessage', resolve)
			popup.show(message, variables || Game.global.variables, animate, sfxOverride)
		})

		return promise
	}

	async hideMessage(id) {
		let popup = this.appRoot.querySelector(`view-message[id="${ id }"]`)

		await popup.hide()
	}

	async showItemView(itemId, messageId, backgroundId, animate) {
		let item = Game.global.getThing(itemId)

		let background = backgroundId ? Game.global.getAsset(backgroundId) : null

		let message = messageId ? T(messageId) : item.getDescription()

		let itemView = new ViewItem()

		await this.appRoot.append(itemView)

		await itemView.show(item, message, background, animate)
	}

	async hideItemView(itemId) {
		let modal = this.appRoot.querySelector(`view-item[id="${ itemId }"]`)

		await modal.hide()
	}

	/**
	 * @param {Character} enemy
	 * @param {Asset} background
	 * @param {object} variables
	 * @param {boolean} [onlyAttack=false]
	 * @param {boolean} [animate]
	 */
	async showAttackView(enemy, background, variables, onlyAttack, animate) {
		let attackView = new ViewAttack()

		await this.appRoot.append(attackView)

		await attackView.show(enemy, background, variables, onlyAttack, animate)
	}

	async hideAttackView(enemyId) {
		let modal = this.appRoot.querySelector(`view-attack[id="${ enemyId }"]`)

		await modal.hide()
	}

}

