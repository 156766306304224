import { sleep, log, warn, error, debug, replaceTransVars } from 'global.js'
import { objectHasOnly, objectHas, isNull, isString, isBoolean, isInteger, isObject, isArray, isDef } from 'validators.mjs'

import { Game } from 'Game/Game.mjs'
import { Character } from 'Thing/Characters.mjs'
import { Door } from 'Thing/Door.mjs'
import { Item } from 'Thing/Item.mjs'
import { Mission } from 'Game/Mission.mjs'

import { Command } from 'Command/Command.mjs'
import { CommandChain } from 'Command/Chain.mjs'

import { createEvent } from 'Command/create.mjs'


/**
 * If command.
 *
 * When evaluate() is true, the next command in the CommandChain can be executed.
 *
 * When evaluate() is false, this will break execution of CommandChain.
 *
 * { "if": [ "any-player", "inventory", "includes", "key-to-the-garden" ] }
 *
 * { "if": [ <target>, <property>, <operation>, <comparison value> ]}
 *
 */
const IF_ALLOWED = ['if', 'event', 'then', 'else']
const IFANY_ALLOWED = ['ifAny', 'event', 'then', 'else']
const IFALL_ALLOWED = ['ifAll', 'event', 'then', 'else']

const IF_TARGET_REGEX = /^(variable|interaction|slideshow|mission|player|current\-player|space|item|npc|monster|door):(\{?\{?[\_\-a-z0-9]+\}?\}?)$/i

const IF_ANY_TARGETS = ["any-player", "any-space", "any-item", "any-npc", "any-monster"]
const IF_ALL_TARGETS = ["all-players", "all-spaces", "all-items", "all-npcs", "all-monsters"]
const IF_INTERACTION_TARGETS = ["interaction", "interaction:event", "interaction:item", "interaction:player", "interaction:space", "dialogue"]
const IF_DIALOGUE_TARGETS = ["dialogue", "dialogue:event", "dialogue:player", "dialogue:character"]
const IF_MISSION_TARGETS = ["mission", "mission:event", "mission:state"]
const IF_SLIDESHOW_TARGETS = ["slideshow"]
const IF_GENERAL_TARGETS = ["event", "variable", "door", "current-player", "player", "space", "item", "npc", "monster"]

const IF_CHARACTER_PROPS = ["id", "inventory", "alive", "onboard", "state", "action"]
const IF_OBJECT_PROPS = ["id", "broken"]
const IF_SPACE_PROPS = ["id", "visible"]
const IF_DOOR_PROPS = ["id", "locked", "state"]
const IF_INTERACTION_PROPS = ["id", "type", "player", "monster", "item", "space"]
const IF_DIALOGUE_PROPS = ["player", "dialogue", "characters", "choices"]
const IF_SLIDESHOW_PROPS = ["player", "slideshow", "characters", "choices"]
const IF_MISSION_PROPS = ["id", "type"]
const IF_SET_PROPS = ["values", "length"]
const IF_EVENT_PROPS = ["name", "variable"]

const IF_STRING_OPS = ['eq', '!eq', 'defined']
const IF_BOOL_INT_OPS = ['eq', '!eq', 'gt', 'gte', '!gt', '!gte', 'lt', 'lte', '!lt', '!lte']
const IF_ARRAY_OPS = ['includes', '!includes', 'isEmpty', '!isEmpty']

const IF_TARGETS = IF_ANY_TARGETS.concat(IF_ALL_TARGETS, IF_INTERACTION_TARGETS, IF_DIALOGUE_TARGETS, IF_MISSION_TARGETS, IF_GENERAL_TARGETS)
const IF_PROPS = IF_CHARACTER_PROPS.concat(IF_EVENT_PROPS, IF_OBJECT_PROPS, IF_DOOR_PROPS, IF_SPACE_PROPS, IF_INTERACTION_PROPS, IF_DIALOGUE_PROPS, IF_SLIDESHOW_PROPS, IF_MISSION_PROPS, IF_SET_PROPS)
const IF_OPS = IF_STRING_OPS.concat(IF_BOOL_INT_OPS, IF_ARRAY_OPS)


export class If extends Command {
	static optionalKeys = ["then", "else"]

	constructor(object) {
		super(object)

		this.validate()

		if ( object.then ) {
			this.then = new CommandChain(object.then)
		}
		if ( object.else ) {
			this.else = new CommandChain(object.else)
		}
	}

	setEvent(eventObject) {
		super.setEvent(eventObject)

		if ( super.hasEvent() ) {
			this.then?.setEvent(super.getEvent())
			this.else?.setEvent(super.getEvent())
		}
	}

	execute(variables) {
		return this.evaluate(variables)
	}

	/**
	 * Convenience wrapper for evaluateOne()
	 *
	 * Note: incase an IfAll or IfAny has an 'event' attribute, it can be
	 * overriden within the enclosed If statement if it has an 'event'
	 * attribute as well.
	 *
	 * @param { object } variables as key-value pairs
	 * @return { boolean } result
	 */
	evaluate(variables) {
		if ( this instanceof IfAll ) {
			for ( let ifObject of this.ifAll ) {

				let event

				if ( ifObject.event ) {
					event = createEvent(ifObject.event)
				} else {
					event = this.getEvent()
				}

				let result = this.evaluateOne(ifObject, variables, event)

				if ( !result ) {
					return false
				}
			}

			return true
		}

		else if ( this instanceof IfAny ) {
			for ( let ifObject of this.ifAny ) {

				let event

				if ( ifObject.event ) {
					event = createEvent(ifObject.event)
				} else {
					event = this.getEvent()
				}

				let result = this.evaluateOne(ifObject, variables, event)

				if ( result ) {
					return true
				}
			}

			return false
		}

		else if (this instanceof If ) {

			let event = this.getEvent()

			return this.evaluateOne(this.if, variables, event)
		}

		throw `If.evaluate(): object is not an If-class object. Got: ${ this }`
	}


	/**
	 * Actually do the If statement evaluation.
	 *
	 * If condition consists of four elements:
	 *
	 * [ "target", "property", "operator", <value> ]
	 *
	 * Examples:
	 * 
	 * [ "variable", "GLOBAL_COUNTER_PLAYER_TURN", "gt", 10 ]
	 * [ "any-player", "inventory", "includes", "key-to-garden" ]
	 * [ "space:some-room", "visible", "eq", true ]
	 *
	 * Additionally any of the elements may includes the {{variable}} string
	 * and it will swapped during execution.
	 *
	 * If the If statement is executed in an event, it may be included.
	 *
	 * @param { array } condition as array of 4 parameters
	 * @param { object } variables (optional) as key-value pairs
	 * @param { object } event (optional)
	 * @return { boolean } result
	 */
	evaluateOne(ifObject, variables, event) {

		const [strTarget, strProperty, strOp, strValue] = ifObject

		let targetSelector = replaceTransVars(strTarget, variables)
		let propertySelector = replaceTransVars(strProperty, variables)
		let operator = replaceTransVars(strOp, variables)
		let value = replaceTransVars(strValue, variables)

		let [ rule, targets ] = this.getTargets(targetSelector, propertySelector, event)

		for ( let target of targets ) {

			let targetValue = target
			let result

			if ( isObject(target) || isArray(target) ) {
				targetValue = this.getProperty(propertySelector, target)
			}
			
			if ( isArray(targetValue) ) {
				result = this.compareMultiple(targetValue, operator, value)
			} else {
				result = this.compare(targetValue, operator, value)
			}

			if ( rule === "any" && result ) {
				return true
			}

			if ( rule === "all" && !result ) {
				return false
			}
		}

		if ( rule === "any" ) {
			return false
		}

		if ( rule === "all" ) {
			return true
		}

		throw `If.evaluateOne(): odd failure with if-command: ${ JSON.stringify( ifObject ) }`
	}

	// [ 'foo', 'bar' ], 'includes', 'bar'
	compareMultiple(lefts, op, right) {
		if ( !isArray(lefts) ) {
			lefts = [ lefts ]
		}

		const opOnly = op.startsWith('!') ? op.substring(1) : op
		let result

		switch (opOnly) {
			case "includes":
				result = lefts.find( (value) => { return this.compare(value, 'eq', right) } ) ? true : false
				break

			case "isEmpty":
				result = this.compare(lefts.length == 0, 'eq', right)
				break

			default:
				throw `If.compareMultiple(): unknown operator: ${opOnly}.\n\nGot: ${ JSON.stringify(this) }`

		}

		if ( op.startsWith('!') ) {
			result = !result
		}

		return result
	}

	// 'foo', 'eq', 'bar'
	compare(left, op, right) {
		const opOnly = op.startsWith('!') ? op.substring(1) : op
		let result

		switch( opOnly ) {
			case "defined":
				result = right ? left != null : left == null
				break

			case "eq":
				result = left == right
				break

			case "gt":
				result = left > right
				break

			case "gte":
				result = left >= right
				break

			case "lt":
				result = left < right
				break

			case "lte":
				result = left <= right
				break

			default:
				throw `If.compare(): unknown operator: ${opOnly}.\n\nGot: ${ JSON.stringify(this) }`
		}

		if ( op.startsWith('!') ) {
			result = !result
		}

		return result
	}

	getProperty(propertySelector, target) {

		let emptyOrTarget = target ? target : {}

		switch(propertySelector) {
			case "inventory":
				// Todo: add split : support for propertySelector too
				// so this would be 'inventory:id'
				return Object.values(emptyOrTarget.items).map(x => x.id)

			case "choices":
				return emptyOrTarget.choices ? Array.from(emptyOrTarget.choices) : []

			case "type":
				return emptyOrTarget.type

			case "visible":
				return emptyOrTarget.isVisible()

			case "dialogue":
				return emptyOrTarget.dialogue

			case "characters":
				return emptyOrTarget.characters

			case "slideshow":
				return emptyOrTarget.slideshow

			case "name":
				return emptyOrTarget.name

			case "id": // any
				return emptyOrTarget.id

			case "action":
				return emptyOrTarget.action

			case "state":
				return emptyOrTarget instanceof Mission ? emptyOrTarget.currentState : emptyOrTarget.state

			case "locked":
				if ( !(emptyOrTarget instanceof Door) ) {
					warn `If.getProperty(): if-statement for 'locked' used for non-door target.\n\nTarget: ${ JSON.stringify(target) }`
					return
				}				
				return emptyOrTarget.isLocked()

			case "alive":
				if ( !(emptyOrTarget instanceof Character) ) {
					warn `If.getProperty(): if-statement for 'alive' used for non-character target.\n\nTarget: ${ JSON.stringify(target) }`
					return
				}
				return emptyOrTarget.isAlive()

			case "broken":
				if ( !(emptyOrTarget instanceof Item) && !(emptyOrTarget instanceof Door) ) {
					warn `If.getProperty(): if-statement for 'broken' used for non-object (item, door) target.\n\nTarget: ${ JSON.stringify(target) }`
					return
				}
				return emptyOrTarget.isBroken()

			case "value":
				if ( emptyOrTarget instanceof Set ) {
					return [ emptyOrTarget ]
				} else {
					return emptyOrTarget
				}

			case "length":
				if ( !(emptyOrTarget instanceof Set) && !isArray(emptyOrTarget)) {
					warn `If.getProperty(): if-statement for 'length' used on variable that is not an array or Set - ignoring this.\n\nTarget: ${ JSON.stringify(target) }`
					return
				}
				return Array.from(emptyOrTarget).length

			default:
				break
		}

		throw `If.getProperty(): if-statement has unsupported properySelector.\n\nGot: ${ propertySelector }`
	}

	getTargets(targetSelector, propertySelector, event) {
		let targets, typeSelector, filter
		let rule = "all"
		let split = IF_TARGET_REGEX.exec(targetSelector)

		if ( split ) {
			typeSelector = split[1]
			filter = split[2]

			if ( typeSelector == "interaction" || typeSelector ==  "dialogue") {
				if ( !(event instanceof Event) ) {
					throw `If.getTargets(): if-statement with 'interaction' used when not in an event CommandChain.\n\nTarget: ${ JSON.stringify(this) }`
				}
			}
		} else {
			typeSelector = targetSelector
		}

		switch ( typeSelector )  {
			case "event":
				targets = [ event ]
				break

			case "interaction":
				switch ( filter ) {
					case "player":
						targets = [ event.player ]
						break

					case "item":
						targets = [ event.item ]
						break

					case "space":
						targets = [ event.space ]
						break

					case "event":
						targets = [ event.event ]
						break

					default:
						targets = [ event.interaction ]
				}

				break

			case "dialogue":
				switch ( filter ) {
					case "player":
						targets = [ event.player ]
						break

					case "character":
						targets = [ event.character ]
						break

					case "event":
						targets = [ event ]
						break

					default:
						targets = [ event.dialogue ]
				}

				break

			case "mission":
				targets = [ Game.global.getMission(filter) ]
				break

			case "current-player":
				if ( !Game.global.getConfig('enable-player-turn-system') ) {
					throw `If.getTargets() target 'current-player' doesn't work if 'enable-player-turn-system' configuration isn't enabled.`
				}
				targets = [ Game.global.getCurrentPlayer() ]
				break

			case "player":
				targets = [ Game.global.getPlayer(filter) ]
				break

			case "item":
				targets = [ Game.global.getItem(filter) ]
				break

			case "npc":
				targets = [ Game.global.getNpc(filter) ]
				break

			case "monster":
				targets = [ Game.global.getMonster(filter) ]
				break

			case "door":
				targets = [ Game.global.getDoor(filter) ]
				break

			case "space":
				targets = [ Game.global.getSpace(filter) ]
				break

			case "all-players":
				targets = Game.global.getAllPlayers()
				break

			case "any-player":
				rule = "any"
				targets = Game.global.getAllPlayers()
				break

			case "all-items":
				targets = Game.global.getAllItems()
				break

			case "any-item":
				rule = "any"
				targets = Game.global.getAllItems()
				break

			case "all-spaces":
				targets = Game.global.getAllSpaces()
				break

			case "any-space":
				rule = "any"
				targets = Game.global.getAllSpaces()
				break

			case "all-npcs":
				targets = Game.global.getAllNpcs()
				break

			case "any-npc":
				rule = "any"
				targets = Game.global.getAllNpcs()
				break

			case "all-monsters":
				targets = Game.global.getAllMonsters()
				break

			case "any-monster":
				targets = Game.global.getAllMonsters()
				break

			case "variable":
				// it's important that this may return array in array
				// so that "includes" will use compareMultiple() instead of
				// compare()
				let value = Game.global.getGlobalVariable(filter)
				if ( !isDef(value) ) { throw `If.getTargets() variable '${filter}' not found` }
				targets = isNull(value) ? [] : [ value ]
				break

			default:
				throw `If.getTargets() unknown target for if condition: ${ typeSelector }\n\n`
		}

		return [ rule, targets || [] ]
	}

	validate() {
		const me = "If.validate(): "

		if ( this.constructor == If ) {
			if ( !objectHas(this, IF_ALLOWED) ) {
				throw me + `unknown attributes in the object.\n\nAllowed: ${ IF_ALLOWED }\n\nObject definition: ${ JSON.stringify(this) }`
			}

			if ( !isArray(this.if) ) {
				throw me + `attribute "if" is not an array.\n\nObject definition: ${ JSON.stringify(this) }`
			}

			this.validateCondition(this.if)

		} else if ( this.constructor == IfAny ) {
			if ( !objectHas(this, IFANY_ALLOWED) ) {
				throw me + `unknown attributes in the object.\n\nAllowed: ${ IFANY_ALLOWED }\n\nObject definition: ${ JSON.stringify(this) }`
			}

			if ( !isArray(this.ifAny) ) {
				throw me + `attribute "ifAny" is not an array.\n\nObject definition: ${ JSON.stringify(this) }`
			}

			for ( let condition of this.ifAny ) {
				this.validateCondition(condition)
			}
		}

		else if ( this.constructor == IfAll ) {
			if ( !objectHas(this, IFALL_ALLOWED) ) {
				throw me + `unknown attributes in the object.\n\nAllowed: ${ IFALL_ALLOWED }\n\nObject definition: ${ JSON.stringify(this) }`
			}

			if ( !isArray(this.ifAll) ) {
				throw me + `attribute "ifAll" is not an array.\n\nObject definition: ${ JSON.stringify(this) }`
			}

			for ( let condition of this.ifAll ) {
				this.validateCondition(condition)
			}
		}

		if ( this.then && !isArray(this.then) && !(this.then instanceof CommandChain)  ) {
			throw me + `attribute 'then' is not an Array nor Chain. Got: ${this.then}`
		}

		if ( this.else && !isArray(this.else) && !(this.else instanceof CommandChain) ) {
			throw me + `attribute 'else' is not an Array nor Chain. Got: ${this.else}`
		}
	}

	validateCondition(condition) {
		const me = "If.validateCondition(): "

		let [target, property, operator, value] = condition

		// wrap target and value in arrays if not
		// and make sure we didn't get empty arrays from 'cmd.if'
		//
		if ( !isArray(target) ) { target = [target] }

		if (target.length < 1 ) {
			throw me + `condition 'target' array is empty.\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
		}

		if ( !isArray(value) ) { value = [value] }

		if (value.length < 1 ) {
			throw me + `condition 'value' array is empty.\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
		}

		// continue with normal checks
		//
		for ( let i = 0 ; i < target.length ; i++ ) {

			let typeSelector = target[i]
			let filter = null
			let split = IF_TARGET_REGEX.exec(typeSelector)

			if ( split ) {
				typeSelector = split[1]
				filter = split[2]
			}

			if ( !isString(typeSelector) && !isInteger(typeSelector) && !isBoolean(typeSelector) ) {
				throw me + `condition 'target' array element #${i} is not a string|integer|boolean.\n\nGot:\n${ typeof typeSelector } (type)\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
			}

			if ( !IF_TARGETS.includes(typeSelector) && !IF_TARGET_REGEX.exec(typeSelector)) {
				throw me + `condition 'target' not recognized.\n\nGot:\n${ typeSelector }\n\nAllowed:\n${ IF_TARGETS }\n–or–\n${ IF_TARGET_REGEX }\n\nYour condition: ${ condition }`
			}

			if ( typeSelector !== "variable" && !IF_PROPS.includes(property) ) {
				throw me + `condition 'property' not recognized.\n\nGot:\n${ property }\n\nAllowed:\n${ IF_PROPS }\n\nYour condition: ${ condition }`
			}
		}

		if ( !isString(property) ) {
			throw me + `condition 'property' is not a string.\n\nGot:\n${ property }\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
		}

		if ( !isString(operator) ) {
			throw me + `condition 'operator' is not a string.\n\nGot:\n${ operator }\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
		}

		if ( !IF_OPS.includes(operator) ) {
			throw me + `condition 'operator' not recognized.\n\nGot:\n${ operator }\n\nAllowed:\n${ IF_OPS }\n\nYour condition: ${ condition }`
		}

		for ( let i = 0 ; i < value.length ; i++ ) {
			if ( !isString(value[i]) && !isInteger(value[i]) && !isBoolean(value[i]) ) {
				throw me + `condition 'value' array element #${i} is not a string|integer|boolean.\n\nGot:\n${ typeof value[i] } (type)\n\nRequired if-cmd:\n{ if: [ <target>, <property>, <operator>, <value> ] }\n\nYour condition:\n${ condition }`
			}
		}
	}

	hasThen() {
		return this.then ? true : false
	}

	getThen() {
		return this.then
	}

	hasElse() {
		return this.else ? true : false
	}

	getElse() {
		return this.else
	}
}


/**
 * IfAll command: all conditions must evaluate() to true

	{ "ifAll": [
		[ "all-players", "player.alive", "eq", true ],
		[ "any-player", "inventory", "!includes", "some-document" ]
	]},

*/
export class IfAll extends If {
	/* If uses instanceof to detect this child class */
}


/**
 * IfAny command: atleast one condition must evaluate() to true

	{ "ifAny": [
		[ "all-players", "player.alive", "eq", false ],
		[ "all-players", "monster.alive", "eq", false ]
	]},

*/
export class IfAny extends If {

	/* If uses instanceof to detect this child class */
}
