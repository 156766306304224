import { sleep, log, warn, error, mixin } from 'global.js'
import { isInteger } from 'validators.mjs'
import { UI } from 'UI/UI.mjs'
import { HasAssets } from 'Thing/HasAssets.mjs'
import { HasProps } from 'Thing/HasProps.mjs'

import { ThingManager } from 'Thing/ThingManager.mjs'

import { T } from 'Game/Translations.mjs'

export class Space extends mixin(HasAssets, HasProps) {
	constructor(id, specs) {
		super()

		this.id = id
		this.name = T(specs.name)
		this.cx = specs.cx
		this.cy = specs.cy

		Object.defineProperty(this, 'contains', { value: {}, writable: true })

		/* TODO: try to refactor this into HasAssets - doesn't seems to work with mixin() */
		Object.defineProperty(this, 'assets', { value: {}, writable: true })

		if ( specs.assets ) {
			this.addAssets(specs.assets)
		}
	}

	getActionAsset() {
		if ( !this.assets.action) {
			return null
		}

		if ( typeof this.assets.action == 'object' ) {
			return this.assets.action
		}

		throw `Space.getActionAsset: my action asset isn't loaded`
	}

	getTileAsset() {
		if ( !this.assets.tile ) {
			warn(`Space.getTileAsset() i don't have an action asset. Id: ${ this.id }`)
			return null
		}

		if ( typeof this.assets.tile == 'object') {
			return this.assets.tile
		}

		throw `Space.getTileAsset: my tile asset isn't loaded`
	}

	addThing(thing, stopPropagate) {
		this.contains[thing.id] = thing

		if ( !stopPropagate ) {
			thing.addToSpace(this, true)
		}

	}

	removeThing(thing, stopPropagate) {
		if ( !stopPropagate ) {
			thing.removeFromSpace(true)
		}

		delete this.contains[thing.id]
	}

	getThings() {
		let things = []

		for ( let id of this.contains ) {
			things.push(this.contains[id])
		}

		return things
	}

	setCenterX(value) {
		if ( !isInteger(value) || value < 0 ) {
			throw `Space.setCenterX() value is not a positive integer. Got: ${ value }`
		}

		this.cx = value
	}

	getCenterX() {
		return this.cx
	}

	setCenterY(value) {
		if ( !isInteger(value) || value < 0 ) {
			throw `Space.setCenterY() value is not a positive integer. Got: ${ value }`
		}

		this.cy = value
	}

	getCenterY() {
		return this.cy
	}

	isVisible() {
		return UI.global.views.gameboard.board.hasSpace(this.getIdForBoard())
	}	
}
